import type { ReactElement, PropsWithChildren } from 'react';
import {} from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { Flex, Icon, SubtitleHeader } from '~/components';
import { colors } from '~/styles';
import DropdownClose from '~/public/icons/Navigation/Down.svg';
import DropdownOpen from '~/public/icons/Navigation/Up.svg';

type Props = PropsWithChildren<{
  label: string;
  value: ReactElement | string;
}>;

export function MinimalDropdown({
  children,
  label,
  value,
}: Props): ReactElement {
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  return (
    <Flex justify="space-between" align="center" mb={4}>
      <SubtitleHeader>{label}</SubtitleHeader>
      <Menu placement="bottom-end" variant="small">
        {/* @ts-ignore */}
        {({ isOpen }) => (
          <>
            <MenuButton fontSize="14px">
              <Flex justify="end" align="center">
                {value}
                <Icon
                  icon={!isOpen ? DropdownClose : DropdownOpen}
                  fill={primary}
                  boxSize={6}
                  ml={1}
                />
              </Flex>
            </MenuButton>
            <MenuList minW="120px">{children}</MenuList>
          </>
        )}
      </Menu>
    </Flex>
  );
}
