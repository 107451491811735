import type { MapCluster } from '~/types/graphika-types';
import { BodyText, Box, Checkbox, Flex, Icon, Tooltip } from '~/components';
import TagIcon from '~/public/icons/Tag.svg';
import { colors } from '~/styles';
import { useSegmentTree } from '~/lib/stores/segment-tree';

type Props = { cluster: MapCluster; isLastChild?: boolean };

export function ClusterTreeItem({ cluster, isLastChild = false }: Props) {
  const [{ active, showLabel }, toggleCluster] = useSegmentTree((state) => [
    state.clusters[cluster.id] ?? {},
    state.toggleCluster,
  ]);
  return (
    <Flex key={cluster.id} align="center" h="32px">
      <Flex
        align="center"
        gap={3}
        flex={1}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleCluster({ id: cluster.id, param: 'active' });
        }}
        cursor="pointer"
        _hover={{ bg: colors.mapViewer.hoverBg }}
      >
        <Box
          bg={`#${cluster.hex_color}`}
          w={2}
          minW={2}
          h="32px"
          borderRadius={isLastChild ? '0 0 2px 2px' : 'none'}
        />
        <Checkbox
          variant="mapviewer"
          isChecked={!!active}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleCluster({ id: cluster.id, param: 'active' });
          }}
        />
        <Icon
          ml={-2}
          icon={TagIcon}
          fill={showLabel ? colors.white : colors.mapViewer.deselected}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleCluster({ id: cluster.id, param: 'showLabel' });
          }}
          cursor="pointer"
          boxSize={6}
        />

        <Tooltip label={cluster.name} openDelay={300} maxW="1000px">
          <span style={{ cursor: 'pointer' }}>
            <BodyText
              color={colors.mapViewer.segmentText}
              userSelect="none"
              wordBreak="break-all"
              noOfLines={1}
            >
              {cluster.name}
            </BodyText>
          </span>
        </Tooltip>
      </Flex>
    </Flex>
  );
}
