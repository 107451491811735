import type { VictoryTooltipProps } from 'victory';
import type { ChartNodeHoverInfo } from './ActivityChart';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Flex } from '~/components';
import { getDateStr } from '~/lib/date';
import { colors } from '~/styles';

type Props = VictoryTooltipProps & { hoveredSegment: ChartNodeHoverInfo };
export function ChartTooltip({ hoveredSegment }: Props) {
  const white = useColorModeValue(colors.white, colors.coolGray[2]);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray5 = useColorModeValue(colors.warmGray[5], colors.coolGray[2]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  const {
    x,
    y,
    date,
    segment: { name, hex_color },
    count,
  } = hoveredSegment;
  const width = 200;
  const estimatedYOffset = 85 + Math.ceil(name.length / 19) * 15;
  return (
    <Box
      position="fixed"
      top={y - estimatedYOffset}
      left={x - width / 2}
      width={`${width}px`}
      zIndex={3}
    >
      <Flex
        direction="column"
        border={`1px solid ${colors.warmGray[3]}`}
        padding={2}
        borderRadius={8}
        bg={white}
        gap={1}
        zIndex={2}
      >
        <BodyText fontWeight={500} fontSize="12px" color={gray1}>
          {getDateStr(date)}
        </BodyText>
        <Flex
          align="center"
          p={1}
          gap={1}
          bg={gray6}
          border={`1px solid ${gray5}`}
        >
          <Box
            minH="10px"
            minW="10px"
            bg={`#${hex_color}`}
            mx={1}
            borderRadius={2}
          ></Box>
          <BodyText
            fontWeight={500}
            color={gray1}
            lineHeight="normal"
            wordBreak={'break-all'}
          >
            {name}
          </BodyText>
        </Flex>
        <Flex gap={1}>
          <BodyText fontWeight="bold">{count ?? 0}</BodyText>
          <BodyText>Posts</BodyText>
        </Flex>
      </Flex>
    </Box>
  );
}
