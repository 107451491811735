import type { ReactElement } from 'react';
import type { Narrative, Signal } from '~/types/graphika-types';
import { Divider, useColorModeValue } from '@chakra-ui/react';
import { useMemo, useState, useRef } from 'react';
import { Box, Button, CurrentSignalPreview, Flex, Modal } from '~/components';
import { ManualAddForm } from '~/components/authoring/modals/signal-modal/manual/ManualAddForm';
import { SignalModalContext } from '~/components/authoring/modals/signal-modal/shared';
import { useUpdateSignal } from '~/queries/mutations/signal';
import { colors } from '~/styles';

type Props = { onClose: () => void; signal: Signal; narrative: Narrative };

export function EditSignalModal({
  onClose,
  signal,
  narrative,
}: Props): ReactElement {
  const [currentSignal, setCurrentSignal] = useState<Signal>(signal);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);

  const originalSignalId = useRef<number>(signal.id!);

  const preview = useMemo(
    () => ({
      currentSignal,
      setCurrentSignal,
    }),
    [currentSignal, setCurrentSignal]
  );
  const updateSignal = useUpdateSignal(narrative.id);
  const context = useMemo<SignalModalContext>(
    () => ({
      tab: 0,
      setTab: () => {},
      onClose: () => {},
      narrative,
      // @ts-ignore
      preview,
    }),
    [narrative, preview]
  );
  return (
    <SignalModalContext.Provider value={context}>
      <Modal title={`Edit Signal`} isOpen onClose={onClose} maxW="840px">
        <Flex maxH="70vh" mx={-4}>
          <Box w="50%" borderRight={`1px solid ${gray4}`}>
            <ManualAddForm signal={signal} isEditingExistingSignal />
          </Box>
          <Box w="50%" overflowY="auto">
            <CurrentSignalPreview />
          </Box>
        </Flex>
        <Box mx={-4} borderTop={`1px solid ${gray4}`} />
        <Flex justifyContent="space-between" mt={4} mb={-2}>
          <Button onClick={onClose} variant="outline" mr={2} type="button">
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateSignal.mutateAsync(
                { ...currentSignal, id: originalSignalId.current },
                {
                  onSuccess: () => {
                    onClose();
                  },
                }
              );
            }}
            disabled={!currentSignal}
            variant="primary"
            type="submit"
            isLoading={updateSignal.status === 'loading'}
          >
            Save Signal
          </Button>
        </Flex>
      </Modal>
    </SignalModalContext.Provider>
  );
}
