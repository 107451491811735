import { useMemo } from 'react';
import { Formik } from 'formik';
import yup, { object, string } from 'yup';
import { Box, Flex, Modal } from '~/components';
import { Form, FormButton, FormInput } from '~/components/elements/Forms';
import { useClipboardValue } from '~/lib/hooks';

const schema = object({
  href: string().url().required('Valid URL Is required'),
  text: string(),
});

interface Schema extends yup.InferType<typeof schema> {}

type Props = {
  onClose: () => void;
  onSubmit: ({ text, href }: Partial<Schema>) => void;
  initialValues?: Partial<Schema>;
};

export function LinkModal({ onClose, onSubmit, initialValues }: Props) {
  const clipboardValue = useClipboardValue();

  const clipboardUrlValue = useMemo(() => {
    let urlValue = clipboardValue;
    try {
      string().url().validateSync(clipboardValue);
    } catch (e) {
      urlValue = '';
    }
    return urlValue;
  }, [clipboardValue]);

  if (clipboardUrlValue === undefined) {
    return null;
  }
  return (
    <Modal title="Insert link" isOpen onClose={onClose} autoFocus isCentered>
      <Formik
        initialValues={{
          href: initialValues?.href ?? clipboardUrlValue,
          text: initialValues?.text ?? '',
        }}
        validationSchema={schema}
        onSubmit={(values: Schema) => {
          onClose();
          onSubmit(values);
        }}
        validateOnMount
      >
        <Box mt={-2}>
          <Form insideModal>
            <FormInput name="href" label="href" type="text" placeholder="" />
            <FormInput
              name="text"
              label="text"
              type="text"
              placeholder=""
              autoFocus={clipboardUrlValue !== ''}
            />
            <Flex justify="flex-end" gap={4}>
              <FormButton variant="outline" onClick={onClose}>
                Cancel
              </FormButton>
              <FormButton type="submit">Insert</FormButton>
            </Flex>
          </Form>
        </Box>
      </Formik>
    </Modal>
  );
}
