const defaultNoOfLines = 8;

function removeLastWord(str: string) {
  const words = str.split(' ');
  if (words.length === 1) {
    return str;
  }
  return words.slice(0, -1).join(' ');
}

/**
 * attempts to shorten a string to around `noOfLines`
 * this assumes a character width of around 120 chars
 */
export const truncateMdLines = (str: string, noOfLines?: number) => {
  const paragraph = str.split('\n');
  let currentLineCount = 0;
  let i = 0;
  let text = '';
  while (i < paragraph.length) {
    const currentParagraphLines = paragraph[i].match(/.{1,120}/g) || [];
    if (paragraph[i].length === 0) {
      text = text + '\n\n';
      currentLineCount++;
    } else {
      currentParagraphLines.forEach((line) => {
        if (currentLineCount < (noOfLines ?? defaultNoOfLines)) {
          text = text + line;
          currentLineCount++;
        }
      });
    }
    if (currentLineCount >= (noOfLines ?? defaultNoOfLines)) {
      text = removeLastWord(text.trim()) + '...';
      break;
    }
    i++;
  }
  return text;
};

export const capitalize = (str?: string) =>
  !str || str.length === 0 ? '' : str[0].toUpperCase() + str.substring(1);

export const replaceHtmlEntities = (str: string) => {
  return String(str)
    .replaceAll('&amp;', '&')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&quot;', `"`);
};

export const pluralize = (noun: string, count?: number) =>
  count !== 1 ? `${noun}s` : noun;

export const truncateText = (str: string, n: number) =>
  str.length > n ? `${str.slice(0, n)}...` : str;

export const chineseRegex = new RegExp(`[\u4e00-\u9fa5]`, 'g');
export const japaneseRegex = new RegExp(`/[\u3040-\u30ff]`, 'g');
export const arabicRegex = new RegExp(`[\u0600-\u06FF]`, 'g');
export const tamilRegex = new RegExp(`[\u0B80-\u0BFF]`, 'g');

export const containsNonUnicodeChars = (str: string) =>
  /[^\u0000-\uFFFF]/gu.test(str);

export const ByteOrderMark = '\uFEFF';
