import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Button, Flex, PageHeader } from '~/components';
import { boxShadows, colors } from '~/styles';
import ErrorImage from '~/public/global_error.png'; // Placeholder image

export function GlobalErrorFallback({ onReset }: { onReset: () => void }) {
  const router = useRouter();
  return (
    <Flex
      bg={useColorModeValue(colors.white, colors.coolGray[1])}
      height="100%"
      flex={1}
      m={4}
      borderRadius="16px"
      boxShadow={boxShadows.cardPopup}
      justifyContent="center"
      alignItems="center"
    >
      <Flex direction="column" alignItems="center" gap={4}>
        <NextImage
          src={ErrorImage}
          width={286}
          height={321}
          alt="Error Notification"
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <Flex direction="column" gap={1}>
          <PageHeader>Something went wrong</PageHeader>
          <BodyText>{`And chances are, it's not your fault`}</BodyText>
        </Flex>
        <Button
          onClick={() => {
            router.push({ pathname: '/' });
            setTimeout(() => onReset(), 200);
          }}
        >
          Go back
        </Button>
      </Flex>
    </Flex>
  );
}
