import { switchAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { calc, cssVar, mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

const $diff = cssVar('switch-track-diff');
const diffValue = calc.subtract($width, $height);
const $translateX = cssVar('switch-thumb-x');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyleTrack = defineStyle((props) => ({
  borderRadius: 'full',
  p: '4px',
  width: [$width.reference],
  height: [$height.reference],
  transitionProperty: 'common',
  transitionDuration: 'fast',
  bg: mode(colors.status.red, colors.status.red)(props),
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    bg: colors.warmGray[4], // Are we sure we want grey for checked and unchecked?
    opacity: 1,
    cursor: 'not-allowed',
  },
  _checked: {
    bg: mode(colors.status.green, colors.status.green)(props),
    _disabled: {
      bg: colors.warmGray[4],
    },
  },
}));

const baseStyleThumb = defineStyle({
  bg: 'white',
  transitionProperty: 'transform',
  transitionDuration: 'normal',
  borderRadius: 'inherit',
  width: [$height.reference],
  height: [$height.reference],
  _checked: {
    transform: `translateX(${$translateX.reference})`,
  },
});

const baseStyle = definePartsStyle((props) => ({
  container: {
    [$diff.variable]: diffValue,
    [$translateX.variable]: $diff.reference,
    _rtl: {
      [$translateX.variable]: calc($diff).negate().toString(),
    },
  },
  track: baseStyleTrack(props),
  thumb: baseStyleThumb,
}));

const sizes = {
  sm: definePartsStyle({
    container: {
      [$width.variable]: '1.375rem',
      [$height.variable]: '0.75rem',
    },
  }),
  md: definePartsStyle({
    container: {
      [$width.variable]: '2.5rem',
      [$height.variable]: '1rem',
    },
  }),
  lg: definePartsStyle({
    container: {
      [$width.variable]: '2.875rem',
      [$height.variable]: '1.5rem',
    },
  }),
};

const blueVariant = definePartsStyle({
  track: {
    bg: colors.g.dark,
    _checked: {
      bg: colors.g.dark,
    },
  },
});

const monochromeVariant = definePartsStyle({
  track: {
    bg: colors.black,
    opacity: 0.32,
    _checked: {
      bg: colors.black,
      opacity: 0.32,
    },
  },
});

export const Switch = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    blue: blueVariant,
    monochrome: monochromeVariant,
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
});
