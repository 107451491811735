// @ts-nocheck
import {
  ListItem,
  TextProps,
  UnorderedList,
  OrderedList,
} from '@chakra-ui/react';
import { Text, useColorModeValue } from '@chakra-ui/react';
import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { SpecialComponents } from 'react-markdown/lib/ast-to-react';
import { NormalComponents } from 'react-markdown/lib/complex-types';
import { useOriginal } from '~/lib/utils';
import { colors } from '~/styles';
import {
  BodyText,
  Box,
  Image,
  Link,
  TitleHeader,
  SubtitleHeader,
  PageHeader,
} from '../..';
import { translateMarkdown } from '../../../lib/markdown';

type Props = TextProps & {
  children: string;
  stripTags?: (keyof JSX.IntrinsicElements & string)[];
};

export function Markdown({
  children,
  stripTags: _stripTags = [],
  ...props
}: Props) {
  const mdStr = useMemo(() => translateMarkdown(children), [children]);
  const stripTags = useOriginal(_stripTags);
  const black = useColorModeValue(colors.black, colors.white);
  const highlightedTextBg = useColorModeValue(colors.g.lavender, colors.g.dark);
  const olListColor = useColorModeValue(colors.g.plum, colors.g.pale);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);

  const components: Partial<
    Omit<NormalComponents, keyof SpecialComponents> & SpecialComponents
  > = {
    p: ({ children }) => {
      return (
        <BodyText
          {...(props as any)}
          overflowY="hidden"
          sx={{
            ':not(:first-of-type)': {
              paddingTop: '1rem',
            },
          }}
        >
          {children}
        </BodyText>
      );
    },
    em: ({ children }) => {
      return (
        <BodyText as="span" {...(props as any)} fontStyle="italic">
          {children}
        </BodyText>
      );
    },
    code: ({ children }) => {
      const childrenAsStr = children as string[];
      const containsTextHighlight = childrenAsStr.filter(
        (child) => child.indexOf('!!') === 0
      );
      if (containsTextHighlight.length > 0) {
        return (
          <Text as="span" {...(props as any)} bg={highlightedTextBg}>
            {childrenAsStr.map((child) => child.replace('!!', ''))}
          </Text>
        );
      } else {
        return (
          <Text as="span" {...(props as any)} fontStyle="italic">
            {children}
          </Text>
        );
      }
    },
    a: ({ children, href }) => {
      let sanitizedHref = href;
      if (href?.indexOf('%60!!') !== -1) {
        sanitizedHref = sanitizedHref?.replace('%60!!', '').replace('%60', '');
      }
      return (
        <BodyText
          {...(props as any)}
          as="span"
          display="inline"
          onClick={(e) => e.stopPropagation()}
        >
          <Link target="_blank" href={sanitizedHref}>
            {children}
          </Link>
        </BodyText>
      );
    },
    ul: ({ children }) => {
      return <UnorderedList my={1}>{children}</UnorderedList>;
    },
    ol: ({ children }) => {
      return (
        <OrderedList my={1} ml="18px" spacing={2} color={olListColor}>
          {children}
        </OrderedList>
      );
    },
    img: ({ src, alt }) => {
      return (
        <Box>
          <Link href={src} target="_blank" rel="noopener">
            <Image
              src={src}
              alt={alt}
              maxW="80%"
              margin="1rem auto"
              maxH="50vh"
            />
          </Link>
          <BodyText
            my={2}
            textAlign="center"
            maxW="75%"
            margin="8px auto"
            color={gray1}
          >
            {alt}
          </BodyText>
        </Box>
      );
    },
    li: ({ children }) => {
      return (
        // @ts-ignore
        <ListItem
          fontSize={props.fontSize}
          textStyle="BodyText"
          color={olListColor}
          {...(props as any)}
        >
          {children}
        </ListItem>
      );
    },
    h1: ({ children }) => <PageHeader>{children}</PageHeader>,
    h2: ({ children }) => <TitleHeader>{children}</TitleHeader>,
    h3: ({ children }) => <SubtitleHeader>{children}</SubtitleHeader>,
    blockquote: ({ children }) => (
      <Box borderLeftWidth="3px" borderLeftColor={black} pl={4} my={3}>
        {children}
      </Box>
    ),
  };

  return (
    <Box>
      <ReactMarkdown
        components={Object.fromEntries(
          Object.entries(components).map(([key, value]) =>
            stripTags.includes(key as any) ? [key, () => null] : [key, value]
          )
        )}
      >
        {mdStr}
      </ReactMarkdown>
    </Box>
  );
}
