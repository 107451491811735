import type {
  MapCluster,
  MapGroup,
  MapInfo,
  MapNode,
  Platform,
} from '~/types/graphika-types';
import {
  MouseEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { DarkMode } from '@chakra-ui/react';
import { BodyText, Box, Flex, Icon } from '~/components';
import { useNodeFollows } from '~/queries';
import { formatNumber } from '~/lib/numbers';
import { colors } from '~/styles';
import CollapseIcon from '~/public/icons/editor/collapse.svg';
import ExpandIcon from '~/public/icons/editor/fullscreen.svg';
import { DownloadCSV } from './DownloadCSV';

type Props = {
  highlightedNode?: MapNode;
  nodes: MapNode[];
  groups: MapGroup[];
  clusters: MapCluster[];
  map: MapInfo;
  followType: FollowsType;
  setFollowType: (type: FollowsType) => void;
};

export type FollowsType = 'follower' | 'following';

const getFollowsWording = (platform: Platform) => {
  if (platform === 'telegram') {
    return ['Forward from', 'Forward to'];
  } else {
    return ['Followers', 'Followed'];
  }
};

export function FollowsSelection({
  highlightedNode,
  nodes,
  groups,
  clusters,
  map,
  followType,
  setFollowType,
}: Props) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [followsNodeIds, setFollowsNodeIds] = useState<string[]>([]);

  const { data: followerData = [], isFetching: isFetchingFollower } =
    useNodeFollows(
      {
        map_id: parseInt(map.id!),
        node_id: highlightedNode?.node_source_id!,
      },
      {
        query: {
          follow_type: 'follower',
        },
      },
      {
        enabled: !!highlightedNode,
      }
    );
  const { data: followingData = [], isFetching: isFetchingFollowing } =
    useNodeFollows(
      {
        map_id: parseInt(map.id!),
        node_id: highlightedNode?.node_source_id!,
      },
      {
        query: {
          follow_type: 'following',
        },
      },
      {
        enabled: !!highlightedNode,
      }
    );

  useEffect(() => {
    if (!highlightedNode) {
      setFollowsNodeIds([]);
    }
    if (!followerData.length || !followerData.length) return;
    if (followType === 'follower') {
      setFollowsNodeIds(followerData.map((node) => node.account_id));
    }
    if (followType === 'following') {
      setFollowsNodeIds(followingData.map((node) => node.account_id));
    }
  }, [followType, followingData, followerData, highlightedNode]);

  if (!highlightedNode) return null;

  return (
    <DarkMode>
      <Box
        position="fixed"
        top="48px"
        right="16px"
        bg="#222A3A"
        borderRadius={8}
        minW="342px"
        p={2}
        zIndex={10}
      >
        <Flex align="center">
          <BodyText fontWeight="700" textAlign="center" flexGrow={1}>
            Highlighting
          </BodyText>
          <Box as="button" onClick={() => setIsCollapsed(!isCollapsed)}>
            <Icon
              icon={isCollapsed ? ExpandIcon : CollapseIcon}
              fill={colors.white}
              boxSize={6}
            />
          </Box>
        </Flex>
        {!isCollapsed && (
          <Box mt={2}>
            <Flex
              gap={1}
              borderRadius={8}
              border={`1px solid ${colors.mapViewer.gray}`}
              p={1}
            >
              <SelectionWrapper
                active={followType === 'follower'}
                handleClick={() => {
                  setFollowType('follower');
                }}
              >
                {!isFetchingFollower && (
                  <Flex whiteSpace="nowrap" gap={1}>
                    <BodyText fontWeight={700}>
                      {formatNumber(followerData?.length ?? 0, {
                        format: 'COMPACT',
                      })}
                    </BodyText>
                    <BodyText>
                      {getFollowsWording(highlightedNode.platform)[0]} In-Map
                    </BodyText>
                  </Flex>
                )}
              </SelectionWrapper>
              <SelectionWrapper
                active={followType === 'following'}
                handleClick={() => {
                  setFollowType('following');
                }}
              >
                {!isFetchingFollowing && (
                  <Flex whiteSpace="nowrap" gap={1}>
                    <BodyText fontWeight={700}>
                      {formatNumber(followingData?.length ?? 0, {
                        format: 'COMPACT',
                      })}
                    </BodyText>
                    <BodyText>
                      {getFollowsWording(highlightedNode.platform)[1]} In-Map
                    </BodyText>
                  </Flex>
                )}
              </SelectionWrapper>
            </Flex>
            <DownloadCSV
              clusters={clusters}
              followsNodeIds={followsNodeIds}
              groups={groups}
              map={map}
              nodes={nodes}
            />
          </Box>
        )}
      </Box>
    </DarkMode>
  );
}

const SelectionWrapper = ({
  children,
  active,
  handleClick,
}: PropsWithChildren<{
  active: boolean;
  handleClick: MouseEventHandler<HTMLButtonElement> &
    MouseEventHandler<HTMLDivElement>;
}>) => (
  <Box
    borderRadius={4}
    bg={active ? '#37445D' : undefined}
    py="2px"
    px="10px"
    w="50%"
    h="24px"
    as="button"
    onClick={handleClick}
  >
    <Flex gap={1} justify="center">
      {children}
    </Flex>
  </Box>
);
