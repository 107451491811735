import { useState, useEffect } from 'react';

/**
 * only updates value when `truthy`
 */
export function usePersistedValue<T>(
  value: T | undefined
): [T | undefined, (newValue: T) => void] {
  const [prevValue, setPrevValue] = useState<T | undefined>(undefined);
  useEffect(() => {
    if (value) {
      setPrevValue(structuredClone(value));
    }
  }, [value]);
  return [prevValue, setPrevValue];
}
