import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { BodyText, Box } from '~/components';

type Props = { signal: Signal };

export function SignalText({ signal }: Props): ReactElement {
  const { signal_value } = signal;
  return (
    <Box>
      <BodyText fontWeight={700} fontSize="16px">
        {signal_value}
      </BodyText>
    </Box>
  );
}
