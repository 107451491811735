import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import { Box, Markdown } from '~/components';
import { tweetTextToMarkdown } from '~/lib/platform';
import { SignalCardUserSection, SignalCardImage, SignalCreatedAt } from './';

type Props = { signal: Signal<SignalPostBase> };

export function SignalPostTwitter({ signal }: Props): ReactElement {
  const {
    user: { profile_image_url, user_name, display_name },
    text,
    image_url,
  } = signal.full_target.entity!;

  return (
    <Box>
      <SignalCardUserSection
        profileImage={profile_image_url}
        topContent={display_name ?? ''}
        bottomContent={`@${user_name || 'N/A'}`}
      />
      <Markdown>{tweetTextToMarkdown(text)}</Markdown>
      {image_url && (
        <SignalCardImage
          src={image_url}
          alt={signal.notes || signal.signal_value}
          my={2}
        />
      )}
      <SignalCreatedAt signal={signal} />
    </Box>
  );
}
