import { useState } from 'react';
import { Avatar } from '@chakra-ui/react';
import { Box, Icon } from '~/components';
import { colors } from '~/styles';
import { MapNode } from '~/types/graphika-types';
import ExternalLinkIcon from '~/public/icons/ExternalLink.svg';
import { platformIcon } from './MapList';

type Props = {
  node: MapNode;
  showHoverEffect?: boolean;
  px: number;
};

export function AvatarWithPlatform({
  node,
  showHoverEffect = false,
  px,
}: Props) {
  const [isAvatarHovered, setIsAvatarHovered] = useState(false);
  return (
    <Box
      borderRadius="100%"
      border="3px solid white"
      position="relative"
      onMouseEnter={() => setIsAvatarHovered(true)}
      onMouseLeave={() => setIsAvatarHovered(false)}
    >
      {isAvatarHovered && (
        <Box position="absolute" top={3} left={3.5}>
          <Icon icon={ExternalLinkIcon} color={colors.white} />
        </Box>
      )}
      <Avatar
        src={node.profile_image_url}
        opacity={isAvatarHovered && showHoverEffect ? 0.4 : 1}
        h={`${px}px`}
        w={`${px}px`}
      />
      <Box position="absolute" bottom={-1} right={-1}>
        <Icon
          icon={platformIcon[node.platform]}
          border={`0.4px solid ${colors.warmGray[5]}`}
          h="20px"
          w="20px"
          bg="white"
          borderRadius={4}
          fill={colors.black}
        />
      </Box>
    </Box>
  );
}
