import type {
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  ReactElement,
} from 'react';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BodyText, Button, Spinner } from '~/components/elements';
import { colors } from '~/styles';

const defaultComponents = {
  Loading() {
    const mutedBorder = useColorModeValue(
      colors.warmGray[4],
      colors.coolGray[1]
    );
    const fileBg = useColorModeValue(colors.g.lightLavender, colors.g.plum);
    return (
      <Flex
        alignItems="center"
        justify="center"
        bg={fileBg}
        borderRadius={8}
        border={`1px solid ${mutedBorder}`}
        h="100%"
      >
        <Spinner size="xl" color="#DCE0FF" />
      </Flex>
    );
  },
  Complete() {
    const primary = useColorModeValue(colors.g.primary, colors.g.light);
    const fileBg = useColorModeValue(colors.g.lightLavender, colors.g.plum);
    return (
      <Flex h="100%">
        <Flex
          bg={fileBg}
          justifyContent="center"
          alignItems="center"
          width="100%"
          p={4}
          borderRadius={8}
          textStyle="BodyText"
          color={primary}
        >
          File uploaded successfully
        </Flex>
      </Flex>
    );
  },
};

type FileInputComponents = typeof defaultComponents;
export type FileInputProps = {
  dropText?: string;
  fileType: string;
  isSubmitting?: boolean;
  onChange(file: File | undefined): void;
  showComplete?: boolean;
  warningMessage?: ReactElement;
  disabled?: boolean;
  components?: FileInputComponents;
};

export function FileInput({
  dropText = 'Drop file here',
  fileType,
  isSubmitting,
  onChange,
  showComplete,
  warningMessage,
  disabled = false,
  components: _components,
}: FileInputProps) {
  const components = Object.assign({}, defaultComponents, _components);

  const { isDragging, inputProps, buttonProps, dropProps } = useFileInput({
    onChange,
    disabled,
    fileType,
  });

  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const textColor = useColorModeValue(colors.warmGray[2], colors.coolGray[1]);
  const mutedBorder = useColorModeValue(colors.warmGray[4], colors.coolGray[1]);
  const white = useColorModeValue(colors.white, colors.black);
  const subtleBg = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  const fileBg = useColorModeValue(colors.g.lightLavender, colors.g.plum);

  if (isSubmitting) return <components.Loading />;
  if (showComplete) return <components.Complete />;

  return (
    <Flex
      alignItems="center"
      justify="center"
      bg={subtleBg}
      borderRadius={8}
      border={`1px solid ${mutedBorder}`}
      direction="column"
      p={4}
      gap="10px"
      h="100%"
    >
      {warningMessage ? (
        <Box w="100%">{warningMessage}</Box>
      ) : (
        <>
          <Flex
            alignItems="center"
            bg={isDragging ? fileBg : white}
            border={`1px dashed ${isDragging ? primary : mutedBorder}`}
            borderRadius={8}
            justifyContent="center"
            p={3}
            width="100%"
            h="136px"
            {...dropProps}
          >
            <BodyText color={isDragging ? primary : textColor}>
              {dropText}
            </BodyText>
          </Flex>
          <BodyText color={textColor}>or</BodyText>
        </>
      )}
      <Button variant="outline" {...buttonProps}>
        Choose file
      </Button>
      <input type="file" hidden style={{ display: 'none' }} {...inputProps} />
    </Flex>
  );
}

type UseFileInputResult = {
  isDragging: boolean;
  reset: () => void;
  inputProps: ComponentPropsWithRef<'input'>;
  buttonProps: ComponentPropsWithoutRef<'button'>;
  dropProps: Pick<
    ComponentPropsWithRef<'div'>,
    'onDragEnter' | 'onDragLeave' | 'ref'
  >;
};

type UseFileInputParams = {
  onChange(file: File | undefined): void;
  disabled?: boolean;
  fileType?: string;
};
export function useFileInput({
  onChange,
  disabled,
  fileType,
}: UseFileInputParams): UseFileInputResult {
  const [file, setFile] = useState<File>();
  const [isDragging, setIsDragging] = useState(false);

  const dropRef = useRef<HTMLDivElement>(undefined!);
  const inputRef = useRef<HTMLInputElement>(undefined!);

  useEffect(() => {
    setIsDragging(false);
    onChange(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    const inputElement = inputRef.current;
    const onInputChange = async () => {
      if (inputElement!.files === null || inputElement!.files.length < 1)
        return;
      const newFile = inputElement!.files[0];
      setFile(newFile);
    };
    inputElement?.addEventListener('change', onInputChange);
    return () => inputElement?.removeEventListener('change', onInputChange);
  }, []);

  useEffect(() => {
    const dropElement = dropRef.current;

    const onDragOver = (event: DragEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const onDrop = (event: DragEvent) => {
      setIsDragging(false);
      event.preventDefault();
      event.stopPropagation();

      if (disabled) return;
      const item = event.dataTransfer?.items?.[0];
      if (
        item?.kind !== 'file' ||
        (fileType ? !fileType.includes(item?.type) : false)
      )
        return;
      const newFile = item.getAsFile()!;
      setFile(newFile);
    };

    dropElement?.addEventListener('dragover', onDragOver);
    dropElement?.addEventListener('drop', onDrop);

    return () => {
      dropElement?.removeEventListener('dragover', onDragOver);
      dropElement?.removeEventListener('drop', onDrop);
    };
  }, [fileType, disabled]);

  const onUploadClick = () => {
    const inputElement = inputRef.current!;
    inputElement.click();
  };

  return {
    isDragging,
    reset: () => setFile(undefined),
    inputProps: {
      ref: inputRef,
      accept: fileType,
      disabled,
    },
    buttonProps: {
      onClick: onUploadClick,
      disabled,
    },
    dropProps: {
      ref: dropRef,
      onDragEnter: () => setIsDragging(true),
      onDragLeave: () => setIsDragging(false),
    },
  };
}
