import { useColorModeValue } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { BodyText, Box, Flex, Input } from '~/components';
import { colors } from '~/styles';
import { type UseDynamicWidgetHook } from '../elements/Editor/plugins/dynamic-insights';

type Props = { widget: UseDynamicWidgetHook };
export function DynamicInsightCaption({ widget: useDynamicWidget }: Props) {
  const { caption, setCaption, ...widget } = useDynamicWidget();
  const [isEditing, setIsEditing] = useState(!caption.length);
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(caption);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);

  if (widget.mode === 'reader' && !caption) {
    return <Box my={4} />;
  }
  if (widget.mode === 'reader') {
    return (
      <Flex justify="center" textAlign="center" my={4}>
        <BodyText color={gray1}>{caption}</BodyText>
      </Flex>
    );
  }
  return (
    <Flex
      onClick={() => {
        setIsEditing(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 10);
      }}
      cursor="pointer"
    >
      <Flex justify="center" w="100%" display={isEditing ? 'none' : 'block'}>
        <BodyText color={gray1} textAlign="center" my={4}>
          {caption || 'Enter caption here'}
        </BodyText>
      </Flex>
      <Input
        my={4}
        ref={inputRef}
        display={!isEditing ? 'none' : 'block'}
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        placeholder="Add caption"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setCaption(e.currentTarget.value);
            setIsEditing(false);
          }
        }}
        onBlur={() => {
          setCaption(value);
          setIsEditing(false);
        }}
      />
    </Flex>
  );
}
