import type { ReactElement } from 'react';
import {} from 'react';
import {} from '@chakra-ui/react';
import {} from '~/components';

type Props = {};

export function SignalKeyterm({}: Props): ReactElement {
  return <div />;
}
