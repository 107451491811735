import type { ReactElement } from 'react';
import { $getRoot } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Flex } from '~/components';
import { colors } from '~/styles';

export function WordCountPlugin(): ReactElement {
  const [editor] = useLexicalComposerContext();
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[1]);
  const gray6 = useColorModeValue(colors.warmGray[6], '#20232A');
  const border = `1px solid ${gray4}`;

  const textContent = editor
    .getEditorState()
    .read(() => {
      return $getRoot().getTextContent();
    })
    .replace(/\n|\s{2,}/g, ' ');

  const noOfWords = textContent.split(' ').filter((word) => word !== '').length;

  return (
    <Flex
      justify="space-between"
      bg={gray6}
      py={2}
      px={4}
      borderBottomLeftRadius={8}
      borderBottomRightRadius={8}
      border={border}
      borderTopStyle="dotted"
    >
      <BodyText color={gray1}>
        Word Count: <b>{noOfWords === 0 ? 'n/a' : noOfWords}</b>
      </BodyText>
    </Flex>
  );
}
