import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  FormLabelProps,
  forwardRef,
  InputProps,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Input } from '~/components';
import { colors } from '~/styles';

type Props = InputProps & {
  label?: string;
  labelProps?: FormLabelProps;
  name: string;
  helperText?: string | JSX.Element;
  hideErrorMessage?: boolean;
};

export const FormInput = forwardRef<Props, 'input'>(
  ({ label, helperText, hideErrorMessage, labelProps, ...props }, ref) => {
    const [field, meta] = useField(props.name!);
    const { name } = field;
    const { touched, error } = meta;
    const { id } = props;
    const isInvalid = !!(touched && error);
    return (
      <FormControl isInvalid={isInvalid}>
        {label && (
          <FormLabel
            pl={2}
            htmlFor={id ?? name}
            color={isInvalid ? colors.status.red : undefined}
            {...(labelProps as any)}
          >
            {label}
          </FormLabel>
        )}
        <Input
          ref={ref}
          id={id ?? name}
          {...(field as any)}
          {...(props as any)}
          mb={0}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {!hideErrorMessage && (
          <FormErrorMessage fontStyle="BodyText" color={colors.status.red}>
            {error}
          </FormErrorMessage>
        )}
      </FormControl>
    );
  }
);
FormInput.displayName = 'FormInput';
