import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Flex } from '~/components';
import { PlatformTag } from '~/components/signals';
import { colors } from '~/styles';

type Props = { signal: Signal };

export function SignalPlatformTypeInfo({ signal }: Props) {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  if (signal.category === 'behavior') return <Box mt={-2} />;
  return (
    <Flex align="center" gap={2}>
      {signal.signal_platform === 'N/A' ? (
        <Box h="20px" ml={-2} />
      ) : (
        <>
          <PlatformTag signal={signal}></PlatformTag>
          {signal.signal_type && <BodyText color={gray1}>•</BodyText>}
        </>
      )}
      <BodyText
        color={gray1}
        sx={{
          fontVariant: 'small-caps',
        }}
      >
        {signal.signal_type?.toUpperCase()}
      </BodyText>
    </Flex>
  );
}
