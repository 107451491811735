import { UnorderedList, ListItem } from '@chakra-ui/react';
import { Box, TitleHeader } from '~/components';
import { colors } from '~/styles';

export function WhatsNew() {
  return (
    <Box p={4} bg="#2c264a" color={colors.white} my={4} borderRadius={8}>
      <TitleHeader mb={2}>{`What's New in the Graphika Platform:`}</TitleHeader>
      <UnorderedList>
        <ListItem ml={1}>
          October 17: The new Graphika monitoring dashboard is live. You can now
          visualize and explore the top daily, weekly, and monthly topics in
          Graphika maps.
        </ListItem>
      </UnorderedList>
    </Box>
  );
}
