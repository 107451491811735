import type { GraphikaOrg } from '~/types/graphika-types';
import { hookify } from './utils';

const keys = {
  all: ['admin'] as const,
  orgs: () => [...keys.all, 'orgs'] as const,
  singleOrg: (orgId: number) => [...keys.all, 'orgs', orgId] as const,
  users: (query: { org_ids?: number[] }) =>
    [...keys.all, 'users', query] as const,
  singleUser: (userId: number) => [...keys.all, 'users', userId] as const,
};

export const adminKeys = keys as Readonly<typeof keys>;

export const { fetch: getOrgs, hook: useOrgs } = hookify<GraphikaOrg[]>()
  .path('/administrative/orgs')
  .method('get')
  .key(() => keys.orgs());

export const { fetch: getSingleOrg, hook: useSingleOrg } =
  hookify<GraphikaOrg>()
    .path('/administrative/orgs/{org_id}')
    .method('get')
    .key(({ params: { org_id } }) => keys.singleOrg(org_id));

export const { fetch: getUsers, hook: useUsers } = hookify()
  .path('/administrative/users')
  .method('get')
  .key(({ query }) => keys.users(query));

export const { fetch: getSingleUser, hook: useSingleUser } = hookify()
  .path('/administrative/users/{user_id}')
  .method('get')
  .key(({ params: { user_id } }) => keys.singleUser(user_id));
