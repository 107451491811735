import { StringSelect as Select } from '~/components/elements';
import CalendarIcon from '~/public/icons/Calendar.svg';

type Props = {
  value: string;
  options?: { label: string; value: string }[];
  onChange: (value: string) => void;
  defaultIsOpen?: boolean;
  noIcon?: boolean;
};

export function DatePicker({
  defaultIsOpen,
  value,
  options = [
    { label: 'Last 30 Days', value: '30' },
    { label: 'Last Week', value: '7' },
    { label: 'Last 3 Days', value: '3' },
    { label: 'Last 24 Hours', value: '1' },
  ],
  onChange,
  noIcon,
}: Props) {
  return (
    <Select
      defaultIsOpen={defaultIsOpen}
      leftIcon={noIcon ? undefined : CalendarIcon}
      matchWidth
      onChange={onChange}
      options={options}
      value={value}
      w="max(30%, 150px)"
    />
  );
}
