import { useEffect, useRef, useState } from 'react';

/**
 * Custom hook that caches entries on a virtual heap, and allows you to
 * withdraw filtered entries.
 *
 * @param entries - Entries to add to the heap.
 * @param keyAccessor - How do we uniquely identify each entry?
 * @param select - Callback function to apply filtering and post-
 * processing of the entries before returning them.
 * @param dependencies - Pass a dependencies array, e.g.
 * [yourQuery.isFetching].
 * @returns
 */
export function useHeapCache<T>(
  entries: T[],
  keyAccessor: (entry: T) => string,
  select: (entries: T[]) => T[],
  dependencies: any[]
) {
  const [filteredEntries, setFilteredEntries] = useState<T[]>(entries);

  const entriesMap = useRef(new Map());
  useEffect(() => {
    entries.forEach((element) =>
      entriesMap.current.set(keyAccessor(element), element)
    );
    const allEntries = [...entriesMap.current.values()];
    const filteredEntries = select(allEntries);
    setFilteredEntries(filteredEntries);
    // eslint-disable-next-line
  }, dependencies);

  return filteredEntries;
}
