import { useEffect } from 'react';
// @ts-ignore
import content from '!!raw-loader!~/components/docs/docsMdContent.mdx';

export const stripHeadingMd = (str: string) =>
  str.startsWith('#') ? str.replace(/^#+\s/, '') : str;

export const parseDocToC = (() => {
  const lines = content.split('\n');
  const sections: Map<string, string[]> = new Map();
  const subsections: Map<string, string[]> = new Map();
  let currentSection = '';
  let currentSubSection: string | undefined = undefined;
  for (const line of lines) {
    if (line.startsWith('# ')) {
      currentSection = stripHeadingMd(line);
      currentSubSection = undefined;
      sections.set(currentSection, []);
    }
    if (line.startsWith('## ')) {
      currentSubSection = currentSection + '__' + stripHeadingMd(line);
      sections.set(currentSection, [
        ...(sections.get(currentSection) ?? []),
        stripHeadingMd(line),
      ]);
    }
    if (line.startsWith('### ')) {
      if (currentSubSection) {
        subsections.set(currentSubSection, [
          ...(subsections.get(currentSubSection) ?? []),
          stripHeadingMd(line),
        ]);
      }
    }
  }
  return { sections, subsections };
})();

export const useActiveNav = ({
  selector,
  handler,
}: {
  selector: string;
  handler: (section: string) => void;
}) => {
  useEffect(() => {
    const targets = document.querySelectorAll(selector);
    const onScroll = () => {
      const firstVisibleTarget = Array.from(targets)
        .filter(
          (target) =>
            target.getBoundingClientRect().top >= 0 &&
            target.getBoundingClientRect().top <= window.innerHeight
        )
        .map((target) => target.textContent)?.[0];
      const aboveTopTarget = Array.from(targets)
        .filter((target) => target.getBoundingClientRect().top <= 0)
        .map((target) => target.textContent)
        .reverse()?.[0];
      if (firstVisibleTarget) {
        handler(firstVisibleTarget ?? '');
      } else if (aboveTopTarget) {
        handler(aboveTopTarget ?? '');
      }
    };
    window.addEventListener('scroll', onScroll);
    onScroll();
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [selector, handler]);
};

export const scrollIntoView = (el?: Element | null) => {
  if (!el) return;
  const targetPosition =
    el.getBoundingClientRect().top + window.pageYOffset - 50;
  window.scroll({
    top: targetPosition,
    behavior: 'smooth',
  });
};
