import type { TooltipProps } from '@chakra-ui/react';
import { Tooltip as ChakraTooltip } from '@chakra-ui/react';
import { colors } from '~/styles';

export function Tooltip({ children, ...rest }: TooltipProps) {
  return (
    <ChakraTooltip
      hasArrow
      openDelay={500}
      bg={rest.bg ?? colors.g.primary}
      placement="top"
      noOfLines={3}
      {...rest}
    >
      {children}
    </ChakraTooltip>
  );
}
