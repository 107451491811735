import {
  DecoratorNode,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
} from 'lexical';
import { ReactNode } from 'react';
import { ObjectEntries } from '@graphika/map-viewer';
import { ImageComponent } from './ImageComponent';

export type SerializedImageNode = Spread<
  ImageNodeParams,
  SerializedLexicalNode
>;

export type ImageNodeParams = {
  caption: string;
  url: string;
};

export class ImageNode extends DecoratorNode<ReactNode> {
  __url!: string;
  __caption!: string;

  static getType(): string {
    return 'image';
  }

  static clone(node: ImageNode): ImageNode {
    return new ImageNode(
      {
        url: node.__url,
        caption: node.__caption,
      },
      node.__key
    );
  }

  constructor(params: ImageNodeParams, key?: NodeKey) {
    super(key);
    Object.entries(params).forEach((entry) => {
      const [key, value] = entry as ObjectEntries<ImageNodeParams>;
      this[`__${key}`] = value;
    });
  }

  createDOM(): HTMLElement {
    const span = document.createElement('span');
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): ReactNode {
    return (
      <ImageComponent
        url={this.__url}
        caption={this.__caption}
        nodeKey={this.__key}
      />
    );
  }

  set<T extends keyof ImageNodeParams>(property: T, value: ImageNodeParams[T]) {
    const self = this.getWritable();
    self[`__${property}`] = value as any;
  }

  get<T extends keyof ImageNodeParams>(property: T): ImageNodeParams[T] {
    const self = this.getLatest();
    return self[`__${property}`];
  }

  getNarrativeId(): number {
    const self = this.getLatest();
    return parseImageUrlIds(self.__url)[0];
  }
  getImageId(): number {
    const self = this.getLatest();
    return parseImageUrlIds(self.__url)[1];
  }

  isExternalResource(): boolean {
    const self = this.getLatest();
    return !self.__url.startsWith(process.env.NEXT_PUBLIC_ASSETS_URL);
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('img');
    element.src = this.__url;
    element.alt = this.__caption;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      img: () => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  exportJSON(): SerializedImageNode {
    return {
      caption: this.__caption,
      url: this.__url,
      type: 'image',
      version: 1,
    };
  }

  static importJSON(serializedNode: SerializedImageNode): ImageNode {
    return $createImageNode({
      caption: serializedNode.caption,
      url: serializedNode.url,
    });
  }
}

export function $createImageNode(params: ImageNodeParams): ImageNode {
  return new ImageNode(params);
}
export function $isImageNode(node: LexicalNode | null): node is ImageNode {
  return node instanceof ImageNode;
}

/** Extracts the narrative_id and id from an image URL.
 *
 * Example image URL: `voyager-assets-staging.graphika.io/narratives/928/images/2.jpeg`
 */
export function parseImageUrlIds(url: string) {
  if (!url.startsWith(process.env.NEXT_PUBLIC_ASSETS_URL))
    return [-1, -1] as [number, number];
  return new URL(url).pathname.match(/\d+/g)!.map(Number) as [number, number];
}

function convertImageElement(domNode: Node): null | DOMConversionOutput {
  if (domNode instanceof HTMLImageElement) {
    const { src: url, alt: caption } = domNode;
    return { node: $createImageNode({ url, caption }) };
  }
  return null;
}
