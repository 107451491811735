import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const baseStyle = defineStyle((props) => ({
  color: mode(colors.g.primary, colors.g.light)(props),
}));

const variantGray = defineStyle((props) => ({
  color: mode(colors.warmGray[1], colors.coolGray[5])(props),
  fontSize: '14px',
}));

export const Link = defineStyleConfig({
  baseStyle,
  variants: { gray: variantGray },
});
