import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import {} from 'react';
import {} from '@chakra-ui/react';
import { Element as HastElement } from 'hast';
import { ContentRenderer } from '~/components';
import { Box } from '~/components';
import { transformElements } from '~/lib/html';
import { SignalCardImage, SignalCardUserSection } from './';

type Props = { signal: Signal<SignalPostBase> };

export function SignalPostFacebook({ signal }: Props): ReactElement {
  const {
    user: { display_name, user_name, url, profile_image_url },
    text,
    html,
    image_url,
  } = signal.full_target.entity!;
  return (
    <Box>
      <SignalCardUserSection
        profileImage={profile_image_url}
        topContent={display_name ?? user_name}
        bottomContent={url}
      />
      <ContentRenderer
        htmlPlugins={[
          [
            transformElements,
            { blockquote: (node: HastElement) => (node.tagName = 'p') },
          ],
        ]}
      >
        {text ?? html ?? ''}
      </ContentRenderer>
      <SignalCardImage
        src={image_url}
        alt={signal.notes || signal.signal_value}
        mt={2}
      />
    </Box>
  );
}
