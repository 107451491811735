import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import boxShadows from '../boxShadows';
import colors from '../colors';
import textStyles from '../textStyles';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  button: {
    transitionProperty: 'common',
    transitionDuration: 'normal',
    minW: '10em',
  },
  list: {
    bg: mode(colors.card.lightMode, colors.card.darkMode)(props),
    boxShadow: boxShadows.cardPopup,
    color: 'inherit',
    minW: '200px',
    py: 4,
    zIndex: 1,
    borderRadius: '16px',
    border: 'none',
  },
  item: {
    alignItems: 'center',
    borderRadius: '4px',
    height: '3rem',
    paddingLeft: '16px',
    _focus: {
      bg: mode(colors.g.lightLavender, colors.g.plum)(props),
    },
    _active: {
      bg: mode(colors.g.lightLavender, colors.g.plum)(props),
    },
  },
}));

const variantTextEditor = definePartsStyle({
  button: {
    minW: '120px',
  },
  item: {
    height: 'inherit',
    ...textStyles.BodyText,
  },
});

const variantSmall = definePartsStyle({
  item: {
    fontSize: '14px',
  },
});

export const Menu = defineMultiStyleConfig({
  baseStyle,
  variants: {
    'text-editor': variantTextEditor,
    small: variantSmall,
  },
});
