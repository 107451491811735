import { Box, Flex, BodyText, Link } from '~/components';
import { colors } from '~/styles';

export function Footer() {
  return (
    <Box bg="linear-gradient(180deg, #504FB7 -115.29%, #4F49AC 100%)" h="170px">
      <Flex align="center" justify="flex-start" ml="10vw" pt="100px" gap="48px">
        <BodyText color={colors.white} fontSize="16px" fontWeight={100}>
          © Graphika {new Date().getFullYear()}
        </BodyText>
        <Link
          href="https://graphika.com/terms-of-use"
          color={colors.white}
          fontSize="16px"
          fontWeight={100}
        >
          Legal
        </Link>
      </Flex>
    </Box>
  );
}
