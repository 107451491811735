import type { PropsWithChildren, ReactElement } from 'react';
import type { FormikFormProps } from 'formik';
import { VStack } from '@chakra-ui/react';
import { Form as FormikForm } from 'formik';

type Props = FormikFormProps & PropsWithChildren<{ insideModal?: boolean }>;

export function Form({ insideModal, children, ...props }: Props): ReactElement {
  return (
    <FormikForm {...(props as any)}>
      <VStack gap={insideModal ? 2 : 4} align="left">
        {children}
      </VStack>
    </FormikForm>
  );
}
