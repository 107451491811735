import type { PostType } from '.';
import { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box, Flex } from '~/components';
import { Post } from './';

type Props = { posts: PostType[] };

const PAGE_SIZE = 20;

export function PostList({ posts }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const { ref, inView } = useInView();

  useEffect(() => {
    containerRef.current?.scrollTo(0, 0);
    setPage(1);
  }, [posts]);

  useEffect(() => {
    if (inView) {
      setPage((page) => page + 1);
    }
  }, [inView, setPage]);

  const slicedPosts = posts.slice(0, page * PAGE_SIZE);

  return (
    <Flex overflowY="auto" direction="column" py={4} ref={containerRef}>
      {slicedPosts.map((post) => (
        <Post key={post.id} post={post} />
      ))}
      <Box ref={ref} h={0} />
    </Flex>
  );
}
