import { breadcrumbAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(breadcrumbAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  container: {
    bg: mode(colors.warmGray[6], colors.coolGray[1])(props),
  },
  link: {
    color: mode(colors.g.primary, colors.g.light)(props),
    fontSize: '14px',
  },
  separator: {
    color: mode(colors.warmGray[1], colors.coolGray[5]),
  },
}));

export const Breadcrumb = defineMultiStyleConfig({ baseStyle });
