import type { MapInfo, MapNode } from '~/types/graphika-types';
import { useColorModeValue, VStack } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import {
  BodyText,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Modal,
  SubtitleHeader,
  Tab,
  TabList,
  Tabs,
} from '~/components';
import { Analytics } from '~/lib/analytics';
import {
  useDebounce,
  useDebounceCallback,
  useMvQueryParams,
} from '~/lib/hooks';
import CheckmarkIcon from '~/public/icons/Checkmark.svg';
import { boxShadows, colors } from '~/styles';
import { AvatarWithPlatform } from './AvatarWithPlatform';

const PAGINATION_SIZE = 50;

type Props = {
  map: MapInfo;
  nodes: MapNode[];
  onClose: () => void;
  highlightedNode?: MapNode;
};

const searchFields = [
  'node_source_id',
  'name',
  'username',
] as (keyof MapNode)[];

export function NodeSearchModal({
  map,
  nodes,
  onClose,
  highlightedNode,
}: Props) {
  const [searchTerm, setSearchTerm] = useState('');
  const [tab, setTab] = useState(0);
  const [selectedNode, setSelectedNode] = useState<MapNode | undefined>(
    highlightedNode
  );
  const [{ selected }, setQuery] = useMvQueryParams();
  const [paginationIndex, setPaginationIndex] = useState(PAGINATION_SIZE);
  const topOfListRef = useRef<HTMLDivElement>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  const { ref: bottomOfListRef, inView } = useInView();
  const hoverBg = useColorModeValue(colors.warmGray[5], colors.coolGray[2]);
  const sortedNodes = useMemo(
    () => nodes.sort((a, b) => (a.radius > b.radius ? -1 : 1)),
    [nodes]
  );
  const filteredNodes = useMemo(
    () =>
      sortedNodes
        .filter((node) =>
          searchFields.some((key) =>
            ((node[key] ?? '') as string)
              .toLocaleLowerCase()
              .includes(debouncedSearchTerm.toLocaleLowerCase())
          )
        )
        .slice(0, paginationIndex),
    [sortedNodes, debouncedSearchTerm, paginationIndex]
  );

  useDebounceCallback(
    () => {
      Analytics.event('search', {
        action: 'node_in_map',
        label: debouncedSearchTerm,
        map: map?.name,
        map_id: map.id?.toString() || 'n/a',
      });
    },
    debouncedSearchTerm,
    1500
  );

  useEffect(() => {
    topOfListRef.current?.scrollIntoView();
    setPaginationIndex(PAGINATION_SIZE);
  }, [searchTerm]);

  useEffect(() => {
    inView &&
      setPaginationIndex(
        (paginationIndex) => paginationIndex + PAGINATION_SIZE
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, setPaginationIndex]);

  const handleHighlightedNodeSelection = () => {
    setQuery({
      selected:
        selectedNode?.node_source_id === selected
          ? undefined
          : selectedNode?.node_source_id,
    });
    onClose();
  };

  return (
    <Modal title="Search Map" isOpen onClose={onClose} maxW="400px">
      <Flex h="500px" mt={-7} justify="space-between" direction="column">
        <Box flex={1}>
          <Tabs index={tab} onChange={() => setTab(Number(!tab))} mb={6}>
            <TabList>
              <Tab>Nodes</Tab>
            </TabList>
          </Tabs>
          <SubtitleHeader mb={2}>Search map node</SubtitleHeader>
          <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            placeholder="Enter node ID, name, or username"
            size="lg"
            autoFocus
          />
          <Box overflow="auto" h="310px" mx={-4} mt={3}>
            <Box ref={topOfListRef} />
            <VStack align="left">
              {filteredNodes.map((node) => (
                <Flex
                  key={node.node_source_id}
                  align="center"
                  gap={2}
                  overflowX="hidden"
                  py={1}
                  pl={4}
                  _hover={{
                    bg: hoverBg,
                  }}
                  onClick={() =>
                    setSelectedNode(node === selectedNode ? undefined : node)
                  }
                  cursor="pointer"
                  justify="space-between"
                >
                  <Flex gap={2}>
                    <AvatarWithPlatform node={node} px={40} />
                    <Box>
                      <SubtitleHeader noOfLines={1}>{node.name}</SubtitleHeader>
                      <BodyText color={colors.warmGray[1]} noOfLines={1}>
                        {node.username}
                      </BodyText>
                    </Box>
                  </Flex>
                  {selectedNode?.node_source_id === node.node_source_id && (
                    <Box w="20px" mr={4}>
                      <Icon
                        color={colors.g.primary}
                        icon={CheckmarkIcon}
                        boxSize={6}
                      />
                    </Box>
                  )}
                </Flex>
              ))}
            </VStack>
            <Box ref={bottomOfListRef} h="1px" />
          </Box>
        </Box>
        <Box boxShadow={boxShadows.card} />
        <Box pt={4} px={0} mb={-2}>
          <Button onClick={handleHighlightedNodeSelection} w="68px">
            Apply
          </Button>
        </Box>
      </Flex>
    </Modal>
  );
}
