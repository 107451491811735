import type { PropsWithChildren } from 'react';
import { Box, Flex, Text, TextProps } from '@chakra-ui/react';
import { FaIcon } from '~/components/elements';
import { Label, Tooltip } from '~/componentsV2';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';

type TitleProps = TextProps &
  PropsWithChildren<{
    size?: 'large' | 'small';
    tooltip?: string;
    label?: string;
    iconLeft?: JSX.Element;
  }>;

export function Headline({ children, ...props }: TextProps) {
  return (
    <Text
      fontSize="24px"
      lineHeight="32px"
      fontWeight={400}
      color={colors.text}
      {...(props as any)}
    >
      {children}
    </Text>
  );
}

export function Title({ children, ...props }: TitleProps) {
  const { label, iconLeft, size = 'small', tooltip, ...rest } = props;
  const fontSize = size === 'large' ? '22px' : '14px';
  const fontWeight = size === 'large' ? 400 : 500;
  const lineHeight = size === 'large' ? '28px' : '20px';
  return (
    <Flex align="center" gap={2}>
      {!!iconLeft && iconLeft}
      <Text
        as="h3"
        color={colors.text}
        fontSize={fontSize}
        fontStyle="normal"
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        {...(rest as any)}
      >
        {children}
      </Text>
      {!!tooltip && (
        <Tooltip text={tooltip}>
          <FaIcon
            icon="circle-info"
            color={colors.secondaryText}
            width="12px"
            height="12px"
            style={{
              transform: 'translateY(0.5px)',
            }}
          />
        </Tooltip>
      )}
      {label && <Label size="compact">{label}</Label>}
    </Flex>
  );
}

type BodyProps = TextProps &
  PropsWithChildren<{
    size?: 'medium' | 'small';
  }>;

export function Body({ children, ...props }: BodyProps) {
  const { size = 'medium' } = props;
  const fontSize = size === 'medium' ? '14px' : '12px';
  const lineHeight = size === 'medium' ? '20px' : '16px';
  return (
    <Text
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={400}
      color={colors.text}
      {...(props as any)}
    >
      {children}
    </Text>
  );
}
