import { PropsWithChildren, useRef, useState } from 'react';
import {
  ListItem,
  UnorderedList,
  DarkMode,
  useOutsideClick,
} from '@chakra-ui/react';
import { Box, Flex, Icon, Tooltip, TitleHeader, BodyText } from '~/components';
import { colors } from '~/styles';
import InfoCircleIcon from '~/public/icons/InfoCircle.svg';

export function MapControlInfo() {
  const [showInfo, setShowInfo] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref,
    handler: () => setShowInfo(false),
  });

  return (
    <DarkMode>
      <Box position="fixed" bottom={2} right={2} ref={ref}>
        <Tooltip label="Map Controls" placement="bottom-end">
          <Icon
            icon={InfoCircleIcon}
            fill={colors.mapViewer.segmentText}
            boxSize={10}
            onClick={() => setShowInfo(!showInfo)}
          />
        </Tooltip>
      </Box>
      {showInfo && (
        <Flex
          position="fixed"
          bottom={14}
          right={6}
          bg="#222A3A"
          borderRadius={8}
          p={4}
          direction="column"
          gap={2}
        >
          <TitleHeader>Map Controls</TitleHeader>
          <BodyText>In 3D mode,</BodyText>
          <UnorderedList ml={2}>
            <Item text="Rotate">
              <Tag>Click</Tag>
              <Tag>Drag</Tag>
            </Item>
            <Item text="Pan">
              <Tag>Shift</Tag>
              <Tag>Click</Tag>
              <Tag>Drag</Tag>
            </Item>
            <Item text="Zoom">
              <Tag>Scroll</Tag>
            </Item>
          </UnorderedList>
          <BodyText>In 2D mode,</BodyText>
          <UnorderedList ml={2}>
            <Item text="Pan">
              <Tag>Shift</Tag>
              <Tag>Click</Tag>
              <Tag>Drag</Tag>
            </Item>
            <Item text="Zoom">
              <Tag>Scroll</Tag>
            </Item>
          </UnorderedList>
        </Flex>
      )}
    </DarkMode>
  );
}

const Item = ({ text, children }: PropsWithChildren<{ text: string }>) => (
  <ListItem mb={1}>
    <Flex align="center" gap={1}>
      <BodyText>&#8226; {text}: </BodyText>
      {children}
    </Flex>
  </ListItem>
);

const Tag = ({ children }: PropsWithChildren) => (
  <Box px={2} py={1} bg="#313D53" borderRadius={8} color="white">
    <BodyText color="white">{children}</BodyText>
  </Box>
);
