import type { HgMapNode } from '~/lib/hypergraph';
import { useMemo } from 'react';
import { defineNodeStyle, NodeOrder, useNodeStyle } from '@graphika/map-viewer';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins/dynamic-insights';

const nonMatchingStyle = defineNodeStyle({
  color: '#666666',
  alpha: 0.2, // TODO: verify style with Dennis, hard to read actual values from Figma
  order: NodeOrder.Behind,
});

export const matchingStyle = defineNodeStyle({
  order: NodeOrder.Natural,
  alpha: 1,
});

type Props = {
  hgNodes: HgMapNode[];
};

export function MvRelevantNodeStylePlugin({ hgNodes }: Props) {
  const { params } = useRelevantMapNodes();
  const isEmptyTerm = !params.term?.length;
  const matchingNodeIds = useMemo(
    () =>
      isEmptyTerm
        ? hgNodes.map((node) => node.node_source_id)
        : hgNodes
            .filter((node) => node.matches_search)
            .map((node) => node.node_source_id),
    [hgNodes, isEmptyTerm]
  );
  const nonMatchingNodeIds = useMemo(
    () =>
      isEmptyTerm
        ? []
        : hgNodes
            .filter((node) => !node.matches_search)
            .map((node) => node.node_source_id),
    [hgNodes, isEmptyTerm]
  );
  useNodeStyle(nonMatchingNodeIds, nonMatchingStyle);
  useNodeStyle(matchingNodeIds, matchingStyle);

  return null;
}
