import type { AlertStatus } from '@chakra-ui/react';
import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import colors from '../colors';

const toastColors: Record<AlertStatus, string> = {
  success: colors.status.green,
  error: colors.status.red,
  warning: colors.status.orange,
  info: colors.status.green,
  loading: colors.status.orange,
};

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  container: {
    px: 4,
    py: 4,
    bg: toastColors[props.status as AlertStatus],
    maxW: '300px',
    mr: 2,
    mb: 2,
  },
  icon: {
    height: 6,
    width: 6,
    mr: 4,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 16,
  },
  description: {
    fontSize: 12,
  },
}));

export const Alert = defineMultiStyleConfig({ baseStyle });
