import type { ReactElement } from 'react';
import { Center } from '@chakra-ui/react';
import { BodyText, Box, Image, PageHeader } from '~/components';
import { colors } from '~/styles';

type Props = { isLoading: boolean };

export function MapImageBg({ isLoading }: Props): ReactElement {
  return (
    <Center h="100%" bg={colors.mapViewer.bg}>
      <Box opacity={0.7} mt="64px">
        <Image
          src="/bg-map-temp.webp"
          alt="graphika map image"
          height="87vh"
          width="auto"
        />
      </Box>
      {!isLoading && (
        <Box position="absolute" top={'16vh'} textAlign="center">
          <PageHeader
            color={colors.white}
            mb={2}
          >{`Let's get started!`}</PageHeader>
          <BodyText color={colors.white}>
            Select from the library or search to view a map
          </BodyText>
        </Box>
      )}
    </Center>
  );
}
