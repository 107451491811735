import type { Narrative } from '~/types/graphika-types';
import { RefObject, useCallback } from 'react';
import { toPng } from 'html-to-image';
import { useColorModeValue } from '@chakra-ui/react';
import { Flex, Icon } from '~/components';
import { colors } from '~/styles';
import DownloadIcon from '~/public/icons/Download-2.svg';

type Props = { chartRef: RefObject<HTMLDivElement>; narrative: Narrative };

export function ChartDownloadButton({ chartRef, narrative }: Props) {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[2]);

  const handleChartDownload = useCallback(() => {
    if (chartRef.current === null) {
      return;
    }

    toPng(chartRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        const sanitizedName = `activity_chart_${narrative.title
          .replaceAll(' ', '_')
          .replaceAll(/[!@#$%^&*(),.?"':{}|<>]/g, '')}`;
        link.download = sanitizedName;
        link.href = dataUrl;
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [chartRef, narrative]);
  return (
    <Flex
      bg={gray6}
      border={`1px solid ${gray4}`}
      width="36px"
      h="36px"
      align="center"
      justify="center"
      borderRadius={8}
      cursor="pointer"
    >
      <Icon
        icon={DownloadIcon}
        onClick={handleChartDownload}
        fill={gray1}
        boxSize={5}
      />
    </Flex>
  );
}
