import { useEffect, useCallback } from 'react';

type KeyCallback = (event: KeyboardEvent) => void;

type KeyListener = {
  keyCode: string;
  metaKey?: boolean;
  callback: KeyCallback;
};

export const useKeyListener = (listeners: KeyListener[]) => {
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!listeners) return;
      const listener = listeners.find((l) => {
        if (l.metaKey) {
          return l.keyCode === event.code && event.metaKey;
        }
        return l.keyCode === event.code;
      });
      if (listener) {
        listener.callback(event);
      }
    },
    [listeners]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);
};
