import { colors } from '~/styles';

export * from './Accordion';
export * from './Alert';
export * from './Breadcrumb';
export * from './Button';
export * from './Checkbox';
export * from './Divider';
export * from './Forms';
export * from './Input';
export * from './Link';
export * from './List';
export * from './Menu';
export * from './Popover';
export * from './Radio';
export * from './Switch';
export * from './Table';
export * from './Tag';
export * from './Tooltip';

export const scrollbarStyleDark = {
  '&::-webkit-scrollbar': {
    width: '10px',
    borderRadius: '16px',
    backgroundColor: `#2C2C2C`,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `#6B6B6B`,
    borderRadius: '16px',
  },
  scrollbarColor: '#2C2C2C #6B6B6B', //Firefox hack
};

export const scrollbarStyleLight = {
  '&::-webkit-scrollbar': {
    width: '10px',
    borderRadius: '16px',
    backgroundColor: colors.warmGray[6],
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: `#c7c7c7`,
    borderRadius: '16px',
  },
  scrollbarColor: `#c7c7c7 ${colors.warmGray[6]} `, // Firefox hack
};
