import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  COMMAND_PRIORITY_EDITOR,
  KEY_MODIFIER_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import { INSERT_LINK_COMMAND } from './GraphikaLinkPlugin';

type Props = {};

export function HotKeysPlugin({}: Props): ReactElement {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      ({ ctrlKey, metaKey, shiftKey, code }) => {
        const isMac = navigator.userAgent.toLowerCase().indexOf('mac') !== -1;
        const redo = isMac
          ? [metaKey, shiftKey, code === 'KeyZ']
          : [ctrlKey, code === 'KeyY'];
        const undo = isMac
          ? [metaKey, code === 'KeyZ']
          : [ctrlKey, code === 'KeyZ'];
        const insertLink = isMac
          ? [metaKey, code === 'KeyK']
          : [ctrlKey, code === 'KeyK'];
        if (redo.every(Boolean))
          return editor.dispatchCommand(REDO_COMMAND, undefined);
        if (undo.every(Boolean))
          return editor.dispatchCommand(UNDO_COMMAND, undefined);
        if (insertLink.every(Boolean)) {
          editor.dispatchCommand(INSERT_LINK_COMMAND, undefined);
        }
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);
  return <div />;
}
