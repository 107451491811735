import type { Report } from '~/types/graphika-types';
import { useQuery } from '@tanstack/react-query';
import { fetchFromApi } from '~/lib/api';
import { formatApiDate } from '~/lib/date';
import { hookify, buildMutation } from './utils';

const keys = {
  all: ['reports'] as const,
};

export const reportsKeys = keys as Readonly<typeof keys>;

export const { fetch: getReports, hook: useReports } = hookify<Report[]>()
  .path('/reports')
  .method('get')
  .key(() => keys.all);

export const useDeleteReport = buildMutation(
  '/reports/{report_id}',
  'delete',
  () => (params: { report_id: number }) => ({ params }),
  () => [keys.all]
);

export const useAddReport = buildMutation(
  '/reports',
  'post',
  () =>
    // @ts-ignore
    ({ file }: { file: any }) => {
      const formData = new FormData();
      formData.append('file', file);
      return { params: {}, form: formData };
    },
  () => [keys.all]
);

export const useChangeReportPermissions = buildMutation(
  '/reports/{report_id}',
  'patch',
  () =>
    ({
      report_id,
      narrative_feed_ids,
    }: {
      report_id: number;
      narrative_feed_ids: number[];
    }) => ({
      params: {
        report_id,
      },
      body: {
        narrative_feed_ids,
      },
    }),
  () => [keys.all]
);

export const useExportReports = ({ reportIds }: { reportIds: number[] }) => {
  return useQuery<any>(
    ['zip=reports', reportIds],
    () => {
      return fetchFromApi<any>(
        '/reports',
        'GET',
        {
          report_ids: reportIds.join(','),
        },
        { zipContentType: true }
      );
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const blob = new Blob([data.data], { type: 'application/zip' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', `${formatApiDate(new Date())}_Reports.zip`);
        a.click();
      },
    }
  );
};
