import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from '~/styles';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  label: {
    ml: 4,
  },
  container: {},
  control: {
    borderColor: mode(colors.g.primary, colors.g.light)(props),
    w: 5,
    h: 5,
    _checked: {
      bg: 'inherit',
      borderColor: mode(colors.g.primary, colors.g.light)(props),
      color: mode(colors.g.primary, colors.g.light)(props),
    },
  },
}));

export const Radio = defineMultiStyleConfig({ baseStyle });
