// @ts-nocheck
import { Children } from 'react';
import ReactSelect, { components, StylesConfig } from 'react-select';
import {
  useColorMode,
  useColorModeValue,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { Box, Checkbox, Flex, Icon } from '~/components';
import { boxShadows, colors } from '~/styles';
import DropdownArrowDownIcon from '~/public/icons/Navigation/Down.svg';
import MultiOptionIcon from '~/public/icons/Picker-Expand.svg';
import { CaptionText } from './typography';

type Option<T> = {
  label: string;
  value: T;
};

// We have several ways of customizing react-select.
// - emotion styles.
// - custom replacement Components.

// emotion styles to override the default react-select styles.
// Note that Chakra convenience props, such as py, maxW, and m are not supported.
// Styles are defined by a function, which receives two parameters:
// - provided: The default styling object.
// - state: State object, containing the list of options, whether the select is
// in focus, open, etc.

const globalStyles = {
  container: (provided) => ({
    ...provided,
    flex: 1,
  }),
  dropdownIndicator: (_provided, state) => ({
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
    padding: '8px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'transparent',
  }),
  menu: (provided, state) => ({
    ...provided,
    background: state.selectProps.darkMode ? colors.coolGray[1] : colors.white,
    boxShadow: boxShadows.cardPopup,
    borderRadius: '16px',
    minWidth: '100%',
    paddingTop: '16px',
    paddingBottom: '16px',
    top: '70%',
    right: 0,
    width: 'max-content',
    zIndex: '9999 !important',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  // menu: (provided) => ({ ...provided, zIndex: '9999 !important' }),
  option: (provided, state) => ({
    ...provided,
    background: getOptionBackground(state),
    color: state.selectProps.darkMode ? colors.white : colors.black,
    height: '3em',
    fontSize: '14px',
    ':active': { background: colors.g.light },
    paddingLeft: '20px',
    paddingTop: '10px',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.selectProps.darkMode ? colors.white : colors.black,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: state.selectProps.darkMode
      ? colors.coolGray[3]
      : colors.warmGray[4],
    borderRadius: '100px',
    fontFamily: 'Roboto Mono, monospace',
    padding: '1px 8px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.selectProps.darkMode ? colors.white : colors.black,
    padding: 0,
    paddingLeft: 0,
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    padding: 0,
    marginLeft: -5,
  }),
};

// Note: this is a quick fix for the <Select> styles in the AMR upload modal. This will be removed in a follow up PR
const globalStyles_amrUploadModal: StylesConfig = {
  ...globalStyles,
  option: (...args) => {
    const style = globalStyles.option(...args);
    delete style['height'];
    return style;
  },
  menu: (...args) => {
    const style = globalStyles.menu(...args);
    delete style['minWidth'];
    delete style['right'];
    return { ...style, width: '480px' };
  },
};

function getOptionBackground(state) {
  const { darkMode } = state.selectProps;
  if (darkMode) {
    return state.isSelected
      ? colors.g.dark
      : state.isFocused
      ? colors.g.plum
      : 'transparent';
  }
  return state.isSelected
    ? colors.g.lavender
    : state.isFocused
    ? colors.g.lightLavender
    : 'transparent';
}

/**
 * Overrides the default Control.
 * - Add leftIcon support
 * - Styling
 *
 * Default: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/Control.tsx
 */
const Control = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: ControlProps<Option, IsMulti, Group>
) => {
  const {
    children,
    cx,
    className,
    isDisabled,
    isFocused,
    innerRef,
    innerProps,
    menuIsOpen,
  } = props;
  const borderColor = useColorModeValue(colors.warmGray[4], colors.coolGray[1]);
  const hoverBorderColor = useColorModeValue(
    colors.warmGray[3],
    colors.coolGray[3]
  );
  return (
    <Flex
      align="center"
      background={useColorModeValue(colors.warmGray[5], colors.coolGray[1])}
      border="1px solid"
      borderColor={isFocused ? colors.g.primary : borderColor}
      borderRadius="8px"
      color={useColorModeValue(colors.black, colors.white)}
      bg={useColorModeValue(colors.warmGray[6], colors.coolGray[1])}
      fontSize="14px"
      height="32px"
      py="8px"
      my="8px"
      minWidth="100px"
      pl="18px"
      _disabled={{
        color: colors.warmGray[2],
        cursor: 'not-allowed',
      }}
      _hover={{
        border: `1px solid ${isFocused ? colors.g.primary : hoverBorderColor}`,
      }}
      className={cx(
        {
          control: true,
          'control--is-disabled': isDisabled,
          'control--is-focused': isFocused,
          'control--menu-is-open': menuIsOpen,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      {props.selectProps.leftIcon && (
        <Icon
          boxSize="20px"
          fill={colors.g.primary}
          icon={props.selectProps.leftIcon}
          mr="5px"
        />
      )}
      {children}
    </Flex>
  );
};

/**
 * Overrides the default DropdownIndicator.
 * - Add dropdownIcon support
 *
 * Default: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/components/indicators.tsx
 */
const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...(props as any)}>
    <Icon
      boxSize="20px"
      fill={colors.g.primary}
      icon={props.selectProps.dropdownIcon || DropdownArrowDownIcon}
    />
  </components.DropdownIndicator>
);

/**
 * Optional: Used by MultiSelect.
 * Overrides the default Option.
 * - Adds a checkbox
 */
const MultiOption = (props) => {
  return (
    <components.Option {...(props as any)} _css={{ input: { width: 100 } }}>
      <Checkbox isChecked={props.isSelected} mr={2} pointerEvents="none" />
      <label>{props.label}</label>
    </components.Option>
  );
};

/**
 * Optional: Used by NarrativeSelect.
 * Overrides the default DropdownIndicator.
 * - Caps the number of options rendered.
 * - Renders an 'Create New Option' option.
 *
 * Default: https://github.com/JedWatson/react-select/blob/ec3bd2eb7f5b10fa6ce3f9cac9d811a46a74f42a/packages/react-select/src/components/Menu.tsx#L410
 */
const NarrativeMenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: MenuListProps<Option, IsMulti, Group>
) => {
  const { children, className, cx, getStyles, innerProps, innerRef, isMulti } =
    props;
  const MAX_OPTIONS_NUM = 7;
  const options = Children.toArray(children).slice(0, MAX_OPTIONS_NUM);
  const showCaption = props.selectProps.inputValue === '';
  return (
    <Box
      sx={getStyles('menuList', props)}
      className={cx(
        {
          'menu-list': true,
          'menu-list--is-multi': isMulti,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      {showCaption && (
        <CaptionText mt="-15px" p="5px" pl="20px" textTransform="uppercase">
          Recent Narratives
        </CaptionText>
      )}
      {options}
      <Box
        __css={props.getStyles('option', props)}
        bg={useColorModeValue(colors.g.lavender, colors.g.dark)}
        _hover={{
          bg: useColorModeValue(colors.g.pale, colors.g.dark),
          color: useColorModeValue(colors.g.dark, colors.g.pale),
        }}
        color={useColorModeValue(colors.g.primary, colors.g.pale)}
        cursor="pointer"
        onClick={props.selectProps.onClickNewNarrative}
      >
        + Create new insight
      </Box>
    </Box>
  );
};

const Placeholder = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  props: PlaceholderProps<Option, IsMulti, Group>
) => {
  const { children, className, cx, getStyles, innerProps, selectProps } = props;
  return (
    <Box
      __css={getStyles('placeholder', props)}
      className={cx(
        {
          placeholder: true,
        },
        className
      )}
      {...innerProps}
    >
      {selectProps.menuIsOpen ? 'Type to search' : children}
    </Box>
  );
};

export type SelectProps = {
  id?: string;
  name?: string;
  components?: {};
  defaultIsOpen?: boolean;
  dropdownIcon?: any;
  label?: string;
  leftIcon?: any;
  isDisabled?: boolean;
  onBlur?: (e: any) => void;
  onChange: (value: Option) => void;
  options: Option[];
  placeholder?: string;
  value: Option;
  variant?: string;
  inAMRUploadModal?: boolean; // TODO: remove me
};

export function BaseSelect({
  components = {},
  defaultIsOpen,
  dropdownIcon,
  leftIcon,
  isDisabled,
  label,
  onChange,
  options,
  styles = {
    container: {
      paddingLeft: '4rem',
    },
  },
  value,
  variant,
  inAMRUploadModal,
  ...rest
}: SelectProps) {
  const { colorMode } = useColorMode();
  const { label: labelStyle } = useMultiStyleConfig('Input', {
    variant,
    colorMode,
  });
  return (
    <Flex
      direction="column"
      cursor={isDisabled ? 'not-allowed' : 'inherit'}
      opacity={isDisabled ? 0.6 : 1}
    >
      {label && (
        <Box as="label" __css={labelStyle} htmlFor={rest.id}>
          {label}
        </Box>
      )}
      <ReactSelect
        components={{
          ...components,
          Control,
          DropdownIndicator,
          Placeholder,
        }}
        darkMode={useColorModeValue(false, true)}
        defaultMenuIsOpen={defaultIsOpen}
        dropdownIcon={dropdownIcon}
        isDisabled={isDisabled}
        leftIcon={leftIcon}
        onChange={onChange}
        options={options}
        styles={{
          ...styles,
          ...(inAMRUploadModal ? globalStyles_amrUploadModal : globalStyles),

          noOptionsMessage() {
            return {
              fontSize: '14px',
              paddingLeft: '16px',
            };
          },
        }}
        value={value}
        menuPortalTarget={document.body}
        {...rest}
      />
    </Flex>
  );
}

export function MultiSelect(props: SelectProps & { isClearable?: boolean }) {
  const { components, ...rest } = props;
  return (
    <BaseSelect
      closeMenuOnSelect={false}
      dropdownIcon={MultiOptionIcon}
      hideSelectedOptions={false}
      isMulti
      isClearable={props.isClearable || false}
      {...rest}
      components={{ ...components, Option: MultiOption }}
    />
  );
}

export function NarrativeSelect(
  props: SelectProps & { onClickNewNarrative: (e: any) => void }
) {
  return (
    <BaseSelect
      {...(props as any)}
      components={{ MenuList: NarrativeMenuList }}
      noOptionsMessage={() => (
        <CaptionText
          mt="-25px"
          p="5px"
          pl="8px"
          textAlign="left"
          textTransform="uppercase"
        >
          No Matching Narratives
        </CaptionText>
      )}
      onClickNewNarrative={props.onClickNewNarrative}
    />
  );
}

export function StringSelect(
  props: Omit<SelectProps, 'onChange', 'value'> & {
    onChange: (value: any) => void;
    value: string;
  }
) {
  const selectedValueFromString = props.options.find(
    (option) => option.value === props.value
  );
  const stringOnChange = (selectedOption: Option) =>
    props.onChange(selectedOption.value);
  return (
    <BaseSelect
      {...(props as any)}
      value={selectedValueFromString}
      onChange={stringOnChange}
    />
  );
}
