import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { TreeView } from '@lexical/react/LexicalTreeView';
import { Box, Button, Flex, QueryParamProvider } from '~/components';

export function TreeViewPlugin() {
  const [editor] = useLexicalComposerContext();
  const [hide, setHide] = useState(false);
  if (hide) return null;
  return (
    <Flex position="fixed" bottom={0} right={0} direction="column" zIndex={3}>
      <Button onClick={() => setHide(true)}>Close</Button>
      <TreeView
        viewClassName="tree-view-output"
        treeTypeButtonClassName="debug-treetype-button"
        timeTravelPanelClassName="debug-timetravel-panel"
        timeTravelButtonClassName="debug-timetravel-button"
        timeTravelPanelSliderClassName="debug-timetravel-panel-slider"
        timeTravelPanelButtonClassName="debug-timetravel-panel-button"
        editor={editor}
      />
    </Flex>
  );
}
