import { useColorModeValue } from '@chakra-ui/react';
import { MutableRefObject, useEffect, useState } from 'react';
import { BodyText, Flex, Icon } from '~/components';
import DownloadIcon from '~/public/icons/Download-2.svg';
import { colors } from '~/styles';

type Props = {
  handleCvsDownload: () => void;
  widgetRef: MutableRefObject<HTMLDivElement | null>;
};

export function DownloadWidgetDataButton({
  handleCvsDownload,
  widgetRef,
}: Props) {
  const [isHovered, setIsHovered] = useState(false);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  useEffect(() => {
    const node = widgetRef.current;
    if (node) {
      const handleMouseEnter = () => setIsHovered(true);
      const handleMouseLeave = () => setIsHovered(false);
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isHovered) return null;
  return (
    <Flex
      justify="flex-end"
      my={2}
      position="absolute"
      top={-10}
      right={-2}
      pb={2}
      onClick={handleCvsDownload}
      as="button"
      align="center"
    >
      <Icon icon={DownloadIcon} boxSize={6} fill={primary} />
      <BodyText color={primary}>Export</BodyText>
    </Flex>
  );
}
