import type {
  FollowsNode,
  MapCluster,
  MapGroup,
  MapInfo,
  MapNode,
} from '~/types/graphika-types';
import { hookify } from './utils';

const keys = {
  all: ['maps'] as const,
  map: (mapId: number) => [...keys.all, mapId, 'info'] as const,
  nodes: (mapId: number) => [...keys.all, mapId, 'nodes'] as const,
  groups: (mapId: number) => [...keys.all, mapId, 'groups'] as const,
  clusters: (mapId: number) => [...keys.all, mapId, 'clusters'] as const,
  follows: (
    mapId: number,
    nodeId: string,
    query: { follow_type: 'follower' | 'following' }
  ) => [...keys.all, mapId, nodeId, query] as const,
};

export const { fetch: getMaps, hook: useMaps } = hookify<MapInfo[]>()
  .path('/maps')
  .method('get')
  .key(() => keys.all);

export const { fetch: getMap, hook: useMap } = hookify<MapInfo>()
  .path('/maps/{map_id}')
  .method('get')
  .key(({ params: { map_id } }) => keys.map(map_id));

export const { fetch: getMapGroups, hook: useMapGroups } = hookify<MapGroup[]>()
  .path('/maps/{map_id}/groups')
  .method('get')
  .key(({ params: { map_id } }) => keys.groups(map_id));

export const { fetch: getMapClusters, hook: useMapClusters } = hookify<
  MapCluster[]
>()
  .path('/maps/{map_id}/clusters')
  .method('get')
  .key(({ params: { map_id } }) => keys.clusters(map_id));

export const { fetch: getMapNodes, hook: useMapNodes } = hookify<MapNode[]>()
  .path('/maps/{map_id}/nodes')
  .method('get')
  .key(({ params: { map_id } }) => keys.nodes(map_id));

export const { fetch: getNodeFollows, hook: useNodeFollows } = hookify<
  FollowsNode[]
>()
  .path('/maps/{map_id}/nodes/{node_id}/follows')
  .method('get')
  .key(({ params: { map_id, node_id }, query }) =>
    keys.follows(map_id, node_id, query)
  );
