import type {
  TabProps,
  TabPanelProps,
  TabPanelsProps,
  TabsProps,
  TabListProps,
} from '@chakra-ui/react';

import {
  useColorModeValue,
  Tab as CTab,
  Tabs as CTabs,
  TabPanel as CTabPanel,
  TabPanels as CTabPanels,
  TabList as CTabList,
} from '@chakra-ui/react';

import { colors } from '~/styles';

export const Tabs = (props: TabsProps) => (
  <CTabs size="sm" mt={4} {...(props as any)} />
);

export const TabList = (props: TabListProps) => (
  <CTabList borderBottom="none" {...(props as any)} />
);

export const Tab = (props: TabProps) => {
  const activeTabColor = useColorModeValue(colors.g.primary, colors.g.light);
  const labelColor = useColorModeValue(
    colors.warmGray['1'],
    colors.coolGray['6']
  );
  return (
    <CTab
      color={labelColor}
      px={0}
      mr={6}
      pb={2}
      fontSize="14px"
      position="relative"
      _active={{ color: activeTabColor }}
      _selected={{
        '&::after': {
          position: 'absolute',
          content: '""',
          bottom: '-2px',
          background: activeTabColor,
          height: '2px',
          left: '10%',
          right: '10%',
        },
        color: activeTabColor,
      }}
      _focus={{
        boxShadow: 'none',
      }}
      {...(props as any)}
    />
  );
};

export const TabPanels = (props: TabPanelsProps) => (
  <CTabPanels mx={-4} p="1px" {...(props as any)} />
);

export const TabPanel = (props: TabPanelProps) => (
  <CTabPanel mr={-8} {...(props as any)} />
);
