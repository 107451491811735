const paletteV2 = {
  primary: {
    main: '#5567EE',
    0: '#EBEEFF',
    50: '#CED6FC',
    100: '#B3BDF9',
    150: '#98A6F6',
    200: '#7E8EF3',
    300: '#5567EE',
    400: '#3A4CED',
    500: '#2536E9',
    600: '#1524E2',
    700: '#0A17D2',
    800: '#030DA9',
    900: '#000669',
    1000: '#000333',
  },
  secondary: {
    main: '#1798E8',
    0: '#EBFBFF',
    50: '#CCF3FF',
    100: '#ADE9FF',
    150: '#8FDEFF',
    200: '#71D1FF',
    300: '#3FB4F4',
    400: '#0090CC',
    500: '#077EC4',
    600: '#0068A1',
    700: '#00537D',
    800: '#003D59',
    900: '#002536',
    1000: '#000D12',
  },
  surface: {
    main: '#5A5F6B',
    0: '#FAFCFE',
    50: '#F6FAFE',
    100: '#F3F8FD',
    150: '#EFF5FD',
    200: '#EBF2FC',
    300: '#E4ECFA',
    400: '#DCE5F7',
    500: '#D4DEF2',
    600: '#CAD3E9',
    700: '#B8C1D7',
    800: '#939AAD',
    900: '#5A5F6B',
    1000: '#2B2D33',
  },
  neutral: {
    main: '#5A5F6B',
    0: '#FFF',
    10: '#FAFAFA',
    25: '#F7F7F7',
    50: '#F2F2F2',
    100: '#E4E4E4',
    150: '#D7D7D7',
    200: '#CACACA',
    300: '#B0B0B0',
    400: '#979797',
    500: '#7E7E7E',
    600: '#656565',
    700: '#4C4C4C',
    800: '#323232',
    900: '#191919',
    1000: '#000',
  },
  error: {
    main: '#E85E50',
    300: '#E85E50',
  },
  success: {
    main: '#1DA73E',
    500: '#1DA73E',
  },
  warning: {
    main: '#C18218',
    400: '#FFA24B',
    600: '#C18218',
  },
} as const;

const { primary, secondary, surface, success, warning, error, neutral } =
  paletteV2;

export const colorsV2 = {
  accent: surface[300],
  black: '#000000',
  error: error.main,
  light: surface[0],
  link: secondary[400],
  neutral,
  outline: neutral[100],
  primary,
  secondary,
  secondaryText: neutral[500],
  subtleText: neutral[300],
  success: success.main,
  surface,
  surfaceVariant: '#46464F',
  text: neutral[900],
  warning: warning.main,
  white: '#FFFFFF',
} as const;

export default colorsV2;
