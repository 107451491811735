import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Button, Flex, Icon, Tooltip } from '~/components';
import { SignalCard } from '~/components/signals';
import { Translation } from '~/components/signals/cards/Translation';
import { colors } from '~/styles';
import CloseIcon from '~/public/icons/Close.svg';
import SquareMinusIcon from '~/public/icons/SquareMinus.svg';
import { SignalPlatformTypeInfo } from '~/components/signals/SignalPlatformTypeInfo';
import { useSignalModal } from '../shared';

type Props = {
  isCurrentSignal?: boolean;
  signal: Signal;
  removeSignal?: (signal: Signal) => void;
};

export function SignalPreview({
  isCurrentSignal,
  signal,
  removeSignal,
}: Props): ReactElement {
  const {
    preview: { setCurrentSignal },
  } = useSignalModal();

  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);

  const removeFullTarget = () => {
    if (!signal) return;
    setCurrentSignal({
      ...signal,
      full_target: {
        type: 'unknown',
        value: signal.signal_value,
      },
    });
  };

  return (
    <Box p={2} pl={isCurrentSignal ? 0 : 2} bg={gray6} borderRadius={4}>
      <Box>
        <Flex mb={1} justify="space-between" align="center" gap={2}>
          <SignalPlatformTypeInfo signal={signal} />
          {removeSignal && (
            <Button
              variant="plain"
              onClick={() => removeSignal(signal)}
              noFocusStyle
              mr={-2}
            >
              <Icon icon={CloseIcon} boxSize={4} />
            </Button>
          )}
        </Flex>
      </Box>
      <Box mb={2} position="relative" w="350px">
        <SignalCard signal={signal} width={350} />
        {signal.full_target?.type !== 'unknown' && isCurrentSignal && (
          <Tooltip label="Remove preview">
            <Box
              position="absolute"
              top="4px"
              right="6px"
              as="button"
              w="32px"
              h="32px"
              pl={1}
              pt={2}
              onClick={removeFullTarget}
            >
              <Icon icon={SquareMinusIcon} fill={colors.g.primary} />
            </Box>
          </Tooltip>
        )}
        <Box mt={2}>
          <Translation signal={signal} />
        </Box>
      </Box>
      <BodyText>{signal.notes}</BodyText>
    </Box>
  );
}
