import type { Narrative, Signal } from '~/types/graphika-types';
import { ReactElement, useState } from 'react';
import { useRouter } from 'next/router';
import { useColorModeValue, MenuItem } from '@chakra-ui/react';
import { Box, SimpleDotsMenu } from '~/components';
import { EditSignalModal } from '~/components/authoring/modals/EditSignalModal';
import { useRemoveSignal } from '~/queries/mutations/signal';
import { colors } from '~/styles';

type Props = { signal: Signal; narrative: Narrative };

export function SignalItemMenu({ signal, narrative }: Props): ReactElement {
  const [showEditModal, setShowEditModal] = useState(false);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const router = useRouter();
  const removeSignal = useRemoveSignal(
    parseInt(router.query.narrativeId as string)
  );

  const handleRemove = () => {
    const response = confirm('Are you sure you want to delete the signal?');
    if (response) {
      removeSignal.mutate(signal.id!);
    }
  };

  return (
    <Box mr={-4} mt={-3}>
      <SimpleDotsMenu placement="bottom-end" color={primary}>
        <MenuItem onClick={() => setShowEditModal(true)}>Edit</MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </SimpleDotsMenu>
      {showEditModal && (
        <EditSignalModal
          signal={signal}
          narrative={narrative}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </Box>
  );
}
