import type { ReactElement } from 'react';
import { useColorModeValue, Avatar, VStack } from '@chakra-ui/react';
import { BodyText, Box, Flex } from '~/components';
import { colors } from '~/styles';

type Props = {
  profileImage?: string;
  topContent: string;
  bottomContent?: string;
};

export function SignalCardUserSection({
  profileImage,
  topContent,
  bottomContent,
}: Props): ReactElement {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  return (
    <VStack align="left" spacing={2} mb={2}>
      <Flex gap={2}>
        <Avatar h={10} w={10} src={profileImage} />
        <Box>
          <BodyText fontSize="16px" noOfLines={2} overflowWrap="anywhere">
            {topContent}
          </BodyText>
          <BodyText color={gray1} noOfLines={2} overflowWrap="anywhere">
            {bottomContent}
          </BodyText>
        </Box>
      </Flex>
    </VStack>
  );
}
