import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  useColorModeValue,
} from '@chakra-ui/react';
import { Box, ContentRenderer, Image } from '~/components';
import { colors } from '~/styles';

type Props = {
  text: string;
  placement: PopoverProps['placement'];
  boxSize?: number | string;
  filled?: boolean;
};

export function DocsLinkPopover({
  text,
  placement,
  boxSize = 4,
  filled,
}: Props) {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);

  return (
    <Popover placement={placement}>
      <PopoverTrigger>
        <Image
          src={
            filled
              ? '/docs/circle-question-dark.png'
              : '/docs/circle-question.png'
          }
          boxSize={boxSize}
          alt="link to help docs"
          cursor="pointer"
        />
      </PopoverTrigger>
      <PopoverContent py={1} w="230px">
        <PopoverBody>
          <Box mx={-1}>
            <ContentRenderer color={gray1}>{text}</ContentRenderer>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
