import { NarrativeFeed } from '~/types/graphika-types';
import { hookify } from './utils';

const keys = {
  all: ['narrative_feeds'] as const,
};

export const { fetch: getNarrativeFeeds, hook: useNarrativeFeeds } = hookify<
  NarrativeFeed[]
>()
  .path('/narrative_feeds')
  .method('get')
  .key(() => keys.all);

export const feedKeys = keys;
