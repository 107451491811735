import type { ReactElement } from 'react';
import type { CheckboxProps } from '@chakra-ui/react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  useColorModeValue,
} from '@chakra-ui/react';
import { useField } from 'formik';
import { Checkbox, Flex } from '~/components';
import { colors } from '~/styles';

type Props = CheckboxProps & {
  label?: string;
  name: string;
  isChecked?: boolean; // only to be used for manual override if field is not part of formik schema
};

export function FormCheckbox({
  label,
  name,
  isChecked,
  ...props
}: Props): ReactElement {
  const [field, { touched, error }] = useField(name!);
  const isInvalid = !!(touched && error);
  const textColor = useColorModeValue(colors.black, colors.white);
  return (
    <FormControl isInvalid={isInvalid}>
      <Flex align="center">
        <Checkbox
          id={props.id ?? name}
          {...(field as any)}
          {...(props as any)}
          isChecked={isChecked !== undefined ? !!isChecked : field.value}
        />
        {label && (
          <FormLabel
            textTransform="none"
            fontSize="14px"
            pl={2}
            pt={1}
            htmlFor={props.id ?? name}
            color={textColor}
            mb={1}
            letterSpacing={0}
          >
            {label}
          </FormLabel>
        )}
      </Flex>
      <FormErrorMessage fontStyle="BodyText" color={colors.status.red}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}
