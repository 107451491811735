import type { RedditData } from '~/types/graphika-types';
import { useColorModeValue, Text } from '@chakra-ui/react';
import {
  BodyText,
  CaptionText,
  Card,
  Divider,
  Flex,
  Icon,
  Link,
  Spacer,
  TitleHeader,
  Tooltip,
  ContentRenderer,
} from '~/components';
import { formatCompactNumber } from '~/lib/numbers';
import RedditLogo from '~/public/icons/Social-Tile/Reddit.svg';
import { colors } from '~/styles';

export function RedditPostCard({ redditPost }: { redditPost: RedditData }) {
  const grayText = useColorModeValue(colors.coolGray[3], colors.warmGray[3]);
  const darkGrayText = useColorModeValue(
    colors.coolGray[2],
    colors.warmGray[4]
  );
  const { userLink } = parseRedditHtml(redditPost.text);
  if (!userLink) {
    return (
      <Card>
        <TitleHeader>Could not render preview</TitleHeader>
      </Card>
    );
  }

  return (
    <Card wordBreak="break-word">
      <Flex direction="column" height="100%">
        <Flex>
          <BodyText fontSize="16px" mb={4}>
            {redditPost.title}
          </BodyText>
          <Spacer />
          <Tooltip bg={colors.brands.reddit} label="Open in Reddit">
            <span>
              <Link basic href={redditPost.link} target="_blank">
                <Icon
                  boxSize="24px"
                  color={colors.brands.reddit}
                  icon={RedditLogo}
                  mr={-2}
                />
              </Link>
            </span>
          </Tooltip>
        </Flex>
        <ContentRenderer>{redditPost.text}</ContentRenderer>
        <Divider />
        <BodyText>
          <strong>r/{redditPost.subreddit}</strong>
          {' • '}
          <Text as="span" color={grayText}>
            posted by {redditPost.author.slice(1)}
          </Text>
        </BodyText>
        <CaptionText color={darkGrayText}>
          {formatCompactNumber(redditPost.upvotes)} upvotes •{' '}
          {formatCompactNumber(redditPost.comments)} comments
        </CaptionText>
      </Flex>
    </Card>
  );
}

function parseRedditHtml(html: string | null | undefined) {
  if (!html) return {};
  const extractedLinks = html.match(/<a href=\".*?<\/a>/g);
  if (!extractedLinks) {
    return {};
  }
  const linkObjects = extractedLinks.reverse().map((a) => ({
    a: a,
    href: a.match(/\"(http.*?)\"/)?.[1],
    fullText: a.match(/>(.*?)<\/a>/)?.[1],
    imgSrc: a.match(/src=\"(.*?)\"/)?.[1].replace(/&amp;/g, '&'),
    imgAlt: a.match(/alt=\"(.*?)\"/)?.[1] ?? 'unknown image',
  }));
  const paragraphs = [...html.matchAll(/<p>(.*?)<\/p>/g)].map((m) => m[1]);
  const [commentsLink, postLink, userLink, optionalEmbeddedLink] = linkObjects;
  return { commentsLink, postLink, userLink, optionalEmbeddedLink, paragraphs };
}
