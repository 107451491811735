import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import {} from 'react';
import {} from '@chakra-ui/react';
import { SignalText, SignalUrl } from '~/components/signals/cards';
import { isUrl } from '~/lib/urls';

type Props = { signal: Signal };

export function SignalFallback({ signal }: Props): ReactElement {
  if (isUrl(signal.signal_value)) {
    return <SignalUrl signal={signal} />;
  } else {
    return <SignalText signal={signal} />;
  }
}
