import type { PropsWithChildren, ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { Children, useState } from 'react';
import { Collapse, useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, ContentRenderer, Flex, Icon } from '~/components';
import DropdownIcon from '~/public/icons/Navigation/Down.svg';
import InfoCircleIcon from '~/public/icons/InfoCircle.svg';
import { capitalize } from '~/lib/text';
import { colors } from '~/styles';
import { SignalCategory } from '~/types/signal-types';

const categoryDescription: Record<SignalCategory, string> = {
  actor: `Actor Signals: Indicators and selectors that help identify the **actors** participating in online activity. These can include prominent accounts, names, email addresses, etc.`,
  behavior: `Behavior Signals: Indicators and selectors that help identify the **behaviors** used to conduct online activity. These can include posting patterns, naming conventions, account creation times, etc.`,
  content: `Content Signals: Indicators and selectors that help identify a certain type of online **content**. These can include key terms, hashtags, example posts, etc.`,
};

type Props = PropsWithChildren<{
  categoryName: SignalCategory;
  hideDescription?: boolean;
  signals: Signal[];
  noMargin?: boolean;
}>;

export function SignalCategorySection({
  children,
  categoryName,
  hideDescription,
  signals,
  noMargin,
}: Props): ReactElement {
  const [isOpen, setIsOpen] = useState(true);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray3 = useColorModeValue(colors.coolGray[3], colors.warmGray[4]);
  const hasSignals = Children.toArray(children).length > 0;

  if (!hasSignals) {
    return (
      <Box>
        <Flex px={noMargin ? 0 : 4} align="center" cursor="not-allowed">
          <Icon
            icon={DropdownIcon}
            boxSize={6}
            ml={-1}
            fill={gray1}
            transform={`rotate(-90deg)`}
          />
          <BodyText color={gray1} fontWeight={500}>
            {capitalize(categoryName)}
          </BodyText>
          <Box mt={2} ml={3}>
            <Icon icon={InfoCircleIcon} fill={gray1} boxSize={6} />
          </Box>
          <BodyText color={gray1} fontStyle="italic">
            No {categoryName} signals identified for this insight
          </BodyText>
        </Flex>
      </Box>
    );
  }

  return (
    <Box>
      <Flex
        onClick={() => setIsOpen(!isOpen)}
        align="center"
        cursor="pointer"
        px={noMargin ? 0 : 4}
      >
        <Icon
          icon={DropdownIcon}
          transform={!isOpen ? `rotate(-90deg)` : ''}
          transition="0.2s transform ease-in-out"
          boxSize={6}
          ml={-1}
        />
        <BodyText fontWeight={500}>
          {`${capitalize(categoryName)} (${signals.length})`}
        </BodyText>
      </Flex>
      {!hideDescription && (
        <Collapse in={isOpen}>
          <ContentRenderer color={gray3} mt={1} mb={4} px={4}>
            {categoryDescription[categoryName]}
          </ContentRenderer>
        </Collapse>
      )}
      <Collapse in={isOpen}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
}
