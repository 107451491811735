import type { HeadingTagType } from '@lexical/rich-text';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';
import {
  BoxProps,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
} from 'lexical';
import { useCallback, useEffect, useState } from 'react';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
} from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { NumberParam, useQueryParam } from 'use-query-params';
import {
  BodyText,
  Box,
  Button,
  CaptionText,
  Flex,
  Icon,
  Tooltip,
} from '~/components';
import BoldIcon from '~/public/icons/editor/bold.svg';
import CollapseIcon from '~/public/icons/editor/collapse.svg';
import FullscreenIcon from '~/public/icons/editor/fullscreen.svg';
import H1Icon from '~/public/icons/editor/h1.svg';
import H2Icon from '~/public/icons/editor/h2.svg';
import H3Icon from '~/public/icons/editor/h3.svg';
import ImageDataEditorImage from '~/public/icons/editor/image.svg';
import ItalicIcon from '~/public/icons/editor/italic.svg';
import LinkIcon from '~/public/icons/editor/link.svg';
import ParagraphIcon from '~/public/icons/editor/normal.svg';
import NumberIcon from '~/public/icons/editor/ol.svg';
import QuoteIcon from '~/public/icons/editor/quote.svg';
import BulletIcon from '~/public/icons/editor/ul.svg';
import UnderlineIcon from '~/public/icons/editor/underline.svg';
import NavigationDownIcon from '~/public/icons/Navigation/Down.svg';
import { useAddNarrativeImage } from '~/queries/mutations/narrative';
import { colors } from '~/styles';
import {
  DynamicWidgetToolbarButton,
  InsertImageModal,
  InsertImageModalForm,
  INSERT_IMAGE_COMMAND,
  INSERT_LINK_COMMAND,
} from './plugins';

type BlockType =
  | Exclude<HeadingTagType, 'h4' | 'h5' | 'h6'>
  | 'paragraph'
  | 'number'
  | 'bullet'
  | 'quote';

const blockTypeToBlockName: { [key in BlockType]: string } = {
  bullet: 'Bulleted List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote',
};

const blockTypeToIcon: { [key in BlockType]: () => ReactNode } = {
  bullet: BulletIcon,
  h1: H1Icon,
  h2: H2Icon,
  h3: H3Icon,
  number: NumberIcon,
  paragraph: ParagraphIcon,
  quote: QuoteIcon,
};

const EditorMenuItem = ({
  icon,
  children,
  ...props
}: MenuItemProps & PropsWithChildren<{ icon: () => ReactNode }>) => (
  <MenuItem {...(props as any)}>
    <Icon icon={icon} boxSize="22px" mr={2} />
    {children}
  </MenuItem>
);

const btnStyle: ButtonProps = {
  w: '32px',
  h: '32px',
  p: 0,
  px: 1,
  borderRadius: 4,
};

const ToolbarButton = ({
  children,
  isSelected,
  tooltipLabel = undefined,
  ...props
}: PropsWithChildren<
  BoxProps & {
    isSelected: boolean;
    tooltipLabel?: string;
  }
>) => {
  const lavender = useColorModeValue(colors.g.lightLavender, colors.g.plum);
  const gray5 = useColorModeValue(colors.warmGray[5], colors.coolGray[1]);
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Tooltip label={tooltipLabel} display={!showTooltip ? 'none' : 'inherit'}>
      <Box
        as="button"
        ml={2}
        sx={btnStyle}
        _hover={{
          bg: gray5,
        }}
        bg={isSelected ? lavender : undefined}
        type="button"
        {...(props as any)}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

type Props = {
  isFullscreen: boolean;
  setIsFullscreen: (fullscreen: boolean) => void;
};
export function Toolbar({
  isFullscreen,
  setIsFullscreen,
}: Props): ReactElement {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [blockType, setBlockType] = useState<BlockType>('paragraph');
  const [showImageModal, setShowImageModal] = useState(false);

  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const black = useColorModeValue(colors.black, colors.white);
  const white = useColorModeValue(colors.white, colors.black);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[1]);
  const border = `1px solid ${gray4}`;

  const isMac = navigator.userAgent.toLowerCase().indexOf('mac') !== -1;
  const hotkey = isMac ? '⌘' : 'CTRL';
  const focusEditor = () => {
    const editorSelector: HTMLElement | null = document.querySelector(
      `[data-lexical-editor="true"]`
    );
    editorSelector?.focus();
  };
  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElement();
      if (element === null) return;
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type === 'ul' ? 'bullet' : 'number');
        } else {
          const type: string = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type as BlockType);
        }
      }
    }
  }, [editor]);

  const formatParagraph = () => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
    setBlockType('paragraph');
  };

  const formatHeading = (headingTagType: HeadingTagType) => {
    if (blockType !== headingTagType) {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(headingTagType));
        }
      });
    }
    setBlockType(headingTagType as BlockType);
    focusEditor();
  };

  const formatBulletList = () => {
    if (blockType !== 'bullet') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setBlockType('bullet');
    focusEditor();
  };

  const formatNumberedList = () => {
    if (blockType !== 'number') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
    setBlockType('number');
    focusEditor();
  };

  const formatQuote = () => {
    if (blockType !== 'quote') {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    }
    focusEditor();
  };

  const narrativeId = Number(useQueryParam('narrativeId', NumberParam)[0]);
  const addNarrativeImage = useAddNarrativeImage(narrativeId);
  const handleInsertImage = async ({ file, caption }: InsertImageModalForm) => {
    const result = await addNarrativeImage.mutateAsync({ file, caption });

    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
      caption: result.caption!,
      // id: result.id!,
      // narrative_id: result.narrative_id!,
      url: result.image_url!,
    });
  };

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      })
    );
  }, [updateToolbar, editor]);

  return (
    <Box
      py={1}
      bg={white}
      w="100%"
      border={border}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      position="sticky"
      top={0}
      zIndex={2}
    >
      <Flex justify="space-between" gap={2}>
        <Flex>
          <Menu placement="bottom-end" variant="text-editor">
            <MenuButton ml={4}>
              <Flex align="center" justify="space-between">
                <Icon icon={blockTypeToIcon[blockType]} boxSize={6} />
                <BodyText>{blockTypeToBlockName[blockType]}</BodyText>
                <Icon icon={NavigationDownIcon} boxSize={6} />
              </Flex>
            </MenuButton>
            <MenuList>
              <EditorMenuItem icon={ParagraphIcon} onClick={formatParagraph}>
                Normal
              </EditorMenuItem>
              <EditorMenuItem icon={H1Icon} onClick={() => formatHeading('h1')}>
                Heading 1
              </EditorMenuItem>
              <EditorMenuItem icon={H2Icon} onClick={() => formatHeading('h2')}>
                Heading 2
              </EditorMenuItem>
              <EditorMenuItem icon={H3Icon} onClick={() => formatHeading('h3')}>
                Heading 3
              </EditorMenuItem>
              <EditorMenuItem icon={BulletIcon} onClick={formatBulletList}>
                Bulleted List
              </EditorMenuItem>
              <EditorMenuItem icon={NumberIcon} onClick={formatNumberedList}>
                Numbered List
              </EditorMenuItem>
              <EditorMenuItem icon={QuoteIcon} onClick={formatQuote}>
                Quote
              </EditorMenuItem>
            </MenuList>
          </Menu>
          <ToolbarButton
            onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')}
            isSelected={isBold}
            tooltipLabel={`Bold (${hotkey}B)`}
          >
            <Icon icon={BoldIcon} boxSize={6} fill={isBold ? primary : black} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
            }
            isSelected={isItalic}
            tooltipLabel={`Italic (${hotkey}I)`}
          >
            <Icon
              icon={ItalicIcon}
              boxSize={6}
              fill={isItalic ? primary : black}
            />
          </ToolbarButton>
          <ToolbarButton
            onClick={() =>
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
            }
            isSelected={isUnderline}
            tooltipLabel={`Underline (${hotkey}U)`}
          >
            <Icon
              icon={UnderlineIcon}
              boxSize={6}
              fill={isUnderline ? primary : black}
              mt={1}
            />
          </ToolbarButton>
          <Box border={`1px solid ${gray4}`} my="6px" mr={2} ml={`10px`} />
          <ToolbarButton
            onClick={() =>
              editor.dispatchCommand(INSERT_LINK_COMMAND, undefined)
            }
            isSelected={false}
            tooltipLabel={`Link (${hotkey}K)`}
          >
            <Icon icon={LinkIcon} fill={black} boxSize={6} />
          </ToolbarButton>
          <ToolbarButton
            onClick={() => setShowImageModal(true)}
            isSelected={showImageModal}
            tooltipLabel={`Add image`}
            _focusVisible={{
              outline: 'none',
            }}
          >
            <Icon icon={ImageDataEditorImage} fill={black} mt="6px" />
          </ToolbarButton>
          <ToolbarButton isSelected={false} tooltipLabel={`Add Chart`}>
            <DynamicWidgetToolbarButton />
          </ToolbarButton>
        </Flex>
        <Button
          variant="text"
          gap={2}
          mr={4}
          onClick={() => setIsFullscreen(!isFullscreen)}
        >
          <Icon
            icon={isFullscreen ? CollapseIcon : FullscreenIcon}
            boxSize={6}
            fill={primary}
          />
          <CaptionText color={primary}>
            {isFullscreen ? 'Collapse' : 'Full View'}
          </CaptionText>
        </Button>
      </Flex>
      <InsertImageModal
        isOpen={showImageModal}
        onClose={() => setShowImageModal(false)}
        onSubmit={handleInsertImage}
      />
    </Box>
  );
}
