import { useColorModeValue } from '@chakra-ui/react';
import { KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { Box, Textarea, Tooltip } from '~/components';
import { colors } from '~/styles';

type Props = {
  value: string;
  onSubmit: (value: string) => void;
  onChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  tooltipText?: string;
};

export function AutoGrowTextArea({
  value,
  onChange,
  onSubmit,
  placeholder = '',
  disabled,
  tooltipText = '',
}: Props) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray2 = useColorModeValue(colors.warmGray[2], colors.coolGray[5]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  const [_value, _setValue] = useState(value);
  useAutosizeTextArea(textAreaRef.current, _value ?? '');

  useEffect(() => {
    _setValue(value);
  }, [value]);

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.code === 'Enter') {
      onSubmit(_value);
      e.preventDefault();
      e.stopPropagation();
    }
  };
  useEffect(() => {
    onChange(_value);
  }, [_value, onChange]);

  return (
    <Tooltip
      label={tooltipText}
      variant="monochrome"
      hasArrow={false}
      w="185px"
      textAlign="center"
      color={gray1}
      background={`${gray6} !important`}
      visibility={disabled && tooltipText ? 'visible' : 'hidden'}
    >
      <Box className="hide-placeholder-on-pdf" w="100%">
        <Textarea
          mt={0}
          ref={textAreaRef}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            _setValue(e.currentTarget.value);
          }}
          placeholder={placeholder}
          onBlur={() => {
            onSubmit(_value);
          }}
          rows={1}
          value={_value}
          overflow="hidden"
          resize="none"
          px={2}
          _focus={{
            outline: 'none',
          }}
          _focusVisible={{
            outline: 'none',
          }}
          _disabled={{
            background: gray4,
            color: gray2,
            cursor: 'not-allowed',
          }}
          disabled={disabled}
        />
      </Box>
    </Tooltip>
  );
}

const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string
) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px';
      const scrollHeight = textAreaRef.scrollHeight;
      textAreaRef.style.height = scrollHeight + 'px';
    }
  }, [textAreaRef, value]);
};
