import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import boxShadows from '../boxShadows';
import colors from '../colors';

const warmGray3 = (props: StyleFunctionProps) =>
  mode(colors.warmGray[3], colors.coolGray[4])(props);
const warmGray5 = (props: StyleFunctionProps) =>
  mode(colors.warmGray[5], colors.coolGray[1])(props);
const primary = (props: StyleFunctionProps) =>
  mode(colors.g.primary, colors.g.light)(props);
const dark = (props: StyleFunctionProps) =>
  mode(colors.g.dark, colors.g.pale)(props);

const baseStyle = defineStyle((props) => ({
  textStyle: 'ButtonText',
  boxSizing: 'border-box',
  border: `1px solid white`,
  borderRadius: 8,
  background: colors.g.primary,
  borderColor: colors.g.primary,
  color: colors.white,
  opacity: 1,
  px: 4,
  py: 0,
  _hover: {
    background: primary(props),
    borderColor: dark(props),
    color: colors.g.lavender,
  },
  _disabled: {
    opacity: 1,
    background: warmGray5(props),
    borderColor: warmGray5(props),
    color: warmGray3(props),
  },
}));

const outline = defineStyle((props) => ({
  backgroundColor: 'inherit',
  borderColor: primary(props),
  color: primary(props),
  border: `1px solid ${primary(props)}`,
  boxShadow: boxShadows.button,
  outline: 'none',
  _hover: {
    backgroundColor: 'inherit',
    borderColor: dark(props),
    color: dark(props),
  },
  _disabled: {
    opacity: 1,
    backgroundColor: 'inherit',
    borderColor: warmGray3(props),
    color: warmGray3(props),
    _hover: {
      backgroundColor: 'inherit',
      borderColor: warmGray3(props),
      color: warmGray3(props),
    },
  },
}));

const text = defineStyle((props) => ({
  background: 'transparent',
  border: 'none',
  boxShadow: 'none',
  padding: 1,
  fontSize: '12px',
  _disabled: {
    background: 'transparent',
  },
  _hover: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  _selected: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  _focus: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
}));

const plain = defineStyle((props) => ({
  background: 'inherit',
  borderColor: colors.white,
  color: colors.g.primary,
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  _hover: {
    background: 'inherit',
    color: colors.g.dark,
    _disabled: {
      background: 'inherit',
      color: warmGray3(props),
      _loading: {
        background: 'inherit',
        color: colors.g.primary,
      },
    },
  },
  _disabled: {
    background: 'transparent',
    color: warmGray3(props),
    opacity: 1,
    _loading: {
      background: colors.white,
      color: colors.g.primary,
    },
  },
}));

export const Button = defineStyleConfig({
  baseStyle,
  sizes: {
    md: {
      // Chakra default styling takes precedence for most properties,
      // so we have to set font-size/line-height here instead of relying
      // on textStyle: 'ButtonText'.
      fontSize: '14px',
      lineHeight: '16px',
      height: '32px',
      fontWeight: 500,
      boxShadow: boxShadows.button,
    },
  },
  variants: { outline, text, plain },
  // @ts-ignore
  defaultProps: { variant: 'primary' },
});
