import type { MapViewMode } from '@graphika/map-viewer';
import type { HgMapNode } from '~/lib/hypergraph';
import type { MapCluster, MapGroup, MapNode } from '~/types/graphika-types';
import { SlideFade, useColorModeValue } from '@chakra-ui/react';
import {
  LabelsPlugin,
  MVNode,
  MapViewerComposer,
  useMvConfig,
} from '@graphika/map-viewer';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { BodyText, Flex, Icon } from '~/components';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins/dynamic-insights';
import { parseApiNodes } from '~/components/maps';
import { useSubscribeSegmentTree } from '~/lib/stores/segment-tree';
import { useMapLabels } from '~/pages/maps/[mapId]';
import InfoIcon from '~/public/icons/Info.svg';
import { colors } from '~/styles';
import { MvRelevantNodeStylePlugin } from './MvRelevantNodeStylePlugin';
import { segmentTreeDefaults } from './RelevantMapNodesWrapper';
import { RMNPopoverPlugin } from './RMNPopoverPlugin';
import { ViewModeControl } from './ViewModeControl';
import { ZoomControl } from './ZoomControl';

type Props = {
  clusters: MapCluster[];
  groups: MapGroup[];
  hgNodes: HgMapNode[];
};

const globalScale = 5.25;
const minNodeSize = 1.5;

export function MvWidgetContainer({ clusters, hgNodes = [], groups }: Props) {
  const { segments, presets, mode, data } = useRelevantMapNodes();
  const [setOrbitControls] = useMvConfig((mv) => [mv.setOrbitControls]);
  const [viewMode, setViewMode] = useState<MapViewMode>('2d');
  const [showDragWarning, setShowDragWarning] = useState(false);
  const white = useColorModeValue(colors.white, colors.coolGray[2]);

  useEffect(() => {
    setViewMode(presets.viewMode ?? '2d');
  }, [presets.viewMode]);

  const [labels, onChange] = useMapLabels({
    clusters,
    groups,
    initialSegments: segments,
    defaults: segmentTreeDefaults,
  });
  useSubscribeSegmentTree(
    (next) => onChange(next.groups, next.clusters),
    [onChange]
  );
  const nodes: MapNode[] = useMemo(
    () =>
      hgNodes.map((node) => ({
        ...node,
        matches_search: undefined,
      })) ?? [],
    [hgNodes]
  );
  const parsedNodes = useMemo(() => {
    return parseApiNodes(nodes, clusters);
  }, [nodes, clusters]);

  useEffect(() => {
    setOrbitControls({ enableZoom: false });
  }, [setOrbitControls]);

  const handleMouseMove = (e: MouseEvent) => {
    if (
      e.buttons === 1 &&
      !showDragWarning &&
      !e.shiftKey &&
      viewMode === '2d'
    ) {
      setShowDragWarning(true);
      setTimeout(() => {
        setShowDragWarning(false);
      }, 1000);
    }
  };

  const [h, setH] = useState<number>();
  return (
    <Flex
      bg={white}
      h={h}
      ref={(div) => void (div && setH(div.clientWidth))}
      justify="center"
      position="relative"
      mx={-4}
      onMouseMove={handleMouseMove}
    >
      <SlideFade in={showDragWarning}>
        <DragWarning />
      </SlideFade>
      <MapViewerComposer
        nodes={parsedNodes}
        globalScale={globalScale}
        minNodeSize={minNodeSize}
        viewMode={viewMode}
        optimalZoom
      >
        <MvRelevantNodeStylePlugin hgNodes={hgNodes} />
        <RMNPopoverPlugin viewMode={viewMode} hgNodes={data?.nodes ?? []} />
        <LabelsPlugin
          labels={labels}
          distance={0.7}
          labelType="HTML"
          labelSize="sm"
        />
      </MapViewerComposer>
      <ViewModeControl value={viewMode} onChange={setViewMode} />
      <ZoomControl mode={mode} nodeCount={nodes.length} />
    </Flex>
  );
}

const DragWarning = () => {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  return (
    <Flex
      position="absolute"
      top={2}
      left={4}
      bg={gray6}
      borderRadius={4}
      color={gray1}
      px={2}
      py={1}
      gap={2}
      align="center"
      w="210px"
    >
      <Icon icon={InfoIcon} fill={gray1} boxSize={4} />
      <BodyText>Hold Shift to move the map</BodyText>
    </Flex>
  );
};
