import type {
  MapCluster,
  MapGroup,
  MapInfo,
  MapNode,
  Platform,
} from '~/types/graphika-types';
import { useRouter } from 'next/router';
import { useMemo, useRef } from 'react';
import { BodyText, Box, Flex, Icon, TitleHeader } from '~/components';
import { HighlightedNodeInfo } from '~/components/maps';
import { platformIcon } from '~/components/maps';
import { formatShortDate } from '~/lib/date';
import CloseIcon from '~/public/icons/Close.svg';
import { colors } from '~/styles';
import { SegmentSelection, SegmentTreeListener } from './SegmentTree';

type Props = {
  map?: MapInfo;
  clusters: MapCluster[];
  groups: MapGroup[];
  onChange: SegmentTreeListener;
  highlightedNode?: MapNode;
  setHighlightedNode: (node: MapNode | undefined) => void;
  nodes: MapNode[];
};

export function MapSidebar({
  map,
  clusters,
  nodes,
  groups,
  onChange,
  highlightedNode,
  setHighlightedNode,
}: Props) {
  const router = useRouter();

  const boxRef = useRef<HTMLDivElement>(null);
  const boxHeight = boxRef.current?.getBoundingClientRect().height ?? 0;
  const hasDataCollectionRange =
    map?.data_collection_range?.start_time &&
    map?.data_collection_range?.end_time;

  const dataSources = useMemo(() => {
    return [...new Set(nodes.map((node) => node.platform))] as Platform[];
  }, [nodes]);

  if (!groups.length || !clusters.length) return null;

  return (
    <Box bg={colors.mapViewer.mapSidebar} w="398px" minW="398px" h="100%">
      {map && (
        <Box h={`calc(100% - ${boxHeight}px)`}>
          <Box ref={boxRef}>
            {highlightedNode ? (
              <HighlightedNodeInfo
                node={highlightedNode}
                setHighlightedNode={setHighlightedNode}
                map={map}
                clusters={clusters}
                groups={groups}
              />
            ) : (
              <Box p={4} pb={2} pr={0} w="100%" mb={2}>
                <Flex
                  align="center"
                  w="100%"
                  justify="space-between"
                  mb={hasDataCollectionRange ? 2 : 0}
                >
                  <TitleHeader color={colors.white}>{map.name}</TitleHeader>
                  <Flex
                    align="center"
                    justify="center"
                    cursor="pointer"
                    as="button"
                    onClick={() => router.push('/maps')}
                    h="24px"
                    w="24px"
                    borderRadius="100%"
                    bg={colors.mapViewer.bgGray}
                    mr={2}
                  >
                    <Icon icon={CloseIcon} fill={colors.white} h={6} w={6} />
                  </Flex>
                </Flex>
                <Flex align="center" my={2} gap={1.5}>
                  <BodyText color={colors.white} mr={1}>
                    Data sources:
                  </BodyText>
                  {dataSources.map((platform) => (
                    <Icon
                      icon={platformIcon[platform]}
                      boxSize={platform === 'twitter' ? 5 : 7}
                      key={platform}
                      fill={colors.white}
                    />
                  ))}
                </Flex>
                {hasDataCollectionRange && (
                  <BodyText color={colors.white}>
                    {formatShortDate(
                      new Date(map.data_collection_range?.start_time ?? '')
                    )}
                    {` - `}
                    {formatShortDate(
                      new Date(map.data_collection_range?.end_time ?? '')
                    )}
                  </BodyText>
                )}
              </Box>
            )}
          </Box>
          )
          {groups.length && clusters.length && (
            <SegmentSelection
              onChange={onChange}
              clusters={clusters}
              groups={groups}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
