import { StyleFunctionProps } from '@chakra-ui/theme-tools';
import { chakraGlobals } from './lexical-theme';

const global = (props: StyleFunctionProps) => ({
  'html,body': {
    padding: 0,
    margin: 0,
  },
  '*': {
    fontFamily: 'Roboto, sans-serif',
  },
  '.navigator .telescope-content': {
    display: 'none',
  },
  ...chakraGlobals(props),
});

export default global;
