import type { Signal } from '~/types/graphika-types';
import { buildMutation, hookify } from './utils';

const keys = {
  all: ['narrative_signals', 'classification'] as const,
  classification: (query: object) => [...keys.all, query] as const,
  fileClassification: () => [...keys.all, 'file'] as const,
};

export const { fetch: getClassification, hook: useClassification } = hookify<
  Partial<Signal>[]
>()
  .path('/narrative_signals/classification')
  .method('get')
  .key(keys.classification);

export const useFileClassification = buildMutation(
  '/narrative_signals/classification/file',
  'post',
  () =>
    ({ file }: { file: File }) => {
      const formData = new FormData();
      formData.append('file', file);
      return { form: formData };
    },
  [],
  { mutationKey: keys.fileClassification() }
);

export const classificationKeys = keys;
