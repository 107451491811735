import type { ReactElement } from 'react';
import type { Narrative, SignalWithCommunities } from '~/types/graphika-types';
import type { Communities } from '~/lib/hooks/useMapsWithGroups';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useColorModeValue, Collapse } from '@chakra-ui/react';
import { NumberParam, useQueryParams } from 'use-query-params';
import {
  BodyText,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  SignalCard,
  SignalEngagmentCount,
  SignalItemMenu,
  SignalPlatformTypeInfo,
} from '~/components';
import { Translation } from '~/components/signals/cards/Translation';
import { getValidSignalLink } from '~/lib/signals';
import { Analytics } from '~/lib/analytics';
import { colors } from '~/styles';
import OpenInNewTabIcon from '~/public/icons/OpenInNewTab.svg';
import { SignalCommunities } from './SignalCommunities';
import { LegacySignalCard } from './LegacySignalCard';

type Props = {
  narrative: Narrative;
  signal: SignalWithCommunities;
  inEditor?: boolean;
  feedsCommunities?: Communities[];
};

export function SignalItem({
  signal,
  inEditor,
  narrative,
  feedsCommunities,
}: Props): ReactElement {
  const [{ signalId }, setQuery] = useQueryParams({
    communityId: NumberParam,
    signalId: NumberParam,
  });
  const isSelectedSignal = signalId === signal.id;
  const dark = useColorModeValue(colors.g.dark, colors.card.darkMode);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const hoveredCardBg = useColorModeValue('#f4f4f4', '#353B45');
  const pale = useColorModeValue(colors.g.pale, colors.g.primary);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  const [isHovered, setIsHovered] = useState(false);
  const [showCommunities, setShowCommunities] = useState(isSelectedSignal);
  const router = useRouter();
  const hasCommunities = signal.communities?.length > 0;

  const shouldShowCommunities =
    router.pathname !== '/authoring' && hasCommunities;

  useEffect(() => {
    if (signal.id !== signalId && showCommunities) {
      setShowCommunities(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signalId]);

  const isLegacySignal = signal.version === '1';
  const showBottomRow = hasCommunities || getValidSignalLink(signal);
  const displayCommunities = isHovered && !inEditor && shouldShowCommunities;
  const displayOpenInTab = isHovered && getValidSignalLink(signal);

  return (
    <Box
      pt={4}
      pb={2}
      sx={{
        ':not(:last-of-type)': {
          borderBottom: `1px solid ${gray4}`,
        },
        transition: '0.2s all ease-in-out',
      }}
      _hover={{
        bg: hoveredCardBg,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box px={4}>
        {!isLegacySignal && <SignalPlatformTypeInfo signal={signal} />}
        <Box my={2}>
          <Flex justify="space-between">
            {isLegacySignal ? (
              <LegacySignalCard signal={signal} key={signal.signal_value} />
            ) : (
              <SignalCard signal={signal} />
            )}
            {inEditor && !isLegacySignal && (
              <SignalItemMenu signal={signal} narrative={narrative!} />
            )}
          </Flex>
        </Box>
        <Translation signal={signal} />
        <BodyText mb={2}>{signal.notes}</BodyText>
        {showBottomRow && (
          <Flex align="center" justify="space-between" mb={1}>
            {hasCommunities ? (
              <SignalEngagmentCount signal={signal} />
            ) : (
              <Box h={8} />
            )}
            <Flex align="center" mt={-2}>
              {displayCommunities ? (
                <Button
                  variant="plain"
                  noFocusStyle
                  pt={2}
                  onClick={() => {
                    if (isSelectedSignal && showCommunities) {
                      setQuery({
                        signalId: undefined,
                        communityId: undefined,
                      });
                    } else {
                      Analytics.event('signal', {
                        action: 'show_community_details',
                        insight: narrative.title,
                        insight_id: narrative.id.toString(),
                        signal: signal.signal_value,
                        signal_id: (signal.id ?? '').toString(),
                      });
                      setQuery({
                        signalId: signal.id,
                        communityId: undefined,
                      });
                    }
                    setShowCommunities(!showCommunities);
                  }}
                >
                  {showCommunities ? 'Hide' : 'Show'} community details
                </Button>
              ) : (
                <Box h={8} />
              )}
              {displayOpenInTab && displayCommunities && (
                <Box borderRight={`1px solid ${pale}`} h={4} mt={2} mr={4} />
              )}
              {displayOpenInTab ? (
                <Link
                  noFocusStyle
                  noHoverStyle
                  href={getValidSignalLink(signal)}
                  fontSize="14px"
                  fontWeight={500}
                  pt={2}
                  onMouseDown={() =>
                    Analytics.event('signal', {
                      action: 'open_in_new_tab',
                      insight: narrative.title,
                      insight_id: narrative.id.toString(),
                      signal: signal.signal_value,
                      signal_id: (signal.id ?? '').toString(),
                    })
                  }
                >
                  <Flex
                    align="center"
                    gap={1}
                    _hover={{ color: dark, p: { color: dark } }}
                  >
                    <Icon icon={OpenInNewTabIcon} boxSize={4} />
                    <BodyText color={primary}>Open in new tab</BodyText>
                  </Flex>
                </Link>
              ) : (
                <Box h={8} />
              )}
            </Flex>
          </Flex>
        )}
        <Collapse in={showCommunities}>
          <SignalCommunities
            narrative={narrative}
            signal={signal}
            feedsCommunities={feedsCommunities}
          />
        </Collapse>
      </Box>
    </Box>
  );
}
