import type { PropsWithChildren } from 'react';
import type { StyleProps, TextProps } from '@chakra-ui/react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useColorModeValue, Text } from '@chakra-ui/react';
import { Button, Box } from '~/components';
import { colors } from '~/styles';

export function ExpandableText({
  children,
  lines = 5,
  buttonStyle,
  ...props
}: StyleProps &
  PropsWithChildren<{ lines?: number; buttonStyle?: TextProps }>) {
  const [clamped, setClamped] = useState(true);
  const [showButton, setShowButton] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const color = useColorModeValue(colors.g.primary, colors.g.light);

  const hasClamping = (el: HTMLDivElement | null) => {
    if (!el) return false;
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
  };

  useLayoutEffect(() => {
    const isClamped = hasClamping(ref.current);
    setClamped(isClamped);
    setShowButton(isClamped);
  }, [ref, children]);

  return (
    <Box {...(props as any)}>
      <Box
        ref={ref}
        noOfLines={clamped ? lines : undefined}
        textStyle="BodyText"
      >
        {children}
      </Box>
      {showButton && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setClamped(!clamped);
          }}
          variant="text"
          _focus={{ border: 'none' }}
          _hover={{ color, textDecoration: 'underline' }}
          color={color}
          ml="-6px"
          mb={-2}
          mt={-4}
        >
          <Text {...(buttonStyle as any)}>{clamped ? 'More' : 'Less'}</Text>
        </Button>
      )}
    </Box>
  );
}
