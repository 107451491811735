import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { Link, SignalCardBg } from '~/components';
import { SignalCardImage } from './SignalCardImage';

type Props = { signal: Signal };

export function SignalImage({ signal }: Props): ReactElement {
  return (
    <SignalCardBg>
      <Link
        href={signal.signal_value}
        variant="gray"
        noFocusStyle
        mb={2}
        noOfLines={2}
      >
        {signal.signal_value}
      </Link>
      <SignalCardImage
        src={signal.signal_value}
        alt={signal.notes || signal.signal_value}
      />
    </SignalCardBg>
  );
}
