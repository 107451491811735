import { FormControl, FormLabel } from '@chakra-ui/react';
import {
  FormEventHandler,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Image,
  Input,
  Modal,
  Spinner,
} from '~/components';
import { onRtfPaste } from '~/lib/markdown';
import { boxShadows } from '~/styles';

export type InsertImageModalForm = { caption: string; file: File };
type Props = {
  isOpen?: boolean;
  onClose: () => void;
  onSubmit: (form: InsertImageModalForm) => void | Promise<any>;
};

export function InsertImageModal({ onClose, onSubmit, isOpen }: Props) {
  const [caption, setCaption] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const _onClose = () => {
    setCaption('');
    setFile(undefined);
    onClose();
  };

  const _onSubmit: FormEventHandler = async (e) => {
    e.preventDefault();
    if (!file) return;
    setIsSubmitting(true);
    await onSubmit({ caption, file });
    setIsSubmitting(false);
    _onClose();
  };

  return (
    <Modal
      title="Insert image"
      isOpen={!!isOpen}
      onClose={_onClose}
      autoFocus
      isCentered
    >
      <Box mt={-2}>
        <form onSubmit={_onSubmit}>
          <ChooseFileButton
            boxShadow={boxShadows.button}
            type="button"
            variant="plain"
            w="100%"
            onChange={setFile}
            accept="image/*"
          >
            {async (file) => {
              const src = await readAsDataURL(file);
              return <Image src={src} alt={'Uploaded file'} />;
            }}
          </ChooseFileButton>
          <FormControl pt={4}>
            <FormLabel>Caption</FormLabel>
            <Input
              type="text"
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              onPaste={onRtfPaste}
            />
          </FormControl>
          <Flex justify="flex-end" gap={4} mt={2}>
            <Button type="button" variant="outline" onClick={_onClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!file || isSubmitting}
              isLoading={isSubmitting}
            >
              Insert
            </Button>
          </Flex>
        </form>
      </Box>
    </Modal>
  );
}

type ChooseFileButtonProps = Omit<
  ButtonProps,
  'onClick' | 'children' | 'onChange'
> & {
  accept: string;
  children: (file: File) => ReactElement | Promise<ReactElement>;
  message?: string;
  onChange: (file: File) => void;
};
function ChooseFileButton(props: ChooseFileButtonProps) {
  const {
    accept,
    children: renderFile,
    message = 'Choose file',
    onChange,
    ...buttonProps
  } = props;
  const [file, setFile] = useState<File | undefined>(undefined);
  const [previewComponent, setPreviewComponent] = useState<
    ReactElement | undefined
  >(undefined);
  const inputRef = useRef<HTMLInputElement>(undefined!);

  useEffect(() => {
    if (file) onChange(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(
    () => {
      (async () => {
        if (!file) return undefined;
        const promiseOrComponent = renderFile(file);
        if (
          typeof promiseOrComponent === 'object' &&
          'then' in promiseOrComponent &&
          typeof promiseOrComponent.then === 'function'
        ) {
          const component = await promiseOrComponent;
          setPreviewComponent(component);
        } else {
          setPreviewComponent(promiseOrComponent as ReactElement);
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  );

  useEffect(() => {
    const inputEl = inputRef.current;
    const onInputChange = async () => {
      if (inputEl.files === null || inputEl.files.length < 1) return;
      setFile(inputEl.files[0]);
    };
    inputEl.addEventListener('change', onInputChange);
    return () => inputEl.removeEventListener('change', onInputChange);
  });

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        hidden
        accept={accept}
        style={{ display: 'none' }}
      />
      {file ? (
        previewComponent ? (
          previewComponent
        ) : (
          <Box>
            <Spinner />
          </Box>
        )
      ) : (
        <Button
          onClick={() => inputRef.current.click()}
          {...(buttonProps as any)}
        >
          {message}
        </Button>
      )}
    </>
  );
}

async function readAsDataURL(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result!.toString());
      };
      reader.readAsDataURL(file);
    } catch (error) {
      reject(error);
    }
  });
}
