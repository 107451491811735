import { useMemo } from 'react';

export const useIndexBy = <T extends {}, K extends keyof T>(
  objects: T[] | undefined,
  key: K
) => useMemo(() => indexBy(objects, key), [objects, key]);

export function indexBy<T extends {}, K extends keyof T>(
  objects: T[] | undefined,
  key: K
): T[K] extends string | number | symbol
  ? Record<T[K], T | undefined>
  : Record<string, T | undefined> {
  return Object.fromEntries(
    (objects ?? []).map((object) => [object[key], object])
  );
}
