import { Box, SubtitleHeader } from '~/components';
import { Analytics } from '~/lib/analytics';
import { scrollIntoView } from '~/lib/docs';
import { colors } from '~/styles';

type Props = {
  currentSection: string;
  sections: Map<string, string[]>;
};

export function SectionSidebar({ currentSection, sections }: Props) {
  return (
    <Box w="25vw" pl="8vw">
      <Box as="nav" position="sticky" top={50} w="156px" alignSelf="start">
        {[...sections.entries()].map(([section]) => (
          <SubtitleHeader
            key={section}
            borderRadius={8}
            py="10px"
            px="12px"
            color={
              currentSection === section ? colors.white : colors.warmGray[2]
            }
            cursor="pointer"
            bg={
              currentSection === section
                ? 'rgba(107, 107, 107, 0.20);'
                : undefined
            }
            onClick={() => {
              Analytics.event('docs', {
                action: 'section_nav_click',
                label: section,
              });
              scrollIntoView(
                document.querySelector(`[data-section-content="${section}"]`)
              );
            }}
          >
            {section}
          </SubtitleHeader>
        ))}
      </Box>
    </Box>
  );
}
