import { Center, BoxProps, TextProps } from '@chakra-ui/react';
import { Box, Flex } from '~/components';
import { colors } from '~/styles/v2';
import { Body } from './TypographyV2';

type CheckboxVariation = 'default' | 'black';
type CheckboxSize = 'sm' | 'md';

type Props = BoxProps & {
  variation?: CheckboxVariation;
  size?: CheckboxSize;
  checked?: boolean;
  textStyle?: TextProps;
};

export const Checkbox = (props: Props) => {
  const {
    children,
    textStyle,
    variation = 'default',
    size = 'md',
    checked = false,
    ...rest
  } = props;
  const s = size === 'sm' ? '12px' : '14px';
  const color = variation === 'default' ? colors.primary[500] : colors.text;
  return (
    <Flex align="center" gap={2}>
      <Center w={4} h={4} {...(rest as any)} cursor="pointer" borderRadius={4}>
        <Center w={s} h={s} border={`1px solid ${color}`} borderRadius={4}>
          {checked && (
            <svg
              width={s}
              height={s}
              viewBox="0 0 14 14"
              xmlns="http://www.w3.org/2000/svg"
              fill={color}
            >
              <path
                fill={color}
                d="M2 0H12C13.0938 0 14 0.90625 14 2V12C14 13.125 13.0938 14 12 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0ZM10.5312 5.53125H10.5C10.8125 5.25 10.8125 4.78125 10.5 4.46875C10.2188 4.1875 9.75 4.1875 9.46875 4.46875L6 7.96875L4.53125 6.5C4.21875 6.1875 3.75 6.1875 3.46875 6.5C3.15625 6.78125 3.15625 7.25 3.46875 7.53125L5.46875 9.53125C5.75 9.84375 6.21875 9.84375 6.53125 9.53125L10.5312 5.53125Z"
              />
            </svg>
          )}
        </Center>
      </Center>
      <Body fontSize={s} {...(textStyle as any)}>
        {children}
      </Body>
    </Flex>
  );
};
