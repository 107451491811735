import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import { colors } from '~/styles';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
  button: {
    '&[aria-expanded="true"]': {
      borderBottomWidth: `2px`,
      borderBottomColor: mode(colors.g.primary, colors.g.light),
      outline: 'none',
      color: mode(colors.g.primary, colors.g.light),
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
});

export const Accordion = defineMultiStyleConfig({ baseStyle });
