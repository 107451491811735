import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  Flex,
} from '@chakra-ui/react';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';
import { FaIcon } from '..';

const { primary, neutral, white } = colors;

type ButtonProps = ChakraButtonProps & {
  iconLeft?: IconProp;
  iconRight?: IconProp;
  size?: ButtonSize;
  variation?: ButtonVariation;
};
type ButtonVariation = 'primary' | 'secondary' | 'tertiary';
type ButtonSize = 'md' | 'sm' | 'xs';

const baseStyle: ChakraButtonProps = {
  display: 'block',
  lineHeight: '20px',
  background: primary[500],
  color: white,
  borderRadius: 8,
  border: `1px solid transparent`,
  _hover: {
    background: primary[800],
  },
  _disabled: {
    background: neutral[50],
    color: neutral[200],
    cursor: 'not-allowed',
    border: 'none',
    _hover: {
      background: neutral[100],
    },
  },
};

const sizeStyle: Record<ButtonSize, ChakraButtonProps> = {
  md: { fontSize: '14px', px: 3, py: 2, h: '38px' },
  sm: { fontSize: '14px', px: 2, py: 1, h: '32px' },
  xs: {
    fontSize: '12px',
    lineHeight: '16px',
    px: 2.5,
    py: 1,
    borderRadius: 6,
    height: '26px',
  },
};

export function Button(props: ButtonProps) {
  const {
    children,
    size = 'md',
    variation = 'primary',
    iconLeft,
    iconRight,
    ...rest
  } = props;
  const variationStyle: Partial<Record<ButtonVariation, ChakraButtonProps>> = {
    primary: {},
    secondary: {
      background: 'transparent',
      border: `1px solid ${primary[500]}`,
      color: primary[500],
      _hover: {
        color: primary[800],
        border: `1px solid ${primary[800]}`,
        background: white,
        '& svg': {
          color: primary[800],
        },
      },
      _disabled: {
        background: white,
        color: neutral[200],
        border: `1px solid ${neutral[200]}`,
        '& svg': {
          color: `${neutral[200]} !important`,
        },
      },
    },
    tertiary: {
      background: 'inherit',
      color: primary[500],
      fontSize: size === 'md' ? '16px' : size === 'sm' ? '14px' : '12px',
      height: size === 'md' ? '24px' : size === 'sm' ? '20px' : '16px',
      p: 0,
      borderRadius: 0,
      border: 'none',
      boxShadow: 'none',
      display: 'inline-block',
      _hover: {
        background: 'inherit',
        textDecoration: 'underline',
      },
      _disabled: {
        cursor: 'not-allowed',
        color: neutral[200],
      },
    },
  };
  return (
    <ChakraButton
      {...baseStyle}
      {...sizeStyle[size as ButtonSize]}
      {...variationStyle[variation as ButtonVariation]}
      {...(rest as any)}
    >
      <Flex justify="center" align="center" gap={2}>
        {!!iconLeft && (
          <FaIcon
            icon={iconLeft}
            color={props.variant === 'outline' ? primary[500] : undefined}
          />
        )}
        {children}
        {!!iconRight && (
          <FaIcon
            icon={iconRight}
            color={props.variant === 'outline' ? primary[500] : undefined}
          />
        )}
      </Flex>
    </ChakraButton>
  );
}
