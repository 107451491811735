import type { ReactElement, PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from '@tanstack/react-query';
import { userRoles } from '~/lib/auth';
import {
  getRoleForUser,
  getIsAdminSpace,
  getIsAnalystSpace,
  getIsReaderSpace,
  useDefaultRouteForUser,
  getIsDiscoveryViewerSpace,
} from '~/lib/routing';
import { UNPROTECTED_PATHS } from '~/constants/routing';
import { useUser } from '~/lib/hooks';

type Props = PropsWithChildren<{}>;

export function RouteCheck({ children }: Props): ReactElement {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const router = useRouter();
  const queryClient = useQueryClient();
  const { pathname } = router;
  const role = getRoleForUser();
  const adminSpace = getIsAdminSpace(pathname);
  const analystSpace = getIsAnalystSpace(pathname);
  const discoveryViewerSpace = getIsDiscoveryViewerSpace(pathname);
  const readerSpace = getIsReaderSpace(pathname);
  const defaultRoute = useDefaultRouteForUser({ asObject: true });

  const { userInfo } = useUser();
  useEffect(() => {
    if (UNPROTECTED_PATHS.includes(pathname)) {
      setIsAuthorized(true);
      return;
    }
    if (
      userInfo?.password_reset_required &&
      router.asPath !== '/settings?view=password'
    ) {
      router.push('/settings?view=password');
    }
    if (router.pathname === '/') {
      router.replace(defaultRoute);
      return;
    }
    const accessDenied = () => {
      if (userInfo?.id) {
        router.replace({
          pathname: '/access-denied',
          query: { prev: router.pathname },
        });
      }
    };
    setIsAuthorized(false);
    if (!role || !defaultRoute || !userRoles.includes(role)) {
      accessDenied();
      return;
    }
    if (role === 'intelligence reader' && !readerSpace) {
      accessDenied();
      return;
    }
    if (role === 'discovery viewer' && !discoveryViewerSpace) {
      accessDenied();
      return;
    }
    if (role === 'analyst' && !analystSpace) {
      accessDenied();
      return;
    }
    setIsAuthorized(true);
  }, [
    readerSpace,
    adminSpace,
    analystSpace,
    defaultRoute,
    discoveryViewerSpace,
    pathname,
    queryClient,
    role,
    router,
    userInfo?.password_reset_required,
    userInfo?.id,
  ]);

  if (!isAuthorized) {
    return <></>;
  }
  return <>{children}</>;
}
