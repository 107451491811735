import type { Narrative } from '~/types/graphika-types';
import type { AnalyticsEvents, EventCategory } from './analyticsEvents';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DependencyList, useEffect } from 'react';
import mixpanel, { track } from 'mixpanel-browser';
import { useUser } from '../hooks';

export const APP_NAME = 'Voyager';
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID;
export const hasMixPanelToken = !!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
const GA_EMAIL_BLACKLIST = /test\+/g;

interface VoyagerAnalytics {
  screenView(params: { name: string }): void;
  event<C extends EventCategory>(
    category: C,
    params: AnalyticsEvents[C] & { label?: string; value?: number }
  ): void;
  userProperties(params: {
    user_role: string;
    user_org: string;
    user_id: number;
    color_mode: string;
  }): void;
}

export const Analytics: VoyagerAnalytics = {
  async userProperties(params) {
    const { user_id, ...rest } = params;
    window.gtag &&
      window.gtag('set', 'user_properties', { ...rest, graphika_id: user_id });
  },
  screenView(params) {
    window.gtag &&
      window.gtag('event', 'screen_view', {
        app_name: APP_NAME,
        screen_name: params.name,
      });
    mixpanel?.track_pageview();
  },
  event(category, { action, label, value, ...rest }) {
    window.gtag &&
      window.gtag('event', category, {
        event_action: action,
        event_label: label,
        event_value: value,
        ...rest,
      });
    hasMixPanelToken &&
      mixpanel?.track(category, { action, label, value, ...rest });
  },
};

export const useAnalytics = GA_TRACKING_ID
  ? _useAnalytics
  : ((() => {}) as typeof _useAnalytics);
function _useAnalytics<T extends keyof VoyagerAnalytics>(
  type: T,
  params: Parameters<VoyagerAnalytics[T]>[0],
  deps: DependencyList = []
) {
  useEffect(() => {
    if (!window?.gtag) return;
    if (deps.length && !deps.every(Boolean)) return;

    const fn = Analytics[type] as (...args: any[]) => any;
    try {
      fn(params);
      if (
        process.env.NODE_ENV !== 'production' &&
        process.env.NEXT_PUBLIC_ADDITIONAL_LOGGING === 'enabled'
      )
        console.log(`Analytics: [${type}]: ${JSON.stringify(params)}`);
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useAnalyticsEvent = GA_TRACKING_ID
  ? _useAnalyticsEvent
  : ((() => {}) as typeof _useAnalyticsEvent);
function _useAnalyticsEvent<C extends EventCategory>(
  category: C,
  params: AnalyticsEvents[C],
  deps: DependencyList = []
) {
  useEffect(() => {
    if (!window?.gtag) return;
    if (deps.length && !deps.every(Boolean)) return;

    Analytics.event(category, params);

    if (
      process.env.NODE_ENV !== 'production' &&
      process.env.NEXT_PUBLIC_ADDITIONAL_LOGGING === 'enabled'
    )
      console.log(`Analytics: [event/${category}]: ${JSON.stringify(params)}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export const useUtmMediumTracking = (narrative?: Narrative) => {
  const router = useRouter();
  const { userInfo } = useUser();

  useEffect(() => {
    const { asPath, query, pathname } = router;

    const removeAndReplace = () => {
      const newQuery = { ...query };
      Object.keys(newQuery)
        .filter((param) => param.includes('utm_'))
        .forEach((utm) => {
          delete newQuery[utm];
        });
      router.replace({ pathname, query: newQuery });
    };

    if (!userInfo || !narrative || asPath.startsWith('/login')) return;

    const containsUtmParams = asPath.includes('utm_');
    const trackedUtmValueForNarrative = sessionStorage.getItem(
      `utm_medium_${narrative?.id}`
    );
    const hasTrackedUtm = sessionStorage.getItem('trackedUtm') === 'true';
    const utmMedium = asPath.includes('utm_medium=email')
      ? 'email'
      : asPath.includes('utm_medium=pdf')
      ? 'pdf'
      : 'direct';

    if (trackedUtmValueForNarrative && !containsUtmParams) return;

    if (utmMedium === 'direct' && !hasTrackedUtm) {
      Analytics.event('trafficSource', {
        action: utmMedium,
        insight: narrative?.title,
        insight_id: narrative?.id.toString(),
      });
      sessionStorage.setItem('trackedUtm', 'true');
      sessionStorage.setItem(`utm_medium_${narrative?.id}`, utmMedium);
      return;
    }

    if (utmMedium === 'direct' && hasTrackedUtm) return;

    if (!trackedUtmValueForNarrative) {
      Analytics.event('trafficSource', {
        action: utmMedium,
        insight: narrative?.title,
        insight_id: narrative?.id.toString(),
      });
      sessionStorage.setItem('trackedUtm', 'true');
      sessionStorage.setItem(`utm_medium_${narrative?.id}`, utmMedium);
    }

    if (containsUtmParams) {
      removeAndReplace();
    }
  }, [router, userInfo, narrative]);
};

export function GoogleAnalytics() {
  return (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              send_page_view: false,
            });
          `,
        }}
      />
    </>
  );
}

export function MixpanelAnalytics() {
  useEffect(() => {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN);
  }, []);
  return null;
}

export function UserTracking() {
  const { userInfo } = useUser();
  if (!userInfo || GA_EMAIL_BLACKLIST.test(userInfo?.email ?? '')) {
    return null;
  }
  return (
    <>
      {!!process.env.NEXT_PUBLIC_GA_ID && <GoogleAnalytics />}
      {hasMixPanelToken && <MixpanelAnalytics />}
    </>
  );
}
