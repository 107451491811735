import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect } from 'react';

export function EditablePropPlugin({ editable }: { editable?: boolean }) {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    if (editable === editor.isEditable()) return;
    editor.setEditable(!!editable);
  }, [editor, editable]);

  return null;
}
