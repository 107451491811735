import type { MapGroup } from '~/types/graphika-types';
import { z } from 'zod';
import {
  cachify,
  fetchFromCoreApi,
  fetchHgActivity,
  fetchHgRelevantMapNodes,
  serializeActivityParams,
  serializeRelevantMapNodesParams,
} from '~/lib/api';
import {
  HgActivityData,
  HgBasicQueryParams,
  HgPostActivitySnapshot,
  HgRelevantMapNodesData,
  HgRelevantMapNodesSnapshot,
  parseActivityData,
} from '~/lib/hypergraph';
import { queryClient } from '~/lib/queryClient';
import {
  createWidgetHook,
  defineDynamicWidget,
  type DynamicWidgetConfig,
} from './shared';

export const totalMapActivityGroup: MapGroup = {
  name: 'Total Map Activity',
  group_number: -1,
  hex_color: '5567EE',
  id: 'group_Total Map Activity',
  position: -1,
};

export const useActivityChart =
  createWidgetHook<typeof widgets.activityChart>();
export const useRelevantMapNodes =
  createWidgetHook<typeof widgets.relevantMapNodes>();

export const getWidgetDefinitions = () =>
  widgets as Record<string, DynamicWidgetConfig<any, any>>;

/** DO NOT IMPORT THIS DIRECTLY. IT WILL LIKELY CAUSE CIRCULAR IMPORTS. USE THE ABOVE FUNCTION */
export const widgets = {
  activityChart: defineDynamicWidget('Activity Chart')
    .params(
      z.object({
        term: z.string().optional(),
        startDate: z.string(),
        endDate: z.string(),
        mapId: z.string().default(''),
        sort: z.string().optional(),
      })
    )
    .events()
    .extras<{ insightId: number }>()
    .loader(async (params) => {
      return await fetchHgActivity(params);
    })
    .snapshot({
      async create(params, extras) {
        const [widgetData, postData] = await Promise.all([
          fetchFromCoreApi<{
            id: string;
            data: HgActivityData;
            parameters: HgBasicQueryParams;
          }>('POST', '/intel_search_results', {
            insight_id: extras.insightId,
            ...serializeActivityParams(params),
          }),
          fetchFromCoreApi<{
            id: string;
            data: HgActivityData;
            parameters: HgBasicQueryParams;
          }>('POST', '/intel_search_results', {
            insight_id: extras.insightId,
            type: 'post',
            parameters: {
              ...serializeActivityParams(params).parameters,
            },
          }),
        ]);
        const { id, data, parameters } = widgetData;
        queryClient.setQueryData(['intel_search_results', id], {
          data,
          params: parameters,
        });

        return id + '_' + postData.id;
      },
      async remove(id) {
        const [data_id, post_id] = id.split('_');
        await Promise.all([
          fetchFromCoreApi('DELETE', `/intel_search_results/${data_id}`),
          fetchFromCoreApi('DELETE', `/intel_search_results/${post_id}`),
        ]);
        queryClient.removeQueries({
          queryKey: [
            ['intel_search_results', data_id],
            ['intel_search_results', post_id],
          ],
        });
        return true;
      },
      async get(id) {
        const [data_id] = id.split('_');
        const widgetData = await cachify(async () => {
          const { parameters, data } =
            await fetchFromCoreApi<HgPostActivitySnapshot>(
              'GET',
              `/intel_search_results/${data_id}`
            );
          return {
            data,
            params: parameters,
          };
        }, ['intel_search_results', data_id]);
        if (widgetData) {
          const {
            data,
            params: { start_date, end_date },
          } = widgetData;
          return {
            data: parseActivityData(
              {
                startDate: start_date,
                endDate: end_date,
              },
              data
            ),
            params: widgetData.params ?? {},
          };
        }
      },
      isLatest(snapParams: HgPostActivitySnapshot['parameters'], nodeParams) {
        return [
          [snapParams.map_id, nodeParams.mapId],
          [snapParams.query ?? '', nodeParams.term ?? ''],
          [snapParams.start_date, nodeParams.startDate],
          [snapParams.end_date, nodeParams.endDate],
          [snapParams.sort ?? 'engagement', nodeParams.sort ?? 'engagement'],
        ].every(([a, b]) => a === b);
      },
    }),
  relevantMapNodes: defineDynamicWidget('Highlight Map')
    .params(
      z.object({
        term: z.string().optional(),
        startDate: z.string(),
        endDate: z.string(),
        mapId: z.string().default(''),
        sort: z.string().optional(),
      })
    )
    .events()
    .extras<{ insightId: number }>()
    .loader(async (params) => {
      return await fetchHgRelevantMapNodes(params);
    })
    .snapshot({
      async create(params, extras) {
        const [widgetData, postData] = await Promise.all([
          fetchFromCoreApi<{
            id: string;
            data: HgRelevantMapNodesData;
            parameters: HgBasicQueryParams;
          }>('POST', '/intel_search_results', {
            insight_id: extras.insightId,
            ...serializeRelevantMapNodesParams(params),
          }),
          fetchFromCoreApi<{
            id: string;
            data: HgRelevantMapNodesData;
            parameters: HgBasicQueryParams;
          }>('POST', '/intel_search_results', {
            insight_id: extras.insightId,
            type: 'post',
            parameters: {
              ...serializeRelevantMapNodesParams(params).parameters,
            },
          }),
        ]);
        const { id, data, parameters } = widgetData;
        queryClient.setQueryData(['intel_search_results', id], {
          data,
          params: parameters,
        });

        return id + '_' + postData.id;
      },
      async remove(id) {
        const [data_id, post_id] = id.split('_');
        await Promise.all([
          fetchFromCoreApi('DELETE', `/intel_search_results/${data_id}`),
          fetchFromCoreApi('DELETE', `/intel_search_results/${post_id}`),
        ]);
        queryClient.removeQueries({
          queryKey: [
            ['intel_search_results', data_id],
            ['intel_search_results', post_id],
          ],
        });
        return true;
      },
      async get(id) {
        const [data_id] = id.split('_');
        const widgetData = await cachify(async () => {
          const { data, parameters } =
            await fetchFromCoreApi<HgRelevantMapNodesSnapshot>(
              'GET',
              `/intel_search_results/${data_id}`
            );
          return { data, params: parameters };
        }, ['intel_search_results', data_id]);
        return widgetData;
      },
      isLatest(
        snapParams: HgRelevantMapNodesSnapshot['parameters'],
        nodeParams
      ) {
        return [
          [snapParams.map_id, nodeParams.mapId],
          [snapParams.query ?? '', nodeParams.term ?? ''],
          [snapParams.start_date, nodeParams.startDate],
          [snapParams.end_date, nodeParams.endDate],
          [snapParams.sort ?? 'engagement', nodeParams.sort ?? 'engagement'],
        ].every(([a, b]) => a === b);
      },
    }),
} satisfies Record<string, DynamicWidgetConfig<any, any>>;

/* Type helpers */
export type WidgetName = keyof typeof widgets;
export type WidgetParams<T extends WidgetName> = z.infer<
  (typeof widgets)[T]['_paramsSchema']
>;
export type WidgetData<T extends WidgetName> = {
  [K in WidgetName]: (typeof widgets)[K] extends DynamicWidgetConfig<
    any,
    infer Data
  >
    ? Data
    : any;
}[T];
