import type { MapGroup, MapInfo } from '~/types/graphika-types';
import {
  DarkMode,
  Slider,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useMvConfig } from '@graphika/map-viewer';
import { useEffect, useRef, useState } from 'react';
import { BodyText, Box, Button, Flex, Icon, TitleHeader } from '~/components';
import { GroupColorPicker } from '~/components/maps/GroupColorPicker';
import { Analytics } from '~/lib/analytics';
import { useDebounce } from '~/lib/hooks';
import ArrowIcon from '~/public/icons/Arrow/Back.svg';
import CloseIcon from '~/public/icons/Close.svg';
import { useMapGroups } from '~/queries';
import { colors } from '~/styles';

const MIN_SCALE = 0.5;
const MAX_SCALE = 2;
const TICK_STEP = 0.1;
const sliderSteps = new Array((MAX_SCALE - MIN_SCALE) / TICK_STEP)
  .fill(0)
  .map((_, i) => MIN_SCALE + i * TICK_STEP);

type Props = {
  onClose: () => void;
  showMapCustomization: boolean;
  map: MapInfo;
  groups: MapGroup[];
};

export function CustomizeMap({
  onClose,
  showMapCustomization,
  map,
  groups,
}: Props) {
  const [customScale, setCustomScale] = useState(
    parseFloat(sessionStorage.getItem(`${map.id!}-custom-scale`) ?? '1')
  );
  const [baseScale, setBaseScale] = useState<number>();
  const [confirmReset, setConfirmReset] = useState(false);
  const [showGroupColorPicker, setShowGroupColorPicker] = useState(false);
  const hasChanged = useRef(false);
  const debouncedScale = useDebounce<number>(customScale, 100);
  const [scale, setScale] = useMvConfig((mv) => [mv.scale, mv.setScale]);
  const { data: apiGroups = [] } = useMapGroups({ map_id: parseInt(map.id!) });

  const mapInfo = {
    map: map.name ?? '',
    map_id: (map.id ?? '').toString(),
  };

  useEffect(() => {
    if (scale !== 1 && !baseScale) {
      setBaseScale(scale);
    }
  }, [scale, baseScale, setBaseScale]);

  useEffect(() => {
    baseScale && setScale(baseScale * debouncedScale);
  }, [debouncedScale, setScale, setBaseScale, baseScale]);

  const isCustomized =
    customScale !== 1 || JSON.stringify(apiGroups) !== JSON.stringify(groups);

  const handleReset = () => {
    setCustomScale(1);
    sessionStorage.removeItem(`${map.id}-custom-scale`);
    sessionStorage.removeItem(`${map.id}-custom-colors`);
    setConfirmReset(false);
    window.dispatchEvent(new Event('customize-group-colors'));
    Analytics.event('mapInteraction', {
      action: 'reset-map-customization',
      ...mapInfo,
    });
  };

  if (!showMapCustomization) return null;

  return (
    <Box
      position="fixed"
      top="48px"
      right="16px"
      borderRadius={16}
      bg="#333A3A"
      w="325px"
      p={4}
    >
      <DarkMode>
        <Flex mb={6}>
          {showGroupColorPicker ? (
            <Flex gap={2} align="center">
              <Icon
                icon={ArrowIcon}
                fill={colors.white}
                boxSize={6}
                onClick={() => setShowGroupColorPicker(false)}
                cursor="pointer"
              />
              <TitleHeader>Edit Group Colors</TitleHeader>
            </Flex>
          ) : (
            <Flex justify="space-between" w="100%">
              <TitleHeader>Customize Map</TitleHeader>
              <Icon
                icon={CloseIcon}
                fill={colors.white}
                boxSize={6}
                onClick={onClose}
                cursor="pointer"
              />
            </Flex>
          )}
        </Flex>
        {showGroupColorPicker ? (
          <GroupColorPicker map={map} groups={groups} />
        ) : (
          <>
            <BodyText
              as="button"
              mb={6}
              onClick={() => setShowGroupColorPicker(true)}
            >
              Edit Group Colors
            </BodyText>
            <Flex justify="space-between">
              <BodyText>Adjust Node Size Scale</BodyText>
              <Flex {...tagStyle}>
                <BodyText>
                  {(Math.round(customScale * 100) / 100).toFixed(2)}x
                </BodyText>
              </Flex>
            </Flex>
            <Slider
              aria-label="node-scale"
              onChange={(val) => {
                if (!hasChanged.current) {
                  hasChanged.current = true;
                  Analytics.event('mapInteraction', {
                    action: 'node-scale',
                    ...mapInfo,
                  });
                }
                setCustomScale(val);
              }}
              onChangeEnd={(val) => {
                sessionStorage.setItem(
                  `${map.id!}-custom-scale`,
                  val.toString()
                );
              }}
              value={customScale}
              min={MIN_SCALE}
              max={MAX_SCALE}
              step={TICK_STEP / 2}
              {...sliderStyle}
            >
              {sliderSteps.map((val) => (
                <SliderMark key={val} value={val} {...sliderMarkStyle} />
              ))}
              <SliderTrack {...sliderTrackStyle} />
              <SliderThumb {...sliderThumbStyle} />
            </Slider>
            <Flex align="center" gap={4}>
              <Button
                bg={colors.white}
                color={colors.g.primary}
                mt={2}
                sx={{
                  _hover: {
                    bg: colors.white,
                  },
                }}
                disabled={!isCustomized}
                onClick={() => {
                  if (confirmReset) {
                    handleReset();
                  } else {
                    setConfirmReset(true);
                  }
                }}
              >
                {confirmReset ? 'Reset changes?' : 'Reset All'}
              </Button>
              {confirmReset && (
                <BodyText
                  as="button"
                  color={colors.g.pale}
                  cursor="pointer"
                  onClick={() => setConfirmReset(false)}
                  pt={2}
                >
                  Cancel
                </BodyText>
              )}
            </Flex>
          </>
        )}
      </DarkMode>
    </Box>
  );
}

export const sliderStyle = {
  focusThumbOnChange: false,
};

export const sliderMarkStyle = {
  mt: '-2px',
  bg: colors.warmGray[3],
  h: '5px',
  w: '1px',
};

export const sliderTrackStyle = {
  bg: colors.warmGray[3],
  h: '1px',
  opacity: 1,
};

export const sliderThumbStyle = {
  w: '10px',
  h: '10px',
};

export const tagStyle = {
  px: 2,
  h: '32px',
  borderRadius: 8,
  bg: colors.mapViewer.bgGray,
  justify: 'center',
  align: 'center',
};
