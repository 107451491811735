import type { ReactElement } from 'react';
import type { Narrative } from '~/types/graphika-types';
import { Box, Flex, Icon, Image, Link } from '~/components';
import EyeSlashIcon from '~/public/icons/EyeSlash.svg';

type Props = { narrative: Narrative; narrativeDetailUrl: string };

export function ContentWarningImage({
  narrative,
  narrativeDetailUrl,
}: Props): ReactElement {
  return (
    <Link href={narrativeDetailUrl}>
      <Flex
        position="relative"
        cursor="pointer"
        overflow="hidden"
        borderRadius={8}
        minH="100px"
        align="center"
        data-cy="content-warning-image"
      >
        <Image
          src={narrative.cover_image_url}
          alt={narrative.title}
          borderRadius={8}
          overflow="hidden"
          filter={`blur(8px)`}
          transition="all 0.2s"
          data-cy="cw-image"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%,-25px)"
          bg="rgba(0,0,0,0.5)"
          p={2}
          borderRadius={4}
          w="100px"
        >
          <Flex justify="center">
            <Icon icon={EyeSlashIcon} color="white" boxSize={6} />
          </Flex>
        </Box>
      </Flex>
    </Link>
  );
}
