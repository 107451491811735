import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers([...inputAnatomy.keys, 'label']);

const baseStyle = definePartsStyle((props) => ({
  field: {
    _placeholder: {
      color: colors.coolGray[4],
    },
  },
  leftIcon: {
    fill: mode(colors.g.primary, colors.g.light)(props),
    boxSize: 19,
    mt: '16px',
    ml: '6px',
  },
  rightIcon: {
    fill: mode(colors.g.primary, colors.g.light)(props),
    boxSize: 19,
    mt: '16px',
    mr: '6px',
  },
  label: {
    fontSize: '10px',
    fontWeight: 500,
    letterSpacing: '0.06em',
    lineHeight: '12px',
    textTransform: 'uppercase',
    pl: '8px',
    mb: '-2px',
    color: mode(colors.warmGray[1], colors.coolGray[5])(props),
  },
}));

const variantDefault = definePartsStyle((props) => ({
  field: {
    background: mode(colors.warmGray[6], colors.coolGray[1])(props),
    border: `1px solid`,
    borderColor: mode(colors.warmGray[4], colors.coolGray[1])(props),
    boxShadow: mode('none', '0px 0px 1px rgba(0, 0, 0, 0.24)')(props),
    color: mode(colors.black, colors.white)(props),
    _hover: {
      borderColor: mode(colors.warmGray[3], colors.coolGray[3])(props),
    },
    _focus: {
      border: `1px solid ${colors.g.primary}`,
    },
    _disabled: {
      color: colors.warmGray[2],
      cursor: 'not-allowed',
    },
    _invalid: {
      borderColor: colors.status.red,
    },
  },
}));

const variantDateRange = definePartsStyle((props) => ({
  field: {
    background: 'transparent',
    fontSize: '16px',
    marginTop: '6px',
    marginLeft: -3,
    color: mode(colors.black, colors.white)(props),
  },
  rightIcon: {
    fill: mode(colors.black, colors.white)(props),
    boxSize: 19,
    mt: 3,
    ml: -10,
  },
}));

const variantNone = definePartsStyle((props) => ({
  field: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    color: mode(colors.black, colors.white)(props),
    _focus: {
      border: 'none',
    },
  },
}));

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: {
    default: variantDefault,
    outline: variantDefault,
    none: variantNone,
    dateRange: variantDateRange,
  },
  sizes: {
    md: {
      field: {
        borderRadius: '8px',
        height: '32px',
        fontSize: '14px',
        my: '8px',
        paddingLeft: '16px',
      },
    },
    lg: {
      field: {
        height: '40px',
        fontSize: '14px',
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
});
