import type { SegmentSelection } from '~/lib/stores/segment-tree';
import type { DynamicWidgetConfig, WidgetPresets } from '../shared';
import {
  DOMConversionMap,
  DOMExportOutput,
  DecoratorNode,
  LexicalNode,
  NodeKey,
  SerializedLexicalNode,
  Spread,
  createCommand,
} from 'lexical';
import { ReactNode } from 'react';
import { AnyZodObject } from 'zod';
import { getWidgetDefinitions } from '../widgets';
import { LexicalWidgetRenderer } from './LexicalWidgetRenderer';

export type SerializedDynamicWidgetNode = Spread<
  DynamicWidgetNodeParams,
  SerializedLexicalNode
>;

export type DynamicWidgetNodeParams = {
  name: string;
  initialParams: Record<string, any>;
  initialCaption?: string;
  initialSegments?: SegmentSelection;
  initialSnapshotId?: string;
  initialPresets: WidgetPresets;
};

export class DynamicWidgetNode extends DecoratorNode<ReactNode> {
  __widgetName: string;
  __params!: Record<string, any>;
  __caption: string;
  __segments: SegmentSelection;
  __snapshotId: string | undefined;
  __presets: WidgetPresets;

  widgets: Record<string, DynamicWidgetConfig<any, any>>;

  static getType(): string {
    return 'dynamic-widget';
  }

  static clone(node: DynamicWidgetNode): DynamicWidgetNode {
    return new DynamicWidgetNode(
      {
        name: node.__widgetName,
        initialParams: node.__params,
        initialCaption: node.__caption,
        initialSegments: node.__segments,
        initialSnapshotId: node.__snapshotId,
        initialPresets: node.__presets,
      },
      node.__key
    );
  }

  constructor(params: DynamicWidgetNodeParams, key?: NodeKey) {
    super(key);
    this.widgets = getWidgetDefinitions();

    if (!this.widgets[params.name])
      throw new Error(`Dynamic widget "${params.name}" not registered.`);

    this.__widgetName = params.name;
    this.__params = params.initialParams;
    this.__snapshotId = params.initialSnapshotId;
    this.__caption = params.initialCaption ?? '';
    this.__segments = params.initialSegments ?? { groups: {}, clusters: {} };
    this.__presets = params.initialPresets ?? {};
  }

  createDOM(): HTMLElement {
    return document.createElement('div');
  }

  updateDOM(): false {
    return false;
  }

  decorate(): ReactNode {
    return (
      <LexicalWidgetRenderer
        config={this.widgets[this.__widgetName]}
        initialParams={this.__params}
        initialSnapshotId={this.__snapshotId}
        nodeKey={this.__key}
        initialCaption={this.__caption}
        initialSegments={this.__segments ?? { groups: {}, clusters: {} }}
        initialPresets={this.__presets}
      />
    );
  }

  getParams(): Record<string, any> {
    const self = this.getLatest();
    return self.__params;
  }

  setParams(params: Record<string, any>) {
    const self = this.getWritable();
    self.__params = params;
  }

  getSnapshotId() {
    const self = this.getLatest();
    return self.__snapshotId;
  }

  setSnapshotId(id: string | undefined) {
    const self = this.getWritable();
    self.__snapshotId = id;
  }

  getCaption(): string {
    const self = this.getLatest();
    return self.__caption;
  }

  setCaption(caption: string) {
    const self = this.getWritable();
    self.__caption = caption;
  }

  getPresets(): WidgetPresets {
    const self = this.getLatest();
    return self.__presets;
  }

  setPresets(presets: WidgetPresets) {
    const self = this.getWritable();
    self.__presets = presets;
  }

  getSegments(): SegmentSelection {
    const self = this.getLatest();
    return self.__segments ?? { groups: {}, clusters: {} };
  }

  setSegments(segments: SegmentSelection) {
    const self = this.getWritable();
    self.__segments = segments;
  }

  async removeSnapshot(): Promise<boolean> {
    if (!this.__snapshotId) return Promise.resolve(false);
    return this.widgets[this.__widgetName]._removeSnapshot(this.__snapshotId);
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('div');
    element.classList.add('dynamic-widget');
    element.dataset.name = this.__widgetName;
    element.dataset.params = JSON.stringify(this.__params);
    element.dataset.caption = this.__caption;
    element.dataset.segments = JSON.stringify(this.__segments);
    element.dataset.presets = JSON.stringify(this.__presets);
    if (this.__snapshotId) element.dataset.snapshot = this.__snapshotId;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    const _widgets = getWidgetDefinitions();
    return {
      div: (node) => {
        if (!node.classList.contains('dynamic-widget')) return null;
        return {
          priority: 0,
          conversion(div) {
            if (!(div instanceof HTMLDivElement)) return null;

            const {
              name,
              params,
              snapshot,
              caption = '',
              presets,
              segments,
            } = div.dataset;
            if (!name || !params)
              throw new Error(
                'Invalid data attributes while importing dynamic widget.'
              );

            if (!_widgets[name])
              throw new Error(`Dynamic widget "${name}" not registered.`);

            const _paramsSchema = _widgets[name]._paramsSchema as AnyZodObject;
            const parsedParams = _paramsSchema
              .partial()
              .safeParse(JSON.parse(params));

            if (!parsedParams.success)
              throw new Error(
                `Failed to parse params for dynamic widget "${name}": ${parsedParams.error.message}`
              );

            return {
              node: $createDynamicWidgetNode({
                name,
                initialParams: parsedParams.data,
                initialCaption: caption ?? '',
                initialSegments: JSON.parse(segments ?? '{}'),
                initialSnapshotId: snapshot,
                initialPresets: JSON.parse(presets ?? '{}'),
              }),
            };
          },
        };
      },
    };
  }

  exportJSON(): SerializedDynamicWidgetNode {
    return {
      name: this.__widgetName,
      initialParams: this.__params,
      initialCaption: this.__caption,
      initialSegments: this.__segments,
      initialSnapshotId: this.__snapshotId,
      initialPresets: this.__presets,
      type: 'dynamic-widget',
      version: 1,
    };
  }
}

export function $createDynamicWidgetNode(
  params: DynamicWidgetNodeParams
): DynamicWidgetNode {
  return new DynamicWidgetNode(params);
}

export function $isDynamicWidgetNode(
  node: LexicalNode | null
): node is DynamicWidgetNode {
  return node instanceof DynamicWidgetNode;
}

export const INSERT_DYNAMIC_WIDGET_COMMAND =
  createCommand<DynamicWidgetNodeParams>();
