import type { MapGroup } from '~/types/graphika-types';
import type { HgMapNode } from '~/lib/hypergraph';
import { Flex, Image, Text, VStack } from '@chakra-ui/react';
import {
  NodeOrder,
  NodePopoverPlugin,
  type MVNode,
  type MapViewMode,
  type PopoverEventHandlers,
} from '@graphika/map-viewer';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Icon, SubtitleHeader } from '~/components';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins/dynamic-insights';
import { Analytics } from '~/lib/analytics';
import { useColorModeValues } from '~/lib/utils';
import { usePostUrlQueryParams } from '~/lib/hooks';
import { isOutsideLiveCoreDataWindow } from '~/lib/date';
import AvatarIcon from '~/public/icons/MapViewerAvatar.svg';
import { boxShadows, colors } from '~/styles';

const { warmGray, mapViewer } = colors;

type NodeInfo = {
  name: string;
  username: string;
  profile_image_url: string;
  group: Pick<MapGroup, 'hex_color' | 'name'>;
};

type Props = { viewMode: MapViewMode; hgNodes: HgMapNode[] };
export function RMNPopoverPlugin({ viewMode, hgNodes }: Props) {
  const isMoving = useRef(false);
  const isSticky = useRef(false);
  const [node, setNode] = useState<MVNode | undefined>();
  const [{ account_id }, setUrlQuery] = usePostUrlQueryParams();
  useEffect(() => void setNode(undefined), [viewMode]);

  const { data, params, widgetId, widgetType } = useRelevantMapNodes();
  const nodeInfo = useMemo<NodeInfo | undefined>(() => {
    if (!data || !node) return;
    const dNode = data.nodes.find((n) => n.node_source_id === node.id);
    if (!dNode) return;
    return {
      name: dNode.name,
      username: dNode.username,
      profile_image_url: dNode.profile_image_url,
      group: data.groups.find((group) => group.id === node.tags.group)!,
    };
  }, [node, data]);

  useEffect(() => {
    if (widgetType !== 'Highlight Map' || !account_id) {
      setNode(undefined);
      isSticky.current = false;
    }
  }, [widgetType, account_id]);

  const handlers = useMemo<Partial<PopoverEventHandlers>>(
    () => ({
      //@ts-ignore
      onNodeClick(popover, { node, variables }) {
        const platform = hgNodes.find(
          (hgNode) => hgNode.node_source_id === node.id
        )?.platform;
        if (isOutsideLiveCoreDataWindow(params)) return;
        if (variables.orders[0] === NodeOrder.Behind) return;
        popover();
        const { endDate, mapId, term, startDate } = params;
        Analytics.event('postWidget', {
          action: 'mv_account_selection',
          label: nodeInfo?.username,
        });
        setUrlQuery(
          {
            account_id: `${node.id}@${platform}`,
            end_date: endDate,
            map_id: mapId,
            query: term,
            start_date: startDate,
            widgetId,
            sort: 'recency',
          },
          'replaceIn'
        );

        setNode((prev) => {
          const isNodesEqual = prev?.id === node.id;
          const next = isSticky.current && isNodesEqual ? undefined : node;
          isSticky.current = !!next;
          return next;
        });
      },
      //@ts-ignore
      onNodeEnter(popover, { node, variables }) {
        if (isSticky.current || variables.orders[0] === NodeOrder.Behind)
          return;
        popover();
        setNode(node);
      },
      //@ts-ignore
      onNodeLeave(_, { variables }) {
        if (isSticky.current || variables.orders[0] === NodeOrder.Behind)
          return;
        setNode(undefined);
      },
      onControlsStart: () => (isMoving.current = true),
      onControlsEnd: () => (isMoving.current = false),
    }),
    [setUrlQuery, params, nodeInfo, widgetId, hgNodes]
  );

  const [
    bgColor,
    white,
    nameColor,
    usernameColor,
    dividerColor,
    groupNameColor,
  ] = useColorModeValues(
    [colors.white, 'rgb(30,37,51)'],
    [colors.black, colors.white],
    [warmGray[1], colors.white],
    [warmGray[1], warmGray[4]],
    [warmGray[3], mapViewer.gray],
    [warmGray[1], '#D2D8E5']
  );

  return !isMoving.current ? (
    // @ts-ignore
    <NodePopoverPlugin
      style={{ width: '250px', minWidth: '750px' }}
      node={node}
      {...handlers}
    >
      {() => {
        if (!nodeInfo) return null;
        const { name, username, profile_image_url, group } = nodeInfo;
        return (
          <Box
            bg={bgColor}
            boxShadow={boxShadows.cardPopup}
            borderRadius={16}
            p={'16px'}
            display="flex"
            flexFlow="row nowrap"
            width="fit-content"
            flexDir="column"
            userSelect="none"
          >
            <Flex gap={'4px'}>
              <Image
                h="40px"
                w="40px"
                src={profile_image_url}
                borderRadius="100%"
                alt={name}
                fallback={<FallbackAvatarImg />}
              />
              <Box>
                <Text
                  color={nameColor}
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="21px"
                  mb="4px"
                >
                  {name}
                </Text>
                <Text
                  color={usernameColor}
                  fontSize="16px"
                  fontWeight="400"
                  lineHeight="19px"
                  wordBreak="break-all"
                >
                  {username}
                </Text>
              </Box>
            </Flex>
            <VStack
              borderTop={`1px solid ${dividerColor}`}
              align="left"
              pt="4px"
              mt="8px"
            >
              {group && (
                <Box mt="4px">
                  <SubtitleHeader color={white}>Map Group</SubtitleHeader>
                  <Flex align="center" gap="8px" mt="5px">
                    <Box
                      h="24px"
                      w="8px"
                      minW="8px"
                      borderRadius="2px"
                      bg={`#${group.hex_color}`}
                    />

                    <Text
                      fontSize="14px"
                      color={groupNameColor}
                      lineHeight="16px"
                      wordBreak="break-all"
                    >
                      {group.name ?? ''}
                    </Text>
                  </Flex>
                </Box>
              )}
            </VStack>
          </Box>
        );
      }}
    </NodePopoverPlugin>
  ) : null;
}

const FallbackAvatarImg = () => (
  <Box w="40px" h="40px" ml={-2} mt={-2}>
    <Icon icon={AvatarIcon} boxSize={'40px'} />
  </Box>
);
