import type { MapInfo, Narrative, NarrativeFeed } from '~/types/graphika-types';
import type { WidgetAnalyticsType } from './analyticsEvents';
import { useEffect, useState } from 'react';
import { getMap, useMap } from '~/queries';
import { Analytics } from './google-analytics';

const useMapById = (id: string | undefined) => {
  const { data: map } = useMap({ map_id: parseInt(id!) }, undefined, {
    enabled: !!id,
  });
  return { map };
};

export const useWidgetAnalyticsEvent = ({
  params,
  insight,
  widgetType,
}: {
  params: WidgetAnalyticsData['params'];
  insight: Narrative;
  widgetType: WidgetAnalyticsType;
}) => {
  const [displayTracked, setDisplayTracked] = useState(false);
  const [insertedTracked, setInsertedTracked] = useState(!!params.mapId);
  const { map } = useMapById(params.mapId);
  useEffect(() => {
    if (!insertedTracked && map && insight) {
      Analytics.event('widget', {
        action: 'insert',
        widgetType,
        ...formatWidgetData({ params, map, insight }),
      });
      setInsertedTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insertedTracked, insight, map]);
  useEffect(() => {
    if (!displayTracked && map && insight) {
      Analytics.event('widget', {
        action: 'display',
        widgetType,
        ...formatWidgetData({ params, map, insight }),
      });
      setDisplayTracked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, insight, displayTracked, setDisplayTracked]);
};

export function useWidgetAnalyticsParamsChangeEvent(args: {
  params: WidgetAnalyticsData['params'];
  insight: Narrative;
  widgetType: WidgetAnalyticsType;
  mode: 'author' | 'reader';
}) {
  const { mode, params: queryParams, insight, widgetType } = args;
  const [initialParams, setInitialParams] =
    useState<WidgetAnalyticsData['params']>(queryParams);
  const [explorationTracked, setExplorationTracked] = useState(false);
  const [prevParams, setPrevParams] =
    useState<WidgetAnalyticsData['params']>(queryParams);
  const { map } = useMapById(queryParams.mapId);
  useEffect(() => {
    if (map && insight && queryParams !== initialParams) {
      const authoringMode = mode === 'author';
      const params = authoringMode ? queryParams : {};
      Analytics.event('widgetQuery', {
        action: authoringMode ? 'params_change' : 'params_change_exploration',
        label: getFirstChangedKey(prevParams, queryParams),
        widgetType,
        ...formatWidgetData({
          params,
          map,
          insight,
        }),
      });
      setPrevParams(queryParams);
      if (!explorationTracked && mode === 'reader') {
        Analytics.event('widget', {
          action: 'exploration',
          widgetType,
          ...formatWidgetData({ params, map, insight }),
        });
        setExplorationTracked(true);
      }
    }
    setInitialParams(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, insight, queryParams]);
}

type WidgetAnalyticsData = {
  params: Partial<{
    startDate: string;
    mapId: string;
    endDate: string;
    term?: string;
  }>;
  map?: MapInfo;
  insight?: Narrative;
};

export const formatWidgetData = ({
  params,
  map,
  insight,
}: WidgetAnalyticsData) => ({
  params: JSON.stringify({
    ...params,
    startDate: params.startDate?.slice(0, 10),
    endDate: params.endDate?.slice(0, 10),
  }),
  map: map?.name ?? '',
  map_id: map?.id ?? '',
  insight: insight?.title ?? '',
  insight_id: insight?.id.toString() ?? '',
});

function getFirstChangedKey<T extends object>(
  prevObj: T,
  currentObj: T
): string | undefined {
  for (const key of Object.keys(currentObj) as Array<keyof T>) {
    if (currentObj[key] !== prevObj[key]) {
      // `startDate` is always before `endDate`, renaming it to date avoids confusion for marketing
      return key === 'startDate' ? 'date' : key.toString();
    }
  }
  return undefined;
}

function extractWidgetsFromHtml(htmlContent: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const dynamicWidgets = doc.querySelectorAll('div.dynamic-widget');
  return Array.from(dynamicWidgets) as HTMLDivElement[];
}

export async function widgetAnalyticsOnPublish(
  insight: Narrative,
  feeds: NarrativeFeed[]
) {
  const { id, summary, title, narrative_feed_ids } = insight;
  const widgetsData = extractWidgetsFromHtml(summary);
  const widgets = widgetsData.map((widget) => ({
    widgetType: widget.dataset?.name,
    params: JSON.parse(widget.dataset?.params ?? ''),
  }));
  for (const widget of widgets) {
    const { data: map } = await getMap({ map_id: widget.params.mapId })();
    Analytics.event('widget', {
      action: 'publish_widget',
      widgetType: widgetNameToAnalyticsName(widget.widgetType || ''),
      insight: title,
      insight_id: id.toString(),
      map: map.name,
      ...widget.params,
    });
  }
  Analytics.event('narrative', {
    action: 'publish_insight',
    label: widgets.length
      ? widgets
          .map((widget) => widget.widgetType)
          .sort()
          .join('-')
      : 'no widgets included',
    value: widgets.length,
    insight: insight.title,
    insight_id: insight.id.toString(),
  });
  narrative_feed_ids?.forEach((feedId) => {
    Analytics.event('feed', {
      action: 'published_insight_to_feed',
      feed: feeds?.find((feed) => feedId === feed.id)?.name ?? 'n/a',
      feed_id: feedId.toString(),
      insight: insight.title,
      insight_id: insight.id.toString(),
      label:
        widgets.length === 0
          ? 'no widget'
          : widgets.map((widget) => widget.widgetType).join('_'),
    });
  });
}

const widgetNameToAnalyticsName = (name: string): WidgetAnalyticsType => {
  switch (name) {
    case 'activityChart':
      return 'activity_chart';
    case 'relevantMapNodes':
      return 'map_highlight';
    default:
      throw Error('add widget statement');
  }
};
