import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import {
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
  $createTextNode,
  $getSelection,
  $isRangeSelection,
} from 'lexical';
import { mergeRegister } from '@lexical/utils';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LinkNode } from '@lexical/link';
import { LinkModal } from '../LinkModal';

export type LinkValues =
  | {
      href?: string;
      text?: string;
    }
  | undefined;

export const INSERT_LINK_COMMAND: LexicalCommand<LinkValues> = createCommand();

export function GraphikaLinkPlugin(): ReactElement {
  const [editor] = useLexicalComposerContext();
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [initialLinkValues, setInitialLinkValues] = useState<LinkValues>();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<LinkValues>(
        INSERT_LINK_COMMAND,
        (linkProps) => {
          const selection = $getSelection();
          const selectedText = selection?.getTextContent();
          const nodes = selection?.getNodes() ?? [];
          const linkNode = nodes.find((n) => n.getType() === 'link');
          const parent = nodes[0].getParent();

          const href =
            linkNode instanceof LinkNode
              ? linkNode?.__url
              : parent instanceof LinkNode
              ? parent?.__url
              : undefined;
          setInitialLinkValues({
            text: selectedText || linkProps?.text,
            href,
          });
          setShowLinkModal(true);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  const handleLinkInsert = ({
    href,
    text,
  }: {
    href?: string;
    text?: string;
  }) => {
    editor.update(() => {
      const selection = $getSelection();
      const nodes = selection?.getNodes() ?? [];
      const selectionNode = nodes[0];
      const parent = selectionNode?.getParent();
      if ($isRangeSelection(selection)) {
        const textNode = $createTextNode(text || href);
        if (parent?.getType() !== 'link') {
          selection.insertNodes([textNode]);
        } else {
          const linkNode = nodes?.find((n) => n.getType() === 'link');
          if (linkNode) {
            linkNode?.replace(textNode);
            nodes.forEach((n) => n.remove());
          } else {
            selectionNode?.replace(textNode);
          }
        }
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, href!);
      }
    });
  };

  return showLinkModal ? (
    <LinkModal
      onClose={() => setShowLinkModal(false)}
      onSubmit={handleLinkInsert}
      initialValues={initialLinkValues}
    />
  ) : (
    <div />
  );
}
