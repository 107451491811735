import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Flex } from '~/components';
import { colors } from '~/styles';

type Props = { onReset: () => void };
export function ModifiedParamsWarning({ onReset }: Props) {
  const gray5 = useColorModeValue(colors.warmGray[5], colors.coolGray[2]);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  return (
    <Flex
      px={4}
      py={2}
      mt={4}
      bg={gray5}
      justify="space-between"
      align="center"
      borderRadius={8}
    >
      <BodyText>Currently in explore mode. Changes will not be saved.</BodyText>
      <BodyText variant="plain" onClick={onReset} color={primary} as="button">
        Reset Chart
      </BodyText>
    </Flex>
  );
}
