import type { BoxProps, HeadingProps, TextProps } from '@chakra-ui/react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';
import { colors } from '~/styles';

export function PageHeader({ children, ...props }: BoxProps) {
  return (
    <Box
      as="h1"
      textStyle="PageHeader"
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function TitleHeader({ children, ...props }: BoxProps) {
  return (
    <Box
      as="h2"
      textStyle="TitleHeader"
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function SubtitleHeader({ children, ...props }: HeadingProps) {
  return (
    <Box
      as="h3"
      textStyle="SubtitleHeader"
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function BodyText({ children, ...props }: TextProps) {
  return (
    <Text
      as="p"
      textStyle="BodyText"
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Text>
  );
}

export function StatsText({ children, ...props }: TextProps) {
  return (
    <Text
      as="span"
      textStyle="StatsText"
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Text>
  );
}

export function CaptionText({ children, ...props }: TextProps) {
  return (
    <Box
      as="h4"
      textStyle="CaptionText"
      color={useColorModeValue(colors.warmGray[2], colors.coolGray[4])}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function BlackCaptionText({ children, ...props }: TextProps) {
  return (
    <Box
      as="h4"
      textStyle="CaptionText"
      fontWeight={500}
      color={useColorModeValue(colors.black, colors.white)}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function LabelText({ children, ...props }: TextProps) {
  return (
    <Box
      as="h4"
      textStyle="LabelText"
      color={useColorModeValue(colors.warmGray[2], colors.coolGray[4])}
      {...(props as any)}
    >
      {children}
    </Box>
  );
}

export function FooterText({ children, ...props }: TextProps) {
  return (
    <Text
      textStyle="FooterText"
      color={useColorModeValue(colors.warmGray[2], colors.coolGray[4])}
      {...(props as any)}
    >
      {children}
    </Text>
  );
}
