import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  container: {},
  closeButton: {},
  label: {
    fontFamily: 'monospace',
    fontWeight: 400,
    fontSize: '12px',
  },
}));

export const Tag = defineMultiStyleConfig({ baseStyle });
