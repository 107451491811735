import { useEffect, useState } from 'react';
import { AppState, useAppSelector } from '~/redux/store';

export function useUser() {
  return useAppSelector((state: AppState) => state.user);
}

export const useIsNavigator = () => {
  const [isNavigator, setIsNavigator] = useState(true);
  const { userInfo } = useUser();
  useEffect(() => {
    setIsNavigator(userInfo?.role === 'analyst' && userInfo.org.id !== 1);
  }, [userInfo]);

  return isNavigator;
};
