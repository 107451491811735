import { useEffect, useRef } from 'react';
import { getRackSession } from '../api';
import { useUser } from './useUser';

export const useDetectAdBlock = () => {
  const adBlockDetected = useRef<boolean>(false);
  const { userInfo } = useUser();

  useEffect(() => {
    // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
    const url = 'https://www3.doubleclick.net';
    if (!userInfo || adBlockDetected.current) return;
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then(({ redirected }) => {
        if (!redirected || adBlockDetected.current) return;
        adBlockDetected.current = true;
        fetch('/api/detect', {
          method: 'POST',
          body: JSON.stringify({
            email: userInfo.email,
            'rack.session': decodeURIComponent(getRackSession()),
          }),
        });
      })
      .catch(() => {
        if (adBlockDetected.current) return;
        adBlockDetected.current = true;
        fetch('/api/detect', {
          method: 'POST',
          body: JSON.stringify({
            email: userInfo.email,
            'rack.session': decodeURIComponent(getRackSession()),
          }),
        });
      });
  }, [userInfo]);
};
