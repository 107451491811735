import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';
import { FaIcon } from '..';

const style = {
  px: 2,
  py: 2,
  minW: '228px',
  color: colors.text,
  borderRadius: 8,
  border: `1px solid ${colors.outline}`,
  background: colors.surface[0],
  fontSize: '14px',
  _focus: {
    outline: 'none',
    background: colors.white,
  },
  _disabled: {
    background: colors.neutral[50],
    cursor: 'not-allowed',
    svg: {
      fill: 'red',
    },
  },
  _placeholder: {
    color: colors.secondaryText,
  },
};

type InputProps = ChakraInputProps & {
  iconLeft?: IconProp;
  iconRight?: IconProp;
};

export function Input(props: InputProps) {
  const { iconLeft, iconRight, ...rest } = props;
  const inputStyle: ChakraInputProps = {
    ...style,
    pl: !!iconLeft ? 8 : 2,
    pr: !!iconRight ? 8 : 2,
    _placeholder: {
      ...style._placeholder,
      pl: !!iconLeft ? 0 : 0,
    },
  };

  return (
    <InputGroup>
      {!!iconLeft && (
        <InputLeftElement pt={3} pl={2}>
          <FaIcon
            icon={iconLeft}
            color={props.isDisabled ? colors.neutral[200] : colors.surface[700]}
          />
        </InputLeftElement>
      )}
      <ChakraInput {...inputStyle} {...(rest as any)} />
      {!!iconRight && (
        <InputRightElement pt={3} pr={2}>
          <FaIcon
            icon={iconRight}
            color={props.isDisabled ? colors.neutral[200] : colors.surface[700]}
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
}

type TextareaProps = ChakraTextareaProps & { autoGrow?: boolean };

export function Textarea(props: TextareaProps) {
  const textareaStyle: ChakraTextareaProps = {
    ...style,
  };
  return <ChakraTextarea {...textareaStyle} {...(props as any)} />;
}
