export type HgBasicQueryParams = {
  startDate: string;
  endDate: string;
  term?: string;
  mapId: string;
  sort?: string;
};

export * from './postActivity';
export * from './relevantMapNodes';
