import {} from 'react';
import { UnorderedList } from '@chakra-ui/react';
import {
  BodyText,
  Box,
  Flex,
  Image,
  Link,
  PageHeader,
  SubtitleHeader,
  TitleHeader,
} from '~/components';
import { colors } from '~/styles';
// @ts-ignore
import Content from '~/components/docs/docsMdContent.mdx';

type Props = {};

export function DocsContent({}: Props) {
  return (
    <Box
      w="50vw"
      maxW="720px"
      px={4}
      mb="100px"
      sx={{
        'h2:first-of-type': {
          mt: 0,
        },
        'h3 + h4': {
          mt: 4,
        },
        'p + ul': {
          mt: -4,
        },
      }}
    >
      <Content components={docComponents} />
    </Box>
  );
}

type MdProps = { children: string; alt?: string; src?: string; href?: string };

const docComponents = {
  p: ({ children }: MdProps) => <BodyText mb={6}>{children}</BodyText>,
  h1: ({ children }: MdProps) => (
    <PageHeader data-section-content={children} mb={6} mt="80px" as="h2">
      {children}
    </PageHeader>
  ),
  h2: ({ children }: MdProps) => (
    <TitleHeader data-subsection-content={children} mt="80px" mb={2} as="h3">
      {children}
    </TitleHeader>
  ),
  h3: ({ children }: MdProps) => (
    <SubtitleHeader
      data-h4-content={children}
      mb={2}
      fontWeight="bold"
      mt="80px"
      as="h4"
    >
      {children}
    </SubtitleHeader>
  ),
  img: ({ alt, src }: MdProps) => (
    <Flex justify="center">
      <Image borderRadius={8} src={src} alt={alt} my={6} />
    </Flex>
  ),
  li: ({ children }: MdProps) => (
    <BodyText as="li" key={children} mb={0.5}>
      {children}
    </BodyText>
  ),
  ul: ({ children }: MdProps) => (
    <UnorderedList mb={4}>{children}</UnorderedList>
  ),
  a: ({ children, href }: MdProps) => (
    <Link href={href} target="_blank" color={colors.g.light}>
      {children}
    </Link>
  ),
  code: ({ children }: MdProps) => (
    <BodyText
      display="inline-block"
      fontFamily="monospace"
      bg=" #2C264A"
      border="1px solid #4A446A"
      px={1}
      borderRadius={4}
    >
      {children}
    </BodyText>
  ),
};
