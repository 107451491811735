import type { MouseEventHandler, PropsWithChildren, ReactElement } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { chakra, forwardRef, Link as ChakraLink } from '@chakra-ui/react';
import NextLink from 'next/link';
import { Analytics } from '~/lib/analytics';

type Props = PropsWithChildren<
  StyleProps & {
    as?: any;
    basic?: boolean;
    external?: boolean;
    href?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    replace?: boolean;
    scroll?: boolean;
    noFocusStyle?: boolean;
    noHoverStyle?: boolean;
    variant?: string;
  }
>;

const LinkWithChakraProps = chakra('a');
/**
 * Wraps Next/Link and @chakra/link
 *
 * @param {any} as Render an alternative Component as a link, instead of the default
 * @chakra/link.
 * @param {boolean} basic Render only an 'a' type link, without @chakra/link styling.
 * @param {boolean} external Force link to open in a new tab/window.
 * @param {string}  href The url to link to.
 * @param {onClick} onClick adds styling but renders simple <ChakraLink>, `href` should be omitted in favor of custom `onClick` behavior
 * @param {boolean} replace Replace the current location in the navigation history.
 * @param {boolean} scroll By default, the page will scroll to the top when navigating.
 */
export const Link = forwardRef<Props, 'a'>(
  (
    {
      basic,
      children,
      external,
      href,
      onClick,
      replace,
      scroll,
      target: propsTarget,
      noFocusStyle,
      noHoverStyle,
      ...props
    },
    ref
  ): ReactElement => {
    if (process.env.NODE_ENV === 'development') {
      if (href && onClick) {
        console.warn(
          'Do not use both href and an onClick handler on a single Link.'
        );
      }
    }
    if (onClick) {
      return (
        <ChakraLink ref={ref} {...(props as any)} onClick={onClick}>
          {children}
        </ChakraLink>
      );
    }
    const target = propsTarget
      ? propsTarget
      : external === true
      ? '_blank'
      : external === false
      ? undefined
      : href?.match(/^http/)
      ? '_blank'
      : undefined;
    const LinkComponent = basic ? LinkWithChakraProps : ChakraLink;
    if (target) {
      // Using NextLink for an external link is not useful.
      return (
        <LinkComponent
          href={href ?? ''}
          target={target}
          ref={ref}
          {...(props as any)}
          _focus={noFocusStyle ? { boxShadow: 'none' } : undefined}
          _hover={noHoverStyle ? { textDecoration: 'none' } : undefined}
          onMouseUp={() => {
            Analytics.event('link', {
              action: 'external',
              label: href ?? 'n/a',
            });
          }}
        >
          {children}
        </LinkComponent>
      );
    }
    return (
      <NextLink href={href ?? ''} passHref replace={replace} scroll={scroll}>
        <LinkComponent
          ref={ref}
          {...(props as any)}
          _focus={noFocusStyle ? { boxShadow: 'none' } : undefined}
          _hover={noHoverStyle ? { textDecoration: 'none' } : undefined}
        >
          {children}
        </LinkComponent>
      </NextLink>
    );
  }
);
