import type { BoxProps } from '@chakra-ui/react';
import { chakra, useColorModeValue } from '@chakra-ui/react';
import { animations, boxShadows, colors } from '~/styles';

interface CardProps extends BoxProps {
  selected?: boolean;
  noHover?: boolean;
}

export function Card(props: CardProps) {
  const { children, selected = false, noHover = false, ...rest } = props;
  const bg = useColorModeValue(colors.white, colors.coolGray[2]);
  const borderColor = useColorModeValue(colors.g.primary, colors.g.light);

  return (
    <chakra.div
      boxShadow={selected ? boxShadows.cardSelected : boxShadows.card}
      borderRadius="8px"
      bg={bg}
      p="16px"
      border={`1px solid ${selected ? borderColor : 'transparent'}`}
      transition={animations.hoverAnimation}
      _hover={
        { border: noHover ? undefined : `1px solid ${borderColor}` } as any
      }
      {...(rest as any)}
    >
      {children}
    </chakra.div>
  );
}
