// @ts-nocheck
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  forwardRef,
  keyframes,
  StyleProps,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { Box, Flex, Icon } from '~/components';

export interface ButtonProps extends ChakraButtonProps {
  icon?: () => React.ReactNode;
  iconProps?: StyleProps;
  noFocusStyle?: boolean;
}
export const Button = forwardRef<ButtonProps, 'button'>((props, ref) => {
  const { children, icon, iconProps, noFocusStyle, ...rest } = props;

  if (icon && !children) {
    return (
      <ChakraButton ref={ref} px={0} {...(rest as any)}>
        <Icon boxSize={6} icon={icon} />
      </ChakraButton>
    );
  }

  const IconComponent = (
    <Icon
      boxSize={6}
      icon={icon || (props.leftIcon as any) || (props.rightIcon as any)}
      ml="-2px"
      {...(iconProps as any)}
    />
  );

  return (
    <ChakraButton
      ref={ref}
      flexShrink={0}
      overflow="hidden"
      leftIcon={(icon || props.leftIcon) && IconComponent}
      rightIcon={props.rightIcon && IconComponent}
      pl={icon && 3}
      spinner={Spinner()}
      _focus={noFocusStyle ? { boxShadow: 'none' } : undefined}
      {...(rest as any)}
    >
      {children}
    </ChakraButton>
  );
});

function Spinner() {
  const dots = 3;
  const frames = usePrefersReducedMotion()
    ? keyframes`
      30% {  opacity: 1; transform: scale(1.5)}
      0%, 60%, 100% {  opacity: 0.8; transform: scale(1.5)}
      `
    : keyframes`
      30% {  opacity: 1; transform: scale(1.5)}
      0%, 60%, 100% {  opacity: 0.4; transform: scale(1)}
      `;
  return (
    <Flex>
      {Array.from({ length: dots }).map((_, idx) => (
        <SpinnerDot key={idx} dots={dots} frames={frames} offset={idx} />
      ))}
    </Flex>
  );
}

function SpinnerDot({
  dots,
  frames,
  offset,
}: {
  dots: number;
  frames: ReturnType<typeof keyframes>;
  offset: number;
}) {
  const duration = 0.8; // sec
  const size = '5px';
  const delay = (duration * offset) / dots;
  return (
    <Box
      animation={`${frames} ${duration}s infinite ease-in-out`}
      bg="currentColor"
      borderRadius="100%"
      display="inline-block"
      height={size}
      mx={size}
      width={size}
      sx={{ animationDelay: `${delay}s` }}
    />
  );
}
