import ContentLoader from 'react-content-loader';

export function PostsSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={400}
      height={909}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect height="40" rx="20" width="40" x="12" y="18" />
      <rect height="12" rx="6" width="77" x="60" y="18" />
      <rect height="216" rx="16" width="384" x="8" y="137" />
      <rect height="12" rx="6" width="364" x="8" y="74" />
      <rect height="12" rx="6" width="229" x="8" y="92" />
      <rect height="12" rx="6" width="378" x="8" y="110" />
      <rect height="40" rx="20" width="40" x="12" y="388" />
      <rect height="12" rx="6" width="83" x="60" y="388" />
      <path d="m8 500c0-3.314 2.6863-6 6-6h348c3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6h-348c-3.3137 0-6-2.686-6-6z" />
      <rect height="12" rx="6" width="229" x="8" y="462" />
      <rect height="12" rx="6" width="378" x="8" y="480" />
      <rect height="40" rx="20" width="40" x="12" y="527" />
      <rect height="12" rx="6" width="83" x="60" y="527" />
      <path d="m8 639c0-3.314 2.6863-6 6-6h348c3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6h-348c-3.3137 0-6-2.686-6-6z" />
      <rect height="12" rx="6" width="229" x="8" y="601" />
      <rect height="12" rx="6" width="378" x="8" y="619" />
    </ContentLoader>
  );
}
