import type { FollowsType } from '~/components/maps/FollowsSelection';
import { NodeOrder, defineNodeStyle, useNodeStyle } from '@graphika/map-viewer';
import { useEffect, useMemo, useState } from 'react';
import { useNodeFollows } from '~/queries';
import { MapInfo, MapNode } from '~/types/graphika-types';
import { useSegmentTree } from '~/lib/stores/segment-tree';

const greyNodeStyle = defineNodeStyle({
  color: '#666666',
  alpha: 0.2, // TODO: set to 0.4, better for debugging for now
  order: NodeOrder.Behind,
});

const mutedNodeStyle = defineNodeStyle({
  alpha: 0.6,
});

export const originalNodeStyle = defineNodeStyle({
  order: NodeOrder.Natural,
  alpha: 1,
});

type Props = {
  highlightedNode?: MapNode;
  nodes: MapNode[];
  map: MapInfo;
  followType: FollowsType;
};

export function StylePlugin({
  highlightedNode,
  nodes,
  map,
  followType,
}: Props) {
  const [followsNodeIds, setFollowsNodeIds] = useState<string[]>([]);
  const { data: followerData = [], isFetching: isFetchingFollower } =
    useNodeFollows(
      {
        map_id: parseInt(map.id!),
        node_id: highlightedNode?.node_source_id!,
      },
      {
        query: {
          follow_type: 'follower',
        },
      },
      {
        enabled: !!highlightedNode,
      }
    );

  const { data: followingData = [], isFetching: isFetchingFollowing } =
    useNodeFollows(
      {
        map_id: parseInt(map.id!),
        node_id: highlightedNode?.node_source_id!,
      },
      {
        query: {
          follow_type: 'following',
        },
      },
      {
        enabled: !!highlightedNode,
      }
    );

  const clusterStates = useSegmentTree((state) => state.clusters);
  const inactiveClusterIds = useMemo(
    () =>
      Object.entries(clusterStates)
        .filter(([_, value]) => !value.active)
        .map(([cluster_number, _]) => cluster_number),
    [clusterStates]
  );
  const activeNodeIds = useMemo(
    () => nodes.filter((node) => !inactiveClusterIds.includes(node.cluster_id)),
    [nodes, inactiveClusterIds]
  );
  const greyNodeIds = useMemo(
    () =>
      nodes
        .filter((node) =>
          highlightedNode ? !followsNodeIds.includes(node.node_source_id) : true
        )
        .filter((node) => inactiveClusterIds.includes(node.cluster_id))
        .filter(
          (node) => node.node_source_id !== highlightedNode?.node_source_id
        )
        .map((node) => node.node_source_id)
        .sort(),
    [nodes, followsNodeIds, inactiveClusterIds, highlightedNode]
  );
  const mutedNodesIds = useMemo(
    () =>
      nodes
        .filter((node) =>
          highlightedNode
            ? !followsNodeIds.includes(node.node_source_id)
            : false
        )
        .filter((node) => !inactiveClusterIds.includes(node.cluster_id))
        .map((node) => node.node_source_id)
        .sort(),
    [nodes, followsNodeIds, highlightedNode, inactiveClusterIds]
  );
  const originalStyleNodeIds = highlightedNode ? followsNodeIds : activeNodeIds;
  useNodeStyle(greyNodeIds, greyNodeStyle);
  useNodeStyle(mutedNodesIds, mutedNodeStyle);
  useNodeStyle(originalStyleNodeIds, originalNodeStyle);

  useEffect(() => {
    if (isFetchingFollower || isFetchingFollower || !highlightedNode) return;
    if (followType === 'follower') {
      setFollowsNodeIds(followerData.map((node) => node.account_id));
    }
    if (followType === 'following') {
      setFollowsNodeIds(followingData.map((node) => node.account_id));
    }
  }, [
    followingData,
    followerData,
    highlightedNode,
    isFetchingFollower,
    isFetchingFollowing,
    followType,
  ]);

  useEffect(() => {
    if (!highlightedNode) {
      setFollowsNodeIds([]);
    }
  }, [highlightedNode]);

  return null;
}
