import { TextMatchTransformer } from '@lexical/markdown';
import { $createImageNode, $isImageNode, ImageNode } from './ImageNode';

export const GRAPHIKA_IMAGE: TextMatchTransformer = {
  dependencies: [ImageNode],
  export: (node) => {
    if (!$isImageNode(node)) return null;

    const caption = node.get('caption'),
      url = node.get('url');

    return `![${caption}](${url})`;
  },
  importRegExp: /\!(?:\[([^\[]*)\])(?:\(([^\(]+)\))/,
  regExp: /\!(?:\[([^\[]*)\])(?:\(([^\(]+)\))$/,
  replace(textNode, match) {
    const [, caption, url] = match;
    const imageNode = $createImageNode({ caption, url });
    textNode.replace(imageNode);
  },
  trigger: ')',
  type: 'text-match',
};
