import { ApiResult, fetchFromApi, HttpMethod, HttpStatusOK } from '~/lib/api';
import { paths } from '~/types/generated';
import {
  hasBodyData,
  hasFormData,
  hasQueryData,
  PathParams,
  Payload,
  ResponseByStatus,
  templateParams,
} from './shared';

/**
 * @summary Creates a typed fetch request for an operation defined by the `path` and `method`.
 *
 * The returned function should be called like so: `fetch({ path_param: 'value' })({ body: { ... }, query: { ... } })`
 *
 * @example
 * ```ts
 * const updateTodo = buildTypedFetch('/todos/{todo_id}', 'put');
 * const modifiedTodo = await updateTodo({ todo_id: 3 })({
 *    body: { description: 'take out the trash' }
 * });
 * ```
 */
export function buildTypedFetch<
  P extends keyof paths,
  M extends keyof paths[P],
  T = ResponseByStatus<paths[P][M], HttpStatusOK>
>(path: P, method: M) {
  return (params: PathParams<paths[P][M]>) => {
    return (payload: Payload<paths[P][M]>): Promise<ApiResult<T>> => {
      const url = templateParams(path, params);
      const useBody = hasBodyData(payload);
      const data = useBody
        ? payload.body
        : hasQueryData(payload)
        ? payload.query
        : undefined;

      return fetchFromApi<T>(
        url,
        (method as string).toUpperCase() as HttpMethod,
        data,
        { useBody, form: hasFormData(payload) ? payload.form : undefined }
      );
    };
  };
}
