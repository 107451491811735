import { extendTheme, ThemeOverride } from '@chakra-ui/react';
import { colorsV2 } from './colorsV2';
import * as components from './componentsV2';
import global from '../globalStyles';
import boxShadows from './boxShadows';

export const zIndices = {
  hide: -1,
  auto: 'auto',
  base: 0,
  docked: 10,
  dropdown: 1000,
  sticky: 1100,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
} as const;

export { boxShadows };

export { colorsV2 as colors };

export const theme: ThemeOverride = extendTheme({
  colors: colorsV2,
  styles: {
    global,
  },
  components,
});
