import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserInfo } from '~/lib/auth';

export interface UserState {
  userInfo: UserInfo | undefined;
  hasCheckedAuthStatus: boolean;
}

const initialState: UserState = {
  userInfo: undefined,
  hasCheckedAuthStatus: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginAction: (state, action: PayloadAction<UserInfo>) => {
      state.hasCheckedAuthStatus = true;
      state.userInfo = action.payload;
    },
    logoutAction: (state) => {
      state.hasCheckedAuthStatus = false;
      state.userInfo = undefined;
    },
    setUserInfoAction: (state, action: PayloadAction<UserInfo | undefined>) => {
      state.hasCheckedAuthStatus = true;
      state.userInfo = action.payload;
    },
  },
});

export const { loginAction, logoutAction, setUserInfoAction } =
  userSlice.actions;

export default userSlice.reducer;
