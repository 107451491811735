import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $insertNodeToNearestRoot, mergeRegister } from '@lexical/utils';
import { $getNodeByKey, COMMAND_PRIORITY_EDITOR, LexicalEditor } from 'lexical';
import { memo, useCallback, useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { ObjectEntries } from '~/lib/utils';
import {
  addWidgetEventListeners,
  type DynamicWidgetComponent,
  type WidgetEventListeners,
} from '../shared';
import { WidgetName, widgets } from '../widgets';
import {
  $createDynamicWidgetNode,
  $isDynamicWidgetNode,
  DynamicWidgetNode,
  INSERT_DYNAMIC_WIDGET_COMMAND,
} from './DynamicWidgetNode';

export type PluginProps = {
  components: Record<keyof typeof widgets, DynamicWidgetComponent>;
  listeners?: WidgetEventListeners<Record<string, any>>;
};
function _DynamicWidgetsPlugin({ components, listeners }: PluginProps) {
  const [editor] = useLexicalComposerContext();

  useRegisterWidgetEvents(editor, listeners ?? {});

  useEffect(() => {
    registerWidgetComponents(components);
    if (!editor.hasNodes([DynamicWidgetNode])) {
      throw new Error(
        `DynamicWidgetsPlugin: DynamicWidgetNode not registered on the editor instance`
      );
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_DYNAMIC_WIDGET_COMMAND,
        (payload) => {
          const widgetNode = $createDynamicWidgetNode(payload);
          $insertNodeToNearestRoot(widgetNode);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerMutationListener(
        DynamicWidgetNode,
        (mutations, { prevEditorState: prevState }) => {
          prevState.read(() =>
            [...mutations]
              .map(([key, mutation]) => {
                if (mutation !== 'destroyed') return;
                const node = $getNodeByKey(key, prevState);
                return $isDynamicWidgetNode(node) ? node : undefined;
              })
              .filter(Boolean)
              .forEach((node) => node!.removeSnapshot())
          );
        }
      )
    );
  }, [editor, components]);

  return null;
}
export const DynamicWidgetsPlugin = memo(_DynamicWidgetsPlugin, (a, b) =>
  shallowEqual(a.components, b.components)
);

function registerWidgetComponents(
  components: Record<WidgetName, DynamicWidgetComponent>
) {
  Object.entries(components).forEach((entry) => {
    const [name, component] = entry as ObjectEntries<typeof components>;
    widgets[name]._component = component;
  });
}

/** Keeps track of the `DynamicWidgetNode` Lexical nodes and ensures that the `listeners` are attached to each node's DOM element
 * @see https://lexical.dev/docs/concepts/dom-events#2-directly-attach-handlers
 */
function useRegisterWidgetEvents(
  editor: LexicalEditor,
  listeners: WidgetEventListeners<Record<string, any>>
) {
  const registeredElements = useRef(new WeakSet<HTMLElement>());
  const addEventListeners = useCallback(
    (element: HTMLElement) => addWidgetEventListeners(element, listeners),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.values(listeners)
  );

  useEffect(() => {
    return editor.registerMutationListener(DynamicWidgetNode, (mutations) => {
      [...mutations].forEach(([key, mutation]) => {
        const element = editor.getElementByKey(key);

        // only attach the listeners if the mutation is create/update and we haven't already attached them to this element
        if (
          element !== null &&
          (mutation === 'created' || mutation === 'updated') &&
          !registeredElements.current.has(element)
        ) {
          registeredElements.current.add(element);
          addEventListeners(element);
        }
      });
    });
  }, [editor, addEventListeners]);
}
