import fetch from 'cross-fetch';
import { JsonBody, QueryParams } from '~/queries/utils';
import { paths } from '~/types/generated';
import { isTruthy, ttEnsureAll } from './js-toolkit-functions';

// ported over and adapted from https://github.com/graphika/starship/blob/61573c0ecaaaae3ceb46c7d5cb10189fec2e1311/src/queries/fetchUtils.ts

/** Type helper to extract the data type from an {@link ApiResult} */
export type ExtractData<T extends ApiResult<any>> = T extends ApiResult<infer U>
  ? U
  : never;

export type ApiResult<T> = {
  result: 'success';
  data: T;
  status: 200;
  statusText?: string;
};

export type ApiFailure = {
  result: 'failure';
  data: null;
  status: number;
  statusText?: string;
};

type ApiUrl<T extends keyof paths> = string & T;
type ApiMethods<T> = T extends ApiUrl<infer U>
  ? Uppercase<keyof paths[U] & string>
  : HttpMethod;
/**
 * Pulls out the type of the request data to be sent based on the path and method.
 * - If the operation is not in the spec, the type is `Record<string, any>`
 * - If the operation has query params, the type is of the query params object
 * - If the operation has a json body, the type is of the json object
 */
type ApiData<P extends keyof paths, M extends string> = M extends keyof paths[P]
  ? QueryParams<paths[P][M]> extends undefined
    ? JsonBody<paths[P][M]> extends undefined
      ? Record<string, any>
      : JsonBody<paths[P][M]>
    : QueryParams<paths[P][M]>
  : Record<string, any>;

export type HttpMethod = 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
export type HttpStatusOK =
  | 200
  | 201
  | 202
  | 203
  | 204
  | 205
  | 206
  | 207
  | 208
  | 209
  | 226;

export const UncaughtQueryException = Symbol('UncaughtQueryException');

// Helpers for setting/getting the `rack.session` token.
// The staging Core API is hosted on a different domain than staging Graphika API, so we make use of these to "forward" the token.
export const getRackSession = () => sessionStorage.getItem('session') ?? '';
export const setRackSession = (token: string | null | undefined) =>
  sessionStorage.setItem('session', token ?? '');

/**
 * creates valid URL From `NEXT_PUBLIC_API_URL` env var and `path`, adds `params` to query string if `useBody` is false (default), otherwise omits it
 *
 * @param {Record<string, any>} [params={}] params of the request
 * @param {boolean} [useBody=false] if true does not use query string
 */
export function getUrl(
  path: string,
  params: Record<string, any> = {},
  useBody = false
): string {
  const targetUrl = new URL(path, process.env.NEXT_PUBLIC_API_URL);
  if (!useBody) {
    Object.entries(params).forEach(([name, value]) => {
      if (value != null) {
        targetUrl.searchParams.append(name, value);
      }
    });
  }
  return targetUrl.toString();
}

/**
 * General utility API fetch function, creates full URL from `url` and NEXT_PUBLIC_API_URL env var
 * returns CallStack on error
 *
 * @note
 *  This function may be used in combination with the `apiUrl` helper for type completions from the OpenApi spec.
 * @example
 * ```ts
 * fetchFromApi(apiUrl('/topics/{topic_id}/narrative_feeds', 2), 'POST', {
 *   narrative_feed_id: 5,
 * });
 * ```
 */
export async function fetchFromApi<
  T = unknown,
  U extends ApiUrl<any> = string,
  M extends ApiMethods<U> = ApiMethods<U>
>(
  url: U,
  method: M = 'GET' as M,
  params?: U extends ApiUrl<infer P>
    ? ApiData<P, Lowercase<typeof method>>
    : Record<string, any>,
  options?: {
    useBody?: boolean;
    binaryBody?: Blob;
    form?: FormData;
    csvContentType?: boolean;
    zipContentType?: boolean;
  }
): Promise<ApiResult<T>> {
  const { useBody, binaryBody, csvContentType, form, zipContentType } =
    options ?? {};
  const fullUrl = getUrl(url as string, params ?? {}, useBody);
  // generate the call stack here since async stack traces are GC'd by the time an error is caught
  const callStack = Object.assign(new Error(), { location: '' });
  const contentType =
    binaryBody != null
      ? 'application/binary'
      : csvContentType
      ? 'text/csv'
      : zipContentType
      ? 'application/zip'
      : useBody
      ? 'application/json'
      : undefined;
  const headers = {
    'Content-Type': contentType,
    'Graphika-API-Client-Name': 'voyager',
    'X-WWW-Auth-Opt-Out': 'True',
  } as Record<string, string>;

  // Explaination: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
  if (form) delete headers['Content-Type'];

  try {
    const response = await fetch(fullUrl, {
      credentials: 'include',
      mode: 'cors',
      body:
        binaryBody ??
        form ??
        (useBody ? JSON.stringify(params ?? {}) : undefined),
      headers,
      method,
    });

    if (response.ok) {
      const contentType = response.headers.get('content-type') ?? '';
      setRackSession(response.headers.get('X-Rack-Session'));
      const result: ApiResult<T> = {
        data: null!,
        result: 'success',
        status: response.status as 200,
        statusText: response.statusText,
      };
      if (contentType.includes('application/zip')) {
        // @ts-expect-error
        result.data = response._bodyBlob;
      }

      if (contentType.includes('application/json')) {
        result.data = await response.json();
      } else if (contentType.includes('text/html')) {
        const text = await response.text();
        result.data = (
          text === 'true' ? true : text === 'false' ? false : text
        ) as any;
      }

      return result;
    } else if (response.status === 401) {
      throw response;
    } else {
      throw response;
    }
  } catch (response: any) {
    const apiErrorResponse = await response.text();
    callStack.location = window.location.toString();
    callStack.message = [
      'Error fetching from API.',
      ttEnsureAll`Response: "${apiErrorResponse}"`,
      ttEnsureAll`Version hash: ${process.env.GIT_COMMIT}`,
      // `Version hash: ${process.env.GIT_COMMIT}`,
      `Referer: ${callStack.location}`,
      `Request: ${method} ${fullUrl}`,
      useBody && JSON.stringify(params ?? {}, null, 2),
    ]
      .filter(isTruthy)
      .join('\n');
    throw {
      result: 'failure',
      data: apiErrorResponse,
      status: response.status,
      statusText: response.statusText,
      [UncaughtQueryException]: callStack,
    };
  }
}

/** A type-completed way to build a url from a template in the openapi spec.
 *  Parameters are filled in the order that they appear in the template url.
 */
export function apiUrl<T extends keyof paths>(
  url: T,
  ...params: any[]
): ApiUrl<T> {
  let n = -1;
  return url
    .split('/')
    .map((part) => {
      if (part.startsWith('{')) {
        ++n;
        if (params[n] === undefined)
          throw new RangeError(
            `API path '${url}' requires more arguments than those provided.`
          );
        return params[n];
      } else {
        return part;
      }
    })
    .join('/') as ApiUrl<T>;
}

/**
 * Helper to fetch from Core API.
 *
 * @param method - GET, POST, DELETE, PATCH, PUT
 * @param path
 * @param data - For GET requests, this object specifies the `searchParams` on the URL. For all other methods, this object is serialized to a string with `JSON.stringify` and used as the request body.
 */
export async function fetchFromCoreApi<T = Record<string, any>>(
  method: HttpMethod,
  path: string,
  data?: Record<string, any>
): Promise<T> {
  const url = new URL(path, process.env.NEXT_PUBLIC_CORE_API_URL);
  const body = data && method !== 'GET' ? JSON.stringify(data) : undefined;
  if (data && method === 'GET') {
    Object.entries(data).forEach(([key, value]) => {
      if (value != null) {
        url.searchParams.append(key, value);
      }
    });
  }

  const response = await fetch(url, {
    method,
    body,
    headers: {
      'Content-Type': 'application/json',
      'x-rack-session': getRackSession(),
    },
  });
  const { ok, status, statusText } = response;
  if (!ok) throw new Error(`[${status} ${statusText}] ${url}`);
  return response.json() as Promise<T>;
}

/**
 * Helper to fetch CSV data from Core API, returns a File object.
 *
 * @param method - GET, POST, DELETE, PATCH, PUT
 * @param path
 * @param data - For GET requests, this object specifies the `searchParams` on the URL. For all other methods, this object is serialized to a string with `JSON.stringify` and used as the request body.
 */
export async function fetchCsvFromCoreApi<T = Record<string, any>>(
  method: HttpMethod,
  path: string,
  data?: Record<string, any>
): Promise<File> {
  const url = new URL(path, process.env.NEXT_PUBLIC_CORE_API_URL);
  const body = data && method !== 'GET' ? JSON.stringify(data) : undefined;
  if (data && method === 'GET') {
    Object.entries(data).forEach(([key, value]) => {
      if (value != null) {
        url.searchParams.append(key, value);
      }
    });
  }

  const response = await fetch(url, {
    method,
    body,
    headers: {
      'Content-Type': 'text/csv',
      'x-rack-session': getRackSession(),
    },
  });
  const { ok, status, statusText } = response;
  if (!ok) throw new Error(`[${status} ${statusText}] ${url}`);
  const result = await response.text();
  const blob = new Blob([result as string], { type: 'text/csv' });
  const file = new File([blob], 'fog.csv', { type: 'text/csv' });
  return file;
}
