import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ApiResult, HttpStatusOK } from '~/lib/api';
import { paths } from '~/types/generated';

type ElementOf<T> = T extends (infer E)[] ? E : never;
export type WithOverride<T, Fallback> = T extends void ? Fallback : T;

export function removeKeys<T extends {}, U extends (keyof T)[]>(
  obj: T,
  keys: U
): Pick<T, Exclude<keyof T, ElementOf<U>>> {
  const result = { ...obj };
  keys.forEach((key) => delete result[key]);
  return result;
}

export function useInvalidateOnSuccess(
  keys: QueryKey[],
  _onSuccess?: (data: any, variables: any, context: any) => unknown
) {
  const queryClient = useQueryClient();

  const options = useState(() => ({
    onSuccess(data: any, variables: any, context: any) {
      return Promise.resolve(_onSuccess?.(data, variables, context)).then(() =>
        Promise.all(keys.map((key) => queryClient.invalidateQueries(key)))
      );
    },
  }))[0];

  return options;
}

export const hasQueryData = <T>(
  data: T
  // @ts-ignore
): data is T & { query: Record<string, any> } => data && 'query' in data;
export const hasBodyData = <T>(
  data: T
  // @ts-ignore
): data is T & { body: Record<string, any> } => data && 'body' in data;
export const hasFormData = <T>(data: T): data is T & { form: FormData } =>
  // @ts-ignore
  data && 'form' in data;

export function templateParams(
  path: string,
  params: Record<string, any> | void
) {
  if (!params) return path;
  return Object.entries(params).reduce((result, [name, value]) => {
    if (value === undefined)
      throw new Error(
        `Error when templating '${path}': parameter '${name}' is undefined`
      );
    return result.replace(`{${name}}`, value?.toString() ?? '');
  }, path);
}

export type TypedFetch<
  P extends keyof paths,
  M extends keyof paths[P],
  ResponseOk = ResponseByStatus<paths[P][M], HttpStatusOK>
> = (
  params: PathParams<paths[P][M]>
) => (payload: Payload<paths[P][M]>) => Promise<ApiResult<ResponseOk>>;

export type TypedKeyGenerator<
  P extends keyof paths,
  M extends keyof paths[P]
> = (data: {
  params: PathParams<paths[P][M]>;
  query: QueryParams<paths[P][M]>;
  body: JsonBody<paths[P][M]>;
}) => QueryKey;

/** Extract the path parameters type of an operation */
export type PathParams<Op, F = undefined, T = undefined> = Op extends {
  parameters: { path: any };
}
  ? T extends undefined
    ? Op['parameters']['path']
    : T
  : F extends undefined
  ? void
  : F;

/** Extract the query parameters type of an operation */
export type QueryParams<Op, F = undefined, T = undefined> = Op extends {
  parameters: { query: any };
}
  ? T extends undefined
    ? Op['parameters']['query']
    : T
  : F;

/** Extract the request body type of an operation */
export type JsonBody<Op, F = undefined, T = undefined> = Op extends {
  requestBody: { content: { 'application/json': any } };
}
  ? T extends undefined
    ? Op['requestBody']['content']['application/json']
    : T
  : F;

/** Extract the multipart/form-data type of an operation */
export type MultipartForm<Op, F = undefined, T = undefined> = Op extends {
  requestBody: { content: { 'multipart/form-data': any } };
}
  ? T extends undefined
    ? Op['requestBody']['content']['multipart/form-data']
    : T
  : F;

/** Extract the JSON response type of an operation by response status */
export type ResponseByStatus<Op, S extends number> = Op extends {
  responses: any;
}
  ? Op['responses'][S & keyof Op['responses']]['content']['application/json']
  : undefined;

/** Helper for typing the payload data passed to a fetchify function.
 * Resolves to `void`, `{ query }`, `{ body }`, or `{ query, body }`
 */
export type Payload<
  Op,
  Q = QueryParams<Op>,
  B = JsonBody<Op>,
  F = MultipartForm<Op>,
  P = _Payload<Q, B, F>
> = P extends { body: any }
  ? P
  : P extends { query: any }
  ? P
  : P extends { form: any }
  ? P
  : void;
type _Payload<Q, B, F> = (Q extends undefined ? {} : { query: Q }) &
  (B extends undefined ? {} : { body: B }) &
  (F extends undefined ? {} : { form: F });
