import type { UseDynamicWidgetHook } from '~/components/elements/Editor/plugins';
import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useColorModeValue, useOutsideClick } from '@chakra-ui/react';
import { Flex } from '~/components';
import { usePostUrlQueryParams } from '~/lib/hooks';
import { ActivityDataSeries, getActivityChartSeries } from '~/lib/hypergraph';
import { boxShadows, colors } from '~/styles';
import {
  PostList,
  PostsSidebarSorting,
  PostsSidebarTop,
  PostsSkeleton,
} from '.';
import { useWidgetPosts } from './utils';

type Props = {
  useWidgetHook: UseDynamicWidgetHook;
};

const getActivityChartPostCount = (
  dataSeries: ActivityDataSeries,
  groupId: string,
  singleDay: string | false
) => {
  if (!dataSeries[groupId]) return undefined;
  const groupDataSeries = dataSeries[groupId];
  const timeRangeSeries = singleDay
    ? groupDataSeries.filter((day) => day.date.slice(0, 10) === singleDay)
    : groupDataSeries;
  const adjustedCount = timeRangeSeries.reduce(
    (prev, curr) => prev + curr.count,
    0
  );
  return adjustedCount ?? 0;
};

export function PostsSidebarWrapper({ useWidgetHook }: Props) {
  const white = useColorModeValue(colors.white, colors.coolGray[1]);
  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [urlQueryParams, _, removeUrlQuery] = usePostUrlQueryParams();
  const { getSnapshotId, data: widgetData, widgetType, mode } = useWidgetHook();
  const postSnapshotId = getSnapshotId()?.split('_')[1];
  const { start_date, end_date } = urlQueryParams;

  const dataSeries = useMemo(
    () => (widgetData ? getActivityChartSeries(widgetData) : {}),
    [widgetData]
  );
  const { data, isLoading, error } = useWidgetPosts(postSnapshotId);
  const postCount =
    widgetType === 'Activity Chart'
      ? getActivityChartPostCount(
          dataSeries,
          urlQueryParams.segment_id,
          start_date === end_date ? start_date : false
        )
      : data?.posts?.length ?? 0;

  const onOutsideClick = (e: Event) => {
    const target = e.target as HTMLElement;
    if ((target as HTMLElement).tagName === 'path') return;
    // @ts-ignore
    if (target?.id?.includes('react-select')) return;
    let currentElement: HTMLElement | null = target;
    while (currentElement && currentElement !== document.documentElement) {
      if (currentElement.id === 'posts-sidebar-wrapper') {
        return; // abort if the outside click is on another posts sidebar
      }
      currentElement = currentElement.parentElement;
    }
    removeUrlQuery();
  };
  useOutsideClick({
    ref,
    handler: onOutsideClick,
  });
  const offsetTop = router.pathname === '/authoring' ? '62px' : '98px';

  if (!postSnapshotId && mode === 'reader') return null; // handle legacy insights by disabling post sidebar

  return (
    <Flex
      position="fixed"
      top={offsetTop}
      bottom={4}
      right={!!router.query.widgetId ? 6 : '-400px'}
      w="400px"
      boxShadow={boxShadows.cardPopup}
      bg={white}
      borderRadius={16}
      direction="column"
      ref={ref}
      transitionDuration="0.5s"
      transitionTimingFunction="ease-in-out"
      zIndex={4}
      id="posts-sidebar-wrapper"
    >
      {isLoading && <PostsSkeleton />}
      {data && (
        <>
          <PostsSidebarTop postsData={{ ...data }} postCount={postCount} />
          <PostsSidebarSorting useWidgetHook={useWidgetHook} />
          <PostList posts={data.posts} />
        </>
      )}
    </Flex>
  );
}
