import { Box } from '~/components';

export const ScreenshotOverlay = () => {
  return (
    <Box
      background="#1A202C"
      zIndex={10}
      position="absolute"
      top={0}
      left={398}
      right={0}
      bottom={0}
    />
  );
};
