import type {
  Group,
  Narrative,
  SignalWithCommunities,
} from '~/types/graphika-types';
import type { Communities } from '~/lib/hooks/useMapsWithGroups';
import { ReactElement, memo } from 'react';
import { useColorModeValue, VStack } from '@chakra-ui/react';
import { NumberParam, useQueryParams } from 'use-query-params';
import { Box, Divider, Flex, BodyText, Icon, StatsText } from '~/components';
import { formatNumber } from '~/lib/numbers';
import { Analytics } from '~/lib/analytics';
import { colors } from '~/styles';
import TotalHitsIcon from '~/public/icons/TotalHits.svg';

type Props = {
  narrative: Narrative;
  signal: SignalWithCommunities;
  feedsCommunities?: Communities[];
};

function SignalCommunitiesNotMemoized({
  narrative,
  signal,
  feedsCommunities = [],
}: Props): ReactElement {
  const { id } = signal;
  const [{ communityId, signalId }, setQuery] = useQueryParams({
    communityId: NumberParam,
    signalId: NumberParam,
  });
  const { communities = [] } = signal;
  const twitterGroups = communities.filter(
    (community) => community.community_type === 'group'
  );
  const subreddits = communities
    .filter((community) => community.community_type === 'subreddit')
    .sort((a, b) => (a.hits > b.hits ? -1 : 1));
  const groupIds = twitterGroups.map((group) => group.community_id);
  const allMaps = feedsCommunities.map((feed) => feed.maps).flat(2);
  const allMapsWithSignalGroups = allMaps
    .map((map) => ({
      ...map,
      groups: map.groups.filter((group) => groupIds.includes(group.id)),
    }))
    .filter((map) => map.groups.length > 0);
  const mapsIncludingGroupHits = allMapsWithSignalGroups.map((map) => ({
    ...map,
    groups: map.groups
      .map((group) => ({
        ...group,
        hits: twitterGroups.find((g) => g.community_id === group.id)?.hits ?? 0,
      }))
      .sort((a, b) => (a.hits > b.hits ? -1 : 1)),
  }));
  const sortedMapsByTotalHits = mapsIncludingGroupHits.sort((a, b) => {
    const aTotalHits = a.groups.reduce((prev, curr) => prev + curr.hits, 0);
    const bTotalHits = b.groups.reduce((prev, curr) => prev + curr.hits, 0);
    return aTotalHits > bTotalHits ? -1 : 1;
  });
  const allCommunityHits =
    communities?.reduce((prev, curr) => prev + curr.hits, 0) ?? 0;
  const isSelectedSignal = signalId === id;

  return (
    <Box mt={-2}>
      <Divider mb={2.5} />
      <Box mb={3}>
        <CommunityRow
          name="All Communities"
          hits={allCommunityHits}
          onClick={() => {
            setQuery({
              communityId: undefined,
              signalId: id,
            });
          }}
          isSelected={!communityId && isSelectedSignal}
        />
      </Box>
      {sortedMapsByTotalHits.map((map) => {
        return (
          <Box key={map.id} mb={5}>
            <Flex gap={2}>
              <BodyText
                noOfLines={1}
                sx={{ fontVariant: 'all-small-caps' }}
                mb={1}
              >
                {map.name}
              </BodyText>
            </Flex>
            <VStack align="left">
              {map.groups.map((group) => (
                <CommunityRow
                  key={group.name}
                  name={group.name}
                  hits={group.hits}
                  onClick={() => {
                    Analytics.event('signal', {
                      action: 'community_selection',
                      insight: narrative.title,
                      insight_id: narrative.id.toString(),
                      signal: signal.signal_value,
                      signal_id: signal.id?.toString(),
                      community: group.name,
                      community_id: communityId?.toString() ?? '',
                    });
                    setQuery({
                      communityId:
                        communityId === group.id && isSelectedSignal
                          ? undefined
                          : group.id,
                      signalId: id,
                    });
                  }}
                  isSelected={group.id === communityId && isSelectedSignal}
                />
              ))}
            </VStack>
          </Box>
        );
      })}
      <Box mt={-1} mb={2}>
        {subreddits.map((subreddit) => (
          <CommunityRow
            key={subreddit.community_id}
            name={`r/${subreddit.community_name}`}
            hits={subreddit.hits}
            onClick={() => {
              setQuery({
                communityId:
                  communityId === subreddit.community_id && isSelectedSignal
                    ? undefined
                    : subreddit.community_id,
                signalId: id,
              });
            }}
            isSelected={
              subreddit.community_id === communityId && isSelectedSignal
            }
          />
        ))}
      </Box>
    </Box>
  );
}

export const SignalCommunities = memo(SignalCommunitiesNotMemoized);

const CommunityRow = ({
  name,
  hits,
  onClick,
  isSelected,
}: {
  hits: number;
  name: string;
  isSelected: boolean;
  onClick: () => void;
}) => {
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  return (
    <Flex
      align="center"
      justify="space-between"
      h="28px"
      onClick={onClick}
      cursor="pointer"
      color={isSelected ? primary : 'inherit'}
      _hover={{
        color: primary,
        fill: primary,
      }}
    >
      <BodyText color="inherit">{name}</BodyText>
      <Flex align="center">
        <Icon icon={TotalHitsIcon} viewBox="-8 -8 32 32" />
        <StatsText color="inherit">{formatNumber(hits)}</StatsText>
      </Flex>
    </Flex>
  );
};
