import { clear as clearIndexDB } from 'idb-keyval';
import mixpanel from 'mixpanel-browser';
import { GraphikaUser } from '~/types/graphika-types';
import { fetchFromApi } from './api';
import { queryClient } from './queryClient';

export type UserInfo = {
  deactivated_after: string | null;
  email: string;
  id: number;
  is_trialing: boolean;
  name: string;
  org: {
    id: number;
    name: string;
  };
  password_reset_required: boolean;
  role: UserRole;
  username: string;
};

type EmailLogin = {
  email: string;
  password: string;
  jwt?: never;
};

type SSOLogin = {
  email?: never;
  password?: never;
  jwt: string;
};

export type LoginPayload = EmailLogin | SSOLogin;

export const userRoles = [
  'intelligence reader',
  'discovery viewer',
  'analyst',
  'admin',
] as const;

export type UserRole = (typeof userRoles)[number];

export const roleName: Record<UserRole, string> = {
  admin: 'Admin',
  'discovery viewer': 'Client',
  'intelligence reader': 'Client',
  analyst: 'Graphika Analyst',
};

export const getRoleName = (user: UserInfo | GraphikaUser | undefined) => {
  if (!user) return '';
  let role = roleName[user.role];
  if ('org_id' in user && user.org_id !== 1 && user.role === 'analyst') {
    role = 'Navigator';
  } else if ('org' in user && user.org.id !== 1 && user.role === 'analyst') {
    role = 'Navigator';
  }
  return role;
};

/**
 * authenticates the user with username and pw OR with a JWT
 *
 * @return {Response} user info with id, name, username, email, role, org info
 */
export function authenticate({
  email,
  password,
  jwt,
  url,
}: LoginPayload & { url?: string }): Promise<Response> {
  const authVal =
    email && password
      ? `Basic ${Buffer.from(`${email}:${password}`).toString('base64')}`
      : `Bearer ${jwt}`;
  return fetch(`${url || process.env.NEXT_PUBLIC_API_URL}/current_user`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: authVal,
      'X-WWW-Auth-Opt-Out': 'True',
    },
    mode: 'cors',
  });
}

/**
 * logs the user out
 *
 * @return {Response}
 */
export function logout() {
  clearLocalUserState();
  return fetchFromApi<null>('/current_user/session', 'DELETE');
}

export async function getUserInfo(): Promise<UserInfo | undefined> {
  try {
    const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/current_user`, {
      method: 'GET',
      credentials: 'include',
      mode: 'cors',
    });
    const data = await res.json();
    return data;
  } catch (e) {
    return undefined;
  }
}

/**
 * tests whether a current session exists and returns boolean
 *
 * @return {Promise<boolean>} authentication status
 */
export async function tickleSession(): Promise<boolean> {
  try {
    await fetchFromApi<null>('', 'GET');
    return true;
  } catch (e) {
    return false;
  }
}

export const clearLocalUserState = () => {
  localStorage?.removeItem('signalWarning');
  localStorage?.removeItem('contentWarning');
  sessionStorage?.removeItem('telescope-feed-change');
  Object.keys(sessionStorage)
    .filter((key) => key.startsWith('utm_medium_'))
    .forEach((key) => sessionStorage.removeItem(key));
  sessionStorage.removeItem('trackedUtm');
  sessionStorage?.removeItem('session');
  clearIndexDB();
  queryClient.clear();
};
