import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const baseStyle = defineStyle({
  bg: colors.g.primary,
  borderRadius: 8,
  color: colors.white,
  fontSize: '12px',
  px: 3,
  py: 2,
});

const monochrome = defineStyle((props) => ({
  color: mode(colors.black, colors.white),
  backgroundColor: mode(colors.warmGray[5], colors.coolGray[2]),
}));

export const Tooltip = defineStyleConfig({
  baseStyle,
  variants: {
    monochrome,
  },
});
