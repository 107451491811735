import { UseQueryResult } from '@tanstack/react-query';
import { SearchQuery } from '~/types/graphika-types';
import { getDateSpan } from '~/lib/date';
import {
  Community,
  Narrative,
  RedditData,
  SignalWithCommunities,
  TweetData,
} from '~/types/graphika-types';
import { hookify } from './utils';

type PostTypes = {
  reddit: RedditData;
  twitter: TweetData;
};

export type SearchQueryPageResults<T = Record<string, any>> = {
  cursor?: string;
  next?: string;
  result_items: T[];
  total_result_count: number;
};

const keys = {
  query: (query: SearchQuery) => ['targets', 'posts', query] as const,
};
export const searchKeys = keys;

const { fetch: _getSearchQuery, hook: _useSearchQuery } = hookify<
  SearchQueryPageResults<any>
>()
  .path('/targets/posts')
  .method('get')
  .key(({ query }) => keys.query(query));

export const useSearchQuery = <T>(
  ...args: Parameters<typeof _useSearchQuery>
) => _useSearchQuery(...args) as UseQueryResult<SearchQueryPageResults<T>>;

export function usePostsSearch<T extends keyof PostTypes>(query: {
  narrative?: Narrative;
  community?: Community;
  signal?: SignalWithCommunities;
  feedId?: number;
}) {
  const { narrative, community, signal, feedId } = query;
  const { target_type, signal_value = '', target_value } = signal ?? {};
  const narrativeHasPosts = signal?.communities?.some(
    (c) => c.community_type === 'group'
  );
  const isTwitter = community?.community_type === 'group' || narrativeHasPosts;
  const isHashtag = target_type === 'hashtag';

  const communityIds = community
    ? [community.community_id]
    : signal
    ? signal.communities?.map((c) => c.community_id)
    : [];

  return useSearchQuery<PostTypes[T]>(
    undefined,
    {
      query: {
        group_ids: communityIds,
        narrative_id: narrative?.id!,
        ...getDateSpan(narrative?.published_at ?? new Date(), 30),
        platform: isTwitter ? 'twitter' : isHashtag ? undefined : 'reddit',
        posts: target_type === 'post' ? [target_value!] : undefined,
        subreddits:
          isTwitter || !community ? undefined : [community.community_name],
        urls: target_type === 'url' ? [signal_value] : undefined,
        domains: target_type === 'domain' ? [signal_value] : undefined,
        hashtags: isHashtag ? [signal_value] : undefined,
      },
    },
    {
      enabled: !!narrative,
    }
  );
}
