/**
 * INFO: Everything here is copied from `js-toolkit`
 * INFO: The reason that jest/StoryBook fail because of the import of `isTruthy` and `ttEnsureAll` from `js-toolkit`
 * INFO: Once this issue is solved uncomment Line 2 in `fetchUtils.ts`, delete Line 3 and delete this file!
 */

/**
 * Adds any falsy values to `T` (anything that will fail an `if` check).
 */
export type Falsy<T = never> = T | undefined | null | '' | 0 | false;

/**
 * Excludes any falsy types from `T`.
 */
export type Truthy<T> = Exclude<T, Falsy>;

/**
 * Type guard to check if `x` is truthy (will pass an `if` check).
 */
export function isTruthy<T>(x: T): x is Truthy<T> {
  return Boolean(x);
}

/** Get the last element of an iterable */
export function last<T>(xs: Iterable<T>): T {
  if (Array.isArray(xs)) return xs[xs.length - 1];
  // @ts-ignore
  for (var x of xs) {
    // eslint-disable-line
  }
  return x!;
}

/**
 * A tagged template function that ensures all expressions are non-null,
 * otherwise it will not interpolate at all and return an empty string
 */
export function ttEnsureAll(
  literalFragments: TemplateStringsArray,
  ...expressions: readonly any[]
) {
  if (expressions.some((exp) => exp == null)) return '';
  const compose: string[] = [];
  for (let i = 0; i < literalFragments.length - 1; i++) {
    compose.push(literalFragments[i], String(expressions[i]));
  }
  compose.push(last(literalFragments));
  return compose.join('');
}
