import type { MapInfo, MapNode } from '~/types/graphika-types';
import {
  NodeOrder,
  defineNodeStyle,
  useEventListeners,
  useMvConfig,
  useNodeDecorator,
} from '@graphika/map-viewer';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Analytics } from '~/lib/analytics';
import { useMvQueryParams } from '~/lib/hooks';
import { useSegmentTree } from '~/lib/stores/segment-tree';
import { mapSegmentTreeDefaults } from './SegmentTree';

const MIN_HIGHTLIGHTED_NODE_AURA = 5;

export const highlightedNodeStyle = defineNodeStyle({
  color: 'rgb(244, 249, 191)',
  order: NodeOrder.Behind,
  alpha: 0.8,
  size: (sizes, index) =>
    (sizes[index] = Math.max(sizes[index] * 1.5, MIN_HIGHTLIGHTED_NODE_AURA)),
});

type Props = {
  highlightedNode?: MapNode;
  setHighlightedNode: (node: MapNode | undefined) => void;
  nodes: MapNode[];
  map: MapInfo;
};

export function HighlightNodePlugin({
  highlightedNode,
  setHighlightedNode,
  nodes,
  map,
}: Props) {
  const router = useRouter();
  const events = useMvConfig((state) => state.events);
  const { reset, toggleRoot } = useSegmentTree();
  const [query, setQuery] = useMvQueryParams();

  useEventListeners(
    events,
    {
      onContextMenu: ({ node, event }) => {
        event.stopPropagation();
        const mapNode = nodes.find((n) => n.node_source_id === node.id);
        setQuery({
          selected:
            mapNode?.node_source_id === highlightedNode?.node_source_id
              ? undefined
              : mapNode?.node_source_id,
        });
      },
    },
    [setHighlightedNode, highlightedNode]
  );
  useNodeDecorator(highlightedNode?.node_source_id ?? [], highlightedNodeStyle);

  useEffect(() => {
    if (!highlightedNode || !map) {
      reset(mapSegmentTreeDefaults);
      return;
    }
    toggleRoot({ param: 'active', value: false });
    Analytics.event('mapSelection', {
      action: 'node_selection',
      map: map.name ?? '',
      map_id: (map.id ?? '').toString(),
      label: highlightedNode.name ?? '',
    });
  }, [toggleRoot, highlightedNode, map, reset]);

  useEffect(() => {
    const findNodeById = () =>
      nodes.find(
        ({ node_source_id }) => node_source_id === router.query.selected
      );
    if (!highlightedNode && router.query.selected) {
      setHighlightedNode(findNodeById());
      return;
    }
    if (highlightedNode && !router.query.selected) {
      setHighlightedNode(undefined);
      return;
    }
    if (highlightedNode?.node_source_id !== router.query.selected) {
      setHighlightedNode(findNodeById());
      return;
    }
  }, [
    router.query,
    query,
    highlightedNode,
    setQuery,
    nodes,
    setHighlightedNode,
  ]);

  return null;
}
