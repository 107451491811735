import type { MapCluster, MapGroup } from '~/types/graphika-types';
import { useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Icon,
  SubtitleHeader,
  Tooltip,
} from '~/components';
import { groupBy } from '~/lib/utils';
import DropdownClosed from '~/public/icons/Dropdown-Closed.svg';
import TagIcon from '~/public/icons/Tag.svg';
import { colors } from '~/styles';
import { scrollbarStyleDark } from '~/styles/components';
import {
  type ClusterState,
  type GroupState,
  type SegmentTreeDefaults,
  useSegmentTree,
  useSubscribeSegmentTree,
} from '~/lib/stores/segment-tree';
import { GroupTreeItem } from './GroupTreeItem';
import { ClusterTreeItem } from './ClusterTreeItem';

export const mapSegmentTreeDefaults: SegmentTreeDefaults = {
  cluster: { active: true, showLabel: false },
  group: { active: true, showLabel: false, isOpen: false },
};

export type SegmentTreeListener = (
  groups: Record<string, GroupState>,
  clusters: Record<string, ClusterState>
) => void;

type Props = {
  clusters: MapCluster[];
  groups: MapGroup[];
  onChange?: SegmentTreeListener;
};
export function SegmentSelection({ clusters, groups, onChange }: Props) {
  const clustersByGroup = useMemo(
    () =>
      groupBy(
        clusters.sort((a, b) => (a.position > b.position ? 1 : -1)),
        (c) => c.group_id
      ),
    [clusters]
  );

  const treeState = useSegmentTree();
  useEffect(() => {
    treeState.load({ groups, clusters, defaults: mapSegmentTreeDefaults });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useSubscribeSegmentTree(
    (state) => onChange?.(state.groups, state.clusters),
    [onChange]
  );

  return (
    <Flex h="100%" flexDirection="column" mt={-6}>
      <Box bg={colors.mapViewer.bgGray} w="100%" pl={5} py={2} mb={2} pr={2}>
        <Flex>
          <Flex align="center" justify="space-between" w="100%">
            <Flex align="center" gap={1}>
              <Tooltip
                label={
                  treeState.active || treeState.active === null
                    ? 'Deselect all'
                    : 'Select all'
                }
                openDelay={300}
              >
                <Box pt={2}>
                  <Checkbox
                    isChecked={!!treeState.active}
                    isIndeterminate={treeState.active === null}
                    onChange={() =>
                      treeState.toggleRoot({
                        param: 'active',
                        value:
                          treeState.active || treeState.active === null
                            ? false
                            : true,
                      })
                    }
                    variant="mapviewer"
                  />
                </Box>
              </Tooltip>
              <Tooltip
                label={
                  treeState.showLabel
                    ? 'Hide all labels'
                    : 'Show all group labels'
                }
                openDelay={300}
              >
                <Icon
                  icon={TagIcon}
                  onClick={() =>
                    treeState.toggleRoot({
                      param: 'showLabel',
                      value:
                        treeState.showLabel || treeState.showLabel === null
                          ? false
                          : true,
                    })
                  }
                  fill={
                    treeState.showLabel
                      ? colors.white
                      : colors.mapViewer.deselected
                  }
                  cursor="pointer"
                  boxSize={6}
                />
              </Tooltip>
              <SubtitleHeader color={colors.white} fontWeight={700} ml={2}>
                Map Groups & Clusters
              </SubtitleHeader>
            </Flex>
            <Tooltip
              label={treeState.isOpen ? 'Collapse all' : 'Expand all'}
              openDelay={300}
            >
              <Box>
                <Icon
                  icon={DropdownClosed}
                  color={colors.white}
                  transform={treeState.isOpen ? '' : `rotate(-90deg)`}
                  transitionDuration="0.2s"
                  onClick={() =>
                    treeState.toggleRoot({
                      param: 'isOpen',
                      value: !treeState.isOpen,
                    })
                  }
                  mr={-1}
                  cursor="pointer"
                />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
      </Box>
      <Box overflowY="auto" sx={scrollbarStyleDark}>
        <Box>
          {groups.map((group) => (
            <GroupTreeItem key={group.id} group={group}>
              {clustersByGroup[group.id]?.map((cluster, index) => (
                <ClusterTreeItem
                  key={cluster.id}
                  cluster={cluster}
                  isLastChild={index === clustersByGroup[group.id].length - 1}
                />
              ))}
            </GroupTreeItem>
          ))}
        </Box>
      </Box>
    </Flex>
  );
}
