import type { ReactNode } from 'react';
import type { MapInfo, Platform } from '~/types/graphika-types';
import type { HoveredMap } from '~/pages/maps';
import { useRef, useState } from 'react';
import { DarkMode, Flex } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';
import {
  BodyText,
  Box,
  Icon,
  SubtitleHeader,
  Link,
  Checkbox,
  AnalyticsScrollObserver,
} from '~/components';
import { colors } from '~/styles';
import { scrollbarStyleDark } from '~/styles/components';
import FacebookIcon from '~/public/mv-icons/mv-facebook.svg';
import YoutubeIcon from '~/public/mv-icons/mv-youtube.svg';
import VkIcon from '~/public/mv-icons/mv-vk.svg';
import TelegramIcon from '~/public/mv-icons/mv-telegram.svg';
import TwitterIcon from '~/public/platform-icons/Twitter.svg';

export const platformIcon: Record<Platform, () => ReactNode> = {
  twitter: TwitterIcon,
  facebook: FacebookIcon,
  youtube: YoutubeIcon,
  vkontakte: VkIcon,
  telegram: TelegramIcon,
};

type Props = {
  maps: MapInfo[];
  setHoveredMap: (map?: MapInfo & { yPos: number }) => void;
};

export function MapList({ maps, setHoveredMap }: Props) {
  const [onlyShowLiveMaps, setOnlyShowLiveMaps] = useState(false);
  const sortedFilteredMaps = maps
    .filter((m) => (onlyShowLiveMaps ? m.is_live : true))
    .sort((a, b) => {
      const timeA = a.created_at;
      const timeB = b.created_at;
      if (!(timeA && timeB)) return timeA ? 1 : -1;
      return new Date(timeB).getTime() - new Date(timeA).getTime();
    });
  return (
    <Box
      bg="rgba(57, 67, 86, 0.6)"
      w="366px"
      mx={4}
      borderRadius={8}
      mt={2}
      mb={1}
      overflowY="auto"
      h="100%"
      sx={scrollbarStyleDark}
    >
      <Flex
        justify="space-between"
        align="center"
        pr={2}
        borderBottom={`1px solid ${colors.mapViewer.gray}`}
      >
        <BodyText color={colors.white} fontWeight={600} py={2} px={3}>
          {sortedFilteredMaps.length}{' '}
          {sortedFilteredMaps.length !== 1 ? 'Maps' : 'Map'}
        </BodyText>
        <DarkMode>
          <Checkbox
            checked={onlyShowLiveMaps}
            onChange={() => setOnlyShowLiveMaps(!onlyShowLiveMaps)}
          >
            <BodyText>Live Maps Only</BodyText>
          </Checkbox>
        </DarkMode>
      </Flex>
      <Box data-cy="map-list">
        {sortedFilteredMaps.map((map, index) => (
          <MapListItem key={index} map={map} setHoveredMap={setHoveredMap} />
        ))}
        <AnalyticsScrollObserver
          event={{
            action: 'map_list_end',
          }}
        />
      </Box>
    </Box>
  );
}

const MapListItem = ({
  map,
  setHoveredMap,
}: {
  map: MapInfo;
  setHoveredMap: (map?: HoveredMap) => void;
}) => {
  const { ref, inView } = useInView();
  const boxRef = useRef<HTMLDivElement>(null);
  const hovered = useRef(false);
  return (
    <Box ref={ref} minH="44px" cursor="pointer" data-cy="map-list-item">
      {inView && (
        <Link href={`/maps/${map.id}`} noHoverStyle>
          <Flex
            px={2}
            py={2}
            _hover={{ bg: colors.mapViewer.gray }}
            ref={boxRef}
            onMouseEnter={(e) => {
              hovered.current = true;
              setTimeout(() => {
                if (hovered.current)
                  setHoveredMap({
                    ...map,
                    yPos: boxRef.current?.getBoundingClientRect().y ?? 0,
                  });
              }, 80);
            }}
            onMouseLeave={(e) => {
              hovered.current = false;
              setHoveredMap();
            }}
          >
            <Box p={map.type === 'twitter' ? 1.5 : 0} pr={2}>
              <Icon
                icon={platformIcon[map.type as Platform]}
                boxSize={map.type === 'twitter' ? 5 : 7}
                fill={colors.white}
              />
            </Box>
            <SubtitleHeader color={colors.coolGray[5]} mt={1.5}>
              {map.name}
            </SubtitleHeader>
          </Flex>
        </Link>
      )}
    </Box>
  );
};
