import type { GraphikaUser, GraphikaOrg } from '~/types/graphika-types';
import type { UserInfo } from '~/lib/auth';
import { differenceInDays } from 'date-fns';

export const getTrialExpiresText = (
  userOrOrg: GraphikaUser | GraphikaOrg | UserInfo,
  withTrialText?: boolean
) => {
  if (!userOrOrg.deactivated_after || !userOrOrg.is_trialing) return '';
  if (
    userOrOrg.is_trialing &&
    'state' in userOrOrg &&
    userOrOrg.state === 'deactivated'
  ) {
    return withTrialText ? 'Trial expired' : 'Expired';
  }
  const distanceDays = differenceInDays(
    new Date(userOrOrg.deactivated_after),
    new Date()
  );
  return distanceDays === 0
    ? `${withTrialText ? 'Trial ' : ''} expires today`
    : distanceDays === 1
    ? `1 ${withTrialText ? 'trial ' : ''} day left`
    : distanceDays > 1
    ? `${distanceDays} ${withTrialText ? 'trial' : ''} days left`
    : ``;
};
