import { useColorModeValue, useMergeRefs } from '@chakra-ui/react';
import { PropsWithChildren, forwardRef, useRef } from 'react';
import { Box } from '~/components';
import { colors } from '~/styles';
import { DownloadWidgetDataButton } from './DownloadWidgetDataButton';

type Props = PropsWithChildren<{
  mode: 'reader' | 'author';
  handleCsvDownload: () => void;
  isOriginalParams: boolean;
}>;

export const WidgetUiWrapper = forwardRef<HTMLDivElement, Props>(
  function WidgetUiWrapper(
    { children, mode, handleCsvDownload, isOriginalParams },
    ref
  ) {
    const internalRef = useRef<HTMLDivElement>(null);
    const white = useColorModeValue(colors.white, colors.coolGray[2]);
    const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);

    return (
      <Box
        className="widget-ui-wrapper"
        ref={useMergeRefs(internalRef, ref)}
        px={4}
        borderRadius={8}
        border={`1px solid ${gray4}`}
        maxW="80%"
        margin="30px auto 16px"
        bg={white}
      >
        {mode === 'reader' && isOriginalParams && (
          <Box position="relative">
            <DownloadWidgetDataButton
              handleCvsDownload={handleCsvDownload}
              widgetRef={internalRef}
            />
          </Box>
        )}
        {children}
      </Box>
    );
  }
);
