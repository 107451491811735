import { paths } from '~/types/generated';
import { buildQueryHook } from './buildQueryHook';
import { buildTypedFetch } from './buildTypedFetch';
import { TypedKeyGenerator } from './shared';

export * from './buildTypedFetch';
export * from './buildQueryHook';
export * from './buildInfiniteQueryHook';
export * from './buildMutation';
export * from './shared';

export function hookify<T = void>() {
  function build<P extends keyof paths, M extends keyof paths[P]>(
    path: P,
    method: M,
    getKey: TypedKeyGenerator<P, M>
  ) {
    return {
      hook: buildQueryHook<T>().path(path).method(method).key(getKey),
      fetch: buildTypedFetch<P, M, T>(path, method),
    };
  }

  return {
    path<P extends keyof paths>(path: P) {
      return {
        method<M extends keyof paths[P]>(method: M) {
          return {
            key(getKey: TypedKeyGenerator<P, M>) {
              return build(path, method, getKey);
            },
          };
        },
      };
    },
  };
}
