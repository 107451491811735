import type { MapCluster, MapGroup, MapNode } from '~/types/graphika-types';
import { Text, TextProps, VStack } from '@chakra-ui/react';
import {
  NodeOrder,
  NodePopoverPlugin,
  type MVNode,
  type PopoverEventHandlers,
} from '@graphika/map-viewer';
import { useMemo, useRef, useState, type ReactElement } from 'react';
import { BodyText, Box, Flex, Icon, Image, SubtitleHeader } from '~/components';
import AvatarIcon from '~/public/icons/MapViewerAvatar.svg';
import { colors } from '~/styles';

const { white, warmGray, mapViewer } = colors;

type Props = { groups: MapGroup[]; clusters: MapCluster[]; nodes: MapNode[] };
export function MapNodePopoverPlugin(data: Props) {
  const isMoving = useRef(false);
  const [node, setNode] = useState<MVNode | undefined>();

  const nodeInfo = useMemo(() => {
    if (!data || !node) return;
    const dNode = data.nodes.find((n) => n.node_source_id === node.id);
    if (!dNode) return;
    return {
      name: dNode.name,
      username: dNode.username,
      profile_image_url: dNode.profile_image_url,
      cluster: data.clusters.find(
        (cluster) => cluster.id === node.tags.cluster
      )!,
      group: data.groups.find((group) => group.id === node.tags.group)!,
    };
  }, [node, data]);

  const handlers = useMemo<Partial<PopoverEventHandlers>>(
    () => ({
      onControlsStart: () => (isMoving.current = true),
      onControlsEnd: () => (isMoving.current = false),
      //@ts-ignore
      onNodeEnter(popover, { node, variables }) {
        if (variables.orders[0] === NodeOrder.Behind) return;
        popover();
        setNode(node);
      },
      //@ts-ignore
      onNodeLeave(_, { variables }) {
        if (variables.orders[0] === NodeOrder.Behind) return;
        setNode(undefined);
      },
    }),
    []
  );

  return (
    // @ts-ignore
    <NodePopoverPlugin
      style={{ width: '250px', minWidth: '750px' }}
      node={node}
      {...handlers}
    >
      {() => {
        if (!nodeInfo) return null;
        const { name, username, profile_image_url, cluster, group } = nodeInfo;
        return (
          <Box
            bg="rgba(30,37,51,0.95)"
            borderRadius={16}
            p={'16px'}
            display="flex"
            flexFlow="row nowrap"
            width="fit-content"
            flexDir="column"
            userSelect="none"
          >
            <Flex gap={'8px'}>
              <Image
                h="48px"
                w="48px"
                src={profile_image_url}
                borderRadius="100%"
                alt={name}
                fallback={<FallbackAvatarImg />}
              />
              <Box>
                <LargeText>{name}</LargeText>
                <MediumText>{username}</MediumText>
              </Box>
            </Flex>
            <VStack
              borderTop={`1px solid ${mapViewer.gray}`}
              align="left"
              pt="4px"
              mt="8px"
            >
              {group && (
                <Box mb="8px">
                  <SubtitleHeader color={white} mb="8px">
                    Map Group
                  </SubtitleHeader>
                  <Flex align="center" gap="12px">
                    <Box
                      h="32px"
                      w="8px"
                      minW="8px"
                      borderRadius="2px"
                      bg={`#${group.hex_color}`}
                    />

                    <SmallText>{group.name ?? ''}</SmallText>
                  </Flex>
                </Box>
              )}
              {cluster && (
                <>
                  <SubtitleHeader color={white} mb="8px">
                    Map Cluster
                  </SubtitleHeader>
                  <Flex align="center" gap="12px">
                    <Box
                      h="32px"
                      w="8px"
                      minW="8px"
                      borderRadius="2px"
                      bg={`#${cluster.hex_color}`}
                    />
                    <SmallText>{cluster.name ?? ''}</SmallText>
                  </Flex>
                </>
              )}
              <Box borderRadius={4} bg="rgba(56,66,85,0.95)" p={2} w="200px">
                <BodyText color={colors.white} p={2} textAlign="center">
                  Ctrl + click to select node
                </BodyText>
              </Box>
            </VStack>
          </Box>
        );
      }}
    </NodePopoverPlugin>
  );
}

const LargeText = ({
  children = '',
  ...props
}: {
  children: string | ReactElement;
}) => (
  <Text
    color={white}
    fontSize="18px"
    fontWeight="700"
    lineHeight="21px"
    mb="4px"
    {...(props as any)}
  >
    {children}
  </Text>
);

const MediumText = ({
  children = '',
  ...props
}: {
  children?: string | ReactElement;
}) => (
  <Text
    color={warmGray[4]}
    fontSize="16px"
    fontWeight="400"
    lineHeight="19px"
    wordBreak="break-all"
    {...(props as any)}
  >
    {children}
  </Text>
);

const SmallText = ({
  children = '',
  ...props
}: TextProps & { children: string | ReactElement }) => (
  <Text
    fontSize="14px"
    color="#D2D8E5"
    lineHeight="16px"
    wordBreak="break-all"
    {...(props as any)}
  >
    {children}
  </Text>
);

const FallbackAvatarImg = () => (
  <Box w="48px" h="48px">
    <Icon icon={AvatarIcon} boxSize={'48px'} />;
  </Box>
);
