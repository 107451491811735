import type { MapCluster, MapGroup } from '~/types/graphika-types';
import { Center } from '@chakra-ui/react';
import {
  MvConfigProvider,
  PointCloudProvider,
  useCreateMvConfig,
} from '@graphika/map-viewer';
import Image from 'next/image';
import {
  MutableRefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Box, createVoyagerToast } from '~/components';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins/dynamic-insights';
import {
  Analytics,
  useWidgetAnalyticsEvent,
  useWidgetAnalyticsParamsChangeEvent,
} from '~/lib/analytics';
import { useInsightContext } from '~/lib/contexts';
import { fetchWidgetExport } from '~/lib/dynamic-widget-exports/client';
import { usePersistedValue } from '~/lib/hooks';
import { downloadRelevantMapNodesCsv } from '~/lib/hypergraph';
import {
  SegmentTreeDefaults,
  SegmentTreeProvider,
  compressTreeState,
  useCreateSegmentTree,
  useSubscribeSegmentTree,
  type SegmentSelection,
} from '~/lib/stores/segment-tree';
import { shallowEquals } from '~/lib/utils';
import { useMaps } from '~/queries';
import { DownloadWidgetDataButton } from '../DownloadWidgetDataButton';
import { DynamicInsightCaption } from '../DynamicInsightCaption';
import { ModifiedParamsWarning } from '../ModifiedParamsWarning';
import { PostsSidebarWrapper } from '../posts';
import { QueryBuilder } from '../QueryBuilder';
import { WidgetUiWrapper } from '../WidgetUiWrapper';
import { MvWidgetContainer } from './MvWidgetContainer';

export const segmentTreeDefaults: SegmentTreeDefaults = {
  root: { active: true, isOpen: false, showLabel: false },
  group: { active: true, isOpen: false, showLabel: false },
  cluster: { active: true, showLabel: false },
};

export const RelevantMapNodesWrapper = forwardRef<HTMLDivElement, {}>(
  function RelevantMapNodesWrapper(props, ref) {
    const {
      params,
      setParams,
      isValidParams,
      data,
      refetch,
      presets,
      segments,
      setPresets,
      setSegments,
      mode,
      ...widget
    } = useRelevantMapNodes();
    const insight = useInsightContext();
    const [persistedData] = usePersistedValue(data);
    const useSegmentTree = useCreateSegmentTree();
    const segmentTree = useSegmentTree();

    const { data: maps = [] } = useMaps();

    const originalParams = useRef(params);
    const originalSegments = useRef(segments);
    const previousMapId = useRef(params.mapId);
    const originalPresets = useRef(presets);
    const isOriginalParams = shallowEquals(params, originalParams.current);

    const setOriginalParams = () => {
      Analytics.event('widgetQuery', {
        action: 'reset',
        widgetType: 'map_highlight',
      });
      setParams(originalParams.current);
      segmentTree.load({
        groups: [...groups],
        clusters,
        defaults: segmentTreeDefaults,
      });
      segmentTree.restoreState(originalSegments.current);
      setPresets(originalPresets.current);
    };

    useWidgetAnalyticsEvent({
      insight,
      params,
      widgetType: 'map_highlight',
    });
    useWidgetAnalyticsParamsChangeEvent({
      insight,
      params,
      mode,
      widgetType: 'map_highlight',
    });

    useEffect(() => {
      if (isValidParams) {
        refetch({ insightId: insight.id });
      }
    }, [isValidParams, params, refetch, insight]);

    const hexColors: Record<string, string> = useMemo(() => {
      const temp: Record<string, string> = {};
      persistedData?.groups.forEach(
        (group) => (temp[group.id] = group.hex_color)
      );
      return temp;
    }, [persistedData?.groups]);

    const groups: MapGroup[] = useMemo(
      () =>
        persistedData?.groups.map((group, i) => ({
          ...group,
          group_number: i,
        })) ?? [],
      [persistedData?.groups]
    );
    const clusters: MapCluster[] = useMemo(() => {
      return (
        persistedData?.clusters.map((cluster, i) => ({
          ...cluster,
          cluster_number: i,
          hex_color: hexColors[cluster.group_id],
        })) ?? []
      );
    }, [persistedData?.clusters, hexColors]);

    const initialSegments = useRef<SegmentSelection | undefined>(segments);

    useEffect(() => {
      if (!groups.length || !clusters.length) return;
      // on map change load new map groups with default state
      if (params.mapId !== previousMapId.current) {
        segmentTree.load({
          groups: [...groups],
          clusters,
          defaults: segmentTreeDefaults,
        });
        previousMapId.current = params.mapId;
      }
      if (!initialSegments.current) return;
      segmentTree.load({
        groups: [...groups],
        clusters,
        defaults: segmentTreeDefaults,
      });
      segmentTree.restoreState(initialSegments.current);
      initialSegments.current = undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, clusters, segmentTree.load, segmentTree.reset]);

    useSubscribeSegmentTree(
      (current, prev) => {
        if (initialSegments.current || !groups.length || !clusters.length)
          return;

        const currentCompressed = compressTreeState(
          current,
          segmentTreeDefaults
        );
        const prevCompressed = compressTreeState(prev, segmentTreeDefaults);
        if (!shallowEquals(currentCompressed, prevCompressed))
          setSegments(compressTreeState(current, segmentTreeDefaults));
      },
      [groups, clusters, setSegments],
      useSegmentTree
    );

    const useMvConfig = useCreateMvConfig();

    const handleCsvDownload = async () => {
      const { toast } = createVoyagerToast();
      const toastId = toast({
        title: 'Generating for download... \n Please wait',
        status: 'info',
        isClosable: false,
        duration: null,
      });

      Analytics.event('widget', {
        action: 'csv_download',
        widgetType: 'map_highlight',
        insight: insight.title,
        insight_id: insight.id.toString(),
      });

      if (!isValidParams) return;
      const blob = await fetchWidgetExport('relevantMapNodes', {
        insightId: insight.id,
        params: params as Required<typeof params>,
        snapshotId: widget.getSnapshotId() ?? '',
        caption: widget.caption,
        presets: presets,
        segments: segments,
      });
      const a = document.createElement('a');
      a.setAttribute('href', URL.createObjectURL(blob));
      a.setAttribute('download', `${insight.title}.png`);
      a.click();
      a.remove();

      downloadRelevantMapNodesCsv({ data: persistedData!, insight });

      toast.close(toastId);
    };

    const isExploring = mode === 'reader' && !isOriginalParams;

    return (
      <SegmentTreeProvider value={useSegmentTree}>
        <WidgetUiWrapper
          ref={ref}
          mode={mode}
          handleCsvDownload={handleCsvDownload}
          isOriginalParams={isOriginalParams}
        >
          {mode === 'reader' && isOriginalParams && (
            <Box position="relative">
              <DownloadWidgetDataButton
                handleCvsDownload={handleCsvDownload}
                widgetRef={ref as MutableRefObject<HTMLDivElement>}
              />
            </Box>
          )}
          {isExploring && <ModifiedParamsWarning onReset={setOriginalParams} />}
          {!!maps.length && insight && (
            <QueryBuilder
              widget={useRelevantMapNodes}
              insightId={insight.id}
              groups={groups}
              clusters={[]}
              withLabelOption
              originalTerm={originalParams.current.term}
            />
          )}
          <MvConfigProvider value={useMvConfig}>
            {(!params.mapId || !persistedData) && <MapPlaceholder />}
            <PointCloudProvider>
              {persistedData && (
                <Box w="100%">
                  <MvWidgetContainer
                    key={JSON.stringify(
                      JSON.stringify(
                        persistedData.nodes.map((m) => m.matches_search)
                      )
                    )}
                    clusters={clusters}
                    groups={groups}
                    hgNodes={persistedData.nodes}
                  />
                </Box>
              )}
            </PointCloudProvider>
          </MvConfigProvider>
          <DynamicInsightCaption widget={useRelevantMapNodes} />
          <PostsSidebarWrapper useWidgetHook={useRelevantMapNodes} />
        </WidgetUiWrapper>
      </SegmentTreeProvider>
    );
  }
);

const MapPlaceholder = () => {
  return (
    <Center>
      <Image
        src="/map_placeholder.webp"
        alt="map placeholder"
        width={621}
        height={621}
        priority
      />
    </Center>
  );
};
