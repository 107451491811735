import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useUser } from './useUser';

export function useAccessDenied(items: unknown[], isLoading: boolean) {
  const router = useRouter();
  const { userInfo } = useUser();
  useEffect(() => {
    if (!isLoading && items.length === 0 && userInfo?.id) {
      router.replace({
        pathname: '/access-denied',
        query: { prev: router.pathname },
      });
    }
  }, [items, isLoading, router, userInfo?.id]);
}
