// @ts-ignore
import tz from 'timezone';
const us = tz(require('timezone/America'));
import { toDate, DateLike } from '~/lib/date';

const EST = 'America/New_York';

const dateFormat = {
  mm: '%m-%d', // '10-02'
  mmm: '%b %-d', // 'Oct 2'
  am: '%-I%p', // '5PM'
  mmam: '%m-%d %-I%p', // 10-02 5PM
  mmmam: '%b %-d, %-I%p', // Oct 2, 5PM
  yymmdd: '%Y-%m-%d', //2024-10-02
} as const;

/**
 * formats the date as EST with the required date format
 *
 * @param {DateLike} date
 * @param {keyof typeof dateFormat} format returns '10-02', 'Oct-2', '5 PM' or '10-02 5PM' date string
 */
export const estFormat = (date: DateLike, format: keyof typeof dateFormat) =>
  us(toDate(date), EST, dateFormat[format]);

const millisecondsInHour = 60 * 60 * 1000;
export const millisecondsInDay = millisecondsInHour * 24;

/**
 *  Will get the unix timestamp for EST 11PM of the last complete EST day irrespective of time zone
 *
 * @param {DateLike} date
 * @return {number} unix timestamp of 11PM for the last full EST day
 */
export const getLastCompleteDayEst11pm = (date: DateLike) =>
  us(
    estFormat(toDate(date).getTime() - millisecondsInDay, 'yymmdd') + ' 23:00',
    EST
  );

/**
 *  Will get the unix timestamp for EST 11PM of `date`
 *
 * @param {DateLike} date
 * @return {number} unix timestamp of 11PM for `date`
 */
export const getEst11pm = (date: DateLike) =>
  us(estFormat(toDate(date).getTime(), 'yymmdd') + ' 23:00', EST);

/**
 * for the `ending_on` API parameter, the date is exclusive
 * 2024-10-04 get's the topic(s) up to 2024-10-03 11pm EST
 *
 * @param {DateLike} date '2024-10-04' etc
 */
export const endingOnApiFormat = (date: DateLike) =>
  estFormat(toDate(date).getTime() + millisecondsInDay, 'yymmdd');
