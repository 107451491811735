import { Box, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getNodeByKey } from 'lexical';
import Link from 'next/link';
import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ContentRenderer } from '~/components/elements/content-renderer';
import { Input } from '~/components/elements/Input';
import { onRtfPaste } from '~/lib/markdown';
import { gAsset } from '~/lib/utils';
import {
  useDeleteNarrativeImage,
  useUpdateNarrativeImage,
} from '~/queries/mutations/narrative';
import { colors } from '~/styles';
import { $isImageNode, ImageNode, parseImageUrlIds } from './ImageNode';

type Props = {
  caption: string;
  url: string;
  nodeKey: string;
};
export function ImageComponent(props: Props) {
  const { caption, url, nodeKey } = props;
  const [narrative_id, image_id] = parseImageUrlIds(url);
  const deleteImage = useDeleteNarrativeImage(narrative_id);
  const [editor] = useLexicalComposerContext();

  const { isEditing, inputProps, triggerProps } = useCaptionEditor({
    narrative_id,
    image_id,
    initialValue: caption,
    onSubmit(value) {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (!$isImageNode(node)) return;
        node.set('caption', value);
      });
    },
  });

  useNodeDeletionListener(nodeKey, () => {
    deleteImage.mutate(image_id);
  });

  const captionColor = useColorModeValue(
    colors.warmGray[1],
    colors.coolGray[5]
  );
  const inputBgColor = useColorModeValue(colors.white, colors.black);
  const isEditable = editor.isEditable();

  return (
    <Flex justifyContent={'center'} maxW="80%" h="auto" pb={1} pt={3} mx="auto">
      <Flex alignItems="center" direction="column" maxW={'80%'} gap={1}>
        {isEditable ? (
          <Image src={gAsset(url)} alt={caption} />
        ) : (
          <Link href={gAsset(url)} target="_blank" rel="noopener noreferrer">
            <Image src={gAsset(url)} alt={caption} />
          </Link>
        )}
        {(isEditing || !caption) && isEditable ? (
          <Input
            autoFocus={!!caption}
            _placeholder={{ color: captionColor }}
            bgColor={inputBgColor}
            color={captionColor}
            variant={'unstyled'}
            onPaste={onRtfPaste}
            {...inputProps}
          />
        ) : (
          <Box cursor={isEditable ? 'pointer' : 'auto'} {...triggerProps}>
            <ContentRenderer
              textAlign="center"
              margin="8px auto"
              color={captionColor}
            >
              {caption}
            </ContentRenderer>
          </Box>
        )}
      </Flex>
    </Flex>
  );
}

function useNodeDeletionListener(nodeKey: string, listener: () => void) {
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerMutationListener(ImageNode, (mutations) => {
        for (const [key, mutation] of mutations) {
          if (mutation === 'destroyed' && key === nodeKey) listener();
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editor, nodeKey]
  );
}

type UseCaptionEditorParams = {
  narrative_id: number;
  image_id: number;
  onSubmit: (value: string) => void;
  initialValue?: string;
};
function useCaptionEditor({
  narrative_id,
  image_id,
  onSubmit,
  initialValue,
}: UseCaptionEditorParams) {
  const updateImage = useUpdateNarrativeImage(narrative_id);

  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue ?? '');

  const triggerProps = {
    onClick: useCallback((e: MouseEvent<HTMLInputElement>) => {
      e.stopPropagation();
      setIsEditing(true);
    }, []),
  };

  const inputProps = {
    placeholder: !value ? 'Enter caption' : '',
    value,
    onChange: useCallback((e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    }, []),
    onBlur: useCallback(() => {
      setValue(initialValue ?? '');
      setIsEditing(false);
    }, [initialValue]),
    onKeyDown: useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (['Enter', 'Escape'].includes(e.key)) {
          if (updateImage.isLoading) return;
          if (initialValue === value) return setIsEditing(false);
          updateImage.mutate(
            { image_id, caption: value },
            {
              onSuccess: () => {
                setIsEditing(false);
                onSubmit(value);
              },
            }
          );
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [image_id, initialValue, value, onSubmit, updateImage]
    ),
  };

  return {
    inputProps,
    triggerProps,
    isEditing,
  };
}
