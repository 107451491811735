import { PostType } from '~/components/dynamic-insights/posts';

export function linkify(inputText: string | undefined): string | undefined {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return inputText?.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );
}

export const getPostAccountLink = (post: PostType) => {
  switch (post.platform) {
    case 'twitter':
      return `https://twitter.com/${post.creator.name}`;
    case 'telegram':
      return `https://t.me/${post.creator.name}`;
    default:
      return '';
  }
};
