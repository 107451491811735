import type { ReactElement } from 'react';
import type { TextareaProps } from '@chakra-ui/react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { useField } from 'formik';
import { Textarea } from '~/components';
import { colors } from '~/styles';

type Props = TextareaProps & {
  label?: string;
};

export function FormTextarea(props: Props): ReactElement {
  const [field, meta] = useField(props.name!);
  const { name } = field;
  const { touched, error } = meta;
  const { id, label, isDisabled, ...propsWithoutLabel } = props;
  const isInvalid = !!(touched && error);
  return (
    <FormControl isInvalid={isInvalid}>
      {label && (
        <FormLabel
          pl={2}
          htmlFor={id ?? name}
          color={isInvalid ? colors.status.red : undefined}
        >
          {label}
        </FormLabel>
      )}
      <Textarea
        id={id ?? name}
        {...(field as any)}
        textStyle="BodyText"
        isDisabled={isDisabled}
        {...propsWithoutLabel}
      />
      <FormErrorMessage fontStyle="BodyText" color={colors.status.red}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}
