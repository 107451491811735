import type { ReactElement } from 'react';
import type { SelectProps } from '~/components';
import {} from 'react';
import { FormControl, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import { BaseSelect, Box } from '~/components';
import { colors } from '~/styles';

type Props<T, Schema> = Omit<SelectProps, 'value' | 'onChange'> & {
  options: T[];
  label?: string;
  name: string;
};

export function FormSelect<T, Schema>({
  label,
  options,
  name,
  ...props
}: Props<T, Schema>): ReactElement {
  const [, meta] = useField(name);
  const context = useFormikContext<Schema>();
  const { touched, error } = meta;
  const isInvalid = !!touched && !!error;
  return (
    <FormControl
      isInvalid={isInvalid}
      onBlur={() => context.setFieldTouched(name, true)}
    >
      <Box mb={-2}>
        {label && (
          <FormLabel
            pl={2}
            htmlFor={name}
            color={isInvalid ? colors.status.red : undefined}
          >
            {label}
          </FormLabel>
        )}
        <BaseSelect
          label={''}
          options={options}
          value={options.find(
            // @ts-ignore
            (option) => option.value === context.values[name]
          )}
          onChange={(value) => context.setFieldValue(name, value.value)}
          {...(props as any)}
        />
      </Box>
      <FormErrorMessage fontStyle="BodyText" color={colors.status.red}>
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}
