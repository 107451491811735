export const targetTypes = [
  'account',
  'domain',
  'hashtag',
  'image',
  'post',
  'unknown',
  'url',
  'user',
  'username',
  'video',
] as const;
export type TargetType = (typeof targetTypes)[number];

export const signalTypes = [
  'Account',
  'Advertising ID',
  'Board',
  'Channel',
  'Email address',
  'Group',
  'Hashtag',
  'Image',
  'Key term',
  'N/A',
  'Other',
  'Page',
  'Phone number',
  'Pin',
  'Podcast',
  'Post',
  'Profile',
  'Search query',
  'Search result',
  'Search term',
  'Shopping',
  'Text description',
  'URL',
  'Video',
] as const;
export type SignalType = (typeof signalTypes)[number];

export const signalPlatformNamesBE = [
  '4chan',
  'BitChute',
  'Communities.win',
  'Douban',
  'Email provider',
  'Facebook',
  'Flickr',
  'Float',
  'Gab',
  'Gettr',
  'Google',
  'GreatAwakening.win',
  'Instagram',
  'LinkedIn',
  'N/A',
  'Odysee',
  'Other',
  'Parler',
  'Patriots.win',
  'Pinterest',
  'Reddit',
  'Rumble',
  'Steam',
  'Steemit',
  'Substack',
  'Telegram',
  'TheDonald',
  'TikTok',
  'TruthSocial',
  'Tumblr',
  'Twitter',
  'VK',
  'WeChat',
  'Weibo',
  'WhatsApp',
  'YouTube',
] as const;
export const signalPlatformNamesFE = [...signalPlatformNamesBE]
  .map((name) => (name === 'Twitter' ? 'X' : name))
  .map((name) => (name === 'TruthSocial' ? 'Truth Social' : name));
export type SignalPlatformName = (typeof signalPlatformNamesBE)[number];

export const targetPlatformNames = [
  '4chan',
  'bitchute',
  'communities.win',
  'douban',
  'email provider',
  'facebook',
  'flickr',
  'float',
  'gab',
  'gettr',
  'google',
  'greatawakening.win',
  'instagram',
  'linkedin',
  'n/a',
  'odysee',
  'other',
  'parler',
  'patriots.win',
  'pinterest',
  'reddit',
  'rumble',
  'steam',
  'steemit',
  'substack',
  'telegram',
  'thedonald',
  'tiktok',
  'truthsocial',
  'tumblr',
  'twitter',
  'vk',
  'wechat',
  'weibo',
  'whatsapp',
  'youtube',
] as const;
export type TargetPlatformName = (typeof targetPlatformNames)[number] | null;

export const signalCategories = ['actor', 'behavior', 'content'] as const;
export type SignalCategory = (typeof signalCategories)[number];

export interface SignalEntityBase {
  date?: string;
  description?: string;
  keywords?: string[];
  successful_metadata: boolean;
  thumbnail_url?: string;
  title?: string;
  video_url?: string;
}

export interface SignalAccountBase {
  description?: string;
  id: string;
  user_name: string;
  display_name?: string;
  profile_image_url: string;
  url?: string;
}
export interface TwitterAccount extends SignalAccountBase {
  followers_count: number;
}

export interface RedditAccount extends SignalAccountBase {
  link_karma: number;
}

export interface SignalPostBase {
  created_at: string;
  html?: string;
  image_url?: string;
  text: string;
  title: string;
  user: SignalAccountBase;
  video_url?: string;
}

export interface SignalVideoBase {
  created_at: string;
  image_url?: string;
  text: string;
  user: SignalAccountBase;
  video_url?: string;
}
