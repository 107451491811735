import { hookify } from './utils';

const keys = {
  all: ['user'] as const,
  preferences: (userId: number) =>
    [...keys.all, userId, 'preferences'] as const,
};

export const userKeys = keys as Readonly<typeof keys>;

export const { fetch: getUserPreferences, hook: useUserPreferences } = hookify()
  .path('/users/{user_id}/preferences')
  .method('get')
  .key(({ params: { user_id } }) => keys.preferences(user_id));
