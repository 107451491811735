import type { ColorResult } from 'react-color';
import type { MapGroup, MapInfo } from '~/types/graphika-types';
import { useMvConfig } from '@graphika/map-viewer';
import { useEffect, useState } from 'react';
import { ChromePicker } from 'react-color';
import { BodyText, Box, Flex, Icon } from '~/components';
import CheckIcon from '~/public/icons/Checkmark.svg';
import CloseIcon from '~/public/icons/Close.svg';
import { colors } from '~/styles';
import { scrollbarStyleDark } from '~/styles/components';

type Props = { map: MapInfo; groups: MapGroup[] };

export function GroupColorPicker({ map, groups }: Props) {
  const mapId = map.id ?? '';
  const [customGroupColors, setCustomGroupColors] = useState<MapGroup[]>([]);
  const [openGroupIndex, setOpenGroupIndex] = useState<number>();
  const [scale, setScale] = useMvConfig((mv) => [mv.scale, mv.setScale]);

  useEffect(() => {
    groups && setCustomGroupColors(groups);
  }, [groups]);

  const handleColorChange = (e: ColorResult, index: number) => {
    const newColors = customGroupColors.map((group, i) => ({
      ...group,
      hex_color: index === i ? e.hex.replace('#', '') : group.hex_color,
    }));
    setCustomGroupColors(newColors);
  };

  const handleSubmit = () => {
    sessionStorage.setItem(
      `${mapId}-custom-colors`,
      JSON.stringify(customGroupColors)
    );
    window.dispatchEvent(new Event('customize-group-colors'));
    setScale(scale + 0.0001); // triggers re-rendering of the pointcloud, This is only necessary when the scale has changed before the group color is modified
    setOpenGroupIndex(undefined);
  };

  const handleCancel = () => {
    setCustomGroupColors(groups);
    setOpenGroupIndex(undefined);
  };

  return (
    <Box
      mx={-4}
      maxHeight={'calc(100vh - 160px)'}
      overflowY="auto"
      sx={scrollbarStyleDark}
    >
      {customGroupColors.map((group, index) => (
        <Box
          key={group.id}
          py={4}
          sx={{
            _notLast: {
              borderBottom: `1px solid #35415A`,
            },
          }}
          cursor="pointer"
        >
          <Flex px={4} gap={1} justify="space-between">
            <Flex
              gap={2}
              flex={1}
              onClick={() =>
                setOpenGroupIndex(openGroupIndex === index ? undefined : index)
              }
            >
              <Box
                pl={4}
                h="18px"
                w="18px"
                borderRadius={4}
                bg={`#${group.hex_color}`}
              />
              <BodyText fontWeight="bold" noOfLines={1}>
                {group.name}
              </BodyText>
            </Flex>
            {openGroupIndex === index && (
              <Flex align="center" gap={2}>
                <Icon
                  icon={CheckIcon}
                  boxSize={5}
                  fill={colors.white}
                  onClick={handleSubmit}
                />
                <Icon
                  icon={CloseIcon}
                  boxSize={5}
                  fill={colors.white}
                  onClick={handleCancel}
                />
              </Flex>
            )}
          </Flex>
          {openGroupIndex === index && (
            <Box p={4} onClick={(e) => e.stopPropagation()}>
              <ChromePicker
                onChange={(e) => handleColorChange(e, index)}
                color={`#${group.hex_color}`}
                disableAlpha
                styles={{
                  default: {
                    picker: { width: '100% ' },
                    body: {
                      background: '#222A3A',
                    },
                  },
                }}
              />
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
}
