import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { Box, Link, SubtitleHeader } from '~/components';
import { hashtagLink } from '~/lib/platform';
import { colors } from '~/styles';

type Props = { signal: Signal };

export function SignalHashtag({ signal }: Props): ReactElement {
  const href = hashtagLink(signal);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);

  return (
    <Box mt={1}>
      {href ? (
        <Link href={href} noFocusStyle noHoverStyle>
          <SubtitleHeader fontWeight={700} color={primary}>
            {signal.signal_value}
          </SubtitleHeader>
        </Link>
      ) : (
        <Box>
          <SubtitleHeader fontWeight={700}>
            {signal.signal_value}
          </SubtitleHeader>
        </Box>
      )}
    </Box>
  );
}
