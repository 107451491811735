import { TypographyProps } from '@chakra-ui/react';

const textStyles = {
  PageHeader: {
    fontFamily: 'heading',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px', // 1.5rem
    lineHeight: '28px', // 1.75rem
  },
  TitleHeader: {
    fontFamily: 'heading',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px', // 1.125rem
    lineHeight: '21px', // 1.3rem
  },
  SubtitleHeader: {
    fontFamily: 'heading',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px', // 1rem
    lineHeight: '18px', // 1.125rem
  },
  BodyText: {
    fontFamily: 'body',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px', // .75rem
    lineHeight: '20px', // 1.25rem
  },
  ButtonText: {
    fontFamily: 'body',
    // Chakra default styling takes precedence for most other properties.
  },
  StatsText: {
    fontFamily: 'monospace',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px', // .75rem
    lineHeight: '16px', // 1rem
  },
  CaptionText: {
    fontFamily: 'body',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px', // .75rem
    lineHeight: '14px', // 0.875rem
  },
  LabelText: {
    fontFamily: 'body',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '10px', // .625rem
    lineHeight: '12px', // 0.75rem
    letterSpacing: '0.06rem',
    textTransform: 'uppercase',
  },
  FooterText: {
    fontSize: '14px', // 0.875rem
    fontWeight: 500,
    lineHeight: '16px', // 1rem
  },
} satisfies Record<string, TypographyProps>;

export default textStyles;
