import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpStatusOK } from '~/lib/api';
import { paths } from '~/types/generated';
import { buildTypedFetch } from './buildTypedFetch';
import {
  PathParams,
  Payload,
  ResponseByStatus,
  TypedKeyGenerator,
  WithOverride,
} from './shared';

export function buildQueryHook<T = void>() {
  function build<
    P extends keyof paths,
    M extends keyof paths[P],
    ResponseOk = WithOverride<T, ResponseByStatus<paths[P][M], HttpStatusOK>>
  >(path: P, method: M, getKey: TypedKeyGenerator<P, M>) {
    const fetch = buildTypedFetch<P, M, ResponseOk>(path, method);
    return (
      params: PathParams<paths[P][M]>,
      payload: Payload<paths[P][M]>,
      options?: UseQueryOptions<ResponseOk>
    ) => {
      const key = getKey({ params, ...(payload as any) });
      const queryFn = async () => {
        try {
          const result = await fetch(params)(payload);
          return result.data;
        } catch (error) {
          throw error;
        }
      };

      return useQuery(
        key,
        queryFn,
        options as UseQueryOptions<ResponseOk, unknown, ResponseOk, typeof key>
      );
    };
  }

  return {
    path<P extends keyof paths>(path: P) {
      return {
        method<M extends keyof paths[P]>(method: M) {
          return {
            key(getKey: TypedKeyGenerator<P, M>) {
              return build<P, M>(path, method, getKey);
            },
          };
        },
      };
    },
  };
}
