import type { ReactElement } from 'react';
import type { Narrative, Signal } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { useIsMutating } from '@tanstack/react-query';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState, useRef } from 'react';
import * as yup from 'yup';
import { BodyText, Box, Flex, Icon } from '~/components';
import {
  Form,
  FormInput,
  FormSelect,
  FormTextarea,
} from '~/components/elements/Forms';
import { capitalize } from '~/lib/text';
import { useClassification } from '~/queries';
import { colors } from '~/styles';
import {
  signalCategories,
  SignalCategory,
  signalPlatformNamesBE,
  signalPlatformNamesFE,
  signalTypes,
} from '~/types/signal-types';
import { inferCategory } from '~/lib/signals';
import PlusIcon from '~/public/icons/Plus.svg';
import { useSignalModal } from '../shared';

const categoryOptions = signalCategories.map((type) => ({
  value: type,
  label: capitalize(type),
}));

const typeOptions = signalTypes.map((type) => ({
  value: type,
  label: type,
}));

const platformOptions = signalPlatformNamesBE.map((platform, index) => ({
  value: platform,
  label: signalPlatformNamesFE[index],
}));
const errMsg = 'This is a required field';

export const manualAddSchema = yup.object({
  category: yup
    .mixed()
    .oneOf<SignalCategory>(signalCategories.map((c) => c))
    .required(errMsg),
  notes: yup.string().nullable().trim().optional(),
  entity: yup.string().trim().optional(),
  signal_platform: yup.string().required(errMsg),
  translation: yup.string().nullable().trim().optional(),
  signal_type: yup.string().required(errMsg),
  signal_value: yup.string().trim(),
});

interface ManualAddSchema extends yup.InferType<typeof manualAddSchema> {}

const emptyForm: ManualAddSchema = {
  category: 'content',
  notes: '',
  entity: '',
  signal_platform: '',
  translation: '',
  signal_type: '',
  signal_value: '',
};

type Option = { label: string; value: string };

type Props = {
  signal?: Signal;
  isEditingExistingSignal?: boolean;
};
export function ManualAddForm({
  signal,
  isEditingExistingSignal,
}: Props): ReactElement {
  const {
    narrative,
    preview: { signals, currentSignal, setCurrentSignal, setSignals },
  } = useSignalModal();
  const gray2 = useColorModeValue(colors.warmGray[2], colors.coolGray[5]);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const isValidSignal = manualAddSchema.isValidSync(currentSignal);

  const addCurrentSignal = () => {
    if (currentSignal && isValidSignal) {
      setCurrentSignal(undefined);
      setTimeout(() => {
        setSignals([...signals, currentSignal]);
      }, 100);
    }
  };

  return (
    <Box px={4} mb={4} minH="700px">
      <Formik
        initialValues={signal ?? emptyForm}
        validationSchema={manualAddSchema}
        onSubmit={() => {}}
      >
        {(formik) => (
          <Form insideModal>
            {!isEditingExistingSignal && (
              <BodyText mb={1}>Enter the signal details.</BodyText>
            )}
            <Box>
              <SignalValueInput
                addCurrentSignal={addCurrentSignal}
                currentSignal={currentSignal}
                setCurrentSignal={setCurrentSignal}
                narrative={narrative}
                signals={signals}
                isEditingExistingSignal={isEditingExistingSignal}
              />
              {!isEditingExistingSignal && (
                <BodyText
                  mt={2}
                  fontWeight={500}
                  color={!isValidSignal ? gray2 : primary}
                  as="button"
                  cursor={!isValidSignal ? 'not-allowed' : 'pointer'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    isValidSignal && addCurrentSignal();
                  }}
                >
                  <Flex align="top">
                    <Icon icon={PlusIcon} boxSize={7} pt={1} mr={-1} />
                    Add another signal
                  </Flex>
                </BodyText>
              )}
              <FormSelect<Option, ManualAddSchema>
                options={categoryOptions}
                label="category"
                name="category"
                isDisabled={!isValidSignal}
              />
            </Box>
            {formik.values.category !== 'behavior' && (
              <>
                <FormSelect<Option, ManualAddSchema>
                  options={typeOptions}
                  label="type"
                  name="signal_type"
                  isDisabled={!isValidSignal}
                />
                <FormSelect<Option, ManualAddSchema>
                  options={platformOptions}
                  label="platform"
                  name="signal_platform"
                  isDisabled={!isValidSignal}
                />
              </>
            )}
            <FormTextarea
              name="notes"
              label="notes (optional)"
              h="250px"
              isDisabled={!isValidSignal}
            />
            <FormInput
              name="translation"
              label="Translation (optional)"
              placeholder="Translation"
              type="text"
              isDisabled={!isValidSignal}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
}

const SignalValueInput = ({
  addCurrentSignal,
  currentSignal,
  narrative,
  setCurrentSignal,
  signals = [],
  isEditingExistingSignal,
}: {
  addCurrentSignal: () => void;
  currentSignal?: Signal;
  narrative: Narrative;
  setCurrentSignal: (signal?: Signal) => void;
  signals: Signal[];
  isEditingExistingSignal?: boolean;
}) => {
  const [refetchClassify, setRefetchClassify] = useState(
    !isEditingExistingSignal
  );
  const isMutating = useIsMutating();
  const hasNewSignalData = useRef<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { values, setFieldValue, setValues, setTouched, setErrors } =
    useFormikContext<ManualAddSchema>();
  useClassification(
    undefined,
    {
      query: { signals: [values.signal_value || ''] },
    },
    {
      enabled: refetchClassify && (values.signal_value || '').length > 0,
      cacheTime: 0,
      onSuccess: (data) => {
        if (data.length === 0) return;
        const defaultCategory = inferCategory(data[0]);
        const { signal_type, signal_platform } = data[0];
        hasNewSignalData.current = true;
        setCurrentSignal({
          ...currentSignal,
          ...data[0],
          category: defaultCategory,
        } as Signal);
        setFieldValue('signal_platform', signal_platform);
        setFieldValue('signal_type', signal_type);
        setFieldValue('category', defaultCategory);
      },
    }
  );
  const currentSignalAlreadyExists =
    [...narrative.signals, ...signals]
      .map((signal) => signal.signal_value)
      .includes(values.signal_value ?? '') &&
    !isMutating &&
    currentSignal;

  useEffect(() => {
    if (!currentSignal) {
      inputRef.current?.focus();
      setTouched({});
      setErrors({});
      setValues({
        ...emptyForm,
        notes: values.notes,
        translation: values.translation,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSignal]);

  useEffect(() => {
    if (hasNewSignalData.current) {
      hasNewSignalData.current = false;
    } else {
      setCurrentSignal({ ...currentSignal, ...values } as Signal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCurrentSignal, values]);

  return (
    <Box mb={isEditingExistingSignal ? 3 : 0}>
      <FormInput
        ref={inputRef}
        name="signal_value"
        label="signal"
        type="text"
        placeholder="Signal"
        autoComplete="off"
        onChange={(e) => {
          if (e.currentTarget.value === '') {
            hasNewSignalData.current = true;
            setCurrentSignal(undefined);
          } else {
            setFieldValue('signal_value', e.currentTarget.value);
          }
          if (!refetchClassify) {
            setRefetchClassify(true);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            addCurrentSignal();
          }
        }}
      />
      {!isEditingExistingSignal && currentSignalAlreadyExists && (
        <Box mt={1} mb={1}>
          <BodyText color={colors.status.red}>Signal already exists</BodyText>
        </Box>
      )}
    </Box>
  );
};
