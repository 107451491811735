import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection } from 'lexical';
import { useEffect } from 'react';
import { $generateHtmlFromNodes } from '@lexical/html';

export function DebugPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' ||
      process.env.NEXT_PUBLIC_ADDITIONAL_LOGGING !== 'enabled'
    )
      return;
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        console.dir({
          state: editorState.toJSON(),
          selection: $getSelection(),
          serialized: {
            html: $generateHtmlFromNodes(editor),
          },
        });
      });
    });
  }, [editor]);
  return null;
}
