import type { Community } from '~/types/graphika-types';
import { useEffect, useState } from 'react';
import { hookify } from '~/queries/utils';
import { colors } from '~/styles';
import { useMaps } from '~/queries';

const keys = {
  all: ['narrative_feeds'] as const,
  communities: (feed_id: number) =>
    [...keys.all, 'communities', feed_id] as const,
};

export type MapWithGroups = {
  id: number;
  type: string;
  name: string;
  color: string;
  groups: {
    id: number;
    name: string;
    group_no: string;
    hex_color: string;
  }[];
};
export type Communities = {
  maps: MapWithGroups[];
  subreddits: [];
};

export const { fetch: getFeedCommunities, hook: useFeedCommunities } =
  hookify<Communities>()
    .path('/narrative_feeds/{narrative_feed_id}/communities')
    .method('get')
    // TODO: check keys
    .key(({ params: { narrative_feed_id } }) =>
      keys.communities(narrative_feed_id)
    );

export const fetchFeedsCommunities = async (feedIds: number[]) => {
  const result = await Promise.all(
    feedIds.map(
      async (id) => await getFeedCommunities({ narrative_feed_id: id })()
    )
  );
  const data = result.map((r) => r.data);
  return data;
};

export function useMapsWithGroups({
  feedIds,
  groups,
}: {
  feedIds: number[];
  groups: Community[];
}) {
  const [feedCommunities, setFeedCommunities] = useState<MapWithGroups[]>([]);
  const { data: allMaps = [] } = useMaps();

  useEffect(() => {
    const mapColorsArr = Object.values(colors.map);
    const liveMapIds = allMaps
      .filter((map) => map.is_live)
      .map((map) => map.id);
    const fetchData = async () => {
      const data = await fetchFeedsCommunities(feedIds);
      let maps: MapWithGroups[] = data.map((d) => d.maps).flat();
      const mapsWithExistingGroups = maps
        .map((map, index) => {
          const signalGroups = map.groups
            .filter((group) =>
              groups.map((g) => g.community_id).includes(group.id)
            )
            .sort((a, b) =>
              a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
            );
          const color = mapColorsArr[index % mapColorsArr.length];
          return { ...map, color, groups: signalGroups };
        })
        .filter((map) => map.groups.length > 0)
        .sort((a, b) =>
          a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1
        );
      const uniqueLiveMaps = mapsWithExistingGroups
        .filter((map) => liveMapIds.includes(map.id.toString()))
        .reduceRight(
          (result, map) =>
            result.find((m) => m.id === map.id) ? result : [...result, map],
          [] as MapWithGroups[]
        )
        .reverse();
      setFeedCommunities(uniqueLiveMaps);
    };
    fetchData();
  }, [feedIds, groups, allMaps]);

  return feedCommunities;
}
