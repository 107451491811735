// @ts-nocheck
import emoji from 'node-emoji';
import rehypeParse from 'rehype-parse';
import rehypeStringify from 'rehype-stringify';
import { unified } from 'unified';
import rehypeRemark from 'rehype-remark';
import remarkStringify from 'remark-stringify';
import { ClipboardEventHandler } from 'react';
import { highlightTextContent, sanitizeHtml } from './html';

/**
 * transforms non-standard MD links from backend to standard MD
 * `<https://google.com|google> into [google](https://google.com)`
 * transforms `:eyes:` into actual emoji
 */
export const translateMarkdown = (md: string): string => {
  if (!md) return '';

  let str = md.replace(/&lt;/g, '<').replace(/&gt;/g, '>');
  if (str[0] === '|') {
    str = str.slice(1);
  }
  const matches = [...str.matchAll(/<([^>]*)>/g)].map((el) => el[1]);
  matches?.forEach((match) => {
    const [url, text] = match.split('|');
    str = str.replace(`<${match}>`, `[${text ?? url}](${url})`);
  });
  str = emoji.emojify(str);
  return str.trim();
};

export const highlightText = (text: string, textToHighlight: string) => {
  if (!textToHighlight) return text ?? '';
  const regex = new RegExp(
    textToHighlight.trim().split(/\s+/).filter(Boolean).join('|'),
    'gi'
  );

  if (text.startsWith('<')) {
    const { value } = unified()
      .use(rehypeParse)
      .use(highlightTextContent, { regex })
      .use(rehypeStringify)
      .processSync(text);
    return value.toString();
  }

  return text.replace(regex, (match) => `\`!!${match}\``);
};

/**
 *  removes mapId links from article markdown and sidebar text, looks for content between `(#` and `)` and removes it
 */
export const removeMapIdLink = (str: string) =>
  str.replace(/ *\(#[^)]*\) */g, '');

export const hasUrlRegex =
  /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/gi;

export const isUrlRegex = new RegExp(`^${hasUrlRegex.source}$`, 'g');

export const replaceWithMarkdownLinks = ({
  clipboardValue,
  currentText,
  start,
  end,
}: {
  clipboardValue: string;
  currentText: string;
  start: number;
  end: number;
}) => {
  if (start !== end) {
    currentText = currentText.slice(0, start) + currentText.slice(end);
  }
  const clipboardWithMdLinks = clipboardValue.replaceAll(
    hasUrlRegex,
    '[$&]($&)'
  );
  const newText =
    currentText.slice(0, start) +
    clipboardWithMdLinks +
    currentText.slice(start);
  return newText;
};

export const onRtfPaste: ClipboardEventHandler<any> = async (e) => {
  const clipboardValue =
    e.clipboardData?.getData('text/html') ||
    e.clipboardData?.getData('text/plain') ||
    '';
  const parsedMd = await unified()
    .use(rehypeParse)
    .use(sanitizeHtml)
    .use(rehypeRemark, {
      handlers: {
        img() {
          return undefined;
        },
        table() {
          return undefined;
        },
      },
    })
    .use(remarkStringify, {})
    .process(clipboardValue);

  // Reference: https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
  document.execCommand('insertText', false, parsedMd.toString());
  e.preventDefault();
  e.stopPropagation();
};
