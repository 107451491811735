import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalEntityBase } from '~/types/signal-types';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Link, SignalCardBg } from '~/components';
import { fallbackEntityData } from '~/lib/signals';
import { colors } from '~/styles';
import { coerceIntoExternalUrl } from '~/lib/urls';
import { SignalCardImage } from './SignalCardImage';

type Props = { signal: Signal };

const getUrlInfo = (signal: Signal): SignalEntityBase => {
  if (!signal.full_target) {
    return fallbackEntityData;
  }
  const {
    full_target: { entity },
  } = signal as Signal<SignalEntityBase>;
  return entity ?? fallbackEntityData;
};

export function SignalUrl({ signal }: Props): ReactElement {
  const { title, thumbnail_url } = getUrlInfo(signal);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const hasClassificationIssue =
    title === 'Log into Facebook' ||
    signal.full_target?.entity?.title === 'Your browser is out of date';
  return (
    <SignalCardBg>
      {!hasClassificationIssue && <BodyText fontWeight={700}>{title}</BodyText>}
      <Link
        href={coerceIntoExternalUrl(signal.signal_value)}
        variant="gray"
        noFocusStyle
        noOfLines={1}
        mb={0}
      >
        <BodyText color={gray1}>{signal.signal_value}</BodyText>
      </Link>
      {thumbnail_url && !hasClassificationIssue && (
        <SignalCardImage
          src={thumbnail_url ?? signal.signal_value}
          alt={signal.notes || signal.signal_value}
          mt={2}
        />
      )}
    </SignalCardBg>
  );
}
