import type { PropsWithChildren } from 'react';
import type { PlacementWithLogical } from '@chakra-ui/react';
import { Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { Button } from '~/components';
import MoreDotsIcon from '~/public/icons/MoreDotsVertical.svg';

export function SimpleDotsMenu({
  children,
  color,
  defaultIsOpen,
  placement = 'left-start',
}: PropsWithChildren<{
  color?: string;
  defaultIsOpen?: boolean;
  placement?: PlacementWithLogical;
}>) {
  return (
    <Menu defaultIsOpen={defaultIsOpen} placement={placement}>
      <MenuButton
        as={Button}
        alignItems="end"
        color={color || 'inherit'}
        icon={MoreDotsIcon}
        my={2}
        w="32px"
        variant="plain"
        _focus={{ boxShadow: 'none' }}
      />
      <MenuList
        textStyle="BodyText"
        p={0}
        zIndex={2}
        borderRadius={8}
        overflow="hidden"
      >
        {children}
      </MenuList>
    </Menu>
  );
}
