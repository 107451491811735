import type { MapCluster, MapGroup, Narrative } from '~/types/graphika-types';
import type { HgBasicQueryParams } from '.';
import { unparse } from 'papaparse';
import { generateDateArray } from '../date';
import { ByteOrderMark } from '../text';

export type HgPostActivitySnapshot = {
  id: string;
  type: 'post_activity';
  parameters: {
    map_id: string;
    start_date: string; // yyyy-MM-dd
    end_date: string; // yyyy-MM-dd
    query: string;
    sort?: string;
  };
  data: HgActivityData;
};

export type HgActivityData = {
  map: {
    id: string;
    name: string;
  };
  groups: Pick<MapGroup, 'id' | 'name' | 'hex_color' | 'position'>[];
  clusters: Pick<MapCluster, 'id' | 'name' | 'position' | 'group_id'>[];
  post_activity: ClusterDataForDate[];
};

export type HgActivityDataWithGroups = HgActivityData & {
  post_activity_groups: GroupDataForDate[];
};

type ClusterDataForDate = {
  date: string;
  clusters: { cluster_id: string; count: number }[];
};

type GroupDataForDate = {
  date: string;
  groups: { group_id: string; count: number }[];
};

export type ActivityDataSeries = Record<
  string,
  { date: string; count: number }[]
>;

export function parseActivityData(
  query: Pick<HgBasicQueryParams, 'startDate' | 'endDate'>,
  data: HgActivityData
): HgActivityDataWithGroups {
  return aggregateGroupCounts(fillActivityData(query, data));
}

export const fillActivityData = (
  query: Pick<HgBasicQueryParams, 'startDate' | 'endDate'>,
  data: HgActivityData
) => {
  const dateArr = generateDateArray(
    new Date(query.startDate),
    new Date(query.endDate)
  );
  const filledData = dateArr.map((day): ClusterDataForDate => {
    const existingDataForDay = data.post_activity.find(
      (dataForDay) => dataForDay.date.slice(0, 10) === day
    );
    if (!existingDataForDay) {
      return {
        date: day + 'T00:00:00.000Z',
        clusters: data.clusters.map((cluster) => ({
          cluster_id: cluster.id,
          count: 0,
        })),
      };
    }
    return {
      date: day + 'T00:00:00.000Z',
      clusters: data.clusters.map((cluster) => {
        const existingDataForClusterDay = existingDataForDay.clusters.find(
          (c) => c.cluster_id === cluster.id
        );
        if (existingDataForClusterDay)
          return {
            cluster_id: cluster.id,
            count: existingDataForClusterDay.count,
          };
        return { cluster_id: cluster.id, count: 0 };
      }),
    };
  });
  return { ...data, post_activity: filledData };
};

export const aggregateGroupCounts = (
  data: HgActivityData
): HgActivityDataWithGroups => {
  const groupsWithClusterIds = data.groups.map((group) => ({
    ...group,
    clustersIds: data.clusters
      .filter((cluster) => cluster.group_id === group.id)
      .map((cluster) => cluster.id),
  }));
  const groupsData = data.post_activity.map((dayData) => {
    const groups = groupsWithClusterIds.map((group) => {
      const groupCount = dayData.clusters.reduce(
        (prev, curr) =>
          (prev += group.clustersIds.includes(curr.cluster_id)
            ? curr.count
            : 0),
        0
      );
      return { group_id: group.id, count: groupCount };
    });
    return { date: dayData.date, groups };
  });
  return { ...data, post_activity_groups: groupsData };
};

export const getActivityChartSeries = (
  data: HgActivityDataWithGroups
): ActivityDataSeries => {
  if (!data?.post_activity_groups) return {};
  const series: ActivityDataSeries = {};
  data.groups.forEach((group) => {
    series[`group_${group.id}`] = data.post_activity_groups.map((day) => ({
      date: day.date,
      count: day.groups.find((g) => g.group_id === group.id)?.count ?? 0,
    }));
  });
  series['group_Total Map Activity'] = data.post_activity_groups.map((day) => {
    const sum = day.groups.reduce((prev, curr) => (prev += curr.count), 0);
    return { date: day.date, count: sum };
  });
  data.clusters.forEach((cluster) => {
    series[`cluster_${cluster.id}`] = data.post_activity.map((day) => ({
      date: day.date,
      count: day.clusters.find((c) => c.cluster_id === cluster.id)?.count ?? 0,
    }));
  });
  return series;
};

export const sortGroupsByMaxCount = (
  groups: MapGroup[],
  data: ActivityDataSeries
) => {
  return groups
    .filter((group) => group.position !== -1)
    .map((group) => ({
      ...group,
      max: Math.max(...(data[`group_${group.id}`] ?? []).map((d) => d.count)),
    }))
    .sort((a, b) => (b?.max ?? 0) - (a?.max ?? 0))
    .map(({ max, ...group }) => group);
};

export const downloadActivityChartCsv = ({
  data,
  groups,
  insight,
}: {
  data: ActivityDataSeries;
  groups: MapGroup[];
  insight: Narrative;
}) => {
  const activeGroupsData: ActivityDataSeries = {};
  groups.forEach(
    (group) =>
      (activeGroupsData[group.name] =
        data[`group_${group.id.replace('group_', '')}`])
  );
  const dataAsArray = Object.entries(activeGroupsData).flatMap(
    ([group, data]) => {
      return data.map((d) => ({
        group,
        ...d,
        date: d.date.slice(0, 10),
      }));
    }
  );
  const csv = unparse(dataAsArray);
  const blob = new Blob([ByteOrderMark + csv], {
    type: 'text/csv;charset=utf-8',
  });
  const a = document.createElement('a');
  a.setAttribute('href', window.URL.createObjectURL(blob));
  a.setAttribute('download', `${insight.title}.csv`);
  a.click();
  a.remove();
};
