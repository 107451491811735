import type { ReactElement } from 'react';
import type { StyleProps, InputProps } from '@chakra-ui/react';
import {
  forwardRef,
  useColorMode,
  useMultiStyleConfig,
  Box,
  Flex,
  InputGroup,
  InputLeftElement,
  Input as ChakraInput,
} from '@chakra-ui/react';
import { Icon } from '~/components';

type Props = StyleProps &
  InputProps & {
    icon?: () => ReactElement;
    label?: string;
  };

export const Input = forwardRef<Props, 'input'>(
  ({ icon, variant, label, ...props }, ref): ReactElement => {
    const { colorMode } = useColorMode();
    const styles = useMultiStyleConfig('Input', { variant, colorMode });
    const inputJsx = icon ? (
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          {/* @ts-ignore */}
          <Icon {...styles.leftIcon} icon={icon} />
        </InputLeftElement>
        <ChakraInput
          ref={ref}
          variant={variant}
          p="4px 16px 4px 34px"
          {...(props as any)}
        />
      </InputGroup>
    ) : (
      <ChakraInput
        ref={ref}
        variant={variant}
        p="4px 16px"
        {...(props as any)}
      />
    );
    if (label) {
      return (
        <Flex direction="column">
          <Box as="label" __css={styles.label} htmlFor={props.id}>
            {label}
          </Box>
          {inputJsx}
        </Flex>
      );
    }
    return inputJsx;
  }
);
