import type { Signal, SignalWithCommunities } from '~/types/graphika-types';
import { SignalCategory, SignalEntityBase } from '~/types/signal-types';
import { getTweetLinkFromId, hashtagLink, twitterUserLink } from './platform';
import { coerceIntoExternalUrl } from './urls';

export const fallbackEntityData: SignalEntityBase = {
  description: '',
  keywords: [],
  successful_metadata: false,
  thumbnail_url: '',
  title: '',
};

export const hashtagRegex = /(?:|^)#[A-Za-z0-9\-\.\_]+(?:|$)/g;
export const userRegex = /(?:|^)@[A-Za-z0-9\-\.\_]+(?:|$)/g;

export const getTargetType = (signal: Signal) => signal.target_type;

export const getTargetPlatform = (signal: Signal<unknown>) =>
  signal.target_platform ?? 'N/A';

export function splitSignalsByCategory<
  T extends SignalWithCommunities | Signal
>(signals: T[]) {
  return [
    signals.filter((signal) => signal.category === 'actor'),
    signals.filter((signal) => signal.category === 'behavior'),
    signals.filter(
      (signal) => signal.category === 'content' || !signal.category
    ),
  ] as [actor: T[], behavior: T[], content: T[]];
}

export const getValidSignalLink = (signal: Signal) => {
  const { signal_value, target_platform, target_type } = signal;
  if (
    target_type === 'unknown' ||
    (signal.target_type === 'hashtag' && signal.version === '1')
  )
    return undefined;
  if (getTweetLinkFromId(signal)) {
    return getTweetLinkFromId(signal);
  }
  if (target_type === 'hashtag') {
    return hashtagLink(signal);
  }
  if (target_platform === 'twitter') {
    if (['user', 'username', 'account'].includes(signal_value)) {
      return twitterUserLink(signal_value);
    }
  }
  if (coerceIntoExternalUrl(signal_value)) {
    return coerceIntoExternalUrl(signal_value);
  }
};

export const inferCategory = (signal: Partial<Signal>): SignalCategory => {
  return ['user', 'username', 'account'].includes(signal.target_type ?? '')
    ? 'actor'
    : 'content';
};
