import { ComponentStyleConfig } from '@chakra-ui/react';
import { colorsV2 } from '../colorsV2';

const avatarSize = {
  lg: {
    h: '40px',
    w: '40px',
  },
  md: {
    h: '32px',
    w: '32px',
  },
  sm: {
    h: '24px',
    w: '24px',
  },
  xs: {
    h: '16px',
    w: '16px',
  },
} as const;

export const Avatar: ComponentStyleConfig = {
  baseStyle: {
    container: {
      bg: colorsV2.surface[200],
    },
    label: {
      color: colorsV2.text,
    },
  },
  sizes: {
    lg: {
      container: avatarSize.lg,
      excessLabel: avatarSize.lg,
      label: {
        fontSize: '14px',
      },
    },
    md: {
      container: avatarSize.md,
      excessLabel: avatarSize.md,
      label: {
        fontSize: '14px',
      },
    },
    sm: {
      container: avatarSize.sm,
      excessLabel: avatarSize.sm,
      label: {
        fontSize: '12px',
      },
    },
    xs: {
      container: avatarSize.xs,
      excessLabel: avatarSize.xs,
      label: {
        display: 'none',
      },
    },
  },
  variants: {},
  defaultProps: {
    size: 'md',
    variant: '',
    colorScheme: '',
  },
};
