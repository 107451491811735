import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalEntityBase, SignalVideoBase } from '~/types/signal-types';
import { BodyText, Link, SignalCardBg } from '~/components';
import { getTargetPlatform } from '~/lib/signals';
import { SignalCardImage } from './SignalCardImage';

type Props = { signal: Signal };

type VideoInfo = {
  thumbnail?: string;
  title?: string;
};

const getVideoInfo = (signal: Signal): VideoInfo => {
  const platform = getTargetPlatform(signal);
  const baseSignal = signal as Signal<SignalEntityBase>;
  const videoSignal = signal as Signal<SignalVideoBase>;
  if (platform === 'tiktok') {
    return {
      thumbnail: videoSignal.full_target.entity?.image_url ?? '',
      title: videoSignal.full_target.entity?.text,
    };
  }
  const {
    full_target: { entity },
  } = baseSignal;
  if (!entity) {
    throw Error();
  }
  return {
    thumbnail: entity.thumbnail_url,
    title: entity.title,
  };
};

export function SignalVideo({ signal }: Props): ReactElement {
  const { thumbnail, title } = getVideoInfo(signal);
  return (
    <SignalCardBg>
      <BodyText>{title}</BodyText>
      <Link
        href={signal.signal_value}
        variant="gray"
        noFocusStyle
        noOfLines={2}
        mb={2}
      >
        {signal.signal_value}
      </Link>
      <SignalCardImage
        src={thumbnail || signal.signal_value}
        alt={signal.notes || signal.signal_value}
      />
    </SignalCardBg>
  );
}
