// https://www.figma.com/file/LtZrLMUnQxFA3eZePIRxoE/Component-Library-Stable?node-id=342%3A2485
const colors = {
  black: '#000000',
  white: '#FFFFFF',
  g: {
    dark: '#3A46A2',
    primary: '#5567EE',
    lightLavender: '#F6F7FE',
    light: '#6273F5',
    pale: '#B4BCF8',
    lavender: '#E5E8FD',
    plum: '#373F67',
  },
  coolGray: {
    0: '#121721',
    1: '#1F2329',
    2: '#2E333C',
    3: '#4B4F56',
    4: '#797B7F',
    5: '#C6C7CA',
    6: '#ECECED',
  },
  warmGray: {
    // 0: not defined.
    1: '#5C5C5C',
    2: '#949494',
    3: '#E2E2E2',
    4: '#EBEBEB',
    5: '#F6F6F6',
    6: '#FAFAFA',
  },
  status: {
    red: '#E85E50',
    green: '#60B667',
    orange: '#FFA24B',
  },
  background: {
    lightMode: '#E2E2E2',
    darkMode: '#121721',
    landscape: '#000000',
    ring: '#292929',
    ringFade: '#141414',
  },
  card: {
    lightMode: '#FFFFFF',
    darkMode: '#2E333C',
    warning: '#FFCB9B',
  },
  map: {
    lightpurple: '#D4B9FF',
    lightblue: '#87E9FF',
    pink: '#FFA8DC',
    orange: '#FFAB5B',
    green: '#BBFC67',
    red: '#F68888',
    brown: '#BA906A',
    darkgreen: '#9ACE82',
    paleblue: '#B5C2EE',
    paleyellow: '#FFEF99',
    blue: '#5A92FF',
    purple: '#9766FF',
    magenta: '#FF73D8',
  },
  brands: {
    reddit_node: '#F7D25D',
    reddit: '#FF5700',
    twitter: '#2A9DED',
    youtube: '#FF0000',
  },
  mapViewer: {
    bg: '#1A202C',
    bgGray: '#313D53',
    deselected: '#4B5D81',
    gray: '#384255',
    hoverBg: '#252E3F',
    mapSidebar: '#1E2533',
    segmentText: '#D2D8E5',
  },
};

export default colors;
