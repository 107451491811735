import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const baseStyle = defineStyle((props) => ({
  height: '1px',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: mode(colors.warmGray[4], colors.coolGray[1])(props),
  marginBlock: 4,
  opacity: 1,
}));

export const Divider = defineStyleConfig({ baseStyle });
