import type { CheckboxProps } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import {
  Box,
  Checkbox as ChakraCheckbox,
  forwardRef,
  Icon,
} from '@chakra-ui/react';

type Props = PropsWithChildren<
  CheckboxProps & { checkedWhenIndeterminate?: boolean }
>;
export const Checkbox = forwardRef<Props, 'input'>((props, ref) => {
  const { children, textStyle, ...rest } = props;
  const mapviewer = rest.variant === 'mapviewer';
  return (
    <ChakraCheckbox
      ref={ref}
      icon={
        (rest.isIndeterminate || rest.isDisabled) &&
        !rest.checkedWhenIndeterminate ? (
          <CheckboxDash />
        ) : (
          <CheckboxTick mapviewer={mapviewer} />
        )
      }
      {...(rest as any)}
    >
      <Box textStyle={textStyle}>{children}</Box>
    </ChakraCheckbox>
  );
});

function CheckboxDash() {
  return (
    <svg viewBox="0 0 10 2" width="10" height="2">
      <path d="M0 0H10V2H0V0Z" fill="currentColor" />
    </svg>
  );
}

function CheckboxTick({ mapviewer }: { mapviewer?: boolean }) {
  return (
    <Icon viewBox="0 0 13 10" boxSize={mapviewer ? 3 : 4}>
      <path
        d="M12.99 2.00008L11.58 0.580078L4.98999 7.17008L2.40999 4.60008L0.98999 6.01008L4.98999 10.0001L12.99 2.00008Z"
        fill="currentColor"
      />
    </Icon>
  );
}
