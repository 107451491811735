import { useMutation } from '@tanstack/react-query';
import { apiUrl, fetchFromApi } from '~/lib/api';
import {
  Narrative,
  NarrativeImage,
  KnownTargetType,
} from '~/types/graphika-types';
import { narrativeKeys } from '../narrative';
import { buildMutation } from '../utils';

export const useCreateNarrative = buildMutation(
  '/narratives',
  'post',
  () =>
    (
      form: Pick<Narrative, 'title' | 'summary'> & {
        signals: {
          label: string;
          type: KnownTargetType;
          value: string;
        }[];
      }
    ) => ({
      body: form,
    }),
  [narrativeKeys.narratives()]
);

export const useUpdateNarrative = buildMutation(
  '/narratives/{narrative_id}',
  'patch',
  (narrative_id: number) => (form: Partial<Narrative>) => ({
    params: { narrative_id },
    body: form as any,
  })
);

export const useDeleteNarrative = buildMutation(
  '/narratives/{narrative_id}',
  'delete',
  () => (params: { narrative_id: number }) => ({ params })
);

export const useAssociateNarrativeWithFeed = buildMutation(
  '/narrative_feeds/{narrative_feed_id}/narratives',
  'post',
  (narrative_id: number) => (narrative_feed_id: number) => ({
    params: { narrative_feed_id },
    body: { narrative_id },
  })
);

export const useSendNarrativeToState = (id: number) => {
  return useMutation((state: 'draft' | 'in review' | 'published') => {
    switch (state) {
      case 'in review':
        return fetchFromApi(
          apiUrl('/narratives/{narrative_id}/send_to_review', id),
          'POST'
        );
      case 'published':
        return fetchFromApi(
          apiUrl('/narratives/{narrative_id}/publish', id),
          'POST'
        );
      case 'draft':
        return fetchFromApi(
          apiUrl('/narratives/{narrative_id}/send_to_draft', id),
          'POST'
        );
    }
  });
};

export const useMergeNarratives = buildMutation(
  '/narratives/merge',
  'post',
  () => (form: { narratives: number[]; title: string }) => ({ body: form }),
  [narrativeKeys.narratives()]
);

export const useAddNarrativeImage = buildMutation(
  '/narratives/{narrative_id}/images',
  'post',
  (narrative_id: number) =>
    (
      form: Pick<NarrativeImage, 'caption' | 'is_cover_image'> & { file: File }
    ) => {
      const formData = new FormData();
      formData.append('file', form.file);
      formData.append('is_cover_image', form.is_cover_image ? '1' : '0');
      form.caption && formData.append('caption', form.caption);
      return { params: { narrative_id }, form: formData };
    },
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.images(narrative_id),
  ]
);

export const useUpdateNarrativeImage = buildMutation(
  '/narratives/{narrative_id}/images/{image_id}',
  'patch',
  (narrative_id: number) => (params: { image_id: number; caption: string }) => {
    const { image_id, caption } = params;
    return { params: { narrative_id, image_id }, body: { caption } };
  },
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.images(narrative_id),
  ]
);

export const useDeleteNarrativeImage = buildMutation(
  '/narratives/{narrative_id}/images/{image_id}',
  'delete',
  (narrative_id: number) => (image_id: number) => ({
    params: { narrative_id, image_id },
  }),
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.images(narrative_id),
  ]
);

export const useLockNarrative = buildMutation(
  '/narratives/{narrative_id}/lock',
  'post',
  (narrative_id: number) => (_: void) => ({ params: { narrative_id } }),
  [narrativeKeys.locks()]
);
