import { QueryKey } from '@tanstack/react-query';
import { fetchFromCoreApi } from '~/lib/api';
import {
  HgBasicQueryParams,
  HgActivityData,
  HgActivityDataWithGroups,
  parseActivityData,
} from '~/lib/hypergraph/';
import { HgRelevantMapNodesData } from '../hypergraph';
import { queryClient } from '../queryClient';

export async function cachify<T = Record<string, any>>(
  fn: () => Promise<T | undefined>,
  queryKey: QueryKey
) {
  try {
    const cachedData = queryClient.getQueryData(queryKey) as T;
    if (!!cachedData) {
      return cachedData;
    } else {
      const newData = await queryClient.fetchQuery(queryKey, () =>
        fn().then((data) => data)
      );
      if (newData) {
        return newData as T;
      }
    }
  } catch (e) {
    throw new Error(`Core API fetch error for ${queryKey.join('_')}`);
  }
}
export async function fetchHgActivity(
  query: HgBasicQueryParams
): Promise<HgActivityDataWithGroups | undefined> {
  const data = await cachify(
    () =>
      fetchFromCoreApi<HgActivityData>(
        'POST',
        '/intel_search',
        serializeActivityParams(query)
      ),
    ['intel_search', 'post_activity', query]
  );
  if (data) {
    return parseActivityData(query, data);
  }
}

export function serializeActivityParams(params: HgBasicQueryParams) {
  return {
    type: 'post_activity',
    parameters: {
      map_id: params.mapId,
      start_date: params.startDate,
      end_date: params.endDate,
      query: params.term,
      sort: params.sort ?? 'engagement',
    },
  };
}

export async function fetchHgRelevantMapNodes(
  query: HgBasicQueryParams
): Promise<HgRelevantMapNodesData | undefined> {
  try {
    return await cachify(
      () =>
        fetchFromCoreApi<HgRelevantMapNodesData>(
          'POST',
          '/intel_search',
          serializeRelevantMapNodesParams(query)
        ),
      ['intel_search', 'relevant_map_nodes', query]
    );
  } catch (e) {
    throw new Error('HG Relevant Map Nodes fetch error');
  }
}

export function serializeRelevantMapNodesParams(params: HgBasicQueryParams) {
  return {
    type: 'relevant_map_nodes',
    parameters: {
      map_id: params.mapId,
      start_date: params.startDate,
      end_date: params.endDate,
      query: params.term,
      sort: params.sort ?? 'engagement',
    },
  };
}
