import * as Sentry from '@sentry/nextjs';
import { differenceInDays, subDays } from 'date-fns';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import { createVoyagerToast } from '~/components';
import {
  totalMapActivityGroup,
  useActivityChart,
} from '~/components/elements/Editor/plugins/dynamic-insights';
import {
  Analytics,
  useWidgetAnalyticsEvent,
  useWidgetAnalyticsParamsChangeEvent,
} from '~/lib/analytics';
import { useInsightContext } from '~/lib/contexts';
import {
  downloadActivityChartCsv,
  getActivityChartSeries,
  sortGroupsByMaxCount,
} from '~/lib/hypergraph';
import {
  SegmentTreeDefaults,
  SegmentTreeProvider,
  compressTreeState,
  useCreateSegmentTree,
  useSubscribeSegmentTree,
  type SegmentSelection,
} from '~/lib/stores/segment-tree';
import { shallowEquals } from '~/lib/utils';
import { MapCluster, MapGroup } from '~/types/graphika-types';
import { fetchWidgetExport } from '~/lib/dynamic-widget-exports/client';
import { DynamicInsightCaption } from '../DynamicInsightCaption';
import { ModifiedParamsWarning } from '../ModifiedParamsWarning';
import { PostsSidebarWrapper } from '../posts';
import { QueryBuilder } from '../QueryBuilder';
import { WidgetUiWrapper } from '../WidgetUiWrapper';
import { ActivityChart } from './ActivityChart';

const segmentTreeDefaults: SegmentTreeDefaults = {
  root: { active: true, isOpen: false },
  group: { active: true, isOpen: false },
  cluster: { active: false },
};

export const ActivityChartWrapper = forwardRef<HTMLDivElement, {}>(
  function ActivityChartWrapper(props, ref) {
    const { toast } = createVoyagerToast();
    const useSegmentTree = useCreateSegmentTree();
    const segmentTree = useSegmentTree();
    const {
      params,
      setParams,
      isValidParams,
      data,
      refetch,
      segments,
      setSegments,
      status,
      mode,
      getSnapshotId,
      caption,
      presets,
    } = useActivityChart();
    const insight = useInsightContext();

    const dataSeries = useMemo(
      () => (data ? getActivityChartSeries(data) : {}),
      [data]
    );

    useWidgetAnalyticsEvent({
      insight,
      params,
      widgetType: 'activity_chart',
    });
    useWidgetAnalyticsParamsChangeEvent({
      insight,
      params,
      mode,
      widgetType: 'activity_chart',
    });

    const groups: MapGroup[] = useMemo(
      () =>
        sortGroupsByMaxCount(
          (data?.groups ?? []).map((group, i) => ({
            ...group,
            group_number: i,
          })),
          dataSeries
        ),
      [data?.groups, dataSeries]
    );
    const clusters: MapCluster[] = useMemo(
      () =>
        (data?.clusters ?? []).map((cluster, i) => ({
          ...cluster,
          cluster_number: i,
          hex_color:
            groups.find((group) => cluster.group_id === group.id)?.hex_color ??
            '000',
        })),
      [data?.clusters, groups]
    );

    useEffect(() => {
      if (isValidParams) {
        refetch({ insightId: insight.id });
      }
    }, [isValidParams, params, refetch, insight]);

    useEffect(() => {
      if (status === 'error' && !toast.isActive('core-api-error')) {
        toast({
          title: 'Error fetching data',
          status: 'error',
          id: 'core-api-error',
        });
        Sentry.captureException(new Error(`CORE API ERROR`), {
          extra: { params: JSON.stringify(params) },
        });
      }
    }, [status, params, toast]);

    const initialSegments = useRef<SegmentSelection | undefined>(segments);

    useEffect(() => {
      if (!groups.length || !clusters.length) return;
      // on map change load new map groups with default state
      if (params.mapId !== previousMapId.current) {
        segmentTree.load({
          groups: [...groups],
          clusters,
          defaults: segmentTreeDefaults,
        });
        previousMapId.current = params.mapId;
      }
      if (!initialSegments.current) return;
      segmentTree.load({
        groups: [...groups, totalMapActivityGroup],
        clusters,
        defaults: segmentTreeDefaults,
      });
      segmentTree.restoreState(initialSegments.current);
      initialSegments.current = undefined;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups, clusters, segmentTree.load, segmentTree.reset]);

    useSubscribeSegmentTree(
      (current, prev) => {
        if (initialSegments.current || !groups.length || !clusters.length)
          return;

        const currentCompressed = compressTreeState(
          current,
          segmentTreeDefaults
        );
        const prevCompressed = compressTreeState(prev, segmentTreeDefaults);
        if (!shallowEquals(currentCompressed, prevCompressed))
          setSegments(compressTreeState(current, segmentTreeDefaults));
      },
      [groups, clusters, setSegments],
      useSegmentTree
    );

    const showTotalActivity =
      segmentTree.groups[totalMapActivityGroup.id]?.active;
    const activeSegments = useMemo(
      () => [
        ...(showTotalActivity ? [totalMapActivityGroup] : []),
        ...groups
          .filter((group) => segmentTree.groups[group.id]?.active)
          .map((group) => ({ ...group, id: `group_${group.id}` })),
        ...clusters
          .filter((cluster) => segmentTree.clusters[cluster.id]?.active)
          .map((cluster) => ({ ...cluster, id: `cluster_${cluster.id}` })),
      ],
      [
        groups,
        clusters,
        segmentTree.groups,
        segmentTree.clusters,
        showTotalActivity,
      ]
    );

    const activeGroups = useMemo(
      () => [
        ...(showTotalActivity ? [totalMapActivityGroup] : []),
        ...groups.filter((group) => segmentTree.groups[group.id]?.active),
      ],
      [groups, segmentTree.groups, showTotalActivity]
    );

    const handleCsvDownload = async () => {
      const toastId = toast({
        title: 'Generating for download... \n Please wait',
        status: 'info',
        isClosable: false,
        duration: null,
      });

      Analytics.event('widget', {
        action: 'csv_download',
        widgetType: 'activity_chart',
        insight: insight.title,
        insight_id: insight.id.toString(),
      });

      if (!isValidParams) return;
      const blob = await fetchWidgetExport('activityChart', {
        insightId: insight.id,
        params: params as Required<typeof params>,
        snapshotId: getSnapshotId() ?? '',
        caption,
        presets,
        segments,
      });
      const a = document.createElement('a');
      a.setAttribute('href', URL.createObjectURL(blob));
      a.setAttribute('download', `${insight.title}.png`);
      a.click();
      a.remove();

      downloadActivityChartCsv({
        data: dataSeries,
        groups: activeGroups,
        insight,
      });

      toast.close(toastId);
    };

    const originalParams = useRef(params);
    const originalSegments = useRef(segments);
    const previousMapId = useRef(params.mapId);
    const isOriginalParams = shallowEquals(params, originalParams.current);

    const setOriginalParams = () => {
      Analytics.event('widgetQuery', {
        action: 'reset',
        widgetType: 'activity_chart',
      });
      setParams(originalParams.current);
      segmentTree.load({
        groups: [totalMapActivityGroup, ...groups],
        clusters,
        defaults: segmentTreeDefaults,
      });
      segmentTree.restoreState(originalSegments.current);
    };

    const isExploring = mode === 'reader' && !isOriginalParams;

    return (
      <WidgetUiWrapper
        ref={ref}
        mode={mode}
        handleCsvDownload={handleCsvDownload}
        isOriginalParams={isOriginalParams}
      >
        <SegmentTreeProvider value={useSegmentTree}>
          {isExploring && <ModifiedParamsWarning onReset={setOriginalParams} />}
          <QueryBuilder
            widget={useActivityChart}
            insightId={insight.id}
            groups={groups}
            showDatesWithoutSearchTerm
            withActiveOption
            withTotalActivityOption
            originalTerm={originalParams.current.term}
          />

          <ActivityChart
            dataSeries={dataSeries}
            activeSegments={activeSegments}
            activeGroups={activeGroups}
            mode={mode}
          />
          <DynamicInsightCaption widget={useActivityChart} />
        </SegmentTreeProvider>
        <PostsSidebarWrapper useWidgetHook={useActivityChart} />
      </WidgetUiWrapper>
    );
  }
);
