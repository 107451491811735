import type { Platform } from '~/types/graphika-types';
import { Box, FaIcon } from '~/components';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type Props = Partial<FontAwesomeIconProps> & {
  platform: Platform;
};

const iconName: Record<Platform, IconProp> = {
  facebook: 'facebook',
  telegram: 'telegram',
  twitter: 'x-twitter',
  vkontakte: 'v',
  youtube: 'youtube',
};

const iconColor: Record<Platform, string> = {
  facebook: '#1877f2',
  telegram: '#0088cc',
  twitter: '#000',
  vkontakte: '#000',
  youtube: '#ff0000',
};

export function PlatformIcon({ platform, ...rest }: Props) {
  if (!platform) return null;
  return (
    <FaIcon
      // @ts-ignore
      icon={['fab', iconName[platform]]}
      color={iconColor[platform]}
      size="sm"
      {...rest}
    />
  );
}
