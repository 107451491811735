import type { ReactElement } from 'react';
import type { ChakraProps } from '@chakra-ui/react';
import { chakra, forwardRef, useColorModeValue } from '@chakra-ui/react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
// @ts-ignore
import { byPrefixAndName, IconLookup } from '@awesome.me/kit-4a3b98357f/icons';
import { colors } from '~/styles';

type IconProps = ChakraProps & {
  icon: () => React.ReactNode;
};

export const Icon = forwardRef<IconProps, 'svg'>((props, ref): ReactElement => {
  const { boxSize = '2rem', icon, fill = 'currentColor', ...rest } = props;
  return (
    <chakra.svg
      ref={ref}
      as="svg"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      boxSize={boxSize}
      fill={fill}
      {...(rest as any)}
    >
      {typeof icon === 'function' ? icon() : icon}
    </chakra.svg>
  );
});

type IconPrefix = IconLookup.IconPrefix;
type IconName = IconLookup.IconName;

export const FaIcon = (props: FontAwesomeIconProps) => {
  const icon = props.icon as IconName | [IconPrefix, IconName];
  const iconName = typeof icon === 'string' ? icon : (icon[1] as IconName);
  const iconType = typeof icon === 'string' ? 'fas' : (icon[0] as IconPrefix);
  return (
    <FontAwesomeIcon {...props} icon={byPrefixAndName[iconType][iconName]} />
  );
};
