import type { ActivityDataSeries } from '~/lib/hypergraph';
import type { MapCluster, MapGroup } from '~/types/graphika-types';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory';
import { Box } from '~/components';
import { useActivityChart } from '~/components/elements/Editor/plugins';
import { Analytics } from '~/lib/analytics';
import { formatNumber } from '~/lib/numbers';
import { usePostUrlQueryParams } from '~/lib/hooks';
import { isOutsideLiveCoreDataWindow } from '~/lib/date';
import { ChartGroupLegend, ChartTooltip, theme } from './index';
import { NoDataChart } from './NoDataChart';

const stroke = 'transparent';
const strokeWidth = 2;

type MapSegment = MapGroup | MapCluster;

export type ChartNodeHoverInfo = {
  segment: MapGroup | MapCluster;
  x: number;
  y: number;
  date: string;
  count: number;
};

type Props = {
  dataSeries?: ActivityDataSeries;
  activeSegments: MapSegment[];
  activeGroups: MapGroup[];
  mode?: 'reader' | 'author';
};

export const ActivityChart = forwardRef<HTMLDivElement, Props>(
  function ActivityChart({ dataSeries, activeSegments, activeGroups }, ref) {
    const { params, getSnapshotId, widgetId } = useActivityChart();
    const chartRef = useRef<HTMLDivElement>(null!);
    useImperativeHandle(ref, () => chartRef.current); // https://stackoverflow.com/questions/62238716/using-ref-current-in-react-forwardref

    const [hoveredSegment, setHoveredSegment] =
      useState<ChartNodeHoverInfo | null>(null);
    const [urlQueryParams, setUrlQuery] = usePostUrlQueryParams();
    const [selectedDatePoint, setSelectedDatePoint] = useState<{
      date: string;
      count: number;
      segment: MapSegment;
    }>();
    const domain = useMemo(() => {
      const filteredCount = activeSegments
        .map((segment) =>
          (dataSeries?.[segment.id] ?? []).map((date) => date.count ?? 0)
        )
        .flat();
      return [0, Math.max(...(filteredCount ?? []), 1)];
    }, [dataSeries, activeSegments]);

    useEffect(() => {
      const isShowingPostSidebar = urlQueryParams.widgetId === widgetId;
      const isSegmentLineSelected =
        urlQueryParams.start_date !== urlQueryParams.end_date;
      const isDifferentSegment =
        selectedDatePoint?.segment.id !== urlQueryParams.segment_id;
      if (
        !isShowingPostSidebar ||
        isSegmentLineSelected ||
        isDifferentSegment
      ) {
        setSelectedDatePoint(undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlQueryParams]);

    const chartWidth = chartRef?.current?.clientWidth ?? 0;
    const chartHeight = chartWidth * 0.4;
    // the post sidebar is synced to the URL search params in this function
    const handlePointClick = (props: any, segment: MapSegment) => {
      if (isOutsideLiveCoreDataWindow(params)) return;
      Analytics.event('postWidget', {
        action: 'ac_group_day_selection',
        label: segment.name,
      });
      setSelectedDatePoint({
        date: props.datum?.date,
        count: props.datum?.count,
        segment,
      });
      const { mapId, term, sort } = params;
      setUrlQuery(
        {
          end_date: props.datum.date.slice(0, 10),
          map_id: mapId,
          query: term,
          segment_id: segment.id,
          start_date: props.datum.date.slice(0, 10),
          widgetId,
          sort,
        },
        'replaceIn'
      );
    };
    const handleLineClick = (segment: MapSegment) => {
      Analytics.event('postWidget', {
        action: 'ac_group_line_selection',
        label: segment.name,
      });
      setSelectedDatePoint(undefined);
      const { startDate, endDate, mapId, term, sort } = params;
      setUrlQuery(
        {
          end_date: endDate,
          map_id: mapId,
          segment_id: segment.id,
          start_date: startDate,
          query: term,
          widgetId,
          sort,
        },
        'replaceIn'
      );
    };

    const hasData = Object.keys(dataSeries ?? {}).length > 0;
    const visibleSegments =
      urlQueryParams.widgetId === widgetId && urlQueryParams.segment_id
        ? activeSegments.filter(
            (segment) => segment.id === urlQueryParams.segment_id
          )
        : activeSegments;

    return (
      <Box pt={2} ref={chartRef} w="100%" position="relative">
        {!hasData || !dataSeries ? (
          <NoDataChart chartHeight={chartHeight} />
        ) : (
          <VictoryChart
            theme={theme}
            width={chartWidth}
            height={chartHeight}
            padding={{
              left: domain[1] > 100000 ? 36 : 30,
              bottom: 18,
              right: 15,
              top: 4,
            }}
          >
            <VictoryAxis
              fixLabelOverlap
              tickCount={5}
              tickFormat={(x: string) => (x.length >= 10 ? x.slice(5, 10) : '')}
              style={{
                grid: {
                  stroke: 'transparent',
                },
                ticks: { size: 5, stroke: 'transparent' },
              }}
            />
            <VictoryAxis
              dependentAxis
              tickFormat={(y: string) => formatNumber(y, { format: 'COMPACT' })}
              style={{
                axis: {
                  stroke: 'transparent',
                },
                ticks: {
                  size: 5,
                  stroke: 'transparent',
                },
              }}
              domain={[0, domain[1]]}
            />
            {visibleSegments.map((segment) => (
              <VictoryLine // actual chart line
                data={dataSeries[segment.id]}
                key={segment.id}
                x="date"
                y="count"
                style={{
                  data: {
                    stroke: `#${segment.hex_color}`,
                    opacity: !!(
                      hoveredSegment &&
                      hoveredSegment.segment.name !== segment.name
                    )
                      ? 0.3
                      : 1,
                    strokeDasharray:
                      segment.id === 'group_Total Map Activity'
                        ? '3'
                        : undefined,
                  },
                }}
              />
            ))}
            {visibleSegments.map((segment) => (
              <VictoryLine // clickable transparent line that triggers segment selection
                data={dataSeries[segment.id]}
                key={segment.id + 'transparent'}
                x="date"
                y="count"
                style={{
                  data: {
                    stroke: `transparent`,
                    strokeWidth: 10,
                    cursor: 'pointer',
                  },
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            callback() {
                              handleLineClick(segment);
                            },
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            ))}
            {visibleSegments.map((segment, i) => (
              <VictoryScatter
                data={dataSeries[segment.id]}
                key={segment.id}
                x="date"
                y="count"
                size={4}
                style={{
                  data: {
                    strokeWidth,
                    stroke,
                    fill: 'transparent',
                    cursor: 'pointer',
                  },
                }}
                events={[
                  {
                    target: 'data',
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            mutation(props) {
                              handlePointClick(props, segment);
                            },
                          },
                        ];
                      },
                      onMouseEnter: (e) => {
                        // @ts-ignore
                        const { clientX, clientY } = e;
                        e.preventDefault();
                        e.stopPropagation();
                        return [
                          {
                            mutation: (props) => {
                              setHoveredSegment({
                                segment,
                                x: clientX,
                                y: clientY,
                                date: props.datum.date,
                                count: props.datum.count,
                              });
                              return {
                                style: {
                                  fill: `#${segment.hex_color}`,
                                  strokeWidth,
                                  stroke,
                                  cursor: 'pointer',
                                },
                              };
                            },
                          },
                        ];
                      },
                      onMouseLeave: (e) => {
                        setHoveredSegment(null);
                        e.preventDefault();
                        e.stopPropagation();
                        return [
                          {
                            mutation: () => {
                              return {
                                style: {
                                  fill: 'transparent',
                                  strokeWidth,
                                  stroke,
                                },
                              };
                            },
                          },
                        ];
                      },
                    },
                  },
                ]}
              />
            ))}
            {!!selectedDatePoint && (
              <VictoryScatter
                data={[
                  {
                    date: selectedDatePoint.date,
                    count: selectedDatePoint.count,
                  },
                ]}
                x="date"
                y="count"
                size={4}
                style={{
                  data: {
                    strokeWidth,
                    stroke,
                    fill: `#${selectedDatePoint.segment.hex_color}`,
                    cursor: 'pointer',
                  },
                }}
              />
            )}
          </VictoryChart>
        )}
        {hoveredSegment && (
          <Box
            position="fixed"
            top={hoveredSegment.y - 110}
            left={hoveredSegment.x - 75}
          >
            <ChartTooltip hoveredSegment={hoveredSegment} />
          </Box>
        )}
        <ChartGroupLegend
          groups={activeGroups}
          hoveredSegment={hoveredSegment}
        />
      </Box>
    );
  }
);
