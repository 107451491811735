import type { FunctionComponent, ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { TargetType } from '~/types/signal-types';

import { Box, ErrorBoundary } from '~/components';
import { getTargetType } from '~/lib/signals';
import {
  SignalAccount,
  SignalFallback,
  SignalHashtag,
  SignalImage,
  SignalPost,
  SignalUrl,
  SignalVideo,
} from './';

type Props = { signal: Signal; width?: number };

const componentByType: Record<
  TargetType,
  FunctionComponent<{ signal: Signal }>
> = {
  account: SignalAccount,
  user: SignalAccount,
  username: SignalAccount,
  hashtag: SignalHashtag,
  url: SignalUrl,
  domain: SignalUrl,
  post: SignalPost,
  image: SignalImage,
  video: SignalVideo,
  unknown: SignalFallback,
};

const SignalCardByType = ({ signal }: { signal: Signal }) => {
  const targetType = getTargetType(signal);
  const Card = componentByType[targetType] ?? SignalFallback;
  if (signal.full_target?.type === 'unknown') {
    return <SignalFallback signal={signal} key={signal.signal_value} />;
  }
  return <Card signal={signal} />;
};

export function SignalCard({ signal, width }: Props): ReactElement {
  return (
    <Box w={width ?? 480}>
      <ErrorBoundary
        fallback={() => (
          <SignalFallback signal={signal} key={signal.signal_value} />
        )}
      >
        <SignalCardByType signal={signal} key={signal.signal_value} />
      </ErrorBoundary>
    </Box>
  );
}
