import type { ReactNode, ReactElement } from 'react';
import { Component } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react';

type Props = {
  children: ReactNode;
  fallback?: ({ onReset }: { onReset: () => void }) => ReactElement;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch() {
    // console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      const FallbackComponent = this.props.fallback || DefaultFallback;
      return (
        <FallbackComponent onReset={() => this.setState({ hasError: false })} />
      );
    }

    return this.props.children || <></>;
  }
}

function DefaultFallback({}: { onReset: () => void }) {
  return (
    <Alert
      status="error"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      height="150px"
      maxWidth="unset"
    >
      <AlertIcon boxSize="30px" mr={0} />
      <AlertTitle mt={4} mb={1} fontSize="lg">
        Local Error
      </AlertTitle>
      <AlertDescription maxWidth="sm">
        Could not render this section.
      </AlertDescription>
    </Alert>
  );
}
