// copied and modified from victory-core/lib/victory-theme/material.js
import type { VictoryThemeDefinition } from 'victory';

import { colors } from '~/styles';

/**
 * Colors
 */
const gray = '#C6C7CA';
const textGray = colors.warmGray[2];

/**
 * Typography
 */
const sansSerif = "'Roboto', sans-serif";
const letterSpacing = 'normal';
const fontSize = 12;

// Layout

const padding = 0;
const baseProps = {
  padding: 20,
};

// Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize: fontSize,
  letterSpacing: letterSpacing,
  padding: padding,
  fill: textGray,
  stroke: 'transparent',
  strokeWidth: 0,
};
const centeredLabelStyles = Object.assign(
  {
    textAnchor: 'middle',
  },
  baseLabelStyles
);

// Strokes
const strokeDasharray = '4 7';
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export const theme: VictoryThemeDefinition = {
  area: Object.assign(
    {
      style: {
        data: {
          fill: gray,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  axis: Object.assign(
    {
      style: {
        axis: {
          fill: 'transparent',
          stroke: gray,
          strokeWidth: 2,
          strokeLinecap: strokeLinecap,
          strokeLinejoin: strokeLinejoin,
        },
        axisLabel: Object.assign({}, centeredLabelStyles, {
          padding: padding,
          stroke: 'transparent',
        }),
        grid: {
          fill: 'none',
          stroke: '#5C5C5C',
          strokeWidth: 1,
          strokeDasharray: strokeDasharray,
          strokeLinecap: strokeLinecap,
          strokeLinejoin: strokeLinejoin,
          pointerEvents: 'painted',
        },
        ticks: {
          size: 0,
        },
        tickLabels: Object.assign({}, baseLabelStyles, {
          fill: textGray,
        }),
      },
    },
    baseProps
  ),
  bar: Object.assign(
    {
      style: {
        data: {
          fill: gray,
          padding: padding,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  chart: baseProps,
  line: Object.assign(
    {
      style: {
        data: {
          strokeWidth: 1,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
};
