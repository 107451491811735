import { Box, Flex } from '~/components';
import { colors } from '~/styles';

type Props = {
  chartHeight: number;
};

export function NoDataChart({ chartHeight }: Props) {
  const gray = '#C6C7CA';
  if (!chartHeight) return null;
  return (
    <Flex
      my={4}
      w="100%"
      height={`${chartHeight}px`}
      justify="space-between"
      direction="column"
      pr="15px"
      pl="30px"
    >
      <GridLine />
      <GridLine />
      <GridLine />
      <Box border={`1px solid ${gray}`} />
    </Flex>
  );
}

const GridLine = () => {
  const gray = colors.coolGray[1];
  return (
    <Box h="1px">
      <svg width="100%" height="100%">
        <line
          x1="0"
          y1="0"
          x2="100%"
          y2="0"
          stroke={gray}
          strokeWidth="1"
          strokeDasharray="4 7"
        />
      </svg>
    </Box>
  );
};
