import ContentLoader from 'react-content-loader';
import { PropsWithChildren } from 'react';

export const SkeletonLoader = ({
  children,
  width,
  height,
  viewBox = '',
}: PropsWithChildren<{ width: number; height: number; viewBox?: string }>) => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    viewBox={viewBox}
    backgroundColor="#E6E6E6"
    foregroundColor="#F2F2F2"
  >
    {children}
  </ContentLoader>
);
