import type { MapCluster, MapGroup } from '~/types/graphika-types';
import {
  ActionsDef,
  createStoreContainer,
  defineImmerActions,
} from '~/lib/state-utils';
import { groupBy, objMap } from '~/lib/utils';

export type GroupState = {
  active: boolean | null;
  showLabel?: boolean;
  isOpen: boolean;
};
export type ClusterState = {
  active: boolean;
  showLabel?: boolean;
};

export type State = GroupState & {
  groups: Record<string, GroupState & { clusters: string[] }>;
  clusters: Record<string, ClusterState & { group: string }>;
};

export type SegmentSelection = {
  groups: Record<string, GroupState>;
  clusters: Record<string, ClusterState>;
};

export type SegmentTreeDefaults = {
  root?: GroupState;
  group: GroupState;
  cluster: ClusterState;
};

export type Actions = {
  load(args: {
    groups: MapGroup[];
    clusters: MapCluster[];
    defaults: SegmentTreeDefaults;
  }): void;
  reset(args: SegmentTreeDefaults): void;
  restoreState(args: SegmentSelection): void;
  toggleRoot<K extends keyof GroupState>(args: {
    param: K;
    value?: boolean;
    ignoreCluster?: boolean;
    ignoreIndeterminate?: boolean;
  }): void;
  toggleGroup<K extends keyof GroupState>(args: {
    id: string;
    param: K;
    value?: boolean;
    ignoreCluster?: boolean;
  }): void;
  focusGroup<K extends keyof GroupState>(
    id: string,
    param: K,
    value: boolean
  ): void;
  toggleCluster<K extends keyof ClusterState>(args: {
    id: string;
    param: K;
    value?: boolean;
    ignoreParent?: boolean;
  }): void;
};

const defaultRootState = { active: true, showLabel: false, isOpen: false };
const initialState: State = { ...defaultRootState, groups: {}, clusters: {} };

const actions: ActionsDef<State, Actions> = {
  load(state, { groups, clusters, defaults }) {
    const clustersByGroup = groupBy(clusters, (c) => c.group_id);
    Object.assign(state, defaults.root ?? defaultRootState);
    state.groups = Object.fromEntries(
      groups.map((group) => [
        group.id,
        {
          ...defaults.group,
          clusters: (clustersByGroup[group.id] ?? []).map(
            (cluster) => cluster.id
          ),
        },
      ])
    );
    state.clusters = Object.fromEntries(
      clusters.map((cluster) => [
        cluster.id,
        { ...defaults.cluster, group: cluster.group_id },
      ])
    );
  },
  reset(state, defaults) {
    Object.assign(state, defaults.root ?? defaultRootState);
    Object.values(state.groups).forEach((group) =>
      Object.assign(group, defaults.group)
    );
    Object.values(state.clusters).forEach((cluster) =>
      Object.assign(cluster, defaults.group)
    );
  },
  restoreState(state, saveState) {
    Object.entries(state.groups).forEach(([id, group]) => {
      if (saveState.groups?.[id]) {
        Object.assign(group, saveState.groups[id]);
      }
    });
    Object.entries(state.clusters).forEach(([id, cluster]) => {
      if (saveState.clusters?.[id]) {
        Object.assign(cluster, saveState.clusters[id]);
      }
    });
  },
  toggleRoot(state, { param, value, ignoreCluster, ignoreIndeterminate }) {
    let newValue = !!value;
    if (!ignoreIndeterminate) {
      state[param] === null
        ? false
        : typeof value === 'undefined'
        ? !state[param]
        : value;
    }
    state[param] = newValue;

    Object.keys(state.groups).forEach((id) =>
      actions.toggleGroup(state, { id, param, value: newValue, ignoreCluster })
    );
  },
  toggleGroup(state, { id, param, value, ignoreCluster }) {
    const group = state.groups[id];
    if (!group) throw new Error(`no group with id ${id}`);
    const newValue =
      group[param] === null
        ? false
        : typeof value === 'undefined'
        ? !group[param]
        : value;
    group[param] = newValue;

    if (!ignoreCluster) {
      switch (param) {
        case 'active':
          group.clusters.forEach((id) =>
            actions.toggleCluster(state, {
              id,
              param,
              value: newValue,
              ignoreParent: true,
            })
          );
          break;
        case 'showLabel':
          if (newValue === false)
            group.clusters.forEach((id) =>
              actions.toggleCluster(state, {
                id,
                param,
                value: newValue,
                ignoreParent: true,
              })
            );

          break;
        default:
      }
    }

    switch (param) {
      case 'active':
        state[param] = arrayToActive(
          Object.values(state.groups),
          (g) => !!g[param]
        );
        break;
      case 'isOpen':
        state[param] = Object.values(state.groups).some((g) => g[param]);
        break;
      default:
    }
  },
  focusGroup(state, id, param, value) {
    Object.keys(state.groups).forEach((gid) => {
      if (gid === id) actions.toggleGroup(state, { id, param, value });
      else actions.toggleGroup(state, { id: gid, param, value: !value });
    });
  },
  toggleCluster(state, { id, param, value, ignoreParent }) {
    const cluster = state.clusters[id];
    if (!cluster) throw new Error(`no cluster with id ${id}`);
    const newValue = typeof value === 'undefined' ? !cluster[param] : value;
    cluster[param] = newValue;

    if (ignoreParent) return;
    const group = state.groups[cluster.group];
    const groupClusters = group.clusters.map((id) => state.clusters[id]);
    switch (param) {
      case 'active':
        group[param] = arrayToActive(groupClusters, (c) => !!c[param]);
        state[param] = !newValue
          ? null
          : arrayToActive(
              Object.values(state.groups),
              (g) => g[param] === null || !!g[param]
            );
        break;
      default:
    }
  },
};
const getActions = defineImmerActions(actions);

function arrayToActive<T>(array: T[], test: (t: T) => boolean): boolean | null {
  return array.every(test) || (array.some(test) && null);
}

export const {
  Provider: SegmentTreeProvider,
  useCreateStore: useCreateSegmentTree,
  useStore: useSegmentTree,
  useSubscribe: useSubscribeSegmentTree,
} = createStoreContainer<State & Actions>(
  (...args) => ({ ...initialState, ...getActions(...args) }),
  { name: 'segmentTree' }
);

export function compressTreeState(
  input: SegmentSelection,
  defaults: SegmentTreeDefaults
): SegmentSelection {
  return {
    clusters: objMap(input.clusters, ([id, { active, showLabel }]) => {
      if (
        active === defaults.cluster.active &&
        showLabel === defaults.cluster.showLabel
      )
        return undefined;
      else return [id, { active, showLabel }];
    }),
    groups: objMap(input.groups, ([id, { active, isOpen, showLabel }]) => {
      if (
        active === defaults.group.active &&
        isOpen === defaults.group.isOpen &&
        showLabel === defaults.group.showLabel
      )
        return undefined;
      else return [id, { active, isOpen, showLabel }];
    }),
  };
}
