import type { PropsWithChildren } from 'react';
import {} from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { Box } from '~/components';
import { colors } from '~/styles';

export const SignalCardBg = ({ children }: PropsWithChildren) => {
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const white = useColorModeValue(colors.white, colors.black);
  return (
    <Box
      borderRadius={8}
      p={4}
      border={`1px solid ${gray4}`}
      w="100%"
      bg={white}
    >
      {children}
    </Box>
  );
};
