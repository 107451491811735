import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import { SignalCardBg } from '~/components';
import {
  SignalPostInsta,
  SignalPostReddit,
  SignalPostTwitter,
  SignalUrl,
} from '~/components/signals/cards';
import { getTweetLinkFromId } from '~/lib/platform';
import { getTargetPlatform } from '~/lib/signals';
import { SignalPostFacebook } from './SignalPostFacebook';

type Props = { signal: Signal };

export function SignalPost({ signal }: Props): ReactElement {
  const platform = getTargetPlatform(signal);
  const postSignal = signal as Signal<SignalPostBase>;
  if (!signal.full_target?.entity) {
    throw Error();
  }
  if (platform === 'twitter') {
    return (
      <SignalCardBg>
        <SignalPostTwitter signal={postSignal} />
      </SignalCardBg>
    );
  }
  if (getTweetLinkFromId(signal)) {
    return (
      <SignalCardBg>
        <SignalUrl
          signal={
            { ...signal, signal_value: getTweetLinkFromId(signal)! } as Signal
          }
        />
      </SignalCardBg>
    );
  }
  if (platform === 'instagram') {
    return (
      <SignalCardBg>
        <SignalPostInsta signal={postSignal} />
      </SignalCardBg>
    );
  }
  if (platform === 'facebook') {
    return (
      <SignalCardBg>
        <SignalPostFacebook signal={postSignal} />
      </SignalCardBg>
    );
  }
  if (platform === 'reddit') {
    return (
      <SignalCardBg>
        <SignalPostReddit signal={postSignal} />
      </SignalCardBg>
    );
  }

  return (
    <SignalCardBg>
      <SignalUrl signal={signal as Signal} />
    </SignalCardBg>
  );
}
