import { useEffect, useState } from 'react';
import type {
  Narrative,
  NarrativeComment,
  NarrativeImage,
  SignalWithCommunities,
} from '~/types/graphika-types';
import { addMonths } from 'date-fns';
import { useIsNavigator } from '~/lib/hooks';
import { buildInfiniteQueryHook, hookify, removeKeys } from './utils';
import { useNarrativeFeeds } from './feeds';

const keys = {
  all: ['narratives'] as const,
  narrative: (narrativeId: number) => [...keys.all, narrativeId] as const,
  signals: (narrativeId: number) =>
    [...keys.all, narrativeId, 'signals'] as const,
  comments: (narrativeId: number) =>
    [...keys.all, narrativeId, 'comments'] as const,
  images: (narrativeId: number) => [...keys.all, narrativeId, 'images'],
  narratives: (
    query: object = {
      page_size: 200,
      states: ['draft', 'in review', 'published'],
    } as const
  ) => [...keys.all, query] as const,
  paginatedNarratives: (query?: object) =>
    [...keys.all, 'paginated', query ?? {}] as const,
  locks: (ids?: unknown) =>
    ids ? [...keys.all, 'locks', ids] : [...keys.all, 'locks'],
};
export const narrativeKeys = keys as Readonly<typeof keys>;

export const usePaginatedNarratives = buildInfiniteQueryHook()
  .path('/narratives')
  .method('get')
  .key(({ query }) => keys.paginatedNarratives(removeKeys(query, ['cursor'])))
  .paginate(({ next_url }) => next_url || undefined);

export const { fetch: getSingleNarrative, hook: useSingleNarrative } =
  hookify<Narrative>()
    .path('/narratives/{narrative_id}')
    .method('get')
    .key(({ params: { narrative_id } }) => keys.narrative(narrative_id));

export const { fetch: getNarrativeSignals, hook: useNarrativeSignals } =
  hookify<SignalWithCommunities[]>()
    .path('/narratives/{narrative_id}/signals')
    .method('get')
    .key(({ params: { narrative_id }, query }) => keys.signals(narrative_id));

export const { fetch: getNarrativeComments, hook: useNarrativeComments } =
  hookify<NarrativeComment[]>()
    .path('/narratives/{narrative_id}/comments')
    .method('get')
    .key(({ params: { narrative_id } }) => keys.comments(narrative_id));

export const { fetch: getNarratives, hook: useNarratives } = hookify<{
  data: Narrative[];
  next_url?: string;
}>()
  .path('/narratives')
  .method('get')
  .key(({ query }) => keys.narratives(query));

export const { fetch: getNarrativeImages, hook: useNarrativeImages } = hookify<
  NarrativeImage[]
>()
  .path('/narratives/{narrative_id}/images')
  .method('get')
  .key(({ params: { narrative_id } }) => keys.images(narrative_id));

export const { fetch: getNarrativeLocks, hook: useNarrativeLocks } = hookify()
  .path('/narratives/locks')
  .method('get')
  .key(({ query: { narrative_ids } }) => keys.locks(narrative_ids));

export const fetchAllNarratives = (feedIds: number[], searchQuery: string) => {
  const isNavigator = useIsNavigator();
  const { data: feeds = [] } = useNarrativeFeeds();
  const [narratives, setNarratives] = useState<Narrative[]>([]);
  const [publishedEndTime] = useState(addMonths(new Date(), 3).toISOString());
  const [cursor, setCursor] = useState<string>();
  const q = usePaginatedNarratives(undefined, {
    query: {
      cursor,
      page_size: 200,
      narrative_feed_ids: feedIds || [],
      search_query: searchQuery || undefined,
      states: ['draft', 'in review', 'published'],
      // @ts-ignore
      published_time_end: publishedEndTime,
    },
  });
  useEffect(() => {
    if (!q.data) return;
    const telescopeFeedIds = feeds
      .filter((feed) => feed.type === 'custom')
      .map((feed) => feed.id);
    const pageCount = q.data.pages.length;
    const currentData = q.data.pages[pageCount - 1];
    const searchParams = new URLSearchParams(currentData.next_url);
    const cursor = searchParams.get('cursor');
    setCursor(cursor || '');
    const allNarratives = q.data.pages
      .map((page) => page.data ?? [])
      .flat()
      .filter(
        (narrative) =>
          !isNavigator ||
          !narrative.narrative_feed_ids?.some((id) =>
            telescopeFeedIds.includes(id)
          )
      ) as Narrative[];
    setNarratives(allNarratives);
    q.fetchNextPage();
  }, [q.data?.pages.length, feeds, isNavigator]);
  return { narratives, isLoading: !!cursor };
};
