import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import { BodyText, Box, SubtitleHeader } from '~/components';
import { SignalCardImage } from './';

type Props = { signal: Signal<SignalPostBase> };

export function SignalPostInsta({ signal }: Props): ReactElement {
  const {
    user: { display_name, user_name },
    text,
    title,
    image_url,
  } = signal.full_target.entity!;
  return (
    <Box>
      <SubtitleHeader fontWeight={700} mb={2}>
        {display_name ?? user_name}
      </SubtitleHeader>
      <BodyText>{title ?? text}</BodyText>
      <SignalCardImage
        src={image_url}
        alt={signal.notes || signal.signal_value}
        mt={2}
      />
    </Box>
  );
}
