import { VStack } from '@chakra-ui/react';
import { BodyText, Box } from '~/components';
import { Analytics } from '~/lib/analytics';
import { scrollIntoView } from '~/lib/docs';
import { colors } from '~/styles';

type Props = {
  currentSection: string;
  currentSubsection: string;
  sections: Map<string, string[]>;
  subsections: Map<string, string[]>;
  currentH4?: string;
};

export function SubsectionSidebar({
  currentSection,
  currentSubsection,
  sections,
  subsections,
  currentH4,
}: Props) {
  const displayedSubsections = sections.get(currentSection) ?? [];
  return (
    <Box w="25vw" ml={3}>
      <Box as="nav" position="sticky" top={50} maxW="228px" alignSelf="start">
        <VStack align="left">
          {displayedSubsections.map((subsection, i) => (
            <>
              <BodyText
                key={subsection}
                color={
                  currentSubsection === subsection
                    ? colors.white
                    : colors.warmGray[2]
                }
                cursor="pointer"
                onClick={() => {
                  const targets = document.querySelectorAll(
                    `[data-section-content="${currentSection}"] ~ [data-subsection-content="${subsection}"]`
                  );
                  if (targets) {
                    Analytics.event('docs', {
                      action: 'subsection_nav_click',
                      label: targets[0].textContent ?? '',
                    });
                    scrollIntoView(targets[0]);
                  }
                }}
              >
                {subsection}
              </BodyText>
              {subsection === currentSubsection &&
                !!subsections.get(`${currentSection}__${subsection}`) && (
                  <VStack align="left">
                    {subsections
                      .get(`${currentSection}__${subsection}`)
                      ?.map((h4) => (
                        <BodyText
                          key={h4}
                          pl={1}
                          color={
                            currentH4 === h4 ? colors.white : colors.warmGray[2]
                          }
                          cursor="pointer"
                          onClick={() => {
                            Analytics.event('docs', {
                              action: 'h4_nav_click',
                              label: h4,
                            });
                            scrollIntoView(
                              document.querySelector(
                                `[data-h4-content="${h4}"]`
                              )
                            );
                          }}
                        >
                          {h4}
                        </BodyText>
                      ))}
                  </VStack>
                )}
              {i !== displayedSubsections.length - 1 && (
                <Box h="1px" bg="#2D2556" />
              )}
            </>
          ))}
        </VStack>
      </Box>
    </Box>
  );
}
