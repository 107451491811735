import type { NextRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export type RouteInfo = Pick<NextRouter, 'asPath' | 'pathname' | 'query'>;

export function useRouteHistory() {
  const router = useRouter();
  const [routes, setRoutes] = useState<RouteInfo[]>([]);
  useEffect(() => {
    const { asPath, pathname, query } = router;
    const updatedRoutes = routes;
    if (routes.length === 0) {
      updatedRoutes.push({ asPath, pathname, query });
    } else if (routes[routes.length - 1].asPath !== asPath) {
      updatedRoutes.push({ asPath, pathname, query });
    } else return;
    typeof window !== 'undefined' &&
      window.sessionStorage.setItem(
        'routeHistory',
        JSON.stringify(updatedRoutes)
      );
    setRoutes(updatedRoutes);
  }, [router, routes]);
  return routes;
}

export const getLastPath = (pathname: string): RouteInfo | undefined => {
  if (typeof window !== 'undefined') {
    const routes: RouteInfo[] = JSON.parse(
      sessionStorage.getItem('routeHistory') ?? ''
    );
    return routes.reverse().find((route) => route.pathname === pathname);
  }
  return undefined;
};
