import type { Narrative } from '~/types/graphika-types';
import { useState } from 'react';

export function useContentWarning(narrative: Narrative) {
  const [hasConsented, setHasConsented] = useState(
    !narrative.has_sensitive_content ||
      !!window?.localStorage
        ?.getItem('contentWarning')
        ?.split(',')
        .find((id) => id === narrative.id.toString())
  );
  const giveConsent = () => {
    if (narrative.has_sensitive_content) {
      setHasConsented(true);
      const consented = [
        ...new Set(
          [
            ...(window?.localStorage?.getItem('contentWarning')?.split(',') ??
              []),
            narrative.id.toString(),
          ].sort()
        ),
      ];
      window?.localStorage?.setItem('contentWarning', consented.join());
    }
  };
  return { hasConsented, giveConsent };
}
