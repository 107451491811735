import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { ApiFailure, HttpStatusOK } from '~/lib/api';
import { paths } from '~/types/generated';
import { buildTypedFetch } from './buildTypedFetch';
import {
  JsonBody,
  MultipartForm,
  PathParams,
  QueryParams,
  ResponseByStatus,
  useInvalidateOnSuccess,
} from './shared';

/** Builds a type-safe mutation hook.
 *
 * @param path  url from the spec
 * @param method method from the spec
 * @param builder A function that returns a function that describes how to map hook parameters to the request params
 * @param dependencies An array or function that returns an array of query keys to invalidate when the mutation succeeds.
 * @param options The standard useMutation options parameter
 *
 * @example
 *
 * export const useMergeNarratives = buildMutation(
 *   '/narratives/merge',
 *   'post',
 *   () => (form: { narratives: number[]; title: string }) => ({ body: form }),
 *   [narrativeKeys.narratives()]
 * );
 *
 */
export function buildMutation<
  P extends keyof paths,
  M extends keyof paths[P],
  Builder extends MutationBuilder<P, M, any[], any>,
  Variables = Builder extends MutationBuilder<P, M, any[], infer V> ? V : never
>(
  path: P,
  method: M,
  builder: Builder,
  dependencies?: ((...config: Parameters<Builder>) => QueryKey[]) | QueryKey[],
  options?: UseMutationOptions<
    ResponseByStatus<paths[P][M], HttpStatusOK>,
    ApiFailure,
    Variables
  >
) {
  const fetch = buildTypedFetch(path, method);

  return ((...config) => {
    const _options = useInvalidateOnSuccess(
      typeof dependencies === 'function'
        ? dependencies(...(config as Parameters<Builder>))
        : dependencies ?? [],
      options?.onSuccess
    );

    const mutation = (variables: Variables) => {
      const { params, ...payload } = builder(...config)(variables);
      return fetch(params!)(payload as any).then(({ data }) => data);
    };

    return useMutation(mutation, { ...options, ..._options });
  }) as UseMutationBuilder<P, M, Builder>;
}

type RequestConfig<
  P extends keyof paths,
  M extends keyof paths[P],
  Op = paths[P][M]
> = PathParams<Op, {}, { params: PathParams<Op> }> &
  QueryParams<Op, {}, { query: QueryParams<paths[P][M]> }> &
  JsonBody<Op, {}, { body: JsonBody<Op> }> &
  MultipartForm<Op, {}, { form: FormData }> &
  Partial<{
    params: PathParams<Op>;
    query: QueryParams<paths[P][M]>;
    body: JsonBody<Op>;
    form: FormData;
  }>;

type MutationBuilder<
  P extends keyof paths,
  M extends keyof paths[P],
  Config extends any[],
  Variables
> = (...config: Config) => (variables: Variables) => RequestConfig<P, M>;

type UseMutationBuilder<
  P extends keyof paths,
  M extends keyof paths[P],
  B extends MutationBuilder<P, M, any[], any>
> = B extends MutationBuilder<P, M, infer C, infer V>
  ? (
      ...config: C
    ) => UseMutationResult<
      ResponseByStatus<paths[P][M], HttpStatusOK>,
      ApiFailure,
      V
    >
  : never;
