import type { Signal } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Flex, Icon } from '~/components';
import { signalPlatformToIcon, platformName } from '~/lib/platform';
import { colors } from '~/styles';

type Props = { signal: Signal };

export function PlatformTag({ signal }: Props) {
  const white = useColorModeValue(colors.black, colors.white);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);
  const icon = signalPlatformToIcon(signal);
  const iconColor =
    signal.signal_platform &&
    ['Twitter', 'Email provider'].includes(signal.signal_platform)
      ? white
      : undefined;
  return (
    <Flex
      key={signal.id}
      border={`1px solid ${gray4}`}
      bg={gray6}
      borderRadius={4}
      align="center"
      pr={2}
      pl={1}
      py="1px"
      mb={0.5}
      h="28px"
    >
      {icon ? (
        <Icon icon={icon} boxSize={6} mr={1} fill={iconColor} />
      ) : (
        <Box w={1} />
      )}
      <BodyText>{platformName(signal.signal_platform) || 'N/A'}</BodyText>
    </Flex>
  );
}
