import type { MapGroup } from '~/types/graphika-types';
import type { HgBasicQueryParams } from '~/lib/hypergraph';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { totalMapActivityGroup } from '~/components/elements/Editor/plugins/dynamic-insights/widgets';
import { cachify, fetchFromCoreApi } from '~/lib/api';
import { shallowEquals } from '~/lib/utils';
import { usePostUrlQueryParams } from '~/lib/hooks';

export type PostPlatform = 'twitter' | 'telegram';

export type PostType = {
  cashtags: string[];
  created_at: string;
  creator: PostCreator;
  domains: string[];
  hashtags: string[];
  id: string;
  images: string[];
  mentions: string[];
  platform: PostPlatform;
  quote_of: string;
  reference_count: {
    total: number;
    by_group: Record<number, number>;
  };
  reply_to: string | null;
  repost_of: string | null;
  text: string;
  title: string | null;
  url: string;
  urls: string[];
  videos: string[];
  view_count: number;
};

export type PostCreator = {
  display_name: string;
  group_id: string | null;
  id: string;
  name: string;
  profile_image_url: string;
  in_map_followers_count?: number;
};

export type PostsData = {
  id: string;
  parameters: HgBasicQueryParams;
  insight_id: number;
  type: 'posts';
  version: '1';
  data: {
    groups: Omit<MapGroup, 'group_number'>[];
    map: {
      id: string;
      name: string;
    };
    posts: PostType[];
  };
};

export const useWidgetPosts = (snapshotId: string | undefined) => {
  const [urlQueryParams] = usePostUrlQueryParams();
  const router = useRouter();
  const [data, setData] = useState<PostsData['data'] | undefined>(undefined);
  const [snapshotData, setSnapshotData] = useState<PostsData | undefined>(
    undefined
  );
  const [error, setError] = useState<unknown>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const snapshotParams = snapshotData?.parameters;

  const fetchSnapshot = useCallback(async () => {
    return await cachify(
      () =>
        fetchFromCoreApi<PostsData>(
          'GET',
          `/intel_search_results/${snapshotId}`,
          {
            type: 'post',
          }
        ),
      [
        'intel_search_results',
        'post_snapshot',
        router.query.narrativeId,
        snapshotId,
      ]
    );
  }, [router.query.narrativeId, snapshotId]);

  useEffect(() => {
    if (snapshotData || !snapshotId) return;
    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const initialSnapshotData = await fetchSnapshot();
        setSnapshotData(initialSnapshotData);
      } catch (e: unknown) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [snapshotId, snapshotData, fetchSnapshot]);

  useEffect(() => {
    const fetchWidgetPosts = async () => {
      try {
        setError(undefined);
        setIsLoading(true);
        setData(undefined);
        const { segment_id, widgetId, ...query } = urlQueryParams;
        const n = query.sort === 'influence' ? 20 : undefined;
        const res = await cachify(
          () =>
            fetchFromCoreApi<PostsData['data']>('POST', '/intel_search', {
              type: 'post',
              insight_id: parseInt(router.query.narrativeId as string),
              parameters: {
                ...query,
                sort: query.sort ?? 'engagement',
                n,
              },
            }),
          ['intel_search', 'post', query, n, router.query.narrativeId]
        );
        setData(res);
        setIsLoading(false);
        if (query.sort === 'influence') {
          const resFull = await cachify(
            () =>
              fetchFromCoreApi<PostsData['data']>('POST', '/intel_search', {
                type: 'post',
                insight_id: parseInt(router.query.narrativeId as string),
                parameters: {
                  ...query,
                  sort: query.sort ?? 'engagement',
                },
              }),
            ['intel_search', 'post', query, router.query.narrativeId]
          );
          setData(resFull);
        }
      } catch (e: unknown) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchSnapshotPosts = async () => {
      try {
        setError(undefined);
        setIsLoading(true);
        setData(undefined);
        const res = await fetchSnapshot();
        setData(res?.data);
        setSnapshotData(res);
      } catch (e: unknown) {
        setError(e);
      } finally {
        setIsLoading(false);
      }
    };

    const isSnapshotQuery = isSameQuery(
      urlQueryParams,
      snapshotData?.parameters ?? {}
    );
    if (isSnapshotQuery === undefined) return;
    if (!!urlQueryParams.widgetId) {
      if (isSnapshotQuery) {
        console.log('FETCH SNAPSHOT');
        fetchSnapshotPosts();
      } else {
        console.log('FETCH LIVE');
        fetchWidgetPosts();
      }
    } else {
      setData(undefined);
      setError(undefined);
      setIsLoading(false);
    }
  }, [
    urlQueryParams,
    router.query.narrativeId,
    snapshotId,
    snapshotData?.parameters,
    fetchSnapshot,
  ]);
  const filteredData: PostsData['data'] | undefined = useMemo(() => {
    const { segment_id, account_id } = urlQueryParams;
    if (!data) return undefined;
    if (account_id) {
      const filteredPosts = data?.posts.filter((post) =>
        // TODO: BE still sometimes sends HG annotations, therefor includes
        post.creator.id.includes(account_id)
      );
      return { ...data, posts: filteredPosts };
    } else if (segment_id) {
      const filteredPosts = data.posts.filter((post) => {
        const groupId = getSafeSegmentId(segment_id);
        if (!groupId) return true;
        return (
          // FIXME: `creator.group_id` still includes HG annotations, will be cleaned up on the BE
          post.creator.group_id?.includes(groupId) ||
          !!post.reference_count?.by_group[groupId]
        );
      });
      return { ...data, posts: filteredPosts };
    } else {
      return data;
    }
  }, [data, urlQueryParams]);

  const sortedData: PostsData['data'] | undefined = useMemo(() => {
    if (!filteredData) return undefined;
    const { sort, segment_id } = urlQueryParams;
    const groupId = getSafeSegmentId(segment_id);
    if (!groupId) return filteredData;
    if (sort === 'engagement') {
      return {
        ...filteredData,
        posts: filteredData?.posts.sort(
          (a, b) =>
            b.reference_count?.by_group[groupId] -
            a.reference_count?.by_group[groupId]
        ),
      };
    }
    return filteredData;
  }, [filteredData, urlQueryParams]);

  return { data: sortedData, isLoading, error };
};

export const getSafeSegmentId = (segmentId: string | any | undefined) =>
  segmentId?.includes(totalMapActivityGroup.name) || !segmentId
    ? undefined
    : segmentId.replace('group_', '').replace('cluster_', '');

const isSameQuery = (
  urlQuery: Record<string, any>,
  snapshotQuery: Record<string, any>
) => {
  const irrelevantQp = ['segment_id', 'n', 'widgetId'];
  function cleanQuery(query: Record<string, any>): Record<string, any> {
    return Object.entries(query).reduce(
      (acc: Record<string, any>, [key, value]) => {
        if (
          !irrelevantQp.includes(key) &&
          value !== undefined &&
          value !== null
        ) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );
  }
  if (!Object.keys(snapshotQuery).length) return undefined;
  const cleanUrlQuery = cleanQuery(urlQuery);
  const cleanSnapshotQuery = cleanQuery(snapshotQuery);
  return shallowEquals(cleanUrlQuery, cleanSnapshotQuery);
};
