import {
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from '@chakra-ui/react';

type AvatarVariation = 'filled' | 'initials' | 'empty';

type AvatarProps = ChakraAvatarProps & { variation?: AvatarVariation };
export function Avatar(props: AvatarProps) {
  const { src, name, variation = 'filled', ...rest } = props;
  return (
    <ChakraAvatar
      name={variation === 'empty' ? '' : name}
      src={variation === 'initials' || variation === 'empty' ? '' : src}
      icon={variation === 'empty' ? <svg /> : undefined}
      {...(rest as any)}
    />
  );
}
