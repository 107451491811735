import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type {
  TwitterAccount,
  RedditAccount,
  SignalAccountBase,
} from '~/types/signal-types';
import { VStack, useColorModeValue } from '@chakra-ui/react';
import { BodyText, Link } from '~/components';
import { redditUserScreenName } from '~/lib/platform';
import { getTargetPlatform } from '~/lib/signals';
import { coerceIntoExternalUrl } from '~/lib/urls';
import { colors } from '~/styles';
import { SignalCardUserSection } from './';
import { SignalUrl } from './';

type Props = { signal: Signal };

type AccountInfo = {
  imageUrl?: string;
  name?: string;
  secondaryContent?: string;
  followers?: number;
};

const getAccountInfo = (signal: Signal): AccountInfo => {
  const platform = getTargetPlatform(signal);
  if (platform === 'twitter') {
    const {
      full_target: { entity },
    } = signal as Signal<TwitterAccount>;
    return {
      imageUrl: entity?.profile_image_url,
      name: entity?.display_name,
      secondaryContent: `@${entity?.user_name}`,
      followers: entity?.followers_count,
    };
  }
  if (platform === 'reddit') {
    const redditAccount = signal as Signal<RedditAccount>;
    const {
      full_target: { entity },
    } = redditAccount;
    return {
      imageUrl: entity?.profile_image_url,
      name: entity?.user_name,
      secondaryContent: redditUserScreenName(redditAccount),
    };
  }
  const {
    full_target: { entity },
  } = signal as Signal<SignalAccountBase>;
  return {
    imageUrl: entity?.profile_image_url,
    name: entity?.display_name,
    secondaryContent: entity?.description,
  };
};

export function SignalAccount({ signal }: Props): ReactElement {
  const { imageUrl, name, secondaryContent } = getAccountInfo(signal);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);

  if (name === 'Log into Facebook' || name === 'Login • Instagram') {
    return <SignalUrl signal={signal} />;
  }

  if (!imageUrl && !name && !secondaryContent) {
    return (
      <Link
        href={coerceIntoExternalUrl(signal.signal_value)}
        variant="gray"
        noFocusStyle
        noOfLines={1}
      >
        <BodyText color={gray1}>{signal.signal_value}</BodyText>
      </Link>
    );
  }
  return (
    <VStack align="left" spacing={2}>
      <SignalCardUserSection
        profileImage={imageUrl}
        topContent={name ?? ''}
        bottomContent={secondaryContent}
      />
    </VStack>
  );
}
