import { useMemo, useRef, useState, useCallback } from 'react';
import { VictoryBar, VictoryGroup, VictoryLine, VictoryScatter } from 'victory';
import { Box } from '~/components';
import { DateSpan } from '~/features/monitoring-dashboard/lib/state';
import { formatNumber } from '~/lib/numbers';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';
import { estFormat } from '~/features/monitoring-dashboard/lib/estTime';
import styles from './sparkline.module.css';

type Activity = { time: string; count: number };

type Datum = Activity & {
  maxCount: number;
  _group: number;
  _stack: number;
  _x: number;
  _x1: number;
  _y: number;
};

type Props = {
  activity: Activity[];
  width: number;
  height: number;
  withTooltip?: boolean;
  dateSpan: DateSpan;
};

export function SparkLine({
  activity,
  height,
  width,
  withTooltip,
  dateSpan,
}: Props) {
  const [hoveredDay, setHoveredDay] = useState<Datum>();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [activityData, maxCount] = useMemo(() => {
    const chartData = dateSpan === 1 ? activity : activityByDay(activity);
    const maxCount = Math.max(...chartData.map((value) => value.count));
    return [chartData.map((data) => ({ ...data, maxCount })), maxCount];
  }, [activity, dateSpan]);

  const handleMouseEnter = useCallback((_: any, value: any) => {
    setHoveredDay(value.datum);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoveredDay(undefined);
  }, []);

  const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width ?? 0;
  const barWidth = wrapperWidth / (activityData.length - 1);

  return (
    <Box
      position="relative"
      className={styles.sparkline}
      zIndex={2}
      ref={wrapperRef}
    >
      <VictoryGroup
        padding={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
        height={height}
        width={width}
      >
        <VictoryLine
          data={activityData}
          x="time"
          y="count"
          style={{
            data: {
              stroke: colors.secondary[400],
              strokeWidth: height < 60 ? 0.6 : 1.5,
              pointerEvents: 'none',
            },
          }}
          interpolation="monotoneX"
        />
        {withTooltip && (
          <VictoryBar
            data={activityData}
            style={{
              data: {
                fill: 'transparent',
                width: barWidth,
              },
            }}
            x="time"
            y="maxCount"
            barRatio={1.5}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onMouseEnter: handleMouseEnter,
                  onMouseLeave: handleMouseLeave,
                },
              },
            ]}
          />
        )}
        {hoveredDay && withTooltip && (
          <VictoryLine
            style={{
              data: {
                stroke: '#18A0FB',
                strokeWidth: 0.5,
                pointerEvents: 'none',
              },
            }}
            data={[
              { x: hoveredDay.time, y: 0 },
              { x: hoveredDay.time, y: maxCount },
            ]}
            x={() => hoveredDay._x}
          />
        )}
        {hoveredDay && (
          <VictoryScatter
            data={[{ time: hoveredDay.time, count: hoveredDay.count }]}
            x={() => hoveredDay._x}
            y="count"
            dataComponent={
              withTooltip ? <Tooltip dateSpan={dateSpan} /> : <></>
            }
          />
        )}
      </VictoryGroup>
    </Box>
  );
}

type TooltipProps = Record<string, unknown> & {
  dateSpan: DateSpan;
};

function Tooltip({ dateSpan, ...rest }: TooltipProps) {
  const { x, y, datum } = rest as { x: number; y: number; datum: Activity };
  const leftAligned = x + 90 > 316;
  return (
    <g pointerEvents="none">
      <circle
        cx={x}
        cy={y}
        r="2"
        fill={colors.secondary[400]}
        stroke={colors.white}
        stroke-width={1}
      />
      <g transform={`translate(${x + (leftAligned ? -90 : 8)}, ${y - 28})`}>
        <svg
          width="87"
          height="56"
          viewBox="0 0 87 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="86" height="55" rx="7.5" fill="white" />
          <rect
            x="0.5"
            y="0.5"
            width="86"
            height="55"
            rx="7.5"
            stroke="#E4E4E4"
          />
        </svg>
      </g>
      <text
        transform={`translate(${x + (leftAligned ? -82 : 15)}, ${y - 8})`}
        style={{
          fontFamily: 'Arial',
          fontSize: '12px',
          fill: colors.secondaryText,
        }}
      >
        {dateSpan === 1 ? estFormat(new Date(datum.time), 'mmmam') : datum.time}
      </text>
      <text
        transform={`translate(${x + (leftAligned ? -82 : 15)}, ${y + 14})`}
        style={{
          fontFamily: 'Arial',
          fontSize: '12px',
          fill: colors.text,
        }}
      >
        {formatNumber(datum.count, { format: 'COMPACT' }).toLowerCase()} posts
      </text>
    </g>
  );
}

function activityByDay(activities: Activity[]): Activity[] {
  const dayCounts = new Map<string, number>();
  activities.forEach((activity) => {
    const day = estFormat(activity.time, 'mmm');
    if (!dayCounts.has(day)) {
      dayCounts.set(day, 0);
    }
    dayCounts.set(day, dayCounts.get(day)! + activity.count);
  });
  return Array.from(dayCounts.entries()).map(([day, count]) => ({
    time: day,
    count,
  }));
}
