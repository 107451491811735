import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import boxShadows from '../boxShadows';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const baseStyle = definePartsStyle({
  body: {
    padding: '4px 16px',
  },
  content: {
    borderRadius: '8px',
    border: 'none',
    boxShadow: boxShadows.card,
  },
});

export const Popover = defineMultiStyleConfig({ baseStyle });
