import type { SegmentSelection } from '~/lib/stores/segment-tree';
import { useCallback, useMemo } from 'react';
import { useLazyRef } from '~/lib/hooks/useLazyRef';
import {
  DynamicWidgetCtx,
  useWidgetData,
  useWidgetDispatch,
  useWidgetEventListeners,
  type DynamicWidgetComponent,
  type DynamicWidgetConfig,
  type WidgetPresets,
} from './shared';

export type StaticWidgetRendererProps = {
  config: DynamicWidgetConfig<any, any>;
  component: DynamicWidgetComponent;
  params: Record<string, any>;
  snapshotId: string;
  getSnapshotId: () => string | undefined;
  caption?: string;
  segments?: SegmentSelection;
  presets?: WidgetPresets;
};
export function StaticWidgetRenderer({
  config,
  component: Component,
  params,
  snapshotId,
  caption = '',
  segments = { groups: {}, clusters: {} },
  presets = {},
}: StaticWidgetRendererProps) {
  const isValidParams = useMemo(
    () => config._paramsSchema.safeParse(params).success,
    [config, params]
  );

  const { data, status, execute } = useWidgetData(config, {
    params,
    mode: 'reader',
    getSnapshotId: useCallback(() => snapshotId, [snapshotId]),
    setSnapshotId: useCallback(() => {}, []),
    immediate: false,
  });

  const component = useMemo(
    () => (
      <Component
        ref={(element) => {
          if (element) window.GRAPHIKA_WIDGET_EXPORT_ELEMENT = element;
        }}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config]
  );

  const eventTarget = useLazyRef(() => document.createElement('div')).current;
  const dispatch = useWidgetDispatch(eventTarget);

  useWidgetEventListeners(eventTarget, {
    onError(event) {
      // Handle widget errors here
      console.dir(event.detail.error);
    },
  });

  const getSnapshotId = useCallback(() => snapshotId, [snapshotId]);
  return (
    <div className="static-widget-renderer">
      <DynamicWidgetCtx.Provider
        value={useMemo(
          () => ({
            data,
            status,
            refetch: execute,
            params,
            segments,
            caption,
            presets,
            isValidParams,
            mode: 'reader',
            isSelected: false,
            setParams: () => {},
            setSegments: () => {},
            setCaption: () => {},
            setPresets: () => {},
            dispatch,
            getSnapshotId,
          }),
          [
            data,
            status,
            execute,
            params,
            caption,
            presets,
            segments,
            isValidParams,
            dispatch,
            getSnapshotId,
          ]
        )}
      >
        {component}
      </DynamicWidgetCtx.Provider>
    </div>
  );
}
