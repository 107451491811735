import type { GraphikaObject } from '~/types/graphika-types';
import * as queryString from 'query-string';
import { isTweetId, isTwitterURL } from './social-networks';
import { objMap } from './utils';

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g;

/**
 * removes protocol and `www` from Url
 */
export const truncateDisplayUrl = (url: string) => {
  try {
    const urlObject = new URL(url);
    const { host, pathname, search } = urlObject;
    return `${host}${pathname.replace(/\/$/, '')}${search}`.replace(
      /^www./,
      ''
    );
  } catch {
    return url.replace(/^www./, '').replace(/\/$/, '');
  }
};

export const twitterHashtagUrl = (value: string) =>
  `https://x.com/search?q=${value.replace('#', '%23')}&src=typed_query&f=top`;

export const tweetOrRedditPostUrl = (value: string) =>
  isTwitterURL(value)
    ? value
    : isTweetId(value)
    ? `https://x.com/i/web/status/${value}`
    : `https://reddit.com/${value}`;

export const twitterOrRedditAccountUrl = (id: string) =>
  id.startsWith('/u/')
    ? `https://reddit.com${id}`
    : `https://x.com/intent/user?user_id=${id}`;

export const objectCoerceIntoUrl = (object: GraphikaObject) => {
  const { object_type: type, object_value: value = '' } = object;
  switch (type) {
    case 'hashtag':
      return twitterHashtagUrl(value);
    case 'post':
      return tweetOrRedditPostUrl(value);
    case 'domain':
      return coerceIntoExternalUrl(value);
    case 'url':
      return coerceIntoExternalUrl(value);
    case 'account':
      return twitterOrRedditAccountUrl(value);
    default:
      return coerceIntoExternalUrl(value);
  }
};

/**
 *  adds protocol to Signal URLs without protocol
 */
export const coerceIntoExternalUrl = (url: string) => {
  try {
    new URL(url);
    return url;
  } catch {
    if (url?.indexOf('http') !== 0) {
      const withScheme = `https://${url}`.replace('////', '//');
      return withScheme;
    }
    return url;
  }
};

export const isUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const stripHttps = (url: string) =>
  url.startsWith('https://') ? url.replace('https://', '') : url;

export function readSearchParams<K extends string>(
  ...keys: K[]
): Record<K, string | undefined> {
  const params = queryString.parse(window.location.search);
  return objMap(params, (entry) =>
    keys.includes(entry[0] as any) ? entry : undefined
  ) as any;
}

export function writeSearchParams(
  params: Record<string, any>,
  action: 'replaceState' | 'pushState' = 'replaceState'
): void {
  const newParams = queryString.parse(window.location.search);
  Object.assign(newParams, params);

  const url = new URL(window.location.href);
  url.search = queryString.stringify(newParams);
  window.history[action](null, '', url.toString());
}
