import { useEffect } from 'react';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  hashQueryKey,
} from '@tanstack/react-query';
import { fetchFromApi, HttpMethod, HttpStatusOK } from '~/lib/api';
import { paths } from '~/types/generated';
import {
  PathParams,
  QueryParams,
  ResponseByStatus,
  templateParams,
  TypedKeyGenerator,
} from './shared';

export function buildInfiniteQueryHook() {
  function build<
    P extends keyof paths,
    M extends keyof paths[P],
    ResponseOk = ResponseByStatus<paths[P][M], HttpStatusOK>
  >(
    path: P,
    method: M,
    getKey: TypedKeyGenerator<P, M>,
    getNextUrl: (response: ResponseOk) => string | undefined
  ) {
    return (
      params: PathParams<paths[P][M]>,
      { query }: { query: QueryParams<paths[P][M]> },
      options?: UseInfiniteQueryOptions<ResponseOk>
    ): UseInfiniteQueryResult<ResponseOk> => {
      const initialUrl = templateParams(path, params);
      const key = getKey({ params, query, body: undefined! });
      const queryKeyHash = hashQueryKey([...key, params]);

      const { refetch, ...rest } = useInfiniteQuery<ResponseOk>(
        key,
        ({ pageParam: nextUrl }) => {
          return fetchFromApi<ResponseOk>(
            nextUrl ?? initialUrl,
            method.toString().toUpperCase() as HttpMethod,
            nextUrl ? undefined : query
          ).then((response) => response.data);
        },
        {
          ...options,
          getNextPageParam: getNextUrl,
        }
      );

      useEffect(() => {
        refetch();
      }, [queryKeyHash, refetch]);

      return { refetch, ...rest };
    };
  }

  return {
    path<P extends keyof paths>(path: P) {
      return {
        method<M extends keyof paths[P]>(method: M) {
          return {
            key(getKey: TypedKeyGenerator<P, M>) {
              return {
                paginate(
                  getNextUrl: (
                    response: ResponseByStatus<paths[P][M], HttpStatusOK>
                  ) => string | undefined
                ) {
                  return build(path, method, getKey, getNextUrl);
                },
              };
            },
          };
        },
      };
    },
  };
}
