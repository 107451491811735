import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import {} from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Flex, Link } from '~/components';
import { colors } from '~/styles';
import { getValidSignalLink } from '~/lib/signals';

type Props = { signal: Signal };

export function LegacySignalCard({ signal }: Props): ReactElement {
  const { signal_value } = signal;
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const link = getValidSignalLink(signal);
  return (
    <Box w="100%" mt={-2}>
      <Flex gap={1}>
        <BodyText>Signal:</BodyText>
        {link ? (
          <Link href={link} variant="gray" noFocusStyle noOfLines={1} mb={0}>
            <BodyText color={gray1} overflowWrap="anywhere">
              {link}
            </BodyText>
          </Link>
        ) : (
          <BodyText color={gray1}>{signal_value}</BodyText>
        )}
      </Flex>
    </Box>
  );
}
