import type { PropsWithChildren } from 'react';
import type { TweetData } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import {
  Avatar,
  BodyText,
  Box,
  CaptionText,
  Card,
  Flex,
  Icon,
  Link,
  Spacer,
  Tooltip,
} from '~/components';
import { formatHumanShortDate } from '~/lib/date';
import TwitterLogo from '~/public/platform-icons/Twitter.svg';
import { colors } from '~/styles';

type Props = { tweet: TweetData; hideBody?: boolean };

export function TwitterPostCard({ tweet, hideBody }: Props) {
  const grayText = useColorModeValue(colors.coolGray[3], colors.warmGray[3]);
  const black = useColorModeValue(colors.black, colors.white);
  const { created_at, user, text, id, id_str } = tweet;

  return (
    <Card pb={hideBody ? '2px' : undefined}>
      <Flex direction="column" height="100%" justifyContent="space-between">
        <Box>
          <Flex justifyContent="space-between" alignItems="start">
            <Avatar
              alignSelf="center"
              height="40px"
              name={user.name}
              src={user.profile_image_url}
              width="40px"
            />
            <Flex direction="column" ml={2}>
              <BodyText>
                <strong>{user.name}</strong>
              </BodyText>
              <BodyText color={grayText}>@{user.screen_name}</BodyText>
            </Flex>
            <Spacer />
            <Tooltip bg={colors.brands.twitter} label="Open on X">
              <span>
                <Link
                  basic
                  href={`https://x.com/anyuser/status/${id_str ?? id}`}
                  target="_blank"
                >
                  <Icon boxSize="24px" icon={TwitterLogo} fill={black} />
                </Link>
              </span>
            </Tooltip>
          </Flex>
        </Box>
        {!hideBody && (
          <Flex direction="column" flex={1} mt={2}>
            <BodyText>
              <DecorateTwitterPostText>{text}</DecorateTwitterPostText>
            </BodyText>
            <Spacer />
            <CaptionText color={grayText} fontWeight={400} mt={2}>
              Posted {formatHumanShortDate(new Date(created_at))}
            </CaptionText>
          </Flex>
        )}
      </Flex>
    </Card>
  );
}

/**
 * This may be slightly overdesigned, but we cannot use our Markdown parser
 * here, so we're gonna have to do some of the heavy lifting ourselves.
 * This Component is co-located to the TwitterPostCard Component, as per
 * our coding guidelines, but could be moved into a library.
 */
function DecorateTwitterPostText({ children }: PropsWithChildren<{}>) {
  if (children === '' || typeof children !== 'string') {
    return <span>{children}</span>;
  }
  let keyIdx = 0;
  // Replacing &amp; should be handled on the back-end but isn't.
  const string = children.replace(/&amp;/, '&');
  const matches = [...string.matchAll(/\B[#$@][\w_]+/g)];
  if (!matches) {
    return <span>{children}</span>;
  }
  const elements = [];
  let lastIndex = 0;
  matches.forEach((match) => {
    const { 0: tag, index } = match;
    if (index! > lastIndex) {
      const text = string.substring(lastIndex, index);
      elements.push(<span key={`${text} ${keyIdx++}`}>{text}</span>);
    }

    const prefix = tag[0];
    const tagWithoutPrefix = tag.slice(1);
    const key = `${tag} ${keyIdx++}`;
    if (prefix === '#') {
      elements.push(
        <Link key={key} href={`https://x.com/hashtag/${tagWithoutPrefix}`}>
          {tag}
        </Link>
      );
    } else if (prefix === '$') {
      elements.push(
        <Link key={key} href={`https://x.com/search?q=${tag}`}>
          {tag}
        </Link>
      );
    } else if (prefix === '@') {
      elements.push(
        <Link key={key} href={`https://x.com/${tagWithoutPrefix}`}>
          {tag}
        </Link>
      );
    }
    lastIndex = index! + tag.length;
  });

  if (string.length > lastIndex) {
    const text = string.substring(lastIndex);
    elements.push(<span key={`${text} ${keyIdx++}`}>{text}</span>);
  }

  return <>{elements}</>;
}
