import type { ReactElement } from 'react';
import type { SignalWithCommunities } from '~/types/graphika-types';
import { useColorModeValue } from '@chakra-ui/react';
import { Box, Flex, Tooltip, StatsText, Icon } from '~/components';
import { formatNumber } from '~/lib/numbers';
import { colors } from '~/styles';
import TotalHitsIcon from '~/public/icons/TotalHits.svg';
import CommunicationIcon from '~/public/icons/Communication.svg';

type Props = {
  signal: SignalWithCommunities;
};

export function SignalEngagmentCount({ signal }: Props): ReactElement {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const communityCount = signal.communities?.length ?? 0;
  const hitCount =
    signal.communities?.reduce((prev, curr) => prev + curr.hits, 0) ?? 0;
  if (communityCount === 0) {
    return <Box h="34px" />;
  }
  return (
    <Flex color={gray1} gap={2}>
      <Tooltip label="number of communities">
        <Flex align="center" gap={2}>
          <Icon icon={CommunicationIcon} boxSize={6} />
          <StatsText mr={4} color={gray1}>
            {formatNumber(communityCount)}
          </StatsText>
        </Flex>
      </Tooltip>
      <Tooltip label="total hits">
        <Flex align="center" gap={1}>
          <Icon icon={TotalHitsIcon} boxSize={6} mt={2.5} />

          <StatsText color={gray1}>{formatNumber(hitCount)}</StatsText>
        </Flex>
      </Tooltip>
    </Flex>
  );
}
