import type { ReactElement } from 'react';
import type { BoxProps } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Flex, Icon } from '~/components';
import { colors } from '~/styles';
import WarningIcon from '~/public/icons/WarningTriangle.svg';

type Props = { smallIcon?: true } & BoxProps;

export function ContentWarningText({
  smallIcon,
  ...props
}: Props): ReactElement {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  return (
    <Flex align="center" gap={1} {...(props as any)}>
      <Icon icon={WarningIcon} boxSize={smallIcon ? 7 : 9} />
      <BodyText color={gray1} fontWeight={500}>
        Contains sensitive content
      </BodyText>
    </Flex>
  );
}
