import type { MapInfo } from '~/types/graphika-types';
import { Menu, MenuItem, useColorModeValue } from '@chakra-ui/react';
import { components } from 'react-select';
import { useMemo } from 'react';
import { BaseSelect, BodyText, Box, Flex, Icon } from '~/components';
import { platformIcon } from '~/lib/platform';
import { colors } from '~/styles';

type Props = {
  value: MapInfo | undefined;
  onChange: (map: MapInfo) => void;
  maps: MapInfo[];
  isLoading?: boolean;
};

const MapOption = (props: any) => {
  const { data, setValue, value, ...rest } = props;
  const lightLavender = useColorModeValue(
    colors.g.lightLavender,
    colors.g.plum
  );
  return (
    <MenuItem
      _hover={{
        bg: lightLavender,
      }}
    >
      <Flex
        w="100%"
        align="center"
        gap={2}
        {...rest}
        onClick={() => {
          setValue(value);
        }}
      >
        {data.icon && <Icon icon={data.icon} boxSize={6} fill={colors.black} />}
        <BodyText>{data.label}</BodyText>
      </Flex>
    </MenuItem>
  );
};

export function MapSelect({ value, onChange, maps, isLoading }: Props) {
  const mapOptions = useMemo(() => {
    return maps
      .map((map) => ({
        label: map.name,
        icon: platformIcon(map.type!),
        value: map,
      }))
      .sort(({ label: a }, { label: b }) => (a ?? '').localeCompare(b ?? ''));
  }, [maps]);

  if (isLoading || mapOptions.length === 0) return null;

  const selectedOption = mapOptions.find(
    (option) => option.label === value?.name
  );
  return (
    <Menu>
      <Box minW="50px" maxW="290px" className="hide-svg-on-pdf">
        <BaseSelect
          onChange={(e) => {
            onChange(e.id ? e : e.value);
          }}
          value={selectedOption}
          options={mapOptions}
          components={{ Option: MapOption }}
          placeholder="Select map"
        />
      </Box>
    </Menu>
  );
}
