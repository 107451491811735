import { defineStyle, defineStyleConfig } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';
import textStyles from '../textStyles';

const baseStyle = defineStyle((props) => ({
  ...textStyles.LabelText,
  mb: 0,
  color: mode(colors.warmGray[1], colors.coolGray[5])(props),
}));

export const FormLabel = defineStyleConfig({ baseStyle });
