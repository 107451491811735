import type { PropsWithChildren } from 'react';
import type { FlexProps } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Flex } from '~/components';

export function SlideInSideBar({
  children = '',
  isOpen,
  openWidth = 340,
  render,
  ...props
}: PropsWithChildren<
  FlexProps & {
    isOpen: boolean;
    openWidth?: number;
    render?: Function;
  }
>) {
  return (
    <Flex {...(props as any)}>
      <motion.div
        initial={false}
        animate={{
          marginLeft: isOpen ? 0 : -16,
          opacity: isOpen ? 1 : -1,
          width: isOpen ? openWidth : 0,
        }}
        transition={{ ease: 'easeOut' }}
      >
        {(isOpen && render && render()) || children}
      </motion.div>
    </Flex>
  );
}
