import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle((props) => ({
  label: {
    userSelect: 'none',
    _disabled: { opacity: 0.4 },
  },
  icon: {
    transitionProperty: 'transform',
    transitionDuration: 'normal',
  },
  control: {
    bg: 'transparent',
    border: '1px solid',
    borderRadius: 4,
    borderColor: mode(colors.warmGray[3], colors.coolGray[4])(props),
    color: 'transparent',
    _checked: {
      bg: mode(colors.g.primary, colors.g.light)(props),
      borderColor: mode(colors.g.primary, colors.g.light)(props),
      color: 'white',
      _hover: {
        bg: mode(colors.g.light, colors.g.primary)(props),
        borderColor: mode(colors.g.primary, colors.g.light)(props),
      },
    },
    _hover: {
      borderColor: mode(colors.g.light, colors.g.dark)(props),
      _disabled: {
        borderColor: mode(colors.warmGray[3], colors.coolGray[3])(props),
      },
    },
    _indeterminate: {
      bg: mode(colors.g.primary, colors.g.light)(props),
      borderColor: mode(colors.g.primary, colors.g.light)(props),
      color: 'white',
    },
    _disabled: {
      bg: mode(colors.warmGray[5], colors.coolGray[1])(props),
      borderColor: mode(colors.warmGray[3], colors.coolGray[3])(props),
      color: mode(colors.warmGray[3], colors.coolGray[3])(props),
    },
    _focus: {
      boxShadow: 'outline',
    },
    _invalid: {
      borderColor: colors.status.red,
    },
  },
}));

const mapviewerVariant = definePartsStyle((props) => ({
  control: {
    bg: colors.white,
    border: 'none',
    height: 4,
    width: 4,
    _checked: {
      color: colors.black,
      background: colors.white,
      _hover: {
        background: colors.white,
      },
    },
    _hover: {
      background: colors.white,
    },
    _focus: {
      boxShadow: 'none',
    },
    _indeterminate: {
      color: colors.black,
      bg: colors.white,
      borderColor: 'none',
    },
  },
}));

export const Checkbox = defineMultiStyleConfig({
  baseStyle,
  variants: {
    default: baseStyle,
    mapviewer: mapviewerVariant,
  },
  sizes: {
    sm: {
      control: { h: 3, w: 3 },
      label: { fontSize: 'sm' },
      icon: { fontSize: '0.45rem' },
    },
    md: {
      control: { w: 5, h: 5 },
      label: { fontSize: 'md' },
      icon: { fontSize: '0.625rem' },
    },
    lg: {
      control: { w: 5, h: 5 },
      label: { fontSize: 'lg' },
      icon: { fontSize: '0.625rem' },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'blue',
  },
});
