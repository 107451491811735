import { UnorderedList } from '@chakra-ui/react';
import { ReactElement, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useInView } from 'react-intersection-observer';

type Props<T> = {
  fetchNextPage: () => any;
  data: T[];
  renderItem: (datum: T) => ReactElement;
};

export function InfiniteScroll<T>({
  fetchNextPage,
  data,
  renderItem,
}: Props<T>) {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) fetchNextPage();
  }, [fetchNextPage, inView]);

  return (
    <UnorderedList ml={0} overflow="hidden">
      {data.map(renderItem)}
      <Box ref={ref} h={0} />
    </UnorderedList>
  );
}
