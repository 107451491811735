import type { ReactElement } from 'react';
import { Center, Flex, useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Spinner } from '~/components';
import { colors } from '~/styles';
import { SignalPreview } from '../authoring/modals/signal-modal/preview/SignalPreview';
import {
  useIsFetchingSignals,
  useSignalModal,
} from '../authoring/modals/signal-modal/shared';

export function CurrentSignalPreview(): ReactElement {
  const {
    preview: { currentSignal: signal },
  } = useSignalModal();
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[1]);

  return (
    <Box mx={4} my={2} borderRadius={4}>
      <BodyText color={gray1} textTransform="uppercase" mb={2}>
        Preview
      </BodyText>
      {useIsFetchingSignals() ? (
        <Center bg={gray6} h="64px">
          <Spinner />
        </Center>
      ) : signal?.signal_value ? (
        <SignalPreview signal={signal} isCurrentSignal />
      ) : (
        <Box bg={gray6} h="64px" />
      )}
    </Box>
  );
}
