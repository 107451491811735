import { listAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(listAnatomy.keys);

const baseStyle = definePartsStyle({
  item: { fontSize: '14px' },
});

export const List = defineMultiStyleConfig({ baseStyle });
