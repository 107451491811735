import type { Narrative } from '~/types/graphika-types';
import { createContext, useContext } from 'react';

export type InsightContextType = Narrative;
export const InsightContext = createContext<InsightContextType>(undefined!);
InsightContext.displayName = 'InsightContext';
export const useInsightContext = () => {
  const ctx = useContext(InsightContext);
  if (!ctx)
    throw new Error(
      'This component expects to be rendered inside an `InsightContext`'
    );
  return ctx;
};
