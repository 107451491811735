import type { AnalyticsEvents } from '~/lib/analytics/analyticsEvents';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Box } from '~/components';
import { Analytics } from '~/lib/analytics';

type Props = {
  event: AnalyticsEvents['scrollCustomEvent'];
};

export function AnalyticsScrollObserver({ event }: Props) {
  const { ref, inView } = useInView();
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (inView && !triggered) {
      Analytics.event('scrollCustomEvent', event);
      setTriggered(true);
    }
  }, [event, inView, triggered]);

  return <Box ref={ref} h="1px" />;
}
