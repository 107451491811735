import NextLink from 'next/link';
import { BoxProps, Flex } from '@chakra-ui/react';
import { Analytics } from '~/lib/analytics';
import { colorsV2 as colors } from '~/styles/v2/colorsV2';
import { Body } from './TypographyV2';

const fontSizes = {
  lg: '16px',
  md: '14px',
  sm: '12px',
};

type LinkProps = BoxProps & {
  href: string;
  newTab?: boolean;
  size?: 'lg' | 'md' | 'sm';
  noTextDecoration?: boolean;
};
const internalLinkRegex =
  /(graphika\.com|graphika\.io|-tfree\.vercel\.app|localhost)/;

export function Link(props: LinkProps) {
  const {
    children,
    newTab,
    size = 'md',
    href,
    noTextDecoration = false,
    ...rest
  } = props;
  const isInternal =
    (href.startsWith('/') || internalLinkRegex.test(href)) && !newTab;
  const targetProps = isInternal
    ? {}
    : { target: '_blank', rel: 'noopener noreferrer' };
  const fontSize = fontSizes[size];

  return (
    <Body
      display="inline-block"
      fontSize={fontSize}
      sx={{
        color: colors.link,
        '&:hover': {
          textDecoration: noTextDecoration ? 'none' : 'underline',
          textDecorationColor: colors.link,
        },
        '& > a:visited': {
          color: colors.secondary[600],
        },
      }}
      {...rest}
      onMouseUp={() => {
        if (!isInternal) {
          Analytics.event('link', {
            action: 'external',
            label: href,
          });
        }
      }}
    >
      <NextLink href={href} {...targetProps}>
        {typeof children === 'string' ? (
          children
        ) : (
          <Flex mr={1}>{children}</Flex>
        )}
      </NextLink>
    </Body>
  );
}
