import { produce } from 'immer';
import {
  createContext,
  DependencyList,
  FunctionComponent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createTrackedSelector } from 'react-tracked';
import {
  type AnyZodObject,
  type SafeParseError,
  type SafeParseReturnType,
  type SafeParseSuccess,
  type z,
} from 'zod';
import {
  create,
  Mutate,
  UseBoundStore,
  type StateCreator,
  type StoreApi,
} from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { useLazyRef } from '~/lib/hooks/useLazyRef';
import { capitalize } from './text';

/** Zustand's React integration binds the store to the hook (i.e. the store _is_ the hook) */
export type GStore<T> = UseBoundStore<GStoreApi<T>>;

/** Convenience type for a {@link StoreApi} that has devtools and immer middleware */
export type GStoreApi<T> = Mutate<
  StoreApi<T>,
  [['zustand/devtools', never], ['zustand/immer', never]]
>;

/** Convenience type for a {@link StateCreator} that has devtools and immer middleware */
export type GStateCreator<T, U = T> = StateCreator<
  T,
  [['zustand/devtools', never], ['zustand/immer', never]],
  [],
  U
>;

type CreateStoreOptions = { name?: string };
export function createStore<T>(
  init: GStateCreator<T>,
  options: CreateStoreOptions = {}
): GStore<T> {
  return create(devtools(immer(init), { name: options.name }));
}

type UseCreateStore<T> = (initialState?: GStateCreator<T>) => GStore<T>;
type UseStoreProvider<T> = FunctionComponent<{
  children?: ReactNode | undefined;
  value: GStore<T>;
}>;

type CreateStoreContainerResult<T> = {
  Provider: UseStoreProvider<T>;
  useCreateStore: UseCreateStore<T>;
  useStore: GStore<T>;
  useSubscribe: (
    listener: (state: T, prevState: T) => void,
    deps: DependencyList,
    /** You only need this argument if you're subscribing above the Provider. */
    store?: GStore<T>
  ) => void;
};
export function createStoreContainer<T>(
  defaultInitialState: GStateCreator<T>,
  options: CreateStoreOptions = {}
): CreateStoreContainerResult<T> {
  options.name ||= 'store';

  const useCreateStore = (
    initialState: GStateCreator<T> = defaultInitialState
  ) =>
    useLazyRef(() => {
      const useZustandStore = createStore<T>(initialState, {
        name: options.name,
      });
      const useTrackedStore = createTrackedSelector(useZustandStore);
      return Object.assign(
        function useStore(selector) {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          if (selector) return useZustandStore(useShallow(selector));
          // eslint-disable-next-line react-hooks/rules-of-hooks
          return useTrackedStore();
        } as GStore<T>,
        {
          subscribe: useZustandStore.subscribe.bind(useZustandStore),
          getState: useZustandStore.getState.bind(useZustandStore),
          setState: useZustandStore.setState.bind(useZustandStore),
        }
      );
    }).current;

  const Context = createContext<GStore<T>>(undefined!);
  Context.displayName = capitalize(options.name) + 'Context';
  const Provider: UseStoreProvider<T> = (props) => (
    <Context.Provider value={props.value}>{props.children}</Context.Provider>
  );
  Provider.displayName = capitalize(options.name) + 'Provider';

  return {
    Provider,
    useCreateStore,
    useStore: ((selector: (state: T) => any) =>
      useContext(Context)(selector)) as any,
    useSubscribe: (listener, deps, store) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const useStore = store ?? useContext(Context);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      useEffect(() => useStore.subscribe(listener), [...deps]);
    },
  };
}

export type ActionsDef<TState, TActions> = {
  [K in keyof TActions]: TActions[K] extends (...args: infer U) => void
    ? (state: TState, ...args: U) => void
    : never;
};
/**
 * Use this to define actions without wrapping your state mutations in `set((state) => { ... })`.
 * It returns a function that takes a `StoreApi` and can wrap the actions upon store creation.
 * Here's a quick example:
 * ```ts
 * // Define types for the store
 * type State = { count: number; };
 * type Actions = {
 *   increase(n?: number): void;
 *   decrease(n?: number): void;
 * }
 *
 * // Define action implementations
 * const myActions = defineImmerActions<State, Actions>({
 *   increase(state, n = 1) {
 *     state.count += n;
 *   },
 *   decrease(state, n = 1) {
 *     state.count -= n;
 *   }
 * })
 *
 * // Create a store
 * const myStore = createStore<State & Actions>((set, get, api) => ({
 *   count: 0,
 *   ...myActions(api)
 * }));
 * ```
 */
export function defineImmerActions<TState, TActions>(
  actions: ActionsDef<TState, TActions>
): GStateCreator<
  TState & TActions,
  {
    [K in keyof TActions]: TActions[K] extends (...args: any) => any
      ? TActions[K]
      : never;
  }
> {
  return (set, get, api) =>
    Object.fromEntries(
      Object.entries(actions).map(([key, fn]) => [
        key,
        (...args: any[]) =>
          set((state: any) => (fn as Function)(state, ...args), undefined, key),
      ])
    ) as any;
}

export type Updater<T> = (update: T | ((value: T) => T | void)) => void;
export function useImmerState<T>(initialState: T | (() => T)): [T, Updater<T>] {
  const [state, setState] = useState(initialState);
  const updateState: Updater<T> = useCallback((update) => {
    if (typeof update !== 'function') return setState(update);
    setState((prev) => produce(prev, update as any));
  }, []);
  return [state, updateState];
}

type SafeParseResult<I, O> = SafeParseReturnType<I, O>;
type UseValidStateResult<TSchema extends AnyZodObject, T = z.infer<TSchema>> =
  | [T, Updater<Partial<T>>, true, SafeParseSuccess<z.output<TSchema>>]
  | [Partial<T>, Updater<Partial<T>>, false, SafeParseError<z.input<TSchema>>];

export function useValidState<
  TSchema extends AnyZodObject,
  T = z.infer<TSchema>
>(
  schema: TSchema,
  initialValue: Partial<T> | (() => Partial<T>)
): UseValidStateResult<TSchema, T> {
  const [value, setValue] = useImmerState(initialValue);
  const parsed = useMemo<SafeParseResult<z.input<TSchema>, z.output<TSchema>>>(
    () => schema.safeParse(value),
    [schema, value]
  );
  return [value as T, setValue, parsed.success, parsed as any];
}
