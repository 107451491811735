import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Flex } from '~/components';
import { colors } from '~/styles';
import NoResultsIcon from '~/public/SearchPageEmptyLight.svg';

type Props = {
  message?: string;
};

export function NoResults({ message = 'No results found' }: Props) {
  const filter = useColorModeValue('', 'invert(0.7)');
  return (
    <Flex
      alignItems="center"
      direction="column"
      justify="center"
      my={4}
      p={40}
      h="100%"
    >
      <Box sx={{ filter }} mb={2}>
        {typeof NoResultsIcon === 'function' && <NoResultsIcon />}
      </Box>
      <BodyText
        color={useColorModeValue(colors.warmGray[2], colors.coolGray[4])}
      >
        {message}
      </BodyText>
    </Flex>
  );
}
