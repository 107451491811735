import { TextProps } from '@chakra-ui/react';
import { useMemo } from 'react';
import { PluggableList } from 'unified';
import { useOriginal } from '~/lib/utils';
import { Html } from './Html';
import { Markdown } from './Markdown';

type Props = TextProps & {
  children: string;
  stripTags?: (keyof JSX.IntrinsicElements & string)[];
  truncateLines?: boolean;
  htmlPlugins?: PluggableList;
};

export function ContentRenderer({
  children,
  stripTags,
  truncateLines = false,
  htmlPlugins,
  ...textProps
}: Props) {
  const isHTML = children?.startsWith('<');

  return useMemo(
    () =>
      isHTML ? (
        <Html
          stripTags={stripTags}
          textProps={textProps}
          truncateLines={truncateLines}
          plugins={htmlPlugins}
        >
          {children}
        </Html>
      ) : (
        <Markdown stripTags={stripTags} {...textProps}>
          {children}
        </Markdown>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isHTML, children, useOriginal(textProps), truncateLines]
  );
}
