import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import { ContentRenderer } from '~/components';
import { BodyText, Box } from '~/components';
import { SignalCardUserSection, SignalCreatedAt, SignalCardImage } from './';

type Props = { signal: Signal<SignalPostBase> };

export function SignalPostReddit({ signal }: Props): ReactElement {
  const {
    user: { user_name, profile_image_url },
    text,
    image_url,
    title,
  } = signal.full_target.entity!;
  return (
    <Box>
      <SignalCardUserSection
        profileImage={profile_image_url}
        topContent={user_name}
      />
      <BodyText fontWeight="bold" mb={2}>
        {title}
      </BodyText>
      <ContentRenderer>{text}</ContentRenderer>
      <SignalCardImage
        src={image_url}
        alt={signal.notes || signal.signal_value}
        my={2}
      />
      <SignalCreatedAt signal={signal} />
    </Box>
  );
}
