import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import { useState } from 'react';
import { useColorModeValue, Collapse } from '@chakra-ui/react';
import { BodyText, Box } from '~/components';
import { colors } from '~/styles';

type Props = { signal: Signal };

export function Translation({ signal }: Props): ReactElement {
  const [showTranslation, setShowTranslation] = useState(false);
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const { translation } = signal;
  if (!translation) return <Box />;
  return (
    <Box>
      <Collapse in={showTranslation}>
        <BodyText>{translation}</BodyText>
      </Collapse>
      <BodyText
        color={primary}
        as="button"
        onClick={(e) => {
          e.preventDefault();
          setShowTranslation(!showTranslation);
        }}
        fontSize="14px"
      >
        {showTranslation ? 'Hide' : 'Show'} translation
      </BodyText>
    </Box>
  );
}
