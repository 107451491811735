import type { ReactElement } from 'react';
import type { Signal } from '~/types/graphika-types';
import type { SignalPostBase } from '~/types/signal-types';
import { useColorModeValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { Box, BodyText, Flex } from '~/components';
import { colors } from '~/styles';

type Props = { signal: Signal<SignalPostBase> };

export function SignalCreatedAt({ signal }: Props): ReactElement {
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  if (!signal.full_target.entity) {
    return <Box />;
  }
  const { created_at } = signal.full_target.entity;
  const createdAt = created_at ? new Date(created_at as string) : undefined;
  if (createdAt) {
    return (
      <Flex gap={2} mt={1}>
        <BodyText color={gray1}>{format(createdAt, 'p')}</BodyText>
        <BodyText color={gray1}>•</BodyText>
        <BodyText color={gray1}>{format(createdAt, 'PP')}</BodyText>
      </Flex>
    );
  }
  return <Box />;
}
