import type {
  Props as SelectProps,
  StylesConfig,
  OptionProps,
  SingleValueProps,
  DropdownIndicatorProps,
} from 'react-select/';
import { useState } from 'react';
import ReactSelect, { components } from 'react-select';
import { Box, Flex } from '~/components';
import { Body, FaIcon } from '~/componentsV2';
import { colors, zIndices } from '~/styles/v2';

type SelectVariation = 'default' | 'text' | 'topObjects';

const baseStyles: StylesConfig = {
  control: (styles) => ({
    ...styles,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? colors.neutral[200] : colors.primary[500],
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
  }),
  menu: (styles, state) => ({
    ...styles,
    outline: `1px solid ${colors.outline}`,
    borderRadius: '8px',
    padding: '4px 0px',
    zIndex: zIndices.dropdown,
  }),
  singleValue: (styles, state) => ({
    ...styles,
    fontSize: '14px',
  }),
  container: (styles, state) => ({ ...styles, width: 'auto' }),
  option: (styles, state) => ({
    ...styles,
    color:
      state.isSelected || state.isFocused ? colors.text : colors.secondaryText,
    background: state.isSelected ? colors.neutral[10] : 'inherit',
    cursor: 'pointer',
    ':hover': {
      color: colors.text,
    },
    ':active': {
      background: colors.neutral[25],
    },
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: colors.secondaryText,
  }),
};

const textStyles: StylesConfig = {
  control: (styles, state) => ({
    ...styles,
    borderWidth: 0,
    background: 'inherit',
    boxShadow: 'none',
    fontSize: '14px',
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: colors.primary[500],
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: colors.primary[500],
  }),
};

const variationStyling: Record<SelectVariation, StylesConfig> = {
  default: {
    ...baseStyles,
    control: (styles, state) => {
      return {
        ...styles,
        ...baseStyles['control'],
        background: state.isDisabled ? colors.neutral[50] : colors.surface[0],
        border: `1px solid ${
          state.isDisabled ? colors.neutral[50] : colors.outline
        }`,
        borderRadius: '8px',
      };
    },
    input: (styles, state) => ({
      ...styles,
      color: colors.text,
    }),
    menu: (styles, state) => {
      return {
        ...styles,
        background: colors.white,
        zIndex: zIndices.dropdown,
        fontSize: '14px',
      };
    },
  },
  text: {
    ...baseStyles,
    ...textStyles,
  },
  topObjects: {
    ...baseStyles,
    ...textStyles,
    control: (styles, state) => ({
      ...styles,
      fontSize: '14px',
      border: 'none',
      boxShadow: 'none',
    }),
    singleValue: (styles, state) => ({
      ...styles,
      fontWeight: 500,
    }),
    menu: (styles, state) => {
      return {
        ...styles,
        background: colors.white,
        marginTop: '-4px',
        marginLeft: '4px',
        fontSize: '14px',
      };
    },
  },
};

const OptionTooltip = ({ tooltipText }: { tooltipText: string }) => (
  <Box
    border={`1px solid ${colors.outline}`}
    py={2}
    px={3}
    borderRadius="8px"
    bg={colors.neutral[10]}
    w="200px"
  >
    <Body size="small" textAlign="center">
      {tooltipText}
    </Body>
  </Box>
);

const Option = ({ children, ...props }: OptionProps) => {
  const [tooltipEl, setTooltipEl] = useState<HTMLDivElement | null>(null);

  return (
    <components.Option {...props}>
      {tooltipEl && (
        <Box
          position="fixed"
          top={tooltipEl.getBoundingClientRect().top - 10}
          left={tooltipEl.getBoundingClientRect().left - 220}
        >
          {/* @ts-ignore */}
          <OptionTooltip tooltipText={props.data.tooltip} />
        </Box>
      )}
      <Flex
        align="center"
        gap={2}
        onMouseEnter={(e) => {
          /* @ts-ignore */
          if (!!props.data.tooltip) {
            setTooltipEl(e.target as HTMLDivElement);
          }
        }}
        onMouseLeave={() => {
          setTooltipEl(null);
        }}
      >
        {/* @ts-ignore */}
        {!!props.data.icon && (
          <Box w="22px">
            {/* @ts-ignore */}
            <FaIcon icon={props.data.icon} color="#90909A" />
          </Box>
        )}
        {children}
      </Flex>
    </components.Option>
  );
};

const DropdownIndicator = ({
  variation,
  ...props
}: DropdownIndicatorProps & { variation: SelectVariation }) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaIcon
        icon={variation === 'topObjects' ? 'caret-down' : 'chevron-down'}
        size="sm"
        color={colors.primary[500]}
      />
    </components.DropdownIndicator>
  );
};
const SingleValue = ({ children, ...props }: SingleValueProps) => {
  return (
    <components.SingleValue {...props}>
      <Flex align="center" gap={2}>
        {/* @ts-ignore */}
        {!!props.data.icon && <FaIcon icon={props.data.icon} color="#90909A" />}
        {children}
      </Flex>
    </components.SingleValue>
  );
};

type Props = SelectProps & {
  variation?: SelectVariation;
  w?: string;
};

export function Select({
  variation = 'default',
  w = '100%',
  options,
  placeholder = 'Select option',
  ...rest
}: Props) {
  return (
    <Box cursor={rest.isDisabled ? 'not-allowed' : 'inherit'} w={w}>
      <ReactSelect
        options={options}
        styles={variationStyling[variation]}
        components={{
          Option,
          SingleValue,
          DropdownIndicator: (props) => (
            <DropdownIndicator {...props} variation={variation} />
          ),
        }}
        placeholder={placeholder}
        {...rest}
      />
    </Box>
  );
}
