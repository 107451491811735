import { createStandaloneToast, extendTheme } from '@chakra-ui/react';
import { $insertDataTransferForRichText } from '@lexical/clipboard';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import {
  $insertNodeToNearestRoot,
  mediaFileReader,
  mergeRegister,
} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  createCommand,
  LexicalCommand,
  PASTE_COMMAND,
} from 'lexical';
import { useEffect } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeStringify from 'rehype-stringify/lib';
import { unified } from 'unified';
import { NumberParam, useQueryParam } from 'use-query-params';
import { createVoyagerToast } from '~/components/elements/toast';
import { imageAssetUploader } from '~/lib/html';
import { useAddNarrativeImage } from '~/queries/mutations/narrative';
import { colors, components, global, textStyles } from '~/styles';
import { $createImageNode, ImageNode, ImageNodeParams } from './ImageNode';

const theme = extendTheme({
  colors,
  config: { initialColorMode: 'light' },
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif',
    monospace: 'Roboto Mono, monospace',
  },
  textStyles,
  styles: { global },
  components: { ...components },
});

const { toast } = createVoyagerToast();

export const INSERT_IMAGE_COMMAND: LexicalCommand<ImageNodeParams> =
  createCommand();

export function GImagesPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error(
        'GraphikaImagesPlugin: ImageNode not registered on editor (initialConfig.nodes)'
      );
    }

    return mergeRegister(
      editor.registerCommand<ImageNodeParams>(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          const imageNode = $createImageNode(payload);
          $insertNodeToNearestRoot(imageNode);
          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return null;
}

const allowedImageTypes = ['image/', 'image/gif', 'image/png', 'image/jpeg'];
export function GImagesPastePlugin(): null {
  const narrative_id = Number(useQueryParam('narrativeId', NumberParam)[0]);
  const addImage = useAddNarrativeImage(narrative_id);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        (async () => {
          try {
            const parsedFiles = await mediaFileReader(files, allowedImageTypes);

            for (const { file } of parsedFiles) {
              await addImage.mutateAsync(
                { file },
                {
                  onSuccess(data) {
                    editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                      url: data.image_url!,
                      caption: data.caption!,
                    });
                  },
                }
              );
            }
          } catch (error) {
            toast({
              status: 'error',
              title: 'Image Paste Failed',
              description:
                'Unable to paste the current clipboard image. Please try uploading the image manually instead.',
            });
          }
        })();

        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, addImage]);

  return null;
}

export function GRtfPastePlugin() {
  const narrative_id = Number(useQueryParam('narrativeId', NumberParam)[0]);
  const addImage = useAddNarrativeImage(narrative_id);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (event) => {
        if (!(event instanceof ClipboardEvent) || !event.clipboardData)
          return false;

        const htmlString = event.clipboardData.getData('text/html');
        if (!htmlString) return false;

        (async () => {
          try {
            const result = await unified()
              .use(rehypeParse, { fragment: true })
              .use(imageAssetUploader, {
                async upload(file, nodeProperties) {
                  const caption = nodeProperties?.alt ?? '';
                  const image = await addImage.mutateAsync({ file, caption });
                  return {
                    src: image.image_url!,
                    alt: image.caption!,
                  };
                },
              })
              .use(rehypeStringify)
              .process(htmlString);
            const processedData = new DataTransfer();
            processedData.setData('text/html', result.toString());

            editor.update(
              () => {
                const selection = $getSelection();
                if (!$isRangeSelection(selection)) return;
                event.preventDefault();
                $insertDataTransferForRichText(
                  processedData,
                  selection,
                  editor
                );
              },
              { tag: 'g-paste' }
            );
          } catch (error) {
            toast({
              status: 'error',
              title: 'Paste Failed',
              description: 'Unable to paste the current clipboard contents.',
            });
          }
        })();

        return true;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor, addImage]);

  return null;
}
