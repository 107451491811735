import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as _QueryClientProvider,
  QueryKey,
  QueryState,
} from '@tanstack/react-query';
import {
  PersistedClient,
  Persister,
  PersistQueryClientProvider,
} from '@tanstack/react-query-persist-client';
import {
  clear,
  delMany,
  get,
  getMany,
  keys,
  set,
  setMany,
  values,
} from 'idb-keyval';
import { ReactNode } from 'react';
import { ApiFailure, handleError, handleRetry } from './api';
import { castBoolean } from './utils';

interface DehydratedQuery {
  queryHash: string;
  queryKey: QueryKey;
  state: QueryState;
}
const MAX_PERSISTED_QUERIES = 100;

const persistCache = castBoolean(process.env.NEXT_PUBLIC_PERSIST_CACHE);
const cacheTime = process.env.NEXT_PUBLIC_QUERY_CACHE_TIME
  ? Number(process.env.NEXT_PUBLIC_QUERY_CACHE_TIME)
  : 1000 * 60 * 60 * 24;

const persister = createIndexedDBPersister();
export const QueryClientProvider: (props: {
  children?: ReactNode | undefined;
}) => JSX.Element = persistCache
  ? (props) => (
      <PersistQueryClientProvider
        client={queryClient}
        persistOptions={{ persister }}
        {...(props as any)}
      />
    )
  : (props) => (
      <_QueryClientProvider client={queryClient} {...(props as any)} />
    );

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount: number, error: unknown) => {
        return handleRetry(failureCount, error as ApiFailure);
      },
      staleTime: 30 * 60 * 1000,
      cacheTime,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      handleError(error as ApiFailure, query);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, _, __, mutation) => {
      handleError(error as ApiFailure, undefined, mutation);
    },
  }),
});

type PersistedQueryClientMeta = Pick<PersistedClient, 'buster' | 'timestamp'>;
function createIndexedDBPersister(): Persister {
  const queryKeyPrefix = 'query_';
  const clientMetaKey = 'queryClientPartial';
  const queryLastUpdateAt: Record<string, number | undefined> = {};
  let isPruningDB = false;

  return {
    async persistClient(client) {
      const { clientState, timestamp, buster } = client;
      const { queries } = clientState;

      await set(clientMetaKey, { timestamp, buster });

      const updatedQueries = queries.filter(({ queryHash, state }) => {
        const lastUpdated = queryLastUpdateAt[queryHash];
        queryLastUpdateAt[queryHash] = state.dataUpdatedAt;
        if (!lastUpdated) return true;
        return lastUpdated < state.dataUpdatedAt;
      });
      if (!updatedQueries.length) return;

      const persistedQueryKeys = await keys();
      if (persistedQueryKeys.length > MAX_PERSISTED_QUERIES && !isPruningDB) {
        try {
          isPruningDB = true;
          const queryValues: DehydratedQuery[] = await values();
          const sorted = queryValues
            .filter((query) => query.queryHash)
            .sort((a, b) =>
              a.state.dataUpdatedAt > b.state.dataUpdatedAt ? 1 : -1
            );
          const olderQueryKeys = sorted
            .slice(
              0,
              Math.floor(persistedQueryKeys.length - MAX_PERSISTED_QUERIES) +
                MAX_PERSISTED_QUERIES / 2
            )
            .map((query) => queryKeyPrefix + query.queryHash);
          console.log(
            `pruning the oldest `,
            olderQueryKeys.length,
            ' indexDB entries'
          );
          await delMany(olderQueryKeys);
          isPruningDB = false;
        } catch (e) {
          isPruningDB = false;
        }
      }

      await setMany(
        updatedQueries.map(
          (query) => [queryKeyPrefix + query.queryHash, query] as [string, any]
        )
      );

      if (process.env.NODE_ENV === 'development')
        updatedQueries.forEach((query) => {
          console.log(`updated query: ${query.queryHash}`);
        });
    },
    async restoreClient() {
      const metadata = await get<PersistedQueryClientMeta>(clientMetaKey);
      if (!metadata) return undefined;

      const queryKeys = (await keys()).filter((key) =>
        key.toString().startsWith(queryKeyPrefix)
      );
      const queries = await getMany<DehydratedQuery>(queryKeys);
      queries.forEach((query) => {
        queryLastUpdateAt[query.queryHash] = query.state.dataUpdatedAt;
      });

      if (process.env.NODE_ENV === 'development')
        // console.log(`loaded ${queries.length} queries from indexeddb`);

        return {
          ...metadata,
          clientState: {
            queries,
            mutations: [],
          },
        };
    },
    removeClient() {
      Object.keys(queryLastUpdateAt).forEach(
        (key) => delete queryLastUpdateAt[key]
      );
      clear();
    },
  };
}
