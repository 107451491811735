import type { PropsWithChildren, ReactElement } from 'react';
import type { ModalContentProps, ModalProps } from '@chakra-ui/react';
import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from '@chakra-ui/react';
import { Button, Divider, Flex, TitleHeader } from '~/components';
import { colors } from '~/styles';
import CloseIcon from '~/public/icons/Close.svg';

export function Modal(
  props: PropsWithChildren<{
    body?: ReactElement;
    hideCloseIcon?: boolean;
    footer?: ReactElement | ReactElement[];
    minH?: string;
    minHeight?: string;
    maxW?: string;
    maxWidth?: string;
    showDivider?: boolean;
    title?: string;
    contentProps?: ModalContentProps;
    extraHeader?: ReactElement;
  }> &
    ModalProps
) {
  const {
    body,
    children,
    hideCloseIcon,
    footer,
    minH,
    minHeight,
    maxW,
    maxWidth,
    showDivider,
    title,
    contentProps,
    extraHeader,
    ...modalProps
  } = props;
  const bg = useColorModeValue(colors.white, colors.coolGray[1]);
  const closeIconColor = useColorModeValue(colors.g.primary, colors.g.light);
  return (
    <ChakraModal {...modalProps}>
      <ModalOverlay />
      <ModalContent
        bg={bg}
        borderRadius="16px"
        maxW={maxW || maxWidth}
        mt="10vh"
        minH={minH || minHeight}
        {...(contentProps as any)}
      >
        <ModalHeader p={4}>
          <Flex w="100%" justify="space-between" align="center">
            {title ? <TitleHeader>{title}</TitleHeader> : null}
            {!hideCloseIcon && (
              <Button
                icon={CloseIcon}
                fill={closeIconColor}
                m="-9px"
                onClick={props.onClose}
                tabIndex={-1}
                variant="plain"
              />
            )}
          </Flex>
          {extraHeader}
        </ModalHeader>
        {showDivider && <Divider m={0} />}
        <ModalBody p={4}>{children || body}</ModalBody>
        {footer ? (
          <ModalFooter px={4} gap={2}>
            {footer}
          </ModalFooter>
        ) : (
          <Flex p={1} />
        )}
      </ModalContent>
    </ChakraModal>
  );
}
