import { Avatar, AvatarGroup, Heading, Text } from '@chakra-ui/react';
import type { PropsWithChildren, ReactElement } from 'react';
import {
  BodyText,
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
  TitleHeader,
} from '~/components';
import { colors } from '~/styles';
import AccountCircle from '/public/icons/Account/Circle.svg';
import Hot from '/public/icons/Hot-Topic.svg';
import ArrowRight from '/public/icons/Navigation/Right.svg';
import Down from '/public/icons/Trending/Down.svg';
import Up from '/public/icons/Trending/Up.svg';

// TODO: Add link support once API is finalized.
type Props = {
  name: string;
  description: string;
  activity?: { change: string; increase: number };
  accounts?: Array<{ name?: string; url: string }>;
};

export function LandscapeCard({
  name,
  description,
  activity,
  accounts,
}: Props): ReactElement {
  if (!activity && !accounts) {
    // Adding this 'simple mode' for now. The API is not
    // providing us with all the data we want to display,
    // so we won't try. If the data becomes available,
    // the Component is ready (and we can remove this
    // conditional).
    return (
      <Card>
        <Flex direction="column">
          <Link basic href="/nv">
            <Flex alignItems="center">
              <TitleHeader pr={2}>{name}</TitleHeader>
              <Icon
                height="24px"
                width="24px"
                fill={colors.g.primary}
                icon={ArrowRight}
              />
            </Flex>
          </Link>
          <Divider />
          <Text fontSize="sm" noOfLines={5}>
            {description}
          </Text>
        </Flex>
      </Card>
    );
  }

  return (
    <Card>
      <Flex direction="column">
        <Link basic href="/nv">
          <Flex alignItems="center">
            <TitleHeader pr={2}>{name}</TitleHeader>
            <Icon
              height="24px"
              width="24px"
              fill={colors.g.primary}
              icon={ArrowRight}
            />
          </Flex>
        </Link>
        <Divider />
        {activity ? (
          <CardItem
            iconFill={
              activity.increase > 0 ? colors.status.green : colors.status.red
            }
            icon={activity.increase > 0 ? Up : Down}
          >
            <BodyText>{activity.increase * 100}% activity increase</BodyText>
            <Text
              fontSize="12px"
              margin={0}
              lineHeight="14px"
              color={colors.warmGray[2]}
              isTruncated
            >{`${activity.change} change since yesterday`}</Text>
          </CardItem>
        ) : (
          <CardItem>
            <BodyText fontSize="14px" margin={0}>
              Activity data not available
            </BodyText>
            <Text
              fontSize="12px"
              margin="-3px 0 0 0"
              lineHeight="14px"
              color={colors.warmGray[2]}
              isTruncated
            >
              unknown number of hits since yesterday
            </Text>
          </CardItem>
        )}
        <CardItem iconFill={colors.status.orange} icon={Hot}>
          <Text
            lineHeight="14px"
            noOfLines={2}
            fontSize="12px"
            fontWeight={500}
          >
            <Link href="/nv">{description}</Link>
          </Text>
        </CardItem>
        <Divider />
        <LandscapeCardAccounts accounts={accounts} />
      </Flex>
    </Card>
  );
}

function LandscapeCardAccounts({
  accounts = [],
}: {
  accounts?: Array<{ name?: string; url: string }>;
}): ReactElement {
  return (
    <Box>
      <Heading fontSize="16px" fontWeight="normal" lineHeight="19px">
        Accounts getting attention
      </Heading>
      <AvatarGroup m={1} size="xs" max={5} spacing={1}>
        {!accounts.length && <Text fontSize="xs">None</Text>}
        {accounts.map(({ name, url }) => (
          <Avatar
            key={name + url}
            name={name}
            m={1}
            src={url}
            background="none"
            border="none"
            icon={<Icon fill={colors.warmGray[2]} icon={AccountCircle} />}
          />
        ))}
      </AvatarGroup>
    </Box>
  );
}

type CardItem = PropsWithChildren<{
  icon?: () => ReactElement;
  iconFill?: string;
}>;

function CardItem({ icon, iconFill, children }: CardItem): ReactElement {
  return (
    <Grid my={1} templateColumns="32px 1fr">
      <GridItem alignSelf="center" justifySelf="start">
        {icon && <Icon width="20px" fill={iconFill} icon={icon} />}
      </GridItem>
      <GridItem alignSelf="center">
        <Flex direction="column">{children}</Flex>
      </GridItem>
    </Grid>
  );
}
