import type { SystemStyleObject } from '@chakra-ui/theme-tools';
import { tableAnatomy } from '@chakra-ui/anatomy';
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
import colors from '../colors';
import textStyles from '../textStyles';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyle = definePartsStyle({
  table: {
    fontVariantNumeric: 'lining-nums tabular-nums',
    borderCollapse: 'collapse',
    width: 'full',
  },
  th: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 'wider',
    textAlign: 'start',
  },
  td: {
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    fontFamily: 'heading',
    textAlign: 'center',
    fontWeight: 'medium',
  },
});

const numericStyles: SystemStyleObject = {
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
};

const variantSimple = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      border: `solid ${mode(colors.warmGray[4], colors.coolGray[1])(props)}`,
      borderWidth: '1px 0',
      ...numericStyles,
    },
    td: {
      borderBottom: 0,
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantStripe = definePartsStyle((props) => {
  const { colorScheme: c } = props;

  return {
    th: {
      color: mode('gray.600', 'gray.400')(props),
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    td: {
      borderBottom: '1px',
      borderColor: mode(`${c}.100`, `${c}.700`)(props),
      ...numericStyles,
    },
    caption: {
      color: mode('gray.600', 'gray.100')(props),
    },
    tbody: {
      tr: {
        '&:nth-of-type(odd)': {
          'th, td': {
            borderBottomWidth: '1px',
            borderColor: mode(`${c}.100`, `${c}.700`)(props),
          },
          td: {
            background: mode(`${c}.100`, `${c}.700`)(props),
          },
        },
      },
    },
    tfoot: {
      tr: {
        '&:last-of-type': {
          th: { borderBottomWidth: 0 },
        },
      },
    },
  };
});

const variantSignalList = definePartsStyle((props) => {
  return {
    th: {
      ...textStyles.LabelText,
      textTransform: 'upperCase',
    },
    td: {
      ...textStyles.BodyText,
      padding: '6px',
      color: mode(colors.black, colors.white)(props),
      p: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
      a: {
        overflow: 'hidden',
      },
      fontWeight: 400,
    },
  };
});

const variantObjectTable = definePartsStyle((props) => {
  return {
    ...variantSignalList,
    tr: {
      borderRadius: 16,
      cursor: 'pointer',
      ':hover': {
        background: mode(colors.g.lightLavender, colors.g.plum)(props),
      },
    },
    thead: {
      'tr td, tr td h4': {
        color: mode(colors.warmGray[1], colors.coolGray[5])(props),
        fontSize: '12px',
      },
      'tr:hover': {
        background: 'inherit',
      },
    },
    tbody: {
      borderTop: '1px solid',
      borderColor: mode(colors.warmGray[4], colors.coolGray[1])(props),
      td: {
        padding: '4px 0',
      },
    },
  };
});

const sizes = defineStyle({
  sm: {
    th: { px: '0', py: '2', lineHeight: '3', fontSize: 'xs' },
    td: { px: '0', py: '2', fontSize: 'sm', lineHeight: '3' },
    caption: { px: '4', py: '2', fontSize: 'xs' },
  },
  md: {
    th: { pl: '0', py: '0', lineHeight: '3', fontSize: 'xs', height: '40px' },
    td: { px: '1', py: '3', lineHeight: '4' },
    caption: { px: '6', py: '2', fontSize: 'sm' },
  },
  lg: {
    th: { pl: '0', py: '5', lineHeight: '5', fontSize: 'sm' },
    td: { pl: '0', py: '5', lineHeight: '6' },
    caption: { px: '6', py: '2', fontSize: 'md' },
  },
});

export const Table = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants: {
    simple: variantSimple,
    striped: variantStripe,
    signal: variantSignalList,
    object: variantObjectTable,
    unstyled: {},
  },
  defaultProps: {
    variant: 'simple',
    size: 'md',
    colorScheme: 'gray',
  },
});
