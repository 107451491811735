import {
  createEnumParam,
  QueryParamConfigMap,
  withDefault,
} from 'serialize-query-params';
import {
  SetQuery,
  useQueryParams as _useQueryParams,
  StringParam,
} from 'use-query-params';

/** Wraps useQueryParams from `use-query-params` package to make types more convenient. */
export function useQueryParams<T extends QueryParamConfigMap>(config: T) {
  return _useQueryParams(config) as [EnsuredDecodedValueMap<T>, SetQuery<T>];
}

export type EnsuredDecodedValueMap<T extends QueryParamConfigMap> = {
  [P in keyof T]: Exclude<ReturnType<T[P]['decode']>, null | undefined>;
};

export type MvQueryParams = {
  selected: typeof StringParam;
};

export function useMvQueryParams() {
  return useQueryParams<MvQueryParams>({
    selected: StringParam,
  });
}

const SortOrderEnumParam = withDefault(
  createEnumParam(['recency', 'engagement', 'influence']),
  'engagement'
);

export type PostUrlQueryParams = {
  account_id?: typeof StringParam;
  end_date?: typeof StringParam;
  map_id?: typeof StringParam;
  query: typeof StringParam;
  segment_id?: typeof StringParam;
  sort: typeof SortOrderEnumParam;
  start_date?: typeof StringParam;
  widgetId?: typeof StringParam;
};

export function usePostUrlQueryParams() {
  const qp: PostUrlQueryParams = {
    account_id: StringParam,
    end_date: StringParam,
    map_id: StringParam,
    query: StringParam,
    segment_id: StringParam,
    sort: SortOrderEnumParam,
    start_date: StringParam,
    widgetId: StringParam,
  };

  const [getter, setter] = useQueryParams<Partial<PostUrlQueryParams>>(qp);
  const removeQp = () => {
    const emptyQp: Partial<PostUrlQueryParams> = {};
    (Object.keys(qp) as (keyof PostUrlQueryParams)[]).forEach(
      (key) => (emptyQp[key] = undefined)
    );
    setter(emptyQp);
  };
  return [getter, setter, removeQp] as [
    typeof getter,
    typeof setter,
    () => void
  ];
}
