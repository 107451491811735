import { useEffect, useState } from 'react';

export function useClipboardValue() {
  const [clipboardValue, setClipboardValue] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    let value = '';
    const getClipboard = async () => {
      try {
        value = await navigator.clipboard.readText();
      } catch (e) {
        value = '';
      }
      setClipboardValue(value);
    };
    getClipboard();
  }, []);
  return clipboardValue;
}
