import type { SyntheticEvent } from 'react';
import { Center, useColorModeValue } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  COMMAND_PRIORITY_LOW,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  NodeKey,
} from 'lexical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Icon } from '~/components';
import { useImmerState } from '~/lib/state-utils';
import { SegmentSelection } from '~/lib/stores/segment-tree';
import CloseIcon from '~/public/icons/Close.svg';
import { boxShadows, colors } from '~/styles';
import {
  DynamicWidgetCtx,
  useWidgetData,
  useWidgetDispatch,
  type DynamicWidgetConfig,
  type WidgetPresets,
} from '../shared';
import { $isDynamicWidgetNode } from './DynamicWidgetNode';

export type LexicalWidgetRendererProps = {
  config: DynamicWidgetConfig<any, any>;
  nodeKey: NodeKey;
  initialParams: Record<string, any>;
  initialSnapshotId: string | undefined;
  initialCaption: string;
  initialSegments: SegmentSelection;
  initialPresets: WidgetPresets;
};
export function LexicalWidgetRenderer({
  config,
  nodeKey,
  initialParams,
  initialSnapshotId,
  initialCaption = '',
  initialSegments = { groups: {}, clusters: {} },
  initialPresets = {},
}: LexicalWidgetRendererProps) {
  const [editor] = useLexicalComposerContext();
  const mode = useMemo(
    () => (editor.isEditable() ? 'author' : 'reader'),
    [editor]
  );

  const [params, setParams] = useImmerState(initialParams);
  const [caption, setCaption] = useState(initialCaption);
  const [segments, setSegments] = useState<SegmentSelection>(initialSegments);
  const [presets, setPresets] = useState<WidgetPresets>(initialPresets);
  useEffect(() => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (!$isDynamicWidgetNode(node)) return;
      node.setParams(params);
      node.setCaption(caption);
      node.setSegments(segments);
      node.setPresets(presets);
    });
  }, [params, caption, segments, presets, editor, nodeKey]);
  const isValidParams = useMemo(
    () => config._paramsSchema.safeParse(params).success,
    [config, params]
  );

  const snapshotId = useRef(initialSnapshotId);
  const getSnapshotId = useCallback(() => snapshotId.current, []);
  const setSnapshotId = useCallback(
    (value: string | undefined) => {
      snapshotId.current = value;
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (!$isDynamicWidgetNode(node)) return;
        node.setSnapshotId(value);
      });
    },
    [editor, nodeKey]
  );

  const { data, status, execute } = useWidgetData(config, {
    params,
    mode,
    getSnapshotId,
    setSnapshotId,
    immediate: false,
  });

  const [isSelected, setIsSelected] = useState(false);

  const onDelete = useCallback(
    (event: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isDynamicWidgetNode(node)) {
          node.remove();
          return true;
        }
      }
      return false;
    },
    [nodeKey, isSelected]
  );

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault();
        const node = $getNodeByKey(nodeKey);
        if ($isDynamicWidgetNode(node)) {
          node.insertAfter($createParagraphNode()).selectStart();
          return true;
        }
      }
      return false;
    },
    [nodeKey, isSelected]
  );

  const onWidgetClick = useCallback(
    (e: SyntheticEvent) => {
      editor.update(() => {
        const node = $getNodeByKey(nodeKey);
        if (!$isDynamicWidgetNode(node)) return;
        node.selectEnd();
      });
      e.stopPropagation();
    },
    [editor, nodeKey]
  );

  const onWidgetDeleteClick = useCallback(() => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if ($isDynamicWidgetNode(node)) {
        node.remove();
        return true;
      }
    });
  }, [editor, nodeKey]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          const isSelected = editorState.read(() => {
            const node = $getNodeByKey(nodeKey);
            return !!node?.isSelected($getSelection());
          });
          setIsSelected(isSelected);
        }),
        editor.registerCommand(
          KEY_DELETE_COMMAND,
          onDelete,
          COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(
          KEY_BACKSPACE_COMMAND,
          onDelete,
          COMMAND_PRIORITY_LOW
        ),
        editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW)
      ),
    [editor, nodeKey, onDelete, onEnter]
  );

  const containerRef = useRef<HTMLDivElement>(null!);
  const componentRef = useRef<HTMLDivElement>(null!);
  const [deleteButtonOffset, setDeleteButtonOffset] = useState(0);
  useEffect(() => {
    setDeleteButtonOffset(
      (containerRef?.current?.clientWidth -
        componentRef?.current?.clientWidth) /
        2
    );
  }, [setDeleteButtonOffset]);

  const component = useMemo(
    () => <config._component ref={componentRef} />,
    [config]
  );

  // the lexical element will always be defined because this React component won't render if it isn't
  const dispatch = useWidgetDispatch(editor.getElementByKey(nodeKey)!);

  return (
    <div>
      <DynamicWidgetCtx.Provider
        value={useMemo(
          () => ({
            data,
            status,
            refetch: execute,
            params,
            setParams,
            segments,
            setSegments,
            caption,
            setCaption,
            presets,
            setPresets,
            isValidParams,
            mode,
            isSelected,
            dispatch,
            getSnapshotId,
            widgetId: nodeKey,
            widgetType: config._name,
          }),
          [
            data,
            status,
            execute,
            params,
            setParams,
            caption,
            setCaption,
            presets,
            setPresets,
            segments,
            isValidParams,
            mode,
            isSelected,
            dispatch,
            getSnapshotId,
            nodeKey,
            config._name,
          ]
        )}
      >
        <Box ref={containerRef} onClick={onWidgetClick} position="relative">
          {mode === 'author' && (
            <DeleteWidgetButton
              onClick={onWidgetDeleteClick}
              offset={deleteButtonOffset}
            />
          )}
          {component}
        </Box>
      </DynamicWidgetCtx.Provider>
    </div>
  );
}

const DeleteWidgetButton = (props: {
  onClick: () => void;
  offset?: number;
}) => {
  const primary = useColorModeValue(colors.g.primary, colors.g.light);
  const white = useColorModeValue(colors.white, colors.coolGray[2]);
  return (
    <Center
      position="absolute"
      top="-12px"
      right={`${(props.offset ?? 0) - 12}px`}
      cursor="pointer"
      boxShadow={boxShadows.card}
      borderRadius="100%"
      bg={white}
      onClick={props.onClick}
      h={6}
      w={6}
    >
      <Icon icon={CloseIcon} fill={primary} boxSize={4} />
    </Center>
  );
};
