const boxShadows = {
  button: '0 0 1px rgba(0, 0, 0, 0.24)',
  card: '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.08)',
  cardSelected:
    '0px 0px 8px rgba(85, 103, 238, 0.8), 0px 0px 2px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.08)',
  cardPopup:
    '0px 0px 16px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.16)',
  header: '0px 1px 2px rgba(0, 0, 0, 0.24), 0px 1px 8px rgba(0, 0, 0, 0.32);',
  sidebarItem:
    '0px 0px 2px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.08);',
};

export default boxShadows;
