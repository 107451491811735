import { useColorModeValue } from '@chakra-ui/react';
import { parse, ParseConfig, ParseResult } from 'papaparse';
import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export type OrArray<T> = T | T[];
export const toArray = <T>(e: T | T[]) => (Array.isArray(e) ? e : [e]);

export type Asyncify<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => Promise<ReturnType<T>>;

/** Immutable Array.splice */
export function splice<T extends any[]>(
  array: T,
  start: number,
  deleteCount: number,
  ...items: T extends (infer E)[] ? E[] : any[]
) {
  const newArray = [...array];
  newArray.splice(start, deleteCount, ...items);
  return newArray;
}

export const escapeFileName = (filename: string) =>
  filename.replace(/\ /g, '_').replace(/\//g, '-');

type FlattenObjectOptions = Partial<{
  /** Defaults to `.`  */
  delimiter: string;
  /** Either a numeric max depth or a function that takes the next child object and returns true if flattening should continue */
  maxDepth: number | ((next: Record<string, any>) => boolean);
}>;
export function flattenObject(
  obj: Record<string, any>,
  prefix = '',
  options: FlattenObjectOptions = {}
): Record<string, any> {
  const { delimiter = '.', maxDepth } = options;
  const shouldStop =
    typeof maxDepth === 'number'
      ? (key: string) => key.split(delimiter).length > maxDepth
      : maxDepth ?? (() => false);

  return Object.keys(obj).reduce((result, key) => {
    const pre = prefix.length ? prefix + delimiter : '';
    if (typeof obj[key] === 'object' && !shouldStop(obj[key]))
      Object.assign(result, flattenObject(obj[key], pre + key, options));
    else result[pre + key] = obj[key];
    return result;
  }, {} as Record<string, any>);
}

export const debounceFn = <T extends (...args: any[]) => any>(
  fn: T,
  ms = 250
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const shallowEquals = (a: any, b: any): boolean => {
  if (a === null || b === null) return a === b;
  if (a === undefined || b === undefined) return a === b;
  if (typeof a !== typeof b) return false;
  if (typeof a !== 'object') return a === b;
  if (Object.keys(a).length !== Object.keys(b).length) return false;
  return Object.keys(a).every((key) => shallowEquals(a[key], b[key]));
};
export function useOriginal<T>(
  value: T,
  equals: (prev: T, current: T) => boolean = shallowEquals
) {
  const cache = useRef<T | undefined>(undefined);

  if (typeof cache.current === 'undefined' || !equals(cache.current, value)) {
    cache.current = value;
  }

  return cache.current;
}

export function flipObject(
  obj: Record<string, string>
): Record<string, string> {
  return Object.fromEntries(
    Object.entries(obj).map((entry) => entry.reverse())
  );
}

export function castBoolean(value?: any): boolean {
  if (!value) return false;
  return (
    typeof value === 'string' &&
    !['false', 'disable', 'disabled', 'no'].includes(value.trim().toLowerCase())
  );
}

const defaultAssetHost =
  typeof window === 'undefined' || process.env.NODE_ENV === 'test'
    ? ''
    : new URL(
        process.env.NEXT_PUBLIC_DEV_ASSETS_PROXY
          ? process.env.NEXT_PUBLIC_DEV_ASSETS_PROXY
          : process.env.NEXT_PUBLIC_ASSETS_URL
      ).host;

const defaultAssetProtocol =
  typeof window === 'undefined' || process.env.NODE_ENV === 'test'
    ? ''
    : process.env.NEXT_PUBLIC_DEV_ASSETS_PROXY?.startsWith('http')
    ? new URL(process.env.NEXT_PUBLIC_DEV_ASSETS_PROXY).protocol
    : 'https';
/**
 * This function should be used to wrap all asset URLs
 * It works with the `DEV_ASSETS_PROXY` env var to allow proxying assets in development.
 */
export function gAsset(url?: string, host = defaultAssetHost) {
  if (!url) return '';
  try {
    const parsed = new URL(url);
    parsed.host = host;
    parsed.protocol = defaultAssetProtocol;
    return parsed.toString();
  } catch (error) {
    console.error(`not an asset url: ${url}`);
    throw error;
  }
}

export function fetchExternalImage(
  url: string,
  filename?: string,
  format = 'image/png'
): Promise<File> {
  return new Promise((resolve, reject) => {
    const _filename = filename ?? url.slice(url.lastIndexOf('/'));
    const img = new Image();
    img.crossOrigin = 'Anonymous';

    const onLoad = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      if (!ctx) return reject(new Error('canvas.getContext returned null'));
      ctx.drawImage(img, 0, 0);
      canvas.toBlob(
        (blob) =>
          blob
            ? resolve(new File([blob], _filename))
            : reject(new Error('canvas.toBlob returned null')),
        format,
        1
      );
    };

    img.addEventListener('load', onLoad);
    img.src = url;
  });
}

export async function drawImageData(
  width: number,
  height: number,
  draw: (context: OffscreenCanvasRenderingContext2D) => void | Promise<void>,
  quality = 1
): Promise<string> {
  const canvas = new OffscreenCanvas(width, height);
  const ctx = canvas.getContext('2d');
  if (!ctx) throw new Error(`couldn't get offscreen canvas context`);

  await Promise.resolve(draw(ctx));

  const blob = await canvas.convertToBlob({ type: 'image/png', quality });
  const result = await readFile(blob, 'DataURL');
  if (!result) throw new Error(`couldn't read blob`);
  return result.toString();
}

type ReadFormat = {
  [K in keyof FileReader]: K extends `readAs${infer Format}` ? Format : never;
}[keyof FileReader];
export async function readFile(
  file: Blob,
  format: ReadFormat
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader[`readAs${format}`](file);
    } catch (error) {
      reject(error);
    }
  });
}

export const parseCSV = <T>(
  source: string,
  config: ParseConfig<T>
): Promise<ParseResult<T>> =>
  new Promise((resolve) => {
    parse(source, {
      ...config,
      complete(results) {
        resolve(results);
      },
    });
  });

export function groupBy<T, U extends (item: T) => string | number | symbol>(
  arr: T[],
  group: U
): Record<ReturnType<U>, T[]> {
  return arr.reduce((result, item) => {
    const key = group(item) as ReturnType<U>;
    return { ...result, [key]: [...(result[key] ?? []), item] };
  }, {} as Record<ReturnType<U>, T[]>);
}

export function usePrevious<T>(value: T): T | undefined {
  const previous = useRef<T>();
  useEffect(() => {
    previous.current = value;
  }, [value]);

  return previous.current;
}

type UseLocalStorageConfig<T> = {
  key: string;
  defaultValue: T;
  encode?: (value: T) => string;
  decode?: (str: string) => T;
};
export function useLocalStorage<T>(
  config: UseLocalStorageConfig<T>
): [T, Dispatch<SetStateAction<T>>] {
  const {
    key,
    defaultValue,
    encode = JSON.stringify,
    decode = JSON.parse,
  } = config;

  const getValue = () => {
    const raw = window.localStorage.getItem(key);
    if (raw === null) return defaultValue;
    else return decode(raw);
  };

  const [value, setValue] = useState(getValue);

  useEffect(() => {
    window.localStorage.setItem(key, encode(value));
  }, [value, key, encode]);

  return [value, setValue];
}

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';
export type UseResourceResult<Data, Err = any> = Omit<
  UseAsyncResult<any, Data, Err>,
  'execute'
>;
export type UseAsyncResult<T extends AnyAsyncFn, Data, Err> = {
  execute: T;
  status: AsyncStatus;
  data: Data | undefined;
  error: Err | undefined;
};
type AnyAsyncFn = (...args: any[]) => Promise<any>;
type UseAsyncResultExtras = Record<
  'isLoading' | 'isError' | 'isSuccess' | 'isIdle',
  boolean
>;

export function useAsync<
  T extends AnyAsyncFn,
  Data = Awaited<ReturnType<T>>,
  Err = any
>(
  fn: T,
  immediate = false
): UseAsyncResult<T, Data, Err> & UseAsyncResultExtras {
  const [data, setData] = useState<Data | undefined>(undefined);
  const [error, setError] = useState<Err | undefined>(undefined);
  const [status, setStatus] = useState<AsyncStatus>('idle');

  const isExecuting = useRef(false);
  const execute = useCallback(
    (...args: Parameters<T>) => {
      if (isExecuting.current) return;
      else isExecuting.current = true;

      setStatus('pending');
      setData(undefined);
      setError(undefined);

      return fn(...args)
        .then((res) => {
          setData(res);
          setStatus('success');
          return res;
        })
        .catch((err) => {
          setError(err);
          setStatus('error');
        })
        .finally(() => {
          isExecuting.current = false;
        });
    },
    [fn]
  ) as T;

  useEffect(() => {
    status === 'idle' && immediate && (execute as any)();
  }, [execute, status, immediate]);

  return {
    data,
    error,
    status,
    execute,
    isLoading: status === 'pending',
    isError: status === 'error',
    isSuccess: status === 'success',
    isIdle: status === 'idle',
  };
}

/**
 * @param str The input string
 * @param tokens An object of token types and their regex
 * @param strict If true, throws an error on unmatched text. By default unmatched text has a token type of `undefined`
 */
export function tokenize<T extends Record<string, RegExp | null>>(
  str: string,
  tokens: T,
  strict?: boolean
): { type: keyof T | undefined; value: string }[] {
  const parser = new RegExp(
    Object.entries(tokens)
      .map(([type, regex]) => (regex ? `(?<${type}>${regex.source})` : false))
      .filter(Boolean)
      .join('|'),
    'ugm'
  );

  const results: { type: keyof T | undefined; value: string }[] = [];
  let match: RegExpMatchArray | null;
  let lastIndex = 0;

  while ((match = parser.exec(str))) {
    const token = Object.entries(match.groups!).filter(([, value]) => value)[0];
    const noMatch = str.slice(lastIndex, parser.lastIndex - token[1].length);

    if (strict && noMatch)
      throw new Error(`unknown token at position ${lastIndex}: "${noMatch}"`);
    else if (noMatch) results.push({ type: undefined, value: noMatch });

    results.push({ type: token[0], value: token[1] });

    lastIndex = parser.lastIndex;
  }

  const endOfString = str.slice(lastIndex);
  if (endOfString) results.push({ type: undefined, value: endOfString });

  return results;
}

export function useColorModeValues(...values: [string, string][]): string[] {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return values.map(([light, dark]) => useColorModeValue(light, dark));
}

export type ObjectEntries<T extends Record<string, any>> = any[] &
  {
    [K in keyof T]: [K, T[K]];
  }[keyof T];

export function objMap<
  T extends Record<string, any>,
  U extends (
    entry: ObjectEntries<T>
  ) => [string | number | symbol, any] | undefined
>(
  obj: T,
  callback: U
): {
  [K in NonNullable<ReturnType<U>>[0]]: NonNullable<ReturnType<U>>[1];
} {
  return Object.fromEntries(
    Object.entries(obj).map(callback).filter(Boolean) as []
  ) as any;
}

export const useEffectOnce = (effect: Function, deps: DependencyList = []) => {
  const hasRun = useRef(false);
  useEffect(() => {
    if (!hasRun.current && deps.every(Boolean)) {
      hasRun.current = true;
      return effect();
    }
  }, [deps, effect]);
};
