import { Signal } from '~/types/graphika-types';
import { narrativeKeys } from '../narrative';
import { buildMutation } from '../utils';

export const useBulkImportSignals = buildMutation(
  '/narratives/{narrative_id}/signals/bulk',
  'put',
  (narrative_id: number) => (csvFile: File) => {
    const formData = new FormData();
    formData.append('file', csvFile);
    return { params: { narrative_id }, form: formData };
  },
  (narrative_id) => [narrativeKeys.narrative(narrative_id)]
);

export const useRemoveSignal = buildMutation(
  '/narratives/{narrative_id}/signals/{signal_id}',
  'delete',
  (narrative_id: number) => (signal_id: number) => ({
    params: { narrative_id, signal_id },
  }),
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.signals(narrative_id),
  ]
);

export const useRemoveAllSignals = buildMutation(
  '/narratives/{narrative_id}/signals',
  'delete',
  (narrative_id: number) => () => ({
    params: { narrative_id },
  }),
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.signals(narrative_id),
  ]
);

export const useAddSignal = buildMutation(
  '/narratives/{narrative_id}/signals',
  'post',
  // @ts-expect-error
  (narrative_id: number) => (signal: Signal) => ({
    params: { narrative_id },
    body: signal,
  }),
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.signals(narrative_id),
  ]
);

export const useReplaceSignals = buildMutation(
  '/narratives/{narrative_id}/signals',
  'put',
  (narrative_id: number) => (signals: Signal[]) => ({
    params: { narrative_id },
    body: signals,
  }),
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.signals(narrative_id),
  ]
);

export const useUpdateSignal = buildMutation(
  '/narratives/{narrative_id}/signals/{signal_id}',
  'put',
  // @ts-expect-error
  (narrative_id: number) => (signal: Signal) => {
    const signal_id = signal.id;
    if (signal_id === undefined)
      throw new Error("can't update signal without an ID");
    return { params: { narrative_id, signal_id }, body: signal };
  },
  (narrative_id) => [
    narrativeKeys.narrative(narrative_id),
    narrativeKeys.signals(narrative_id),
  ]
);
