import type {
  StyleFunctionProps,
  SystemStyleFunction,
  SystemStyleObject,
} from '@chakra-ui/theme-tools';
import type { EditorThemeClasses } from 'lexical';
import { flattenObject } from '~/lib/utils';
import { colors } from '~/styles';

const editorTheme: ChakraEditorThemeFunction = ({ mode, sx }) => {
  const textSx = withSx({});

  const theme: ChakraEditorThemeObject = {
    heading: {
      h1: textSx({
        fontSize: '24px',
      }),
      h2: textSx({
        fontSize: '18px',
      }),
      h3: textSx({
        fontSize: '16px',
      }),
    },
    paragraph: textSx(),
    text: {
      bold: textSx({ fontWeight: 700 }),
      italic: textSx({ fontStyle: 'italic' }),
      underline: textSx({ textDecoration: 'underline' }),
    },
    link: sx({
      color: mode(colors.g.primary, colors.g.light),
      cursor: 'pointer',
      _hover: {
        textDecoration: 'underline',
      },
    }),
    list: {
      ul: textSx({
        pl: '1rem',
      }),
      ol: textSx({
        pl: '1rem',
      }),
    },
    quote: textSx({
      my: 3,
      borderLeftColor: mode(colors.black, colors.white),
      borderLeftWidth: '3px',
      pl: 4,
    }),
  };
  return theme;
};

export const chakraGlobals = toChakraGlobals(editorTheme);
export const lexicalTheme = toLexicalThemeClasses(editorTheme);

type ChakraEditorThemeObject<
  T extends Record<string, any> = EditorThemeClasses
> = {
  [K in keyof T]: T[K] extends string | undefined
    ? SystemStyleObject & { __isStyle: true }
    : T[K] extends Array<any> | undefined
    ? Array<SystemStyleObject & { __isStyle: true }>
    : T[K] extends Record<string, any> | undefined
    ? ChakraEditorThemeObject<T[K]>
    : never;
};
type ChakraEditorThemeFunction = (
  props: StyleFunctionProps & {
    sx: _StyleMarkerFunction;
    mode: _ColorModeHelper;
  }
) => ChakraEditorThemeObject;

type _StyleMarkerFunction = (style: SystemStyleObject) => _StyleMarkerObject;
type _StyleMarkerObject = SystemStyleObject & { __isStyle: true };
type _ColorModeHelper = <L, D>(light: L, dark: D) => L | D;

function toChakraGlobals(
  theme: ChakraEditorThemeFunction
): SystemStyleFunction {
  return (props) =>
    flattenObject(
      theme({
        ...props,
        sx: (obj) => ({ ...obj, __isStyle: true }),
        mode: (light, dark) => (props.colorMode === 'dark' ? dark : light),
      }),
      '.editor',
      {
        delimiter: '-',
        maxDepth: (obj) => '__isStyle' in obj,
      }
    );
}

function toLexicalThemeClasses(
  theme: ChakraEditorThemeFunction
): EditorThemeClasses {
  const sxShim = {
    sx: (obj) => ({ ...obj, __isStyle: true }),
    mode: (light, dark) => light,
  } as StyleFunctionProps & {
    sx: _StyleMarkerFunction;
    mode: _ColorModeHelper;
  };
  const toThemeClasses = (obj: Record<string, any>, path = '') =>
    Object.keys(obj).reduce((result, key) => {
      if ('__isStyle' in obj[key]) result[key] = 'editor-' + path + key;
      else result[key] = toThemeClasses(obj[key], path + key + '-');

      return result;
    }, {} as Record<string, any>);

  return toThemeClasses(theme(sxShim));
}

function withSx(
  baseStyle: SystemStyleObject
): (style?: SystemStyleObject) => _StyleMarkerObject {
  return (style) => ({
    ...baseStyle,
    ...style,
    __isStyle: true,
  });
}
