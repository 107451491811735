import type { Element as HastElement, Root } from 'hast';
import type { Transformer } from 'unified';
import type { Node as UnistNode } from 'unist';
import type { BuildVisitor, Test as UnistTest } from 'unist-util-visit';
import { findAndReplace } from 'hast-util-find-and-replace';
import { h } from 'hastscript';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import { visit } from 'unist-util-visit';
import { fetchExternalImage, flipObject } from './utils';
import { hasUrlRegex } from './markdown';

type HighlightTextContentOptions = {
  regex: string | RegExp;
  className?: string;
};
/**
 * Rehype plugin to wrap the matching text like so: `<span class="highlight">match</span>`.
 * The class name can be changed in the options.
 */
export function highlightTextContent(
  options: HighlightTextContentOptions
): Transformer<Root, Root> {
  return (tree) => {
    findAndReplace(tree, [
      [
        options.regex,
        (match: string) => {
          return h(
            'span',
            {
              class: options.className ?? 'highlight',
            },
            match
          );
        },
      ],
    ]);
  };
}

type TransformerMap = Partial<
  Record<keyof JSX.IntrinsicElements, (node: HastElement) => void>
>;
export function transformElements(
  transforms: TransformerMap
): Transformer<Root, Root> {
  return (tree) => {
    visit(tree, 'element', (node) =>
      transforms[node.tagName as keyof JSX.IntrinsicElements]?.(node)
    );
  };
}

/**
 * Rehype plugin to transform lexical-serialized HTML such that the text formatting
 *  is preserved when calling Lexical's `$generateNodesFromDOM`.
 *
 * The way the plugin works is by "expanding" text nodes (`span`, `em`, `strong`) that have
 *  lexical format classes on them. It does that by turning the text node into a `span` and reapplying the formating as nested text elements.
 *  Take the following lexical-serialized (`$generateHtmlFromNodes`) as an example:
 *
 * ```html
 * <!-- Original lexical-serialized html -->
 * <strong class="editor-text-bold editor-text-italic editor-text-underline">
 *  bold, italic, and underlined.
 * </strong>
 *
 * <!-- Transformed html -->
 * <span class="editor-text-bold editor-text-italic editor-text-underline">
 *   <u>
 *     <em>
 *       <strong>bold, italic, and underlined. </strong>
 *     </em>
 *   </u>
 * </span>
 * ```
 */
export function transformLexicalFormatting(): Transformer<Root, Root> {
  const isFormattedTextNode = (node: HastElement) =>
    ['span', 'strong', 'em'].includes(node.tagName) &&
    ['bold', 'underline', 'italic'].some((format) =>
      hasClass(node, `editor-text-${format}`)
    );

  const classToTag: Record<string, string> = {
    'editor-text-bold': 'strong',
    'editor-text-italic': 'em',
    'editor-text-underline': 'u',
  };
  const tagToClass = flipObject(classToTag);

  function unwrapFormatting(node: HastElement) {
    if (!isFormattedTextNode(node)) return;

    const formatClasses =
      typeof node.properties!.className === 'string'
        ? [node.properties!.className]
        : (node.properties!.className as string[]);

    if (
      node.tagName !== 'span' &&
      !formatClasses.includes(tagToClass[node.tagName])
    ) {
      formatClasses.push(tagToClass[node.tagName]);
    }

    node.tagName = 'span';

    const child = formatClasses.reduce(
      (child, format) => h(classToTag[format], undefined, [child]),
      node.children[0]
    );
    node.children = [child];
  }

  return (tree) => {
    visit(tree, 'element', unwrapFormatting);
  };
}

function hasClass(node: HastElement, ...classNames: string[]) {
  if (!node.properties?.className) return false;
  const nodeClass = node.properties.className as string | string[];
  return typeof nodeClass === 'string'
    ? classNames.includes(nodeClass)
    : classNames.every((klass) => nodeClass.includes(klass));
}

type SanitizeHtmlOptions = { stripImages?: boolean };
export function sanitizeHtml(options: SanitizeHtmlOptions = {}) {
  const allowedTags = [
    'a',
    'b',
    'blockquote',
    'br',
    'code',
    'div',
    'em',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hr',
    'i',
    'li',
    'ol',
    'p',
    'span',
    'strong',
    'u',
    'ul',
    options.stripImages ? '' : 'img',
  ].filter(Boolean);
  return rehypeSanitize({
    ...defaultSchema,
    tagNames: allowedTags,
    attributes: {
      ...defaultSchema.attributes,
      '*': ['className'],
      img: ['alt', 'src'],
    },
  });
}

type ImageAssetUploaderOptions = {
  upload(
    file: File,
    nodeProperties?: Record<string, any>
  ): Promise<{ src: string; alt?: string }>;
};
export function imageAssetUploader(
  options: ImageAssetUploaderOptions
): Transformer<Root, Root> {
  const isImageElement = (node: UnistNode): node is HastElement =>
    node.type === 'element' &&
    (node as HastElement).tagName === 'img' &&
    !!(node as HastElement).properties?.src;

  return async (tree, input, next) => {
    try {
      await Promise.all(
        visitMap(tree, isImageElement, ({ properties = {} }) =>
          (async () => {
            const file = await fetchExternalImage(properties.src! as string);
            const assetProperties = await options.upload(file, properties);
            Object.assign(properties, assetProperties);
          })()
        )
      );
      next();
    } catch (error) {
      next(error as Error);
    }
  };
}

/** Helper to collect tuples of {@link BuildVisitor} arguments into an array. The argument tuples look like this:
 * ```ts
 * [node, index?, parent?]
 * ````
 *
 * The types of the elements vary depending on the `Tree` and `Check` type parameters inferred from the arguments to this function.
 */
export function visitMap<
  Tree extends UnistNode,
  Check extends UnistTest,
  T = Parameters<BuildVisitor<Tree, Check>>
>(
  tree: Tree,
  check: Check,
  map?: (...args: Parameters<BuildVisitor<Tree, Check>>) => T
): T[] {
  const mapped: T[] = [];
  visit(
    tree,
    check,
    (...args) => void mapped.push(map ? map(...args) : (args as any))
  );
  return mapped;
}

export const replaceUrlWithHtmlATag = (text: string) => {
  return text.replace(
    hasUrlRegex,
    (url) => `<a href="${url}" target="_blank" rel="noreferrer">${url}</a>`
  );
};
