import type { PropsWithChildren } from 'react';
import type {
  MapCluster,
  MapGroup,
  MapInfo,
  MapNode,
} from '~/types/graphika-types';
import {
  BodyText,
  Box,
  Divider,
  Flex,
  Icon,
  Link,
  SubtitleHeader,
  TitleHeader,
  Tooltip,
} from '~/components';
import { Analytics } from '~/lib/analytics';
import { useMvQueryParams } from '~/lib/hooks';
import { getNodeAccountLink, getPlatformName } from '~/lib/platform';
import MinusCircleIcon from '~/public/icons/MinusCircle.svg';
import { colors } from '~/styles';
import { useSegmentTree } from '~/lib/stores/segment-tree';
import { AvatarWithPlatform } from './AvatarWithPlatform';
import { mapSegmentTreeDefaults } from './SegmentTree';

type Props = {
  node?: MapNode;
  setHighlightedNode: (node: MapNode | undefined) => void;
  map: MapInfo;
  clusters: MapCluster[];
  groups: MapGroup[];
};

export function HighlightedNodeInfo({ node, map, clusters, groups }: Props) {
  const resetSegmentTree = useSegmentTree((state) => state.reset);
  const [_, setQuery] = useMvQueryParams();
  const nodeCluster = clusters.find(
    (cluster) => cluster.id === node?.cluster_id
  );
  const nodeGroup = groups.find((group) => group.id === node?.group_id);

  if (!node) return null;
  return (
    <Box p={4}>
      <Flex justify="space-between" mb={2}>
        <Flex gap={2}>
          <LinkTooltip node={node} map={map}>
            <AvatarWithPlatform node={node} showHoverEffect px={48} />
          </LinkTooltip>
          <Box>
            <LinkTooltip node={node} map={map}>
              <TitleHeader color={colors.white} noOfLines={1}>
                {node.name}
              </TitleHeader>
            </LinkTooltip>
            <LinkTooltip node={node} map={map}>
              <BodyText color={colors.warmGray[4]} noOfLines={1}>
                {node.username}
              </BodyText>
            </LinkTooltip>
          </Box>
        </Flex>

        <Icon
          icon={MinusCircleIcon}
          boxSize={6}
          mr={-2}
          cursor="pointer"
          onClick={() => {
            setQuery({ selected: undefined });
            resetSegmentTree(mapSegmentTreeDefaults);
          }}
        />
      </Flex>
      <Flex direction="column" gap={2}>
        {node.description && (
          <BodyText color={colors.warmGray[4]}>{node.description}</BodyText>
        )}
      </Flex>
      <Divider borderColor={colors.mapViewer.gray} mx={2} />
      <Flex gap={4} direction="column">
        {nodeGroup && (
          <Flex gap={2} direction="column">
            <SubtitleHeader color={colors.white}>Map Group</SubtitleHeader>

            <Flex gap={3} align="center">
              <Box h="32px" w="8px" bg={`#${nodeGroup.hex_color}`} />
              <BodyText color="#d2d8e5">{nodeGroup.name}</BodyText>
            </Flex>
          </Flex>
        )}
        {nodeCluster && (
          <Flex gap={2} direction="column">
            <SubtitleHeader color={colors.white}>Map Cluster</SubtitleHeader>
            <Flex gap={3} align="center">
              <Box h="32px" w="8px" bg={`#${nodeCluster.hex_color}`} />
              <BodyText color="#d2d8e5">{nodeCluster.name}</BodyText>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

const LinkTooltip = ({
  node,
  map,
  children,
}: PropsWithChildren<{ node: MapNode; map: MapInfo }>) => {
  const nodeLink = getNodeAccountLink(node);
  const platformName = getPlatformName(node.platform);
  return (
    <Tooltip label={`View profile on ${platformName}`} placement="bottom">
      <Link
        onClick={() => {
          Analytics.event('mapSidebar', {
            action: 'node_external_account_link_click',
            label: node.username,
            map: map.name ?? '',
            map_id: (map.id ?? '').toString(),
          });
          window.open(nodeLink);
        }}
        color={colors.white}
      >
        {children}
      </Link>
    </Tooltip>
  );
};
