import { MapViewMode } from '@graphika/map-viewer';
import Link from 'next/link';
import { useColorModeValue } from '@chakra-ui/react';
import { BodyText, Box, Checkbox, Flex, Icon, Tooltip } from '~/components';
import { useRelevantMapNodes } from '~/components/elements/Editor/plugins';
import { Analytics } from '~/lib/analytics';
import { colors } from '~/styles';
import ExternalLinkIcon from '~/public/icons/ExternalLink2.svg';
import { usePostUrlQueryParams } from '~/lib/hooks';

type Props = {
  value: MapViewMode;
  onChange: (mode: MapViewMode) => void;
};

export function ViewModeControl({ value, onChange }: Props) {
  const { params, presets, setPresets, widgetId } = useRelevantMapNodes();
  const [{}, setUrlQuery] = usePostUrlQueryParams();
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray3 = useColorModeValue(colors.warmGray[3], colors.coolGray[1]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const white = useColorModeValue(colors.white, colors.coolGray[1]);

  const handleShowAllPosts = () => {
    const { endDate, mapId, term, startDate, sort } = params;
    Analytics.event('postWidget', {
      action: 'mv_all_posts',
    });
    setUrlQuery(
      {
        account_id: undefined,
        end_date: endDate,
        map_id: mapId,
        query: term,
        start_date: startDate,
        widgetId,
        sort,
      },
      'replaceIn'
    );
  };

  return (
    <Flex
      position="absolute"
      top={2}
      right={4}
      borderRadius={4}
      border={`1px solid ${gray4}`}
      bg={white}
      px={2}
      pr={1}
      py={1}
      align="center"
      gap={2}
      className="hide-on-pdf"
    >
      <Checkbox
        isChecked={value === '3d'}
        onChange={() => {
          const newViewMode = value === '2d' ? '3d' : '2d';
          setPresets({ ...presets, viewMode: newViewMode });
          onChange(newViewMode);
        }}
      >
        <BodyText>3D View</BodyText>
      </Checkbox>
      <Box w="1px" bg={gray3} h="20px" />
      <Tooltip label="View All Posts" variant="monochrome" hasArrow={false}>
        <Box ml={1} cursor="pointer" onClick={handleShowAllPosts}>
          <Icon icon={ListIcon} boxSize={4} fill={gray1} />
        </Box>
      </Tooltip>
      <Link href={`/maps/${params.mapId}`}>
        <Icon icon={ExternalLinkIcon} boxSize={6} fill={gray1} />
      </Link>
    </Flex>
  );
}

const ListIcon = () => (
  <svg viewBox="0 0 14 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.40625 0.0625C2.76172 0.0625 3.0625 0.363281 3.0625 0.71875V2.03125C3.0625 2.41406 2.76172 2.6875 2.40625 2.6875H1.09375C0.710938 2.6875 0.4375 2.41406 0.4375 2.03125V0.71875C0.4375 0.363281 0.710938 0.0625 1.09375 0.0625H2.40625ZM13.125 0.5C13.5898 0.5 14 0.910156 14 1.375C14 1.86719 13.5898 2.25 13.125 2.25H5.25C4.75781 2.25 4.375 1.86719 4.375 1.375C4.375 0.910156 4.75781 0.5 5.25 0.5H13.125ZM13.125 4.875C13.5898 4.875 14 5.28516 14 5.75C14 6.24219 13.5898 6.625 13.125 6.625H5.25C4.75781 6.625 4.375 6.24219 4.375 5.75C4.375 5.28516 4.75781 4.875 5.25 4.875H13.125ZM13.125 9.25C13.5898 9.25 14 9.66016 14 10.125C14 10.6172 13.5898 11 13.125 11H5.25C4.75781 11 4.375 10.6172 4.375 10.125C4.375 9.66016 4.75781 9.25 5.25 9.25H13.125ZM0.4375 5.09375C0.4375 4.73828 0.710938 4.4375 1.09375 4.4375H2.40625C2.76172 4.4375 3.0625 4.73828 3.0625 5.09375V6.40625C3.0625 6.78906 2.76172 7.0625 2.40625 7.0625H1.09375C0.710938 7.0625 0.4375 6.78906 0.4375 6.40625V5.09375ZM2.40625 8.8125C2.76172 8.8125 3.0625 9.11328 3.0625 9.46875V10.7812C3.0625 11.1641 2.76172 11.4375 2.40625 11.4375H1.09375C0.710938 11.4375 0.4375 11.1641 0.4375 10.7812V9.46875C0.4375 9.11328 0.710938 8.8125 1.09375 8.8125H2.40625Z" />
  </svg>
);
