import type { WidgetExportOptions } from './types';
import { WidgetName } from '~/components/elements/Editor/plugins/dynamic-insights';
import { Overwrite } from '~/types/graphika-types';
import { getRackSession } from '../api';

/** Sends a request to `/api/widget/export ` and returns a `Blob` with the image data (png).
 *  **This should only be used in the browser.**
 */
export async function fetchWidgetExport<T extends WidgetName>(
  name: T,
  options: Overwrite<WidgetExportOptions<T>, { name?: T }>,
  noCache = false
): Promise<Blob> {
  const res = await fetch(`/api/widget/export`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      'rack.session': decodeURIComponent(getRackSession()),
      name,
      noCache,
      ...options,
    }),
  });
  return await res.blob();
}

declare global {
  export interface Window {
    /** This is set on the window once the `StaticWidgetRenderer` renders the widget
     *  so that the puppeteer script knows which element to screenshot. */
    GRAPHIKA_WIDGET_EXPORT_ELEMENT: HTMLDivElement | undefined;
  }
}
