import type { PropsWithChildren } from 'react';
import type { MapGroup } from '~/types/graphika-types';
import { Collapse, Text } from '@chakra-ui/react';
import { Box, Checkbox, Flex, Icon, Tooltip } from '~/components';
import DropdownClosed from '~/public/icons/Dropdown-Closed.svg';
import TagIcon from '~/public/icons/Tag.svg';
import { colors, textStyles } from '~/styles';
import { useSegmentTree } from '~/lib/stores/segment-tree';

type Props = PropsWithChildren<{ group: MapGroup }>;

export function GroupTreeItem({ group, children }: Props) {
  const [{ active, showLabel, isOpen }, toggleGroup] = useSegmentTree(
    (state) => [state.groups[group.id] ?? {}, state.toggleGroup]
  );

  return (
    <Box>
      <Flex
        align="center"
        h="32px"
        pr={1}
        cursor="pointer"
        _hover={{ bg: colors.mapViewer.hoverBg }}
      >
        <Flex
          align="center"
          gap={3}
          flex={1}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleGroup({ id: group.id, param: 'active' });
          }}
        >
          <Box
            bg={`#${group.hex_color}`}
            w={2}
            minW={2}
            h="32px"
            borderRadius={!isOpen ? `2px` : '2px 2px 0 0'}
          />
          <Checkbox
            variant="mapviewer"
            isChecked={!!active}
            isIndeterminate={active === null}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              toggleGroup({ id: group.id, param: 'active' });
            }}
          />

          <Box>
            <Icon
              ml={-2}
              icon={TagIcon}
              fill={showLabel ? colors.white : colors.mapViewer.deselected}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                toggleGroup({ id: group.id, param: 'showLabel' });
              }}
              cursor="pointer"
              boxSize={6}
            />
          </Box>
          <Tooltip label={group.name} openDelay={300}>
            <Text
              {...textStyles.BodyText}
              color={colors.white}
              fontWeight="bold"
              key={group.id}
              userSelect="none"
              cursor="pointer"
              wordBreak="break-all"
              noOfLines={1}
            >
              {group.name}
            </Text>
          </Tooltip>
        </Flex>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            toggleGroup({ id: group.id, param: 'isOpen' });
          }}
          cursor="pointer"
        >
          <Icon
            icon={DropdownClosed}
            color={colors.white}
            transform={isOpen ? '' : `rotate(-90deg)`}
            transitionDuration="0.2s"
          />
        </Box>
      </Flex>
      <Box mb={2}>
        <Collapse in={isOpen}>{children}</Collapse>
      </Box>
    </Box>
  );
}
