import type { PropsWithChildren, ReactElement } from 'react';
import type { ButtonProps } from '~/components';
import { useFormikContext } from 'formik';
import { Button } from '~/components';

type Props = PropsWithChildren<ButtonProps> & {
  isInvalid?: boolean;
};

export function FormButton({
  isInvalid = false,
  ...props
}: Props): ReactElement {
  const { isValid } = useFormikContext<unknown>();
  return (
    <Button
      disabled={isInvalid || (props.type === 'submit' && !isValid)}
      {...(props as any)}
    />
  );
}
