import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';
import { colors } from '~/styles';
import { Narrative, Signal } from '~/types/graphika-types';
import { classificationKeys } from '~/queries';

export const SignalModalContext = createContext<SignalModalContext>(undefined!);

export type SignalModalContext = {
  tab: number;
  setTab: (tab: number) => void;
  onClose: (force?: boolean) => void;
  narrative: Narrative;
  preview: {
    currentSignal: Signal | undefined;
    setCurrentSignal: Dispatch<SetStateAction<Signal | undefined>>;
    signals: Signal[];
    setSignals: Dispatch<SetStateAction<Signal[]>>;
  };
  uploadErrors?: string;
  setUploadErrors: (errors?: string) => void;
};
export const useSignalModal = () => useContext(SignalModalContext);

export const useIsFetchingSignals = () => {
  const isFetching = useIsFetching({ queryKey: classificationKeys.all });
  const isMutating = useIsMutating({ mutationKey: classificationKeys.all });

  return isFetching || isMutating;
};

export function SignalModalActions(props: Record<'start' | 'end', ReactNode>) {
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  return (
    <Flex
      p={4}
      justify="space-between"
      align="center"
      borderTop={`1px solid ${gray4}`}
    >
      <Flex gap={4}>{props.start}</Flex>
      <Flex gap={4}>{props.end}</Flex>
    </Flex>
  );
}
