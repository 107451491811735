import type { ReactElement } from 'react';
import type { StyleProps, TextareaProps } from '@chakra-ui/react';
import {
  forwardRef,
  useColorMode,
  useColorModeValue,
  useMultiStyleConfig,
  Box,
  Flex,
  Textarea as ChakraTextarea,
} from '@chakra-ui/react';
import { colors } from '~/styles';

type Props = StyleProps &
  TextareaProps & {
    label?: string;
  };

export const Textarea = forwardRef<Props, 'textarea'>(
  ({ variant, label, ...props }, ref): ReactElement => {
    const { colorMode } = useColorMode();
    const styles = useMultiStyleConfig('Input', { variant, colorMode });
    const textarea = (
      <ChakraTextarea
        ref={ref}
        variant={variant}
        mt={2}
        backgroundColor={useColorModeValue(
          colors.warmGray[6],
          colors.coolGray[1]
        )}
        borderColor={useColorModeValue(colors.warmGray[4], colors.coolGray[1])}
        _hover={{
          borderColor: useColorModeValue(
            colors.warmGray[3],
            colors.coolGray[3]
          ),
        }}
        _focus={{
          borderColor: colors.g.primary,
          boxShadow: 'none',
        }}
        fontSize="14px"
        {...(props as any)}
      />
    );
    if (label) {
      return (
        <Flex direction="column">
          <Box as="label" __css={styles.label} htmlFor={props.id}>
            {label}
          </Box>
          {textarea}
        </Flex>
      );
    }
    return textarea;
  }
);
