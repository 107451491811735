import { useMvConfig } from '@graphika/map-viewer';
import { useColorModeValue } from '@chakra-ui/react';
import { Box, Flex, Icon } from '~/components';
import { DocsLinkPopover } from '~/components/docs';
import { colors } from '~/styles';
import PlusIcon from '~/public/icons/widget-mv-plus.svg';
import MinusIcon from '~/public/icons/widget-mv-minus.svg';

const ZOOM_DELTA = 0.14;

type Props = { mode: 'author' | 'reader'; nodeCount: number };

export function ZoomControl({ mode, nodeCount }: Props) {
  const camera = useMvConfig((state) => state.controls?.object);
  const gray1 = useColorModeValue(colors.warmGray[1], colors.coolGray[5]);
  const gray3 = useColorModeValue(colors.warmGray[3], colors.coolGray[3]);
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);
  const gray6 = useColorModeValue(colors.warmGray[6], colors.coolGray[3]);
  const white = useColorModeValue(colors.white, colors.coolGray[1]);

  const handleZoom = (direction: 'IN' | 'OUT') => {
    camera.translateZ(direction === 'OUT' ? ZOOM_DELTA : -ZOOM_DELTA);
  };

  return (
    <Flex
      position="absolute"
      bottom={0}
      right={4}
      borderRadius={4}
      border={`1px solid ${gray4}`}
      bg={white}
      flexDirection="column"
      cursor="pointer"
      className="hide-on-pdf"
    >
      <Box
        p={2}
        pb={1.5}
        onClick={() => {
          handleZoom('IN');
        }}
        borderRadius={4}
        _hover={{
          bg: gray6,
        }}
      >
        <Icon icon={PlusIcon} boxSize={4} fill={gray1} />
      </Box>
      <Box
        p={2}
        pt={1.5}
        onClick={() => {
          handleZoom('OUT');
        }}
        borderRadius={4}
        _hover={{
          bg: gray6,
        }}
      >
        <Icon icon={MinusIcon} boxSize={4} fill={gray1} />
      </Box>
      {mode === 'reader' && (
        <Box pl={2} pb={2}>
          <Box pl="2px" mr="6px" borderTop={`1px solid ${gray3}`} pb={2}></Box>
          <DocsLinkPopover
            placement="start-end"
            text={`<p>This network map is made up of ${nodeCount} accounts. Learn more about how Graphika maps are made <a href='/docs?subsection=Maps'>here</a>.</p>`}
            boxSize="16px"
          />
        </Box>
      )}
    </Flex>
  );
}
