import type { ReactElement } from 'react';
import type { StyleProps } from '@chakra-ui/react';
import { EmptyFallbackImage, Image } from '~/components';
import { coerceIntoExternalUrl } from '~/lib/urls';

type Props = StyleProps & { src?: string; alt?: string };

export function SignalCardImage({ src, alt, ...props }: Props): ReactElement {
  return (
    <Image
      src={coerceIntoExternalUrl(src ?? '')}
      alt={alt}
      maxH="252px"
      fallback={<EmptyFallbackImage />}
      borderRadius={8}
      {...(props as any)}
    />
  );
}
