import type { ReactElement } from 'react';
import { Flex, Modal, BodyText, Button } from '~/components';

type Props = {
  cancelText: string;
  message: string;
  okText: string;
  onCancel(): void;
  onClose(): void;
  onOK(): void;
  title: string;
  isSubmitting?: boolean;
};

export function ConfirmationModal({
  cancelText,
  isSubmitting,
  message,
  okText,
  onCancel,
  onClose,
  onOK,
  title,
}: Props): ReactElement {
  return (
    <Modal isOpen onClose={onClose} title={title} maxW="400px">
      <BodyText mt={-4}>{message}</BodyText>
      <Flex justify="flex-end" gap={2} mt={4} mb={-1}>
        {cancelText && (
          <Button variant="outline" onClick={onCancel}>
            {cancelText}
          </Button>
        )}
        <Button onClick={onOK} isLoading={isSubmitting}>
          {okText}
        </Button>
      </Flex>
    </Modal>
  );
}
