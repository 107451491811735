import type { UseDynamicWidgetHook } from '~/components/elements/Editor/plugins';
import { useState } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { BaseSelect, Box } from '~/components';
import { Analytics } from '~/lib/analytics';
import { usePostUrlQueryParams } from '~/lib/hooks';
import { isOutsideLiveCoreDataWindow } from '~/lib/date';
import { colors } from '~/styles';

const sortingOptions = [
  { label: 'Engagement (Highest to Lowest)', value: 'engagement' },
  { label: 'Date (Newest to Oldest)', value: 'recency' },
  { label: 'Influential Accounts (Most to Least)', value: 'influence' },
];

type Props = {
  useWidgetHook: UseDynamicWidgetHook;
};

export function PostsSidebarSorting({ useWidgetHook }: Props) {
  const [urlQueryParams, setUrlQueryParams] = usePostUrlQueryParams();
  const { params, setParams, widgetType, widgetId } = useWidgetHook();
  const isMVWidgetWithAccountSelected =
    widgetType === 'Highlight Map' &&
    widgetId === urlQueryParams.widgetId &&
    !!urlQueryParams.account_id;
  const [sorting, setSorting] = useState(
    urlQueryParams.sort
      ? sortingOptions.find((option) => option.value === urlQueryParams.sort) ??
          sortingOptions[0]
      : sortingOptions[0]
  );
  const gray4 = useColorModeValue(colors.warmGray[4], colors.coolGray[2]);

  const isSortable = !isOutsideLiveCoreDataWindow({
    startDate: urlQueryParams.start_date,
    endDate: urlQueryParams.end_date,
  });
  const currentSortValue = urlQueryParams.sort ?? sortingOptions[0].value;
  const dropdownOptions = isSortable
    ? sortingOptions
    : sortingOptions.filter((option) => option.value === currentSortValue);
  const widgetSpecificDropdownOptions = isMVWidgetWithAccountSelected
    ? [dropdownOptions.find((option) => option.value === 'recency')]
    : dropdownOptions;

  return (
    <Box px={2} borderBottom={`1px solid ${gray4}`}>
      <BaseSelect
        onChange={(e) => {
          setSorting(e);
          Analytics.event('postWidget', {
            action: 'sort',
            label: e.value,
          });
          setUrlQueryParams({ ...urlQueryParams, sort: e.value });
          setParams({ ...params, sort: e.value });
        }}
        value={sorting}
        options={widgetSpecificDropdownOptions}
      />
    </Box>
  );
}
