import { BrowserOptions } from '@sentry/nextjs';
const release = (
  (process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF as string) ??
  'local-dev-server'
).replaceAll('/', '-');

const environment =
  {
    main: 'production',
    staging: 'staging',
  }[release] ?? 'development';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export const sentryOptions: BrowserOptions = {
  dsn:
    SENTRY_DSN ||
    'https://687f518156f3423596438bde1aee9ec5@o4504877734756352.ingest.sentry.io/4504877737246720',
  tracesSampleRate: environment === 'production' ? 1 : 0.05,
  release,
  environment,
  enabled:
    process.env.NEXT_PUBLIC_SENTRY_TRACKING === 'enabled' ||
    process.env.NODE_ENV === 'production',
};
