import type { BoxProps } from '@chakra-ui/react';
import type { MapInfo } from '~/types/graphika-types';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { Tag } from '@chakra-ui/react';
import { BodyText, Box, Flex, TitleHeader } from '~/components';
import { formatNumber } from '~/lib/numbers';
import { formatShortDate } from '~/lib/date';
import { colors } from '~/styles';

type Props = { map: MapInfo & { yPos: number } };

export function MapPopover({ map }: Props): ReactElement {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [positioning, setPositioning] = useState<BoxProps>();
  const hasDataCollectionRange =
    map?.data_collection_range?.start_time &&
    map?.data_collection_range?.end_time;
  useEffect(() => {
    const boundingClient = popoverRef.current?.getBoundingClientRect();
    const positioning: BoxProps =
      map.yPos - 45 + (boundingClient?.height ?? 0) > window.innerHeight
        ? { bottom: 2 }
        : { top: map.yPos - 40 };
    setPositioning(positioning);
  }, [map.yPos]);

  return (
    <Box
      position="fixed"
      {...positioning}
      left={400}
      bg={colors.black}
      borderRadius={16}
      p={4}
      pb={2}
      w="340px"
      ref={popoverRef}
    >
      <TitleHeader color={colors.white} mb={3}>
        {map.name}
      </TitleHeader>
      <Flex my={3} justify="space-between" align="flex-end">
        {hasDataCollectionRange && (
          <Box>
            <BodyText color={colors.white} fontWeight={600} mb={1}>
              Collection date range
            </BodyText>
            <Flex align="center">
              <BodyText color={colors.white}>
                {formatShortDate(
                  new Date(map.data_collection_range?.start_time ?? '')
                )}
                {` - `}
                {formatShortDate(
                  new Date(map.data_collection_range?.end_time ?? '')
                )}
              </BodyText>
            </Flex>
          </Box>
        )}
        {map.is_live && (
          <Tag bg={colors.status.green} color={colors.white} borderRadius={4}>
            Live
          </Tag>
        )}
      </Flex>
      <Flex mt={3} gap={4}>
        <Box w="60px">
          <BodyText color={colors.white} fontWeight={600}>
            Nodes
          </BodyText>
          <BodyText color={colors.white}>
            {formatNumber(map.node_count ?? 0)}
          </BodyText>
        </Box>
        <Box w="60px">
          <BodyText color={colors.white} fontWeight={600}>
            Clusters
          </BodyText>
          <BodyText color={colors.white}>
            {formatNumber(map.cluster_count ?? 0)}
          </BodyText>
        </Box>
        <Box w="60px">
          <BodyText color={colors.white} fontWeight={600}>
            Groups
          </BodyText>
          <BodyText color={colors.white}>
            {formatNumber(map.group_count ?? 0)}
          </BodyText>
        </Box>
      </Flex>
    </Box>
  );
}
