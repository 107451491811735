import { createStandaloneToast } from '@chakra-ui/react';
import { colors } from '~/styles';

export function createVoyagerToast() {
  return createStandaloneToast({
    defaultOptions: {
      position: 'bottom-right',
      status: 'error',
      containerStyle: {
        color: colors.black,
      },
    },
  });
}
