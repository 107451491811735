import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ReactNode, useCallback } from 'react';
import { Box, Icon } from '~/components';
import SquarePlusIcon from '~/public/icons/font-awesome/square-plus.svg';
import { widgets } from '../widgets';
import { INSERT_DYNAMIC_WIDGET_COMMAND } from './DynamicWidgetNode';

type Props = {
  itemsBefore?: () => ReactNode[];
  itemsAfter?: () => ReactNode[];
};
export function DynamicWidgetToolbarButton(props: Props) {
  const [editor] = useLexicalComposerContext();
  const onInsertWidget = useCallback(
    (name: string) => {
      editor.dispatchCommand(INSERT_DYNAMIC_WIDGET_COMMAND, {
        name,
        initialParams: {},
        initialCaption: '',
        initialPresets: {},
      });
    },
    [editor]
  );

  return (
    <Box w="26px" overflow="hidden">
      <Menu variant="text-editor">
        <MenuButton>
          <Box transform="translate(3px,5px)">
            <Icon icon={SquarePlusIcon} boxSize={9} />
          </Box>
        </MenuButton>
        <MenuList py={4} borderRadius={16}>
          {props.itemsBefore?.()}
          {Object.entries(widgets).map(([name, config]) => (
            <MenuItem
              key={name}
              onClick={() => onInsertWidget(name)}
              fontSize="14px"
              p={4}
              h="34px"
            >
              {config._name}
            </MenuItem>
          ))}
          {props.itemsAfter?.()}
        </MenuList>
      </Menu>
    </Box>
  );
}
